import { Stack } from "@mui/material";
import KotoStepper from "@common/SignUpNew/KotoStepper";
import BusinessDetailsStep from "./BusinessDetailsStep";
import BusinessAddressStep from "./BusinessAddressStep";
import BusinessOwner from "./BusinessOwner";
import MerchantInfo from "./MerchantInfo";
import { HiddenComponent } from "@containers/HiddenComponent";
import useBusinessProfileSteps from "./hooks/useBusinessProfileSteps";
import { checkPortals } from "@utils/routing";
import EnterpriseInfo from "./EnterpriseInfo";
import { generateNameLabelPlaceholder } from "@components/Signup/Forms/SignupOrganizationDetails";
import { useRef } from "react";
import useBusinessDetailsData, {
  TSetFormValues,
} from "./hooks/useBusinessDetailsData";
import { DynamicReturnType } from "../BusinessProfileSetup/helpers/refineData";
import { useCalculatePercentage } from "@common/SignUpNew/useCalculatePercentage";
import { IFileWithMeta } from "react-dropzone-uploader";
import {
  TBusinessOwner,
  TMerchantDocument,
} from "@components/Merchants/MerchantPreview/data.types";

type TData = {
  merchant: any;
  legalEntity: any;
};

type Props = {
  backLink: () => void;
  data?: TData;
  parsedData: DynamicReturnType;
  warningMessage: string | null;
  isIncomplete: boolean;
  completionStatus: Record<
    string,
    { isComplete: boolean; tabName: string }
  > | null;
};

const REQUIRED_HELPER_TEXT = "Required for business verification";

export type TBusinessStepsCommons = {
  submitHandler: TSetFormValues;
  handleBack: () => void;
  statusBar: number;
  updateStatusBar: (v: number) => void;
  isSubmitting: boolean;
};

const BusinessProfileSetupNew = ({
  backLink,
  data,
  parsedData,
  warningMessage,
  isIncomplete,
  completionStatus,
}: Props) => {
  const {
    businessDetails,
    businessAddress,
    businessOwners,
    merchantInfo,
    enterpriseInfo,
  } = parsedData || {};
  const forceSubmitHandlerRef = useRef<any>(null);
  const { isEnterprisePortal, isMerchantPortal } = checkPortals();

  const { calculatePercentageWithoutSchema } = useCalculatePercentage({
    isEdit: false,
  });

  const legalEntityId = data?.merchant?.legalEntityID;

  const canEdit = data?.legalEntity
    ? typeof data.legalEntity?.canEdit === "boolean"
      ? data.legalEntity?.canEdit
      : true
    : true;

  const isSoleProprietorship =
    data?.legalEntity?.type?.name === "individual_sole_proprietorship";

  const providerPrimaryAccoundHolderData = isEnterprisePortal
    ? enterpriseInfo?.primaryAccountHolder
    : undefined;

  const businessOwnerDefaultValues = getBusinessOwnerDefaultValues({
    isSoleProprietorship: isSoleProprietorship,
    primaryAccountHolder: providerPrimaryAccoundHolderData,
  }) as any;

  const allSteps = getAllSteps(data?.merchant?.class?.name);

  const steps = getDefaultSteps(
    allSteps,
    isEnterprisePortal,
    parsedData,
    calculatePercentageWithoutSchema,
    businessOwnerDefaultValues,
    ["id", "pepStatusName"], // We need this for business owner to be ignored while calculation
  );

  const defaultStep = allSteps.BUSINESS_DETAILS;

  const getHelperText = (value: string) =>
    !value && isMerchantPortal ? REQUIRED_HELPER_TEXT : undefined;

  const {
    stepperConfig,
    handleUpdateStatusValue,
    statusBar,
    step,
    isLoaded,
    handleBack,
    handleNext,
  } = useBusinessProfileSteps(steps, defaultStep);

  const { setFormValues, isLoading: isSubmitting } = useBusinessDetailsData({
    legalEntityId,
  });

  const submitHandler: TSetFormValues = (section, values, options) => {
    const nextHandler =
      section === "enterpriseInfo" || section === "merchantInfo"
        ? backLink
        : handleNext;

    if (options?.forbidRedirect) {
      if (!options?.makeApiCall) {
        options?.handleNext && options.handleNext(options?.param);
        return;
      }

      setFormValues(section, values, {
        ...options,
      });
      return;
    }

    if (!options?.makeApiCall) {
      nextHandler();
      return;
    }

    setFormValues(section, values, {
      ...options,
      handleNext: nextHandler,
    });
  };

  const kotoStepClickHandler = (cb: any, param: any) => {
    if (forceSubmitHandlerRef.current) {
      forceSubmitHandlerRef.current.execute(cb, param);
    }
  };

  const commonProps: Record<string, TBusinessStepsCommons> = {
    [allSteps.BUSINESS_DETAILS]: {
      handleBack: backLink,
      submitHandler,
      statusBar: statusBar[0]?.barValue || 0,
      updateStatusBar: handleUpdateStatusValue(allSteps.BUSINESS_DETAILS),
      isSubmitting,
    },
    [allSteps.BUSINESS_ADDRESS]: {
      handleBack,
      submitHandler,
      statusBar: statusBar[1]?.barValue || 0,
      updateStatusBar: handleUpdateStatusValue(allSteps.BUSINESS_ADDRESS),
      isSubmitting,
    },
    [allSteps.BUSINESS_OWNER]: {
      handleBack,
      submitHandler,
      statusBar: statusBar[2]?.barValue || 0,
      updateStatusBar: handleUpdateStatusValue(allSteps.BUSINESS_OWNER),
      isSubmitting,
    },
    [allSteps.MERCHANT_INFO]: {
      handleBack,
      submitHandler,
      statusBar: statusBar[3]?.barValue || 0,
      updateStatusBar: handleUpdateStatusValue(allSteps.MERCHANT_INFO),
      isSubmitting,
    },
    [allSteps.ENTERPRISE_INFO]: {
      handleBack,
      submitHandler,
      statusBar: statusBar[3]?.barValue || 0,
      updateStatusBar: handleUpdateStatusValue(allSteps.ENTERPRISE_INFO),
      isSubmitting,
    },
  };

  const components = [
    {
      node: (
        <BusinessDetailsStep
          {...commonProps[allSteps.BUSINESS_DETAILS]}
          canEdit={canEdit}
          legalEntityId={legalEntityId}
          data={businessDetails}
          ref={forceSubmitHandlerRef}
          warningMessage={warningMessage}
          isIncomplete={isIncomplete}
          getHelperText={getHelperText}
        />
      ),
      visible: step === allSteps.BUSINESS_DETAILS,
    },
    {
      node: (
        <BusinessAddressStep
          {...commonProps[allSteps.BUSINESS_ADDRESS]}
          canEdit={canEdit}
          legalEntityId={legalEntityId}
          data={businessAddress}
          ref={forceSubmitHandlerRef}
          warningMessage={warningMessage}
          isIncomplete={isIncomplete}
          getHelperText={getHelperText}
        />
      ),
      visible: step === allSteps.BUSINESS_ADDRESS,
    },
    {
      node: (
        <BusinessOwner
          {...commonProps[allSteps.BUSINESS_OWNER]}
          canEdit={
            data?.legalEntity
              ? typeof data.legalEntity.canEdit === "boolean"
                ? data.legalEntity.canEdit
                : true
              : false
          }
          data={businessOwners}
          businessType={data?.legalEntity?.type?.name}
          ref={forceSubmitHandlerRef}
          warningMessage={warningMessage}
          isLegalEntityCreated={!!data?.legalEntity}
          isIncomplete={isIncomplete}
          getHelperText={getHelperText}
          isSoleProprietorship={isSoleProprietorship}
          businessOwnerDefaultValues={businessOwnerDefaultValues}
        />
      ),
      visible: step === allSteps.BUSINESS_OWNER,
    },
    {
      node: (
        <MerchantInfo
          {...commonProps[allSteps.MERCHANT_INFO]}
          data={merchantInfo}
          ref={forceSubmitHandlerRef}
          warningMessage={warningMessage}
          isIncomplete={isIncomplete}
          getHelperText={getHelperText}
        />
      ),
      visible: step === allSteps.MERCHANT_INFO && !isEnterprisePortal,
    },
    {
      node: (
        <EnterpriseInfo
          {...commonProps[allSteps.ENTERPRISE_INFO]}
          data={enterpriseInfo}
        />
      ),
      visible: step === allSteps.ENTERPRISE_INFO && isEnterprisePortal,
    },
  ];

  return (
    <Stack direction="column" justifyContent="stretch" height="inherit">
      <Stack direction="row" alignItems="center" gap={2}>
        <KotoStepper
          sx={{ flexGrow: 1 }}
          {...stepperConfig}
          alwaysEnable
          kotoStepClickHandler={kotoStepClickHandler}
          completionStatus={completionStatus}
        />
      </Stack>
      {components.map(({ node, visible }, index) => (
        <HiddenComponent key={index} hidden={!visible || !isLoaded}>
          {node}
        </HiddenComponent>
      ))}
    </Stack>
  );
};

export default BusinessProfileSetupNew;

const getDefaultSteps = (
  allSteps: Record<string, string>,
  isEnterprisePortal: boolean,
  parsedData: DynamicReturnType,
  calculatePercentage: (values: object, ignoredFields?: string[]) => number,
  defaultValues: any,
  ignoredFields?: string[],
) => {
  const businessSteps = [
    {
      step: allSteps.BUSINESS_DETAILS,
      values: parsedData?.businessDetails,
      ignoredFields: null,
    },
    {
      step: allSteps.BUSINESS_ADDRESS,
      values: parsedData?.businessAddress,
      ignoredFields: null,
    },
    {
      step: allSteps.BUSINESS_OWNER,
      values: !parsedData?.businessOwners
        ? [defaultValues]
        : parsedData.businessOwners,
      ignoredFields: ignoredFields,
    },
  ];
  const merchantStep = [
    {
      step: allSteps.MERCHANT_INFO,
      values: parsedData?.merchantInfo,
      ignoredFields: null,
    },
  ];
  const enterpriseStep = [
    {
      step: allSteps.ENTERPRISE_INFO,
      values: parsedData?.enterpriseInfo,
      ignoredFields: null,
    },
  ];

  const steps = [
    ...businessSteps,
    ...(isEnterprisePortal ? enterpriseStep : merchantStep),
  ];

  return steps.map(({ step, values, ignoredFields }) => ({
    label: step,
    barValue: !isEnterprisePortal
      ? calculatePercentage(values, ignoredFields || [])
      : 0,
  }));
};

const getAllSteps = (merchantDisplayInfo: string) => {
  const displayName = generateNameLabelPlaceholder(merchantDisplayInfo);
  const allSteps: Record<string, string> = {
    BUSINESS_DETAILS: "Business details",
    BUSINESS_ADDRESS: "Business address",
    BUSINESS_OWNER: "Business owner",
    MERCHANT_INFO: `${displayName} Info`,
    ENTERPRISE_INFO: `${displayName} Info`,
  };
  return allSteps;
};

const getBusinessOwnerDefaultValues = ({
  isSoleProprietorship = false,
  primaryAccountHolder,
}: {
  isSoleProprietorship: boolean;
  primaryAccountHolder?: Partial<TBusinessOwner>;
}) => {
  return {
    firstName:
      isSoleProprietorship && primaryAccountHolder?.firstName
        ? primaryAccountHolder?.firstName
        : "",
    lastName:
      isSoleProprietorship && primaryAccountHolder?.lastName
        ? primaryAccountHolder?.lastName
        : "",
    email:
      isSoleProprietorship && primaryAccountHolder?.email
        ? primaryAccountHolder?.email
        : "",
    ssn: "",
    DOB:
      isSoleProprietorship && primaryAccountHolder?.dob
        ? (primaryAccountHolder?.dob as string)
        : "",
    contactPhone:
      isSoleProprietorship && primaryAccountHolder?.phone
        ? primaryAccountHolder?.phone
        : "",
    isNotUSResident: false,
    citizenship:
      isSoleProprietorship && primaryAccountHolder?.citizenship
        ? primaryAccountHolder?.citizenship
        : "",
    isNotResidentInCitizenshipCountry: false,
    countryOfResidence:
      isSoleProprietorship && primaryAccountHolder?.countryOfResidence
        ? primaryAccountHolder?.countryOfResidence
        : "",
    ownership: "10",
    useBusinessAddress: true,
    country: "US",
    street:
      isSoleProprietorship && primaryAccountHolder?.address?.name
        ? primaryAccountHolder?.address?.name
        : "",
    city:
      isSoleProprietorship && primaryAccountHolder?.address?.city
        ? primaryAccountHolder?.address?.city
        : "",
    state:
      isSoleProprietorship && primaryAccountHolder?.address?.state
        ? primaryAccountHolder?.address?.state
        : "",
    zip:
      isSoleProprietorship && primaryAccountHolder?.address?.zip
        ? primaryAccountHolder?.address?.zip
        : "",
    id: "",
    pepStatusName: "",
    files: {
      allFiles: [] as IFileWithMeta[] | TMerchantDocument[],
    },
  };
};
