import { showMessage } from "@common/Toast";
import { customInstance } from "@services/api";
import { useMutation, useQueryClient } from "react-query";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  selectConversationTopic,
  setConversationTopic,
} from "@redux/slices/conversations";

const markAsSolved = async ({
  merchantId,
  id,
}: {
  merchantId: number;
  id: number;
}) => {
  return customInstance({
    url: `/merchants/${merchantId}/underwriting-challenges/${id}/status`,
    method: "PATCH",
    data: {
      status: "closed",
      type: "enhanced_due_diligence",
    },
  });
};

const markAsSolvedRiskMonitor = async ({
  merchantId,
  id,
}: {
  merchantId: number;
  id: number;
}) => {
  return customInstance({
    url: `/merchants/${merchantId}/threads/${id}/status`,
    method: "PATCH",
    data: {
      statusName: "closed",
    },
  });
};

const useMarkAsSolved = ({
  merchantId,
  isRiskMonitor,
}: {
  merchantId: number;
  isRiskMonitor: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { queryObject } = useAppSelector(selectConversationTopic);
  const queryClient = useQueryClient();

  const onSuccess = () => {
    const paths = queryObject?.paths;
    const pathIndex = paths?.findIndex((item) => item.isConversation);
    const newPaths = [...paths];

    if (pathIndex !== -1) {
      newPaths[pathIndex] = {
        ...newPaths[pathIndex],
        hideInputs: ["module", "subject", "message"],
      };
    } else {
      newPaths.push({
        hideInputs: ["module", "subject", "message"],
        isConversation: true,
        pathName: "",
        avatars: [],
      });
    }
    dispatch(
      setConversationTopic({
        queryObject: {
          ...queryObject,
          paths,
        },
      }),
    );
    queryClient.invalidateQueries("fetch-activity-list");
    queryClient.refetchQueries("get-challenges");
  };

  const markAsSolvedMutation = useMutation(
    (id: number) => {
      return isRiskMonitor
        ? markAsSolvedRiskMonitor({ merchantId, id })
        : markAsSolved({ merchantId, id });
    },
    {
      onError: (err: unknown) => {
        const axiosError = err as any;
        const errorMessage = axiosError?.response?.data?.message;
        showMessage("Error", errorMessage || "Unable to change assignee");
      },
      onSuccess: () => {
        onSuccess();
      },
    },
  );

  return markAsSolvedMutation;
};

export default useMarkAsSolved;
