export const TEST_VALUE = 10;
export const DRAWER_OPEN_WIDTH = 244;
export const DRAWER_CLOSED_WIDTH = 80;
export const SINGLE_KB = 1024;
export const IMAGE_UPLOAD_SIZE_LIMIT = SINGLE_KB * SINGLE_KB * 5; // 5 mb
export const APP_BAR_HEIGHT = 40;

export const userVerificationSteps = {
  toBeVerified: "TO_BE_VERIFIED",
  methodSelection: "METHOD_SELECTION",
  codeInput: "CODE_INPUT",
  verified: "VERIFIED",
  idle: "IDLE",
  moneyTransferDetails: "MONEY_TRANSFER_DETAILS",
  moneyTransferSuccess: "MONEY_TRANSFER_SUCCESS",
} as const;

export const DESCRIPTOR_PREFIX = "GIV";

export const CLOUD_FRONT_DOMAIN = "cloudfront.net";
export const AWS_FRONT_DOMAIN = "amazonaws.net";
export const MINIMUM_REQUIRED_AGE = 18;
export const MAXIMUM_REQUIRED_AGE = 100;
export const PASSWORD_MIN_CHARS = 12;
export const SLUG_MAX_CHARACTER_LENGTH = 128;
export const BILLING_DESCRIPTOR_MAX_LENGTH = 21;
export const ACCEPTED_IMAGE_FORMATS = "image/png, image/jpeg, image/jpg";
export const MAX_TEXT_LENGTH = 1600;
export const ORANGE_TEXT_THRESHOLD = 50;
export const MERCHANT_PROVIDER_MAX_CHARS = 128;

export const durations: Record<string, any> = {
  "One-Time": ["Lifetime"],
  Monthly: ["3 months", "6 months", "12 months", "24 months", "Every Month"],
  Quarterly: [
    "3 months",
    "6 months",
    "12 months",
    "24 months",
    "Every Quarter",
  ],
  Yearly: ["3 years", "5 years", "10 years", "Every Year"],
};
export const ANY_AMOUNT_MIN = 1;
export const ANY_AMOUNT_MAX = 25000;
export const ANY_AMOUNT_MIN_STR = "1.00";
export const ANY_AMOUNT_MAX_STR = "25000.00";

export const VIDEO_BASE_URL = `${process.env.REACT_APP_ASSETS_CDN_HOST}`;
export const BACKGROUND_VIDEO_PRIMARY = `${VIDEO_BASE_URL}/background.mp4`;
export const START_CAMPAIGN_BACKGROUND_VIDEO = `${VIDEO_BASE_URL}/Givebox-second-video.mp4`;
export const MANAGE_MONEY_PROCESSING_VIDEO = `${VIDEO_BASE_URL}/-4476-4d78-89a2-85cc2d1d3bd0.mp4`;
export const TERMS_OF_SERVICE_URL = "/terms_of_service";
export const PRIVACY_POLICY_URL = "https://www.givecorporation.com/privacy/";

export const navigationKeys = [
  "ArrowDown",
  "ArrowUp",
  "ArrowLeft",
  "ArrowRight",
  "Enter",
  "Escape",
  "Backspace",
  "Delete",
  "Tab",
];

export const AMOUNTS_REGION = "en-US";

export const DOMAIN_NAME = "givepayments.com";
export const isValidEmailRegex =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/; // eslint-disable-line

export const shouldBeHidden = {
  merchantAgreement: false,
  conversations: false,
  hideForPresentation: true,
  riskProfile: false,
  refund: false,
  transferMoney: false,
  welcomePage: true,
  customersLocations: true,
  customPermission: false,
  enterpriseConfiguration: false,
  deescalationLevel: true,
  pushNotificationSettings: false,
  changeLog: false,
  settlement: false,
  HIDE_CODE_ICON_FF: true,
};

export const BANK_ACCOUNT_NUMBER_REGEX = /^[0-9]{8,17}$/;
