import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  ErrorType_bulk_merchant,
  TImportedRow,
  TInviteElement,
  TInvitesMap,
} from "../types";
import NiceModal from "@ebay/nice-modal-react";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { getSelectedKeys, invitesSorter } from "../utils";
import {
  ADD_INVITE_MODAL,
  DELETE_CONFIRMATION_MODAL,
  EDIT_INVITE_MODAL,
  SEND_INVITATIONS_MODAL,
} from "modals/modal_names";
import { BulkInviteActions } from "../components/panel.atoms";
import useSorting from "@hooks/Reducers/useSorting";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  saveInvitationsDraft,
  selectInvitationsDraft,
} from "@redux/slices/enterprise/merchants";
import { useGetCurrentMerchantId } from "@hooks/common";
import { removeSpecialChars } from "@utils/slug";
import { SLUG_MAX_CHARACTER_LENGTH } from "@constants/constants";
import { checkPortals } from "@utils/routing";
import { useMutation, useQuery } from "react-query";
import { customInstance } from "@services/api";
import { showMessage } from "@common/Toast";
import { isEmpty, uniqBy } from "lodash";
import { MAXIMUM_NUMBER_OF_MERCHANTS_TO_INVITE } from "../constants";
// /accounts/1/background-tasks
type TModifyHandler = (key: string, value: TInviteElement) => void;
type TValuesCount = Record<string, string[]>;

const useBulkInvite = (initialProviderId: number) => {
  const mapRef = useRef<TInvitesMap>(new Map());
  const [isLoading, setIsLoading] = useState(false);
  const [isInvitingMerchants, setIsinvitingMerchants] = useState(false);
  const [providerId, setProviderId] = useState(initialProviderId);
  const [invitesList, setInvitesList] = useState(() =>
    Array.from(mapRef.current.entries()),
  );
  const theEntireList = Array.from(mapRef.current.values());
  const allCheckedHaveError = theEntireList
    .filter((item: any) => item.checked)
    .every((item: any) => item.errorType !== "");
  const totalSelected = useRef<number>(0);
  const areAllSelected = totalSelected.current === mapRef.current.size;
  const { isEnterprisePortal, isAcquirerPortal } = checkPortals();
  const dispatch = useAppDispatch();
  const { onClose } = useNiceModal();
  const { isMobileView } = useCustomTheme();
  const { merchantId } = useGetCurrentMerchantId();

  const savedInvitesDraft = useAppSelector((state) =>
    selectInvitationsDraft(state, merchantId),
  );

  const { attribute, order, toggleSorting } = useSorting({
    tableName: "merchant-invite",
  });

  const { isLoading: isFetchingListLoading, refetch } = useQuery(
    ["check-bulk-merchant-invites-background", providerId],
    async () => {
      const task = await fetchTasks(providerId);

      if (task?.status === "running") {
        setIsinvitingMerchants(true);
        return []; // Exit early if task is still running
      }

      if (task?.readAt) {
        setIsinvitingMerchants(false);
        mapRef.current = new Map(); // Reset the map when task is read
        return []; // Exit early if task is already read
      }
      const getList =
        task?.id && (await fetchTaskDetails(providerId, task?.id));

      task?.id &&
        (await customInstance({
          url: `/accounts/${providerId}/background-tasks/read`,
          method: "PUT",
          data: { taskIDs: [task?.id] },
        }));

      return getList || [];
    },
    {
      async onSuccess(data) {
        const { success: successfulMerchants, Failures: failedMerchants } =
          data?.result || {};

        if (!isEmpty(successfulMerchants)) {
          const acceptedMerchantsArray = successfulMerchants?.map(
            (merchant: any) => merchant?.merchantName,
          );
          await deleteEntriesByKeys(acceptedMerchantsArray);
        }
        if (!isEmpty(failedMerchants)) {
          await mapInit(() => {
            failedMerchants?.forEach((payloadObject: any) => {
              const email = payloadObject?.ownerEmail;
              const newEntry: TInviteElement = {
                ...payloadObject,
                checked: false,
                errorType: ErrorType_bulk_merchant.MERCHANT_NAME_EXISTS, //to read from api when ready,
                merchantName: payloadObject?.merchantName,
                pahEmail: email,
              };
              const key = generateKey(newEntry.merchantName);
              if (mapRef.current.has(key)) {
                // Merge with the existing entry in the map
                mapRef.current.set(key, {
                  ...mapRef.current.get(key), // Keep the existing properties
                  ...newEntry, // Update with new data
                });
              } else {
                // If no existing entry, set the new entry in the map
                mapRef.current.set(key, newEntry);
              }
            });
            const selectedItems = theEntireList.filter((item) => item.checked);
            totalSelected.current = selectedItems.length;
          });
        }

        const selectedItems = Array.from(mapRef.current.values()).filter(
          (item) => item.checked,
        );
        totalSelected.current = selectedItems.length;
        updateList();
      },
      enabled: isEnterprisePortal || (isAcquirerPortal && Boolean(providerId)),
    },
  );

  const { mutateAsync } = useMutation((data: any) => {
    return customInstance({
      url: "/merchants/bulk-check",
      method: "POST",
      data,
    });
  });

  // count items with the same name to check for duplicates
  // if valuesCount[merchantName].length === 1 the value is unique
  const valuesCount = useMemo(
    () =>
      invitesList.reduce((acc: TValuesCount, [key, value]) => {
        const keyValue = value.merchantName;
        if (!keyValue) return acc;
        if (!acc[keyValue]) {
          acc[keyValue] = [];
        }
        acc[keyValue].push(key);
        return acc;
      }, {}),
    [invitesList],
  );
  const entryIsUnique = (merchantName: string) =>
    !!merchantName && valuesCount[merchantName]?.length === 1;

  const checkIsUnique = (newName: string, originalName?: string) => {
    if (originalName && newName === originalName) {
      return valuesCount[newName]?.length < 2;
    } else if (originalName) {
      const total = valuesCount[newName];
      return !total || total?.length < 1;
    } else {
      return !valuesCount[newName];
    }
  };
  const showMaximumError = () =>
    showMessage(
      "Warning",
      `A maximum of ${MAXIMUM_NUMBER_OF_MERCHANTS_TO_INVITE} merchants can be invited each time`,
    );
  const updateList = useCallback(() => {
    const list = Array.from(mapRef.current.entries());
    const newList = attribute
      ? list.sort(invitesSorter(attribute, order))
      : list;

    setInvitesList(newList);
  }, [attribute, order]);

  useEffect(() => {
    updateList();
  }, [attribute, order]);

  const mapInit = async (callback: () => void) => {
    setIsLoading(true);
    // handle initialization asynchronously
    return new Promise((resolve) => {
      callback();
      updateList();
      resolve(undefined);
    }).finally(() => setIsLoading(false));
  };

  // Function to generate a lowercase key based on the merchantName
  const generateKey = (merchantName: string) => merchantName.toLowerCase();

  const deleteEntriesByKeys = async (keys: string[]) => {
    if (!keys.length) return; // Early return if no keys provided
    await mapInit(() => {
      keys.forEach((key) => {
        const lowercaseKey = generateKey(key); // Ensure key is lowercase
        mapRef.current.delete(lowercaseKey); // Delete the entry from the map
      });

      // Update selected items logic if needed
      const selectedItems = theEntireList.filter((item) => item.checked);
      totalSelected.current = selectedItems.length;
    }).then(() => updateList());
  };

  const importedRowsInit = async (arr: TImportedRow[]) => {
    setIsLoading(true);
    const initialEntries = cleanArray(arr)?.slice(
      0,
      MAXIMUM_NUMBER_OF_MERCHANTS_TO_INVITE,
    );

    if (arr?.length > MAXIMUM_NUMBER_OF_MERCHANTS_TO_INVITE) showMaximumError();

    const validatedList = await mutateAsync({ merchants: initialEntries });

    await mapInit(() => {
      validatedList?.results?.forEach((entry: any) => {
        const email = entry?.ownerEmail;
        const newEntry: TInviteElement = {
          ...entry,
          checked: entry?.isValid && emailPattern.test(email || ""),
          errorType: !entry?.isValid
            ? ErrorType_bulk_merchant.MERCHANT_NAME_EXISTS
            : !email
            ? ErrorType_bulk_merchant.EMAIL_REQUIRED
            : "",
          merchantName: entry?.name,
          pahEmail: email,
        };

        // Generate a lowercase key for the merchantName
        const key = generateKey(newEntry.merchantName);

        // Check if the map already has this merchantName (case-insensitive match)
        if (mapRef.current.has(key)) {
          // Merge with the existing entry in the map
          mapRef.current.set(key, {
            ...mapRef.current.get(key), // Keep the existing properties
            ...newEntry, // Update with new data
          });
        } else {
          // If no existing entry, set the new entry in the map
          mapRef.current.set(key, newEntry);
        }
      });

      // Handle selected items logic
      const selectedItems = theEntireList.filter((item) => item.checked);
      totalSelected.current = selectedItems.length;
    });
    updateList();
    setIsLoading(false);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (savedInvitesDraft.length > 0) {
      setIsLoading(true);
      // delay init to handle animations
      timeout = setTimeout(() => {
        mapInit(() => {
          mapRef.current = new Map(savedInvitesDraft);
          totalSelected.current = savedInvitesDraft.reduce(
            (acc, [key, value]) => {
              if (value.checked) return (acc += 1);
              return acc;
            },
            0,
          );
        });
      }, 400);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
      dispatch(
        saveInvitationsDraft({
          merchantId,
          entries: Array.from(mapRef.current.entries()),
        }),
      );
    };
  }, []);

  const setInvite: TModifyHandler = (key, value) => {
    mapRef.current.set(key, value);
    updateList();
  };

  const onAddInvite = () => {
    NiceModal.show(ADD_INVITE_MODAL, {
      onSubmit: async (newEntries: TImportedRow[]) => {
        importedRowsInit(newEntries);
      },
      listItems: theEntireList,
    });
  };

  const confirmRemoval = (cb: VoidFunction, totalItems?: string) => {
    NiceModal.show(DELETE_CONFIRMATION_MODAL, {
      variant: "merchantInvitation",
      itemName: totalItems,
      deleteHandler: cb,
    });
  };

  const editInvite: TModifyHandler = (key, currentValue) => {
    NiceModal.show(EDIT_INVITE_MODAL, {
      merchantName: currentValue.merchantName,
      pahEmail: currentValue.pahEmail,
      checkIsUnique,
      handleDelete: () => confirmRemoval(() => removeInvite(key)),
      handleSubmit: async (newValue: Partial<TInviteElement>) => {
        mapRef.current.delete(key);
        importedRowsInit([
          {
            ...currentValue,
            merchantName: newValue?.merchantName || "",
            pahEmail: newValue?.pahEmail || "",
          },
        ]);
      },
    });
  };

  const toggleSelectItem = (key: string) => {
    const prevItem = mapRef.current.get(key);
    if (!prevItem) return;
    setInvite(key, { ...prevItem, checked: !prevItem.checked });

    if (prevItem.checked) {
      totalSelected.current -= 1;
    } else {
      totalSelected.current += 1;
    }
  };

  const toggleSelectAll = () => {
    Array.from(mapRef.current.keys()).forEach((key) => {
      const prevItem = mapRef.current.get(key);
      if (prevItem) {
        mapRef.current.set(key, { ...prevItem, checked: !areAllSelected });
      }
    });
    totalSelected.current = !areAllSelected ? mapRef.current.size : 0;
    updateList();
  };

  const removeInvite = (key: "all" | string | string[]) => {
    if (key === "all") {
      // remove all
      mapRef.current = new Map();
      totalSelected.current = 0;
    } else if (Array.isArray(key)) {
      // remove bulk
      key.forEach((k) => mapRef.current.delete(k));
      totalSelected.current -= key.length;
    } else {
      // remove single item
      mapRef.current.delete(key);
      totalSelected.current -= 1;
    }
    updateList();
  };

  const deleteSelected = () => {
    if (totalSelected.current === 0) return;

    if (totalSelected.current === mapRef.current.size) {
      removeInvite("all");
    } else if (totalSelected.current === 1) {
      const itemKey = getSelectedKeys(mapRef.current, "single");
      !!itemKey && removeInvite(itemKey);
    } else {
      const itemKeys = getSelectedKeys(mapRef.current, "bulk");
      !!itemKeys && itemKeys.length > 0 && removeInvite(itemKeys);
    }
  };
  const { mutate, isLoading: isUploadLoading } = useMutation(
    (data: any) => {
      return customInstance({
        url: "/merchants/bulk-create-async",
        method: "POST",
        data,
      });
    },
    {
      async onSuccess(data) {
        const acceptedMerchantsArray = data?.success?.map(
          (merchant: any) => merchant?.merchantName,
        );
        const rejectedMerchantsArray = data?.failures;

        if (
          isEmpty(acceptedMerchantsArray) &&
          isEmpty(rejectedMerchantsArray)
        ) {
          dispatch(
            saveInvitationsDraft({
              merchantId: providerId,
              entries: [],
            }),
          );
          showMessage(
            "Info",
            "Merchants are being invited process not completed",
          );
          mapRef.current = new Map();
          const selectedItems = Array.from(mapRef.current.values()).filter(
            (item) => item.checked,
          );
          totalSelected.current = selectedItems.length;
          updateList();
          return setIsinvitingMerchants(true);
        }
        await deleteEntriesByKeys(acceptedMerchantsArray);
        await mapInit(() => {
          rejectedMerchantsArray?.forEach((payloadObject: any) => {
            const email = payloadObject?.ownerEmail;

            const newEntry: TInviteElement = {
              ...payloadObject,
              checked: false,
              errorType: ErrorType_bulk_merchant.MERCHANT_NAME_EXISTS, //to read from api when ready,
              merchantName: payloadObject?.merchantName,
              pahEmail: email,
            };
            const key = generateKey(newEntry.merchantName);
            if (mapRef.current.has(key)) {
              // Merge with the existing entry in the map
              mapRef.current.set(key, {
                ...mapRef.current.get(key), // Keep the existing properties
                ...newEntry, // Update with new data
              });
            } else {
              // If no existing entry, set the new entry in the map
              mapRef.current.set(key, newEntry);
            }
          });
          const selectedItems = theEntireList.filter((item) => item.checked);
          totalSelected.current = selectedItems.length;
        });
        updateList();
      },
    },
  );

  const handleSendInvitations = async () => {
    const listToInvite = theEntireList
      ?.filter((item) => item?.checked && item?.merchantName && item?.pahEmail)
      ?.map((merchant: any) => {
        return {
          name: merchant.merchantName,
          parentAccID: providerId,
          inviteOwner: true,
          slug: removeSpecialChars(
            merchant.merchantName,
            SLUG_MAX_CHARACTER_LENGTH,
          ),
          owner: { email: merchant?.pahEmail },
        };
      });
    mutate({
      merchants: listToInvite,
      type: "submerchant",
      signupType: isEnterprisePortal
        ? "enterprise_imported"
        : "acquirer_imported",
    });
  };

  const hasSelectedItems = theEntireList?.some((item) => item?.checked);

  const actions: BulkInviteActions[] = [
    {
      label: "Delete Selected",
      onSelect: () =>
        confirmRemoval(
          deleteSelected,
          areAllSelected ? "all" : `${totalSelected.current}`,
        ),
      hidden: !hasSelectedItems,
      disabled: isUploadLoading || isInvitingMerchants,
      labelProps: {
        sx: {
          color: palette.filled.red,
        },
      },
    },
    {
      label: "Cancel",
      onSelect: onClose,
      hidden: isMobileView,
      disabled: isUploadLoading || isInvitingMerchants,
    },
    {
      label: "Send Invitations",
      onSelect: () => {
        NiceModal.show(SEND_INVITATIONS_MODAL, {
          total: totalSelected.current,
          handleSubmit: handleSendInvitations,
        });
      },
      hidden: false,
      disabled:
        !hasSelectedItems ||
        !providerId ||
        isUploadLoading ||
        isInvitingMerchants ||
        allCheckedHaveError,
      tooltipProps: {
        show: !hasSelectedItems || !providerId || allCheckedHaveError,
        message: !providerId
          ? "Provider is missing"
          : allCheckedHaveError && hasSelectedItems
          ? "All merchants are invalid. Please check and edit merchant's data."
          : "Select the merchants to whom you want to send an invitation",
      },
    },
  ];

  return {
    actions,
    data: invitesList,
    areAllSelected,
    isLoading: isFetchingListLoading || isLoading,
    importedRowsInit,
    toggleSelectItem,
    toggleSelectAll,
    onAddInvite,
    editInvite,
    entryIsUnique,
    providerId,
    isUploadLoading,
    setProviderId,
    isInvitingMerchants,
    sorting: {
      attribute,
      order,
      toggleSorting,
    },
    handleCheckInviteDone: refetch,
  };
};

export default useBulkInvite;

const cleanArray = (arr: TImportedRow[]) => {
  const initialEntries = uniqBy(arr, "merchantName")?.map((item: any) => {
    const slug = removeSpecialChars(
      item.merchantName,
      SLUG_MAX_CHARACTER_LENGTH,
    );
    return {
      ...item,
      name: item.merchantName,
      merchantName: item.merchantName,
      slug,
      owner: {
        email: item?.pahEmail,
      },
    };
  });
  return initialEntries;
};

const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const fetchTasks = async (providerId: string | number) => {
  const { data: allTasks } = await customInstance({
    url: `/accounts/${providerId}/background-tasks`,
    method: "GET",
  });
  return allTasks?.find(
    (task: any) => Number(task?.accID) === Number(providerId),
  );
};

const fetchTaskDetails = async (
  providerId: string | number,
  taskId: string,
) => {
  return await customInstance({
    url: `/accounts/${providerId}/background-tasks/${taskId}`,
    method: "GET",
  });
};
