import { ChipProps } from "@mui/material";
import { getJsonValue } from "../utils";

declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    light: true;
    outline: true;
  }

  interface ChipPropsColorOverrides {
    default: true;
    success: true;
    warning: true;
    info: true;
    error: true;
  }
  interface ChipPropsSizeOverrides {
    small: true;
    large: true;
  }
}

type ThemeMode = "light" | "dark";

export const getLargeChipStyle = () => [
  {
    props: (props: ChipProps) => props.size === "large",
    style: {
      borderRadius: "40px",
      padding: "6px 16px",
    },
  },
];

export const getSmallChipStyle = () => [
  {
    props: (props: ChipProps) => props.size === "small",
    style: {
      borderRadius: "8px",
      padding: "4px 8px",
    },
  },
];

export const getDefaultStyles = (mode: ThemeMode) => [
  {
    props: (props: ChipProps) =>
      props.variant === "light" && props.color === "default",
    style: {
      backgroundColor: getJsonValue(`tokens.${mode}.colour.surface.tertiary`),
      color: getJsonValue(`tokens.${mode}.colour.text.primary`),
    },
  },
  {
    props: (props: ChipProps) =>
      props.variant === "outline" && props.color === "default",
    style: {
      backgroundColor: getJsonValue(`tokens.${mode}.colour.surface.tertiary`),
      color: getJsonValue(`tokens.${mode}.colour.text.primary`),
      border: `1px solid ${getJsonValue(
        `tokens.${mode}.colour.border.tertiary`,
      )}`,
    },
  },
];

export const getSuccessStyles = (mode: ThemeMode) => [
  {
    props: (props: ChipProps) =>
      props.variant === "light" && props.color === "success",
    style: {
      backgroundColor: getJsonValue(`tokens.${mode}.primitive.success.25`),
      color: getJsonValue(`tokens.${mode}.primitive.success.100`),
    },
  },
  {
    props: (props: ChipProps) =>
      props.variant === "outline" && props.color === "success",
    style: {
      backgroundColor: getJsonValue(`tokens.${mode}.primitive.success.25`),
      color: getJsonValue(`tokens.${mode}.primitive.success.100`),
      border: `1px solid ${getJsonValue(
        `tokens.${mode}.primitive.success.50`,
      )}`,
    },
  },
];

export const getWarningStyles = (mode: ThemeMode) => [
  {
    props: (props: ChipProps) =>
      props.variant === "light" && props.color === "warning",
    style: {
      backgroundColor: getJsonValue(`tokens.${mode}.primitive.warning.10`),
      color: getJsonValue(`tokens.${mode}.primitive.warning.100`),
    },
  },
  {
    props: (props: ChipProps) =>
      props.variant === "outline" && props.color === "warning",
    style: {
      backgroundColor: getJsonValue(`tokens.${mode}.primitive.warning.10`),
      color: getJsonValue(`tokens.${mode}.primitive.warning.100`),
      border: `1px solid ${getJsonValue(
        `tokens.${mode}.primitive.warning.50`,
      )}`,
    },
  },
];

export const getBlueStyles = (mode: ThemeMode) => [
  {
    props: (props: ChipProps) =>
      props.variant === "light" && props.color === "info",
    style: {
      backgroundColor: getJsonValue(`tokens.${mode}.primitive.blue.10`),
      color: getJsonValue(`tokens.${mode}.primitive.blue.100`),
    },
  },
  {
    props: (props: ChipProps) =>
      props.variant === "outline" && props.color === "info",
    style: {
      backgroundColor: getJsonValue(`tokens.${mode}.primitive.blue.10`),
      color: getJsonValue(`tokens.${mode}.primitive.blue.100`),
      border: `1px solid ${getJsonValue(`tokens.${mode}.primitive.blue.50`)}`,
    },
  },
];

export const getErrorStyles = (mode: ThemeMode) => [
  {
    props: (props: ChipProps) =>
      props.variant === "light" && props.color === "error",
    style: {
      backgroundColor: getJsonValue(`tokens.${mode}.primitive.error.25`),
      color: getJsonValue(`tokens.${mode}.primitive.error.100`),
    },
  },
  {
    props: (props: ChipProps) =>
      props.variant === "outline" && props.color === "error",
    style: {
      backgroundColor: getJsonValue(`tokens.${mode}.primitive.error.25`),
      color: getJsonValue(`tokens.${mode}.primitive.error.100`),
      border: `1px solid ${getJsonValue(`tokens.${mode}.primitive.error.50`)}`,
    },
  },
];
