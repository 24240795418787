import { TColumnNames, TColumnTranslations } from "../types";

type TSectionNames =
  | "summary"
  | "status"
  | "risk"
  | "customerInfo";
//TODO the commented out columns are temporary fix bc BE side is not implemented, will be fixed in a new task
export const RISK_MONITOR_TRANSACTIONS: TColumnNames<TSectionNames> = {
  titles: {
    summary: "Transaction Summary",
    status: "Transaction Status",
    risk: "Risk",
    customerInfo: "Customer Information",
  },
  visible: ["Date", "Amount", "Transaction Status", "Risk Level"],
  summary: [
    "Merchant Name",
    "Provider Name",
    "Transaction Id",
    "Transaction Type",
    // "Customer",
    "Name on Card",
    "Card Type",
    "Card Number Last4",
    "Email",
    "Amount",
    "Fees",
    "Charged",
    "Ending Balance",
  ],
  status: ["Transaction Status", "Blocked", "Email Status"],
  risk: [
    "Risk Level",
    "Reason of Escalation",
    // "Customer",
    "Name on Card",
    "Card Type",
    "Card Number Last4",
    "Risk Status Transaction",
    "Amount",
  ],
  customerInfo: [
    "Customer First Name",
    "Customer Last Name",
    "Customer Email",
    "Customer Date of Birth",
    "Customer Phone Number",
    "Customer Occupation",
    // "Customer Employer",
    "Customer City",
    "Customer State",
    "Customer Zip",
    "Customer Address",
    "Customer Notes",
    // "Customer total",
  ],
};

export const RISK_MONITOR_TRANSACTIONS_TRANSLATIONS: TColumnTranslations = {
  "Card Number Last4": "Card number",
  Blocked: "Blocked transaction",
  "Customer First Name": "First name",
  "Customer Last Name": "Last name",
  "Customer Email": "Email",
  "Customer Date of Birth": "Date of birth",
  "Customer Phone Number": "Phone number",
  "Customer Occupation": "Occupation",
  "Customer Employer": "Employer",
  "Customer City": "City",
  "Customer State": "State",
  "Customer Zip": "Zip",
  "Customer Address": "Address",
  "Customer Notes": "Notes",
  // "Customer total": "Sum total",
};
