import NiceModal from "@ebay/nice-modal-react";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { palette } from "@palette";
import {
  TSidePanelContent,
  useEnterprisePreview,
} from "./hooks/useEnterprisePreview";
import EnterprisePanelBody from "./components/EnterprisePanelBody";
import { useMobileDrawerActions } from "@components/MobileDrawerActions/hooks/useMobileDrawerActions";
import { HorizontalMoreIcon } from "@assets/icons/RebrandedIcons";
import MerchantPreviewLoadingSkeleton from "@components/Merchants/MerchantPreview/MerchantPreviewLoadingSkeleton";
import { isMobileSafari } from "@utils/helpers";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import { Box, Collapse, useMediaQuery, useTheme } from "@mui/material";
import { CAMPAIGN_PANEL_WIDTH } from "@common/CampaignCard/CampaignDetailsModal/useCampaignModal";
import ErrorCatcher from "@common/Error/ErrorCatcher";
import ManageUnderwritingChallenges from "@components/Merchants/MerchantPreview/manageUnderwriting/ManageUnderwritingChallenges";
import EnterpriseAgreement from "./components/EnterpriseAgreement";

import { OFACPanel as OFAC } from "@components/Merchants/MerchantPreview/OFAC";
import useUserReducer from "@hooks/Reducers/useUserReducer";
import PoliticallyExposed from "@components/Merchants/MerchantPreview/PEP";
import { shouldBeHidden } from "@constants/constants";
import Conversations from "features/Minibuilders/Conversations/Conversations";
import { checkPortals } from "@utils/routing";
import { useGetGlobalTopics } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { Pane1Minimised } from "@components/Merchants/MerchantPreview/Panel1Minimised/Panel1Minimised";
import { useEffect, useRef } from "react";
import { TMerchantDoublePanel } from "@components/Merchants/MerchantPreview/hooks/useMerchantPreview";

type Props = {
  id: number;
  isFirst?: boolean;
  isLast?: boolean;
  setSelectedRow?: (newIdx: string | number) => void;
  initialSection?: Extract<TMerchantDoublePanel, "ofac">;
};

const EnterprisePreviewPanel = NiceModal.create(
  ({
    id,
    isFirst = false,
    isLast = false,
    setSelectedRow,
    initialSection,
  }: Props) => {
    // Register conversation topics
    useGetGlobalTopics();
    const { isMobileView } = useCustomTheme();
    const theme = useTheme();
    const { role } = useUserReducer();
    const isAcquirer = role === "acquirer";
    const hasEnoughWidth = useMediaQuery(
      theme.breakpoints.up(CAMPAIGN_PANEL_WIDTH * 2),
    );

    const {
      data,
      handlers,
      actions,
      isLoading,
      isControlModeAllowed,
      doublePanel,
      setDoublePanel,
      closeFullPanel,
      open,
      SlideProps,
      pepOwnerID,
      merchant,
      panel1Collapsed,
      setPanel1Collapsed,
      OFACTab,
    } = useEnterprisePreview({
      id,
      setSelectedRow,
    });

    const isMounted = useRef<boolean>(false);

    useEffect(() => {
      if (initialSection && data && !isLoading && !isMounted.current) {
        setDoublePanel(initialSection);
        isMounted.current = true;
      }

      return () => {
        isMounted.current = false;
      };
    }, [data, isLoading]);

    const { isAcquirerPortal } = checkPortals();

    const { BottomActions } = useMobileDrawerActions();

    const mobileActions = actions.map(
      ({ onClick, hidden, title, disabled }) => ({
        onSelect: onClick,
        hidden,
        label: title,
        disabled: disabled || false,
        labelProps:
          title === "Delete Provider"
            ? {
                sx: {
                  color: palette.error.hover,
                },
              }
            : undefined,
      }),
    );

    const bodyProps = {
      id,
      data,
      handlers,
      actions,
      isControlModeAllowed,
      setDoublePanel,
      doublePanel,
      hasEnoughWidth,
      isAcquirerPortal: isAcquirerPortal,
      isFirst,
      isLast,
      panel1Collapsed,
      selectedPEPOwner: pepOwnerID,
    };

    const hidePanel1 = (doublePanel && !hasEnoughWidth) || panel1Collapsed;

    const sidePanelContent: TSidePanelContent = {
      manageUnderwriting: (
        <ManageUnderwritingChallenges
          merchantData={data}
          isEnterprise
          handleClosePanel={() => handlers.handleManageUnderwriting(false)}
          isAcquirer={isAcquirerPortal}
          headerData={{
            name: data?.header?.name,
            imageURL: data?.header?.imageURL,
          }}
          id={id}
        />
      ),
      MerchantAgreement: (
        <EnterpriseAgreement
          handleClosePanel={() => handlers.handleEnterpriseAgreement(false)}
          data={data}
        />
      ),
      ofac: (
        <OFAC
          headerData={{
            name: data?.header?.name,
            imageURL: data?.header?.imageURL,
          }}
          isAcquirer={isAcquirer}
          toggleSecondModal={() => handlers.handleOpenOFACPanel(false)}
          merchantId={id}
          legalEntityId={data?.businessProfile?.id}
          lastCheck={data?.businessProfile?.ofac}
          initialActiveTab={OFACTab}
          PAH={data?.primaryAccountHolder}
          businessOwners={data?.businessOwnersList}
          isEnterprisePanel={true}
        />
      ),
      pep: (
        <PoliticallyExposed
          selectedOwner={pepOwnerID}
          merchantId={data.merchantInfo.merchantID}
          legalEntityID={data.businessProfile.id}
          onClose={() => handlers.handleOpenPep(false)}
          isAcquirer={isAcquirer}
          isEnterprise
        />
      ),
    };

    return (
      <ModalFactory
        variant="sidepanel"
        modalProps={{
          open,
          onClose: closeFullPanel,
          SlideProps,
          SidePanelProps: {
            width:
              hasEnoughWidth && doublePanel && !panel1Collapsed
                ? CAMPAIGN_PANEL_WIDTH * 2
                : CAMPAIGN_PANEL_WIDTH,
          },
        }}
      >
        {isMobileView ? (
          <>
            {isLoading ? (
              <MerchantPreviewLoadingSkeleton />
            ) : (
              <Box
                width="100%"
                height="100%"
                display="flex"
                position="relative"
                sx={{
                  display: hidePanel1 ? "none" : "block",
                }}
              >
                <EnterprisePanelBody {...bodyProps} />

                <BottomActions
                  sx={{
                    position: "fixed",
                    "& .MuiPaper-root": {
                      padding: 0,
                      bottom: "100px",
                    },
                  }}
                  label={
                    <HorizontalMoreIcon
                      height={41}
                      width={41}
                      fill={palette.background.dimmedWhite}
                    />
                  }
                  items={mobileActions}
                  buttonSx={{
                    position: isMobileSafari() ? "absolute" : "fixed",
                    bottom: 0,
                    left: 0,
                    right: 0,
                  }}
                />

                <Box height={70} width="100%" />
              </Box>
            )}

            <Collapse in={!!doublePanel} orientation="horizontal">
              <Box
                bgcolor={palette.neutral[5]}
                padding="12px"
                display="flex"
                flex={1}
                height="100vh"
                width="95vw"
              >
                <ErrorCatcher errorID="enterprise-double-panel">
                  {doublePanel && sidePanelContent[doublePanel]}
                </ErrorCatcher>
              </Box>
            </Collapse>
          </>
        ) : (
          <Box width="100%" height="100%" display="flex">
            <Box
              height="100%"
              flex={1}
              sx={{
                display: hidePanel1 ? "none" : "block",
              }}
            >
              {isLoading ? (
                <MerchantPreviewLoadingSkeleton />
              ) : (
                <EnterprisePanelBody {...bodyProps} />
              )}
            </Box>

            <Collapse in={!!doublePanel} orientation="horizontal">
              <Box
                bgcolor={palette.neutral[5]}
                padding="12px 16px"
                flex={1}
                height="100vh"
              >
                <ErrorCatcher errorID="enterprise-double-panel">
                  {doublePanel && sidePanelContent[doublePanel]}
                </ErrorCatcher>
              </Box>
            </Collapse>
          </Box>
        )}
        {!shouldBeHidden.conversations && (
          <Conversations
            merchant={merchant}
            handleOpenManageUnderwriting={handlers?.handleManageUnderwriting}
            isLoading={isLoading}
          />
        )}
        {doublePanel && panel1Collapsed && (
          <Pane1Minimised
            image={merchant?.imageURL}
            onBack={() => {
              setPanel1Collapsed(false);
            }}
          />
        )}
      </ModalFactory>
    );
  },
);

export default EnterprisePreviewPanel;
