import { Text } from "@common/Text";
import { palette } from "@palette";
import { Stack } from "@mui/material";
import { DisputesTableTag } from "@common/Tag/DisputesTableTag";
import { Button } from "@common/Button";
import NiceModal from "@ebay/nice-modal-react";
import { RESPOND_TO_DISPUTE_MODAL } from "modals/modal_names";
import { Tooltip } from "@common/Tooltip";
import { WarningDiamond } from "@phosphor-icons/react";
import { TDisputeData } from "../data.types";
import { formatDistanceToNow, fromUnixTime } from "date-fns";
import { useMemo } from "react";

type Props = {
  data: TDisputeData;
};

const TitleSection = ({ data }: Props) => {
  const handleOpenModal = () => {
    NiceModal.show(RESPOND_TO_DISPUTE_MODAL);
  };

  const dueTime = useMemo(() => {
    const responseDueAt = data?.lastCase?.responseDueAt;

    if (!responseDueAt) return "";

    const dueDate = fromUnixTime(responseDueAt);

    return formatDistanceToNow(dueDate, { addSuffix: true });
  }, [data?.lastCase?.responseDueAt]);

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack gap={1}>
        <Stack direction="row" gap={1} alignItems="center">
          <Text
            fontSize={24}
            fontWeight="book"
            color={palette.neutral[80]}
            lineHeight="32.4px"
          >
            Dispute Case
          </Text>
          {data?.markedAsFraud && (
            <Tooltip
              placement="top"
              title="Marked as fraud"
              bgColor={palette.neutral[80]}
              titleSx={{
                fontSize: 12,
                fontWeight: 350,
              }}
              toolTipSx={{
                padding: "4px 16px",
                borderRadius: "8px",
                top: 8,
              }}
            >
              <WarningDiamond
                size={20}
                weight="duotone"
                color={palette.tag.warning.hover}
                data-testid="marked-as-fraud-sign"
              />
            </Tooltip>
          )}
        </Stack>
        <Stack direction="row" gap={1} alignItems="center">
          <DisputesTableTag
            type={data?.status}
            sx={{ minWidth: "auto !important" }}
          />
          <Text
            fontWeight="book"
            fontSize={12}
            color={palette.neutral[80]}
            lineHeight="14.4px"
          >
            Due {dueTime}
          </Text>
        </Stack>
      </Stack>
      <Button
        size="medium"
        sx={{ fontWeight: 400, lineHeight: "14px" }}
        onClick={handleOpenModal}
      >
        Respond to Dispute
      </Button>
    </Stack>
  );
};

export default TitleSection;
