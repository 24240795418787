import { RHFSelect } from "@common/Select";
import { useListAllEnterprises } from "@hooks/acquirer-api/merchants/useListAllEnterprises";
import { useListEnterpriseCategoryCodes } from "@hooks/enterprise-api/merchants/useListEnterpriseCategoryCodes";
import { useEffect, useMemo, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useAppSelector } from "@redux/hooks";
import { selectQueryString } from "@redux/slices/search";
import { QKEY_LIST_ENTERPRISES } from "@constants/queryKeys";
import NiceModal from "@ebay/nice-modal-react";
import { CHANGE_PROVIDER_MODAL } from "modals/modal_names";

type EnterpriseType = {
  accID: number;
  name: string;
};

type EnterprisesSelectProps = {
  id?: string;
  name: string;
  disabled?: boolean;
  slug?: string;
  category?: string;
  phone?: string;
  focusViewColor?: string;
  isConfirmModal?: boolean;
};

export const useInfiniteEnterprises = (arg?: { searchText?: string }) => {
  const searchText = useAppSelector((state) =>
    selectQueryString(state, QKEY_LIST_ENTERPRISES),
  );
  const [data, setData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const {
    data: pageData,
    isLoading,
    error,
  } = useListAllEnterprises({
    page: page,
    searchText: searchText,
  });
  const searchQueryRef = useRef(false);

  const BOTTOM_OFFSET_PX = 3;

  useEffect(() => {
    setPage(1);
    searchQueryRef.current = true;
  }, [searchText]);
  useEffect(() => {
    if (pageData?.data) {
      if (searchQueryRef.current) {
        setData([...(pageData?.data || [])]);
        searchQueryRef.current = false;
      } else {
        if (data.length === 0 && pageData?.data) {
          setData(pageData.data);
        } else if (data.length > 0) {
          setData((prev) => [...prev, ...pageData.data]);
        }
      }
    }
    if (pageData?.data === null && !isLoading) setData([]);
  }, [pageData]);

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    if (isLoading) return;
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    const isNearBottom =
      scrollHeight - (scrollTop + clientHeight) <= BOTTOM_OFFSET_PX;
    if (isNearBottom && pageData?.total > data.length) {
      setPage((prev) => prev + 1);
    }
  };

  return { handleScroll, data, isLoading, error };
};

const EnterprisesSelect = ({
  name,
  disabled,
  slug,
  category,
  phone,
  isConfirmModal,
  ...rest
}: EnterprisesSelectProps) => {
  const { setValue, watch } = useFormContext();
  const { data, handleScroll } = useInfiniteEnterprises();
  const {
    categories,
    isLoading,
    servicePhoneNumber,
    slug: enterpriseSlug,
  } = useListEnterpriseCategoryCodes(watch(name));

  const handleChangeEnterprise = (enterpriseID: string) => {
    setValue(name, enterpriseID, { shouldDirty: true, shouldValidate: true });

    if (slug && data) {
      setValue(slug, enterpriseSlug);
    }

    if (phone) {
      setValue(phone, servicePhoneNumber);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const enterpriseID = e.target.value;

    if (isConfirmModal) {
      NiceModal.show(CHANGE_PROVIDER_MODAL, {
        handleChange: () => handleChangeEnterprise(enterpriseID),
      });
    } else {
      handleChangeEnterprise(enterpriseID);
    }
  };

  useEffect(() => {
    if (slug && data) {
      setValue(slug, enterpriseSlug);
    }
  }, [enterpriseSlug]);

  const enterpriseOptions = useMemo(() => {
    if (!data) return [];
    return data.map((provider: EnterpriseType) => ({
      value: provider.accID,
      label: provider.name,
    }));
  }, [data]);
  useEffect(() => {
    if (
      category &&
      categories &&
      categories?.length > 0 &&
      !isLoading &&
      watch(name)
    ) {
      setValue(category, categories[0].id, {
        shouldValidate: true,
        shouldDirty: true,
      });
    } else if (category && categories?.length === 0) {
      setValue(category, null);
    }
  }, [isLoading, watch(name)]);

  return (
    <RHFSelect
      label="Provider"
      name={name}
      placeholder="Choose Merchant’s Provider"
      options={enterpriseOptions}
      onChange={handleChange}
      fullWidth
      disabled={disabled}
      onScroll={handleScroll}
      sxMenu={{ maxWidth: "300px" }}
      {...rest}
    />
  );
};

export default EnterprisesSelect;
