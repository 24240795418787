import { Stack, styled } from "@mui/material";
import { palette } from "@palette";
import NiceModal from "@ebay/nice-modal-react";
import {
  MerchantPreviewHeaderAction,
  TMerchantDoublePanel,
} from "./hooks/useMerchantPreview";
import MerchantHeader from "./components/MerchantHeader";
import MerchantPreviewHeader from "./Header";
import StatusReview from "./sections/StatusReview/StatusReview";
import { EditMerchantSection } from "./components/atoms";
import {
  EDIT_MERCHANT_INFO_MODAL,
  EDIT_PRIMARY_ACCOUNT_HOLDER,
  CREATE_BUSINESS_PROFILE_MODAL,
  UPDATE_PRIMARY_ACCOUNT_HOLDER_EMAIL,
} from "modals/modal_names";
import useGetSectionItems from "./hooks/useGetSectionItems";
import MerchantBankAccounts from "./components/BankAccounts/MerchantBankAccounts";
import MerchantStats from "./components/MerchantStats";
import DocumentsSection from "./components/DocumentsSection";
import MerchantFees from "./components/MerchantFees";
import { checkPortals } from "@utils/routing";
import MerchantBusinessOwners from "./components/BusinessOwners/MerchantBusinessOwners";
import { IParsedData } from "./data.types";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { WithMissingPermissionModule } from "@common/WithMissingPermissionModule";
import { useAppSelector } from "@redux/hooks";
import { Fragment, PropsWithChildren, useMemo } from "react";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import WarningPlaceholders from "./components/WarningPlaceholdersSection";
import MerchantAgreement from "./sections/MerchantAgreement/MerchantAgreement";
import MerchantPreviewBusinessProfile from "./components/MerchantPreviewBusinessProfile";
import RiskProfileAlert from "./components/RiskProfileAlert";
import { shouldBeHidden } from "@constants/constants";
import { Text } from "@common/Text";
import IdentityVerification from "./components/IdentityVerification";
import MerchantPrimaryAccountHolder from "./components/MerchantPrimaryAccountHolder";
import { selectMerchantPreview } from "@redux/slices/merchantPreview";
import ErrorCatcher from "@common/Error/ErrorCatcher";
import OFACPreviewSection from "./OFAC/components/OFACPreviewSection";
import MerchantOFACWarning from "./components/MerchantOFACWarning";
import { useGetGlobalTopics } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { EditBusinessHandler } from "../CreateMerchantPanel/modals/CreateBusinessProfileModal";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import MSPWarning from "./sections/MSPWarning";
import { EditMerchantHeaderHandlers } from "./helpers/types";
import { RiskSummary } from "./sections/StatusReview/RiskSummary";
import { UnderwritingReportSummary } from "./sections/StatusReview/UnderwritingReportSummary";
import { useComponentVisibilityOnStatus } from "./sections/StatusReview/hooks/useComponentVisibilityOnStatus";
import { useUnderwriterPermissions } from "features/Permissions/AccessControl/hooks";
import SponsorApproval from "./sections/SponsorApproval/SponsorApproval";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { ofacArraySorter } from "./helpers/parsers";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";
import { isOFACClear } from "./utils";
import { TPEPOwner } from "./PEP/types";
import MATCHPreviewSection from "./MATCH/MATCHPreviewSection";
import { useGetUserRole } from "@hooks/common/useGetUserRole";

interface PreviewModalBodyProps {
  id: number;
  data: IParsedData;
  isFirst: boolean;
  isLast: boolean;
  actions: MerchantPreviewHeaderAction[];
  handlers: EditMerchantHeaderHandlers;
  pendingEmail?: string;
  isControlModeAllowed: boolean;
  openedSecondPanel: TMerchantDoublePanel;
  selectedPEPOwner: TPEPOwner;
}

const PreviewModalBody = ({
  id,
  data,
  isFirst,
  isLast,
  actions,
  handlers,
  pendingEmail,
  isControlModeAllowed,
  openedSecondPanel,
  selectedPEPOwner,
}: PreviewModalBodyProps) => {
  useGetGlobalTopics();
  const { isDesktopView } = useCustomTheme();
  const { businessAddressItems, primaryAccountHolderItems, merchantInfoItems } =
    useGetSectionItems(data);
  const { isAcquirerPortal } = checkPortals();
  const {
    risk_monitoring,
    modify_merchant,
    merchant_underwriting,
    bank_account_linking,
    manage_bank_account,
    money_transfers,
  } = useEnterprisePermissions();

  const { isNewOfacTabsEnabled } = useGetFeatureFlagValues();
  const { isViewUnderwritingAllowed } = useUnderwriterPermissions();

  const {
    isUnderwritingReportVisible,
    isRiskSummaryVisible,
    isStatusOverviewVisible,
    status,
  } = useComponentVisibilityOnStatus({
    data: data.status,
  });

  const { showSponsorCardByRole } = useGetUserRole();

  const acquirerUpdateLegalEntityPermission = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.LEGAL_ENTITY,
    ),
    operation: OPERATIONS.UPDATE,
  });

  const legalEntityEditableByAcquirer = Boolean(
    isAcquirerPortal && acquirerUpdateLegalEntityPermission,
  );
  const editMerchantInfo = () =>
    NiceModal.show(EDIT_MERCHANT_INFO_MODAL, { id, data: data.merchantInfo });

  const editPrimaryAccountHolder = () =>
    NiceModal.show(EDIT_PRIMARY_ACCOUNT_HOLDER, {
      id,
      data: data.primaryAccountHolder,
      ownerAccId: data?.ownerId,
    });

  const editBusinessProfile: EditBusinessHandler = (section) =>
    NiceModal.show(CREATE_BUSINESS_PROFILE_MODAL, {
      merchantId: id,
      section,
      data: {
        ...data.businessProfile,
        address: data.businessAddress,
      },
      legalEntityID: data.businessProfile.id,
      isLegalEntityApproved:
        data.header.businessProfileStatus.status?.toLowerCase() === "approved",
      isEdit: true,
      isBPLinked: data.businessProfile?.isBPLinked,
    });

  const hasNotBankAccountsListPermission = useAppSelector(
    (state) => state.app.permissions?.bank_accounts_list,
  );

  const hasNotDocumentsListPermission = useAppSelector(
    (state) => state.app.permissions?.["list-documents"],
  );

  const hasNotMerchantAgreementPermission = useAppSelector(
    (state) => state.app.permissions?.["merchant-agreement"],
  );

  const hasNotMerchantDataPermission = useAppSelector(
    (state) => state.app.permissions?.merchant_data,
  );

  const hasNotPrincipalsPermission = useAppSelector(
    (state) => state.app.permissions?.[`get_merchant_${id}_principals`],
  );

  const hasEditPahPermission = useAccessControl({
    resource: composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.OWNER),
    operation: OPERATIONS.UPDATE,
  });

  const { isSponsorApprovalEnabled } = useGetFeatureFlagValues();

  const isSponsorApproval =
    data.status.sponsorStatusName &&
    ["declined", "approved"].includes(data.status.sponsorStatusName);

  const isAllowedSponsorApproval = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.SPONSOR_STATUS,
    ),
    operation: OPERATIONS.UPDATE,
  });

  const showSponsorApprovalCard =
    (["pre-approval", "auto-approval"].includes(data.status.statusName) ||
      isSponsorApproval) &&
    isSponsorApprovalEnabled &&
    isAllowedSponsorApproval;

  const hideIdentityVerification =
    !data?.primaryAccountHolder?.email ||
    !modify_merchant ||
    !(
      data?.primaryAccountHolder?.owner?.statusName === "approved" ||
      data?.invitation?.inviteStatus === "joined"
    );

  const businessOwnersOfacSort = ofacArraySorter(data?.businessOwnersList)[0];
  const hasFullPAH =
    !!data.primaryAccountHolder.email &&
    !!data.primaryAccountHolder.firstName &&
    !!data.primaryAccountHolder.lastName;

  const sectionsList = [
    {
      section: (
        <WithMissingPermissionModule
          message="Merchant informations hidden"
          notAuthorized={hasNotMerchantDataPermission}
        >
          <MerchantHeader
            {...data.header}
            merchantId={data.merchantInfo.merchantID}
            dispalyStatusInfo={modify_merchant}
          />
        </WithMissingPermissionModule>
      ),
      delay: 100,
    },
    { section: <MerchantStats data={data.stats} /> },
    {
      section: <RiskProfileAlert onOpen={handlers.handleOpenRiskProfile} />,
      hidden:
        data.riskProfile.level === "low" ||
        !risk_monitoring ||
        !isRiskSummaryVisible,
    },
    {
      section: (
        <MerchantOFACWarning
          BP={{
            label: "Business Profile",
            status: data?.businessProfile?.ofac?.lastCheckStatusName,
          }}
          PAH={
            isNewOfacTabsEnabled
              ? {
                  label: "Primary Account Holder",
                  status: data?.primaryAccountHolder?.ofac?.lastCheckStatusName,
                }
              : {}
          }
          BO={
            isNewOfacTabsEnabled
              ? {
                  label: "Business Owner",
                  status: businessOwnersOfacSort?.ofac?.lastCheckStatusName,
                }
              : {}
          }
        />
      ),
      hidden:
        isOFACClear(data?.businessProfile?.ofac?.lastCheckStatusName) &&
        isOFACClear(data?.primaryAccountHolder?.ofac?.lastCheckStatusName) &&
        isOFACClear(businessOwnersOfacSort?.ofac?.lastCheckStatusName),
    },
    {
      section: <WarningPlaceholders data={data} isWarnings />,
      hidden: !modify_merchant,
    },
    {
      section: <MSPWarning data={data?.mspStatus} />,
      hidden: !data.mspStatus || !data.msp.displayMSPMessage,
    },
    {
      section: (
        <WithMissingPermissionModule
          message="Underwriting informations hidden"
          notAuthorized={hasNotMerchantDataPermission}
          wrapperProps={{
            margin: 0,
            padding: 8,
          }}
        >
          <UnderwritingReportSummary
            status={status}
            data={data.status}
            merchantId={data.status.accID}
            underwritingScorePercentage={
              data.status.underwritingScorePercentage
            }
            handleManageUnderwriting={handlers.handleOpenManageUnderwriting}
          />
        </WithMissingPermissionModule>
      ),
      hidden:
        !isViewUnderwritingAllowed ||
        !merchant_underwriting ||
        !isUnderwritingReportVisible ||
        showSponsorApprovalCard,
    },
    {
      section: (
        <WithMissingPermissionModule
          message="Risk summary informations hidden"
          notAuthorized={hasNotMerchantDataPermission}
          wrapperProps={{
            margin: 0,
            padding: 8,
          }}
        >
          <RiskSummary
            riskProfile={data?.riskProfile}
            statusName={data?.status?.statusName}
            merchantId={data?.merchantInfo?.merchantID}
            status={data?.status}
            handleManageRiskProfile={handlers.handleOpenRiskProfile}
          />
        </WithMissingPermissionModule>
      ),
      hidden: !risk_monitoring || !isRiskSummaryVisible,
    },
    {
      section: (
        <WithMissingPermissionModule
          message="Underwriting informations hidden"
          notAuthorized={hasNotMerchantDataPermission}
          wrapperProps={{
            margin: 0,
            padding: 8,
          }}
        >
          <SponsorApproval
            data={data.status}
            name={data.merchantInfo.merchantName}
            mspStatus={data.msp}
          />
        </WithMissingPermissionModule>
      ),
      hidden: !showSponsorApprovalCard,
    },
    {
      section: (
        <WithMissingPermissionModule
          message="Underwriting informations hidden"
          notAuthorized={hasNotMerchantDataPermission}
          wrapperProps={{
            margin: 0,
            padding: 8,
          }}
        >
          <StatusReview data={data.status} mspStatus={data.msp} />
        </WithMissingPermissionModule>
      ),
      hidden:
        !merchant_underwriting ||
        !isStatusOverviewVisible ||
        showSponsorApprovalCard,
    },
    {
      section: (
        <WithMissingPermissionModule
          message="Merchant informations hidden"
          notAuthorized={hasNotMerchantDataPermission}
        >
          <ContentFocusWrapper id="merchant_info">
            <EditMerchantSection
              sectionTitle="Merchant Info"
              editHandler={modify_merchant ? editMerchantInfo : undefined}
              items={merchantInfoItems}
              isAcquirer={isAcquirerPortal}
              containerSx={{
                borderRadius: "12px",
                backgroundColor: palette.neutral.white,
                boxShadow: "1px 1px 15px 0px rgba(161, 175, 180, 0.10)",
                padding: "16px",
                border: "none",
              }}
            />
          </ContentFocusWrapper>
        </WithMissingPermissionModule>
      ),
    },
    {
      section: <WarningPlaceholders data={data} handlers={handlers} />,
    },
    {
      section: (
        <OFACPreviewSection
          onOpen={handlers.handleOpenOFACPanel}
          id={id}
          disabled={
            (!data?.businessProfile?.taxID &&
              !data.businessProfile.ssn &&
              !hasFullPAH &&
              data?.businessOwnersList?.length < 1) ||
            !isDesktopView
          }
          isAcquirer={isAcquirerPortal}
          opened={openedSecondPanel === "ofac"}
          lastCheckBP={data?.businessProfile?.ofac}
          lastCheckBO={businessOwnersOfacSort?.ofac}
          lastCheckPAH={data?.primaryAccountHolder?.ofac}
        />
      ),
      hidden: !merchant_underwriting,
    },
    {
      section: (
        <MATCHPreviewSection
          isAcquirer={isAcquirerPortal}
          disabled={!data?.businessProfile?.taxID && !data.businessProfile.ssn}
          onClick={handlers.handleOpenMATCH}
          opened={openedSecondPanel === "match"}
        />
      ),
      hidden: !merchant_underwriting,
    },
    {
      section: (
        <WithMissingPermissionModule
          message="Bank accounts list hidden"
          notAuthorized={hasNotBankAccountsListPermission}
          wrapperProps={{
            margin: 0,
            padding: "10px",
          }}
        >
          <ContentFocusWrapper id="bank_account">
            <MerchantBankAccounts
              isAcquirer={isAcquirerPortal}
              data={data.bankAccountList}
              merchantId={id}
              parentAccID={data.merchantInfo.enterpriseID}
              bankAccountSettings={data.bankAccountSettings}
            />
          </ContentFocusWrapper>
        </WithMissingPermissionModule>
      ),
      hidden:
        (!modify_merchant &&
          !(bank_account_linking || manage_bank_account || money_transfers)) ||
        data.bankAccountList?.length === 0,
    },
    {
      section: (
        <WithMissingPermissionModule
          message="Merchant informations hidden"
          notAuthorized={hasNotMerchantDataPermission}
        >
          <ContentFocusWrapper spacing id="primary_account_holder">
            <MerchantPrimaryAccountHolder
              data={data}
              isAcquirer={isAcquirerPortal}
              pendingEmail={pendingEmail}
              id={id}
              items={primaryAccountHolderItems}
              editHandler={
                modify_merchant && hasEditPahPermission
                  ? editPrimaryAccountHolder
                  : undefined
              }
            />
          </ContentFocusWrapper>
        </WithMissingPermissionModule>
      ),
      hidden: !data.primaryAccountHolder?.email,
    },
    {
      section: (
        <ContentFocusWrapper id="identity-verification-section">
          <IdentityVerification id={id} data={data?.documents} />
        </ContentFocusWrapper>
      ),
      hidden: hideIdentityVerification,
    },
    {
      section: (
        <ContentFocusWrapper spacing id="business_profile">
          <MerchantPreviewBusinessProfile
            id={id}
            data={data}
            editHandler={editBusinessProfile}
            isAcquirer={isAcquirerPortal}
          />
        </ContentFocusWrapper>
      ),
      hidden:
        (!data.businessProfile?.taxID && !data.businessProfile?.ssn) ||
        !modify_merchant,
    },
    {
      section: (
        <EditMerchantSection
          isAcquirer={isAcquirerPortal}
          sectionTitle="Business address"
          editHandler={
            data?.businessProfile?.ofac?.lastCheckStatusName ===
            "confirmed_match"
              ? undefined
              : legalEntityEditableByAcquirer
              ? () => editBusinessProfile("business_address")
              : undefined
          }
          items={businessAddressItems}
        />
      ),
      hidden:
        (!data.businessProfile?.taxID && !data.businessProfile?.ssn) ||
        !modify_merchant,
    },
    {
      section: (
        <WithMissingPermissionModule
          message="Business owners hidden"
          notAuthorized={hasNotPrincipalsPermission}
          wrapperProps={{
            margin: 0,
            padding: "10px",
          }}
        >
          <ContentFocusWrapper id="business_owners">
            <MerchantBusinessOwners
              isAcquirer={isAcquirerPortal}
              merchantId={id}
              withPepStatus
              data={data.businessOwnersList}
              legalEntityID={data.businessProfile.id}
              isIndividualSoleProprietorship={
                data.businessProfile.businessType ===
                "individual_sole_proprietorship"
              }
              isApprovedLE={
                data?.header?.businessProfileStatus?.status?.toLowerCase() ===
                "approved"
              }
              businessType={data.businessProfile.businessType}
              selectedPEPOwner={selectedPEPOwner}
              selectBusinessOwner={handlers.selectPEPOwner}
              isController={data?.businessProfile?.isController}
              isBPLinked={data?.businessProfile?.isBPLinked}
              documents={data?.documents}
              isMerchant
            />
          </ContentFocusWrapper>
        </WithMissingPermissionModule>
      ),
      hidden: data.businessOwnersList?.length === 0 || !modify_merchant,
    },
    {
      section: (
        <WithMissingPermissionModule
          message="Merchant informations hidden"
          notAuthorized={hasNotMerchantDataPermission}
        >
          <ContentFocusWrapper id="merchant-fees-section">
            <MerchantFees
              id={id}
              data={data.fees}
              isAcquirer={isAcquirerPortal}
            />
          </ContentFocusWrapper>
        </WithMissingPermissionModule>
      ),
    },
    {
      section: (
        <WithMissingPermissionModule
          message="Merchant Agreement hidden"
          notAuthorized={hasNotMerchantAgreementPermission}
          wrapperProps={{
            margin: 0,
            padding: "10px",
          }}
        >
          <ContentFocusWrapper id="merchant-agreement-section">
            <MerchantAgreement
              id={id}
              isAcquirer={isAcquirerPortal}
              data={data.merchantAgreement}
              handleOpenMerchantAgreement={
                handlers.handleManageMerchantAgreement
              }
            />
          </ContentFocusWrapper>
        </WithMissingPermissionModule>
      ),
      hidden:
        !data.merchantAgreement.msaPCICompliant ||
        shouldBeHidden.merchantAgreement,
    },
    {
      section: (
        <WithMissingPermissionModule
          message="Documents hidden"
          notAuthorized={hasNotDocumentsListPermission}
          wrapperProps={{
            margin: 0,
            padding: "10px",
          }}
        >
          <ContentFocusWrapper id="documents-section">
            <DocumentsSection
              isAcquirer={isAcquirerPortal}
              id={id}
              data={data.documents}
            />
          </ContentFocusWrapper>
        </WithMissingPermissionModule>
      ),
      hidden: !modify_merchant || !merchant_underwriting,
    },
  ];

  const hasNotAcceptedInvitationSectionsList = [
    {
      section: (
        <WithMissingPermissionModule
          message="Merchant informations hidden"
          notAuthorized={hasNotMerchantDataPermission}
        >
          <MerchantHeader
            {...data.header}
            merchantId={data.merchantInfo.merchantID}
            dispalyStatusInfo={false}
          />
        </WithMissingPermissionModule>
      ),
      delay: 100,
      hidden: false,
    },
    {
      section: (
        <Stack
          sx={{
            backgroundColor: "#E6F3EC",
            padding: "12px 16px",
            borderRadius: "12px",
          }}
          mt={1}
          mb={1}
        >
          <Text color="#106D3D" fontWeight="book">
            The invitation has been successfully sent to{" "}
            {data?.primaryAccountHolder?.email}. We&apos;re now waiting for them
            to complete the onboarding process. If you need to change the
            primary account holder, feel free to update the email field below
            and resend the invitation.
          </Text>
        </Stack>
      ),
      delay: 100,
      hidden: false,
    },
    {
      section: (
        <MerchantPrimaryAccountHolder
          data={data}
          pendingEmail={pendingEmail}
          id={id}
          items={primaryAccountHolderItems}
          editHandler={() => {
            NiceModal.show(UPDATE_PRIMARY_ACCOUNT_HOLDER_EMAIL, {
              data: {
                email: data?.primaryAccountHolder?.email,
              },
              ownerAccId: data?.ownerId,
              id,
            });
          }}
        />
      ),
      delay: 100,
      hidden: false,
    },
  ];

  const sectionsToDisplay = useMemo(() => {
    const inviteStatus = data?.invitation?.inviteStatus;
    return inviteStatus &&
      inviteStatus === "invited" &&
      !isAcquirerPortal &&
      !shouldBeHidden.enterpriseConfiguration
      ? hasNotAcceptedInvitationSectionsList
      : sectionsList;
  }, [data, openedSecondPanel]);

  return (
    <PanelContainer>
      {isDesktopView && (
        <MerchantPreviewHeader
          nextPrevState={{ isFirst, isLast }}
          actions={actions}
          handlers={handlers}
          hasAlert={data.riskProfile.level !== "low"}
          isControlModeAllowed={isControlModeAllowed}
          isRiskMonitorAllowed={isRiskSummaryVisible}
        />
      )}
      <ErrorCatcher errorID="merchant-side-panel-body">
        {sectionsToDisplay.map(({ section, hidden, delay }, index) => (
          <Fragment key={index}>
            {!hidden && (
              <FadeUpWrapper delay={(delay || 100) + index * 80}>
                {section}
              </FadeUpWrapper>
            )}
          </Fragment>
        ))}
      </ErrorCatcher>
    </PanelContainer>
  );
};

const PanelContainer = styled(Stack)(({ theme }) => ({
  background: palette.neutral[5],
  width: "100%",
  height: "100%",
  alignItems: "stretch",
  flexDirection: "column",
  justifyContent: "flex-start",
  padding: "16px",
  gap: "16px",
  scrollBehavior: "smooth",

  [theme.breakpoints.up("sm")]: {
    overflowX: "hidden",
    overflowY: "auto",
    scrollbarGutter: "stable both-edges",
  },
  [theme.breakpoints.down("sm")]: {
    padding: 0,
    overflowY: "hidden",
    // "::-webkit-scrollbar-track": {
    //   marginBlock: "16px",
    // },
  },
}));

export default PreviewModalBody;

export const ContentFocusWrapper = ({
  children,
  id,
  spacing,
}: PropsWithChildren & { id: string; spacing?: boolean }) => {
  const { sectionFocus } = useAppSelector(selectMerchantPreview);
  return (
    <div
      id={id}
      style={{
        borderRadius: "8px",
        border: "2px solid transparent",
        transition: "border 250ms ease",
        borderColor: sectionFocus.section === id ? "#6D9CF8" : "transparent",
        padding: spacing ? "10px 5px" : "0",
      }}
    >
      {children}
    </div>
  );
};
