import { getJsonValue } from "../utils";

export const getCustomBaseRadioRootStyles = (
  mode: "light" | "dark",
) => ({
  padding: 0,
  backgroundColor: "transparent",
  '& > input:hover:checked ~ .RadioIcon': {
    backgroundColor: getJsonValue(`tokens.${mode}.colour.buttons.default-hover`),
    '&:before': {
      backgroundColor: getJsonValue(`tokens.${mode}.colour.surface.primary`),
      opacity: 0.7
    },
  },
  '.RadioIcon': {
    border: `1.5px solid ${getJsonValue(`tokens.${mode}.colour.surface.tertiary`)}`,
    '&:before': {
      backgroundColor: getJsonValue(`tokens.${mode}.colour.surface.primary`),
    },
  },
  '& > input:checked ~ .RadioIcon': {
    backgroundColor: getJsonValue(`tokens.${mode}.colour.buttons.default`),
    border: 'none',
  },
  '& > input:checked:disabled ~ .RadioIcon': {
    backgroundColor: getJsonValue(`tokens.${mode}.colour.buttons.default-hover`),
    border: 'none',
    '&:before': {
      backgroundColor: getJsonValue(`tokens.${mode}.colour.surface.primary`),
      opacity: 0.7
    },
  },
});

export const getSmallRadioStyle = () => [
  {
    props: (props: any) => props.size === "small",
    style: {
      ".RadioIcon": {
        width: "20px",
        height: "20px",
        "&:before": {
          width: "8px",
          height: "8px"
        }
      }
    },
  },
];
