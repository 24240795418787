import CustomPopover from "@common/Modal/Popover/CustomPopover";
import { Text } from "@common/Text";
import { Stack } from "@mui/material";
import { palette } from "@palette";
import { TransactionStatus } from ".";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { accumulateFees } from "../transactions.helpers";
import { FeeItem } from "../transactions.types";
import { checkPortals } from "@utils/routing";

type Props = {
  anchorElement: SVGSVGElement | null;
  onClose: () => void;
  status: string;
  feePaidByCustomer: boolean;
  feeItems: FeeItem[];
  cost: number;
  originalSettledTransactionFeeItems?: FeeItem[];
};

const SETTLED_TITLE = "Fee Allocation";
const BASE_TITLE = "Estimated Fee Allocation";
const BASE_DESCRIPTION =
  "The transaction fees are allocated between the enterprise and the acquirer";
const CHARGEBACK_MERCHANT_PAID_FEES_DESCRIPTION =
  "The provider will refund its fees to the merchant, but the acquirer's fees will not be refunded.";
const CHARGEBACK_CUSTOMER_PAID_FEES_DESCRIPTION =
  "The provider will refund its fees to the customer, and the merchant will cover the acquirer's fees.";
const CHARGEBACK_REVERSAL_MERCHANT_PAID_FEES_DESCRIPTION =
  "The provider fee is returned, but the acquirer's fee remains covered by the merchant and will not be refunded.";
const CHARGEBACK_REVERSAL_CUSTOMER_PAID_FEES_DESCRIPTION =
  "The provider will reclaim its fee, and the acquirer's fee will be returned to the merchant by the customer.";
const PROVIDER_REFUND = "Provider refund (USD)";
const PROVIDER_FEES = "Provider fee (USD)";
const PROVIDER_REVENUE = "Provider revenue (USD)";
const ACQUIRER_ORIGINAL_REVENUE = "Acquirer original revenue (USD)";
const PAID_FEES = "Paid fees (USD)";

const TransactionFeesPopover = ({
  anchorElement,
  onClose,
  status,
  feePaidByCustomer,
  feeItems,
  cost,
  originalSettledTransactionFeeItems,
}: Props) => {
  if (!anchorElement) return null;

  const { isMobileView } = useCustomTheme();
  const { title, description, feeAllocations } = getFeesPopoverContent(
    status,
    feePaidByCustomer,
    feeItems,
    cost,
    originalSettledTransactionFeeItems,
  );

  const content = (
    <Stack spacing={isMobileView ? 1 : 0.75}>
      <Stack spacing={isMobileView ? 1 : 0.25}>
        <Text
          color={palette.neutral[80]}
          fontSize={isMobileView ? "18px" : "12px"}
          fontWeight="book"
        >
          {title}
        </Text>
        <Text
          color={palette.neutral[70]}
          fontSize={isMobileView ? "14px" : "12px"}
          fontWeight="book"
          lineHeight="14.4px"
        >
          {description}
        </Text>
      </Stack>

      {feeAllocations.map((item: any, index: number) => {
        return (
          <Stack direction="row" justifyContent="space-between" key={index}>
            <Text
              color={palette.neutral[70]}
              fontSize={isMobileView ? "14px" : "12px"}
              fontWeight="book"
            >
              {item.key}
            </Text>
            <Text
              fontSize={isMobileView ? "14px" : "12px"}
              fontWeight="book"
              color={palette.neutral[80]}
            >
              {item.feeValue}
            </Text>
          </Stack>
        );
      })}
    </Stack>
  );

  return (
    <CustomPopover
      open={Boolean(anchorElement)}
      DrawerProps={{
        PaperProps: {
          sx: {
            background: palette.common.white,
            padding: "24px 16px ",
          },
        },
      }}
      anchorEl={anchorElement}
      onClose={onClose}
      renderMobile={isMobileView}
      PopoverSx={{
        width: "248px",
        minHeight: "130px",
        padding: "12px",
      }}
      PopoverProps={{
        anchorOrigin: {
          vertical: "center",
          horizontal: "center",
        },
        transformOrigin: {
          vertical: "center",
          horizontal: -20,
        },
      }}
      sx={{ pointerEvents: isMobileView ? "auto" : "none" }}
    >
      {content}
    </CustomPopover>
  );
};

export default TransactionFeesPopover;

const getFeesPopoverContent = (
  status: string,
  feePaidByCustomer: boolean,
  feeItems: FeeItem[],
  cost: number,
  originalSettledTransactionFeeItems?: FeeItem[],
) => {
  const { isAcquirerPortal } = checkPortals();

  const providerRevenue = accumulateFees(
    feeItems,
    (curr: FeeItem) => curr.destinationAccount?.merchant?.type === "enterprise",
  );

  const acquirerRevenue = accumulateFees(
    feeItems,
    (curr: FeeItem) => curr.destinationAccount?.merchant?.type === "acquirer",
  );

  const providerRefund = accumulateFees(
    feeItems,
    (curr: FeeItem) => curr.sourceAccount?.merchant?.type === "enteprise",
  );

  const chargebackReversalProviderFees = accumulateFees(
    feeItems,
    (curr: FeeItem) => curr.destinationAccount?.merchant?.type === "enteprise",
  );

  const acquirerFeeRefundedToMerchant = accumulateFees(
    feeItems,
    (curr: FeeItem) =>
      curr.destinationAccount?.merchant?.type === "submerchant" &&
      curr.type !== "chargeback_fee",
  );

  const acquirerOriginalRevenue = accumulateFees(
    originalSettledTransactionFeeItems ?? [],
    (curr: FeeItem) => curr.destinationAccount?.merchant?.type === "acquirer",
  );

  // The fees acquirer is calculated with the following formula: 2% of the cost + 0.2$
  const paidFeesToNetwork = (cost * 0.02 + 0.2).toFixed(2);

  switch (status) {
    case TransactionStatus.SETTLED:
      return {
        title: SETTLED_TITLE,
        description: BASE_DESCRIPTION,
        feeAllocations: isAcquirerPortal
          ? [
              { key: PROVIDER_REVENUE, feeValue: providerRevenue },
              { key: "Acquirer revenue (USD)", feeValue: acquirerRevenue },
              { key: PAID_FEES, feeValue: paidFeesToNetwork },
            ]
          : [
              { key: PROVIDER_REVENUE, feeValue: providerRevenue },
              { key: PAID_FEES, feeValue: acquirerRevenue },
            ],
      };
    case TransactionStatus.CHARGEBACK:
      return {
        title: `${SETTLED_TITLE} - ${status}`,
        description: feePaidByCustomer
          ? CHARGEBACK_CUSTOMER_PAID_FEES_DESCRIPTION
          : CHARGEBACK_MERCHANT_PAID_FEES_DESCRIPTION,

        feeAllocations: feePaidByCustomer
          ? [
              {
                key: PROVIDER_REFUND,
                feeValue: providerRefund,
              },
              {
                key: "Acquirer refund covered by Merchant (USD)",
                feeValue: acquirerFeeRefundedToMerchant,
              },
            ]
          : [
              {
                key: PROVIDER_REFUND,
                feeValue: providerRefund,
              },
              {
                key: ACQUIRER_ORIGINAL_REVENUE,
                feeValue: acquirerOriginalRevenue,
              },
            ],
      };

    case TransactionStatus.CHARGEBACK_REVERSAL:
      return {
        title: `${SETTLED_TITLE} - ${status}`,
        description: feePaidByCustomer
          ? CHARGEBACK_REVERSAL_CUSTOMER_PAID_FEES_DESCRIPTION
          : CHARGEBACK_REVERSAL_MERCHANT_PAID_FEES_DESCRIPTION,
        feeAllocations: feePaidByCustomer
          ? [
              {
                key: PROVIDER_FEES,
                feeValue: chargebackReversalProviderFees,
              },
              {
                key: "Acquirer fee refunded to Merchant (USD)",
                feeValue: acquirerFeeRefundedToMerchant,
              },
            ]
          : [
              {
                key: PROVIDER_FEES,
                feeValue: chargebackReversalProviderFees,
              },
              {
                key: ACQUIRER_ORIGINAL_REVENUE,
                feeValue: acquirerOriginalRevenue,
              },
            ],
      };

    default:
      return {
        title: BASE_TITLE,
        description: BASE_DESCRIPTION,
        feeAllocations: isAcquirerPortal
          ? [
              { key: PROVIDER_REVENUE, feeValue: providerRevenue },
              { key: "Acquirer revenue (USD)", feeValue: acquirerRevenue },
              { key: PAID_FEES, feeValue: paidFeesToNetwork },
            ]
          : [
              { key: PROVIDER_REVENUE, feeValue: providerRevenue },
              { key: PAID_FEES, feeValue: acquirerRevenue },
            ],
      };
  }
};
