import { showMessage } from "@common/Toast";
import { useFileUploadContext } from "@components/UploadFile/FileUploadContext";
import { useModal } from "@ebay/nice-modal-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { deleteDocument } from "@hooks/common/documents/utils";
import { useUploadPresignedDocument } from "@hooks/upload-api/uploadHooks";
import {
  createBusinessOwner,
  patchBusinessOwner,
} from "@services/api/business-owners";
import {
  convertPhoneNumber,
  isEmptyPhone,
  toUnixDateFormat,
} from "@utils/date.helpers";
import {
  addressSchema,
  dateOfBirthSchema,
  nonResidentInputsSchema,
  phoneSchema,
  ssnSchema,
} from "@utils/validation.helpers";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { uniqueId } from "lodash";
import moment from "moment";
import { useEffect } from "react";
import { StatusValue } from "react-dropzone-uploader";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as Yup from "yup";
import { TBusinessOwner, TMerchantDocument } from "../data.types";

type TinType = "ssn" | "ein";
type file = {
  fileWithMeta: any;
  status: StatusValue;
  allFiles: any;
};
type FormInputs = TBusinessOwner & {
  tinType: TinType;
  files?: file;
  uploadedfiles?: file;
};

type Props = {
  merchantId: number;
  totalOwnerships: number;
  data?: TBusinessOwner;
  legalEntityID?: number;
  onClose?: (data: TBusinessOwner) => void;
  idFile?: TMerchantDocument;
  primaryAccountHolder?: Partial<TBusinessOwner>;
  isIndividualSoleProprietorship?: boolean;
};

export const useCreateMerchantBusinessOwner = ({
  merchantId,
  legalEntityID,
  data: businessOwner,
  totalOwnerships,
  onClose,
  idFile,
  primaryAccountHolder,
  isIndividualSoleProprietorship,
}: Props) => {
  const { handleUpload, isLoading: loadingPresignedDocument } =
    useUploadPresignedDocument();
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();
  const modal = useModal();
  const open = modal.visible;
  const queryClient = useQueryClient();
  const { populateSnackbarFiles, isLoading: uploadLoading } =
    useFileUploadContext();

  const defaultValues = businessOwner
    ? generateDefaultValues(businessOwner)
    : defaultFormValues;

  const methods = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    reset,
    setValue,
    formState: { dirtyFields },
    watch,
  } = methods;

  const handleCancel = () => modal.remove();

  useEffect(() => {
    if (
      primaryAccountHolder &&
      !businessOwner &&
      isIndividualSoleProprietorship
    ) {
      Object.entries(primaryAccountHolder).forEach(([key, value]) => {
        setValue(
          key as keyof FormInputs,
          value as FormInputs[keyof FormInputs],
          {
            shouldDirty: true,
          },
        );
      });
    }
  }, []);

  const handleChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue("isBusinessAddress", e.target.checked, { shouldDirty: true });
    setValue("address.country", "US", { shouldDirty: true });
  };

  const createBusinessOwnerMutation = useMutation(
    ({ leID, data }: { leID: number; data: any }) => {
      return businessOwner?.id
        ? patchBusinessOwner(leID, businessOwner.id, data, merchantId)
        : createBusinessOwner(leID, data, merchantId);
    },
  );

  const isConfirmMatch = businessOwner?.pepStatusName === "confirmed_match";

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const computedOwnership =
      +(data?.ownership || 0) +
      totalOwnerships -
      +(businessOwner?.ownership || 0);

    if (computedOwnership > 100) {
      showMessage("Info", "Ownership sum cannot exceed 100%");
      return;
    }

    let uploadRes: string[] | "upload_failed" = [];

    if (data?.uploadedfiles && idFile) {
      deleteDocument(
        merchantId,
        {
          fileName: idFile.fileName,
          id: idFile.id,
        },
        () => {
          queryClient.invalidateQueries(["get-merchant-preview", merchantId]);
          queryClient.invalidateQueries(["get-bo-files", merchantId]);
        },
        {
          hideModal: true,
        },
      );
    }
    // if file doesnt have meta field, that means the file is from BE, so no upload required
    const isLocalFile = !!data?.files?.allFiles[0]?.meta;
    if (
      isLocalFile &&
      !businessOwner?.createdAt &&
      data?.files?.allFiles?.length > 0 &&
      data?.files?.allFiles[0]?.meta?.previewUrl !=
        businessOwner?.files?.allFiles[0]?.meta?.previewUrl
    ) {
      // upload when BO creation
      if (isFileUploadRefactorEnabled) {
        uploadRes = await handleUpload({
          list: data.files?.allFiles,
          attachmentType: "legal_principal",
        });
      } else {
        uploadRes = (await populateSnackbarFiles({
          fileWithMeta: data.files?.fileWithMeta || data.files?.allFiles[0],
          status: data.files?.status as StatusValue,
          allFiles: data.files?.allFiles,

          attachmentType: "legal_principal",
        })) as string[] | "upload_failed";
      }
    }

    if (
      isLocalFile &&
      businessOwner?.createdAt &&
      data?.files?.allFiles.length > 0
    ) {
      //upload when BO update
      uploadRes = (await populateSnackbarFiles(
        {
          fileWithMeta: data.files?.fileWithMeta || data.files?.allFiles[0],
          status: data.files?.status as StatusValue,
          allFiles: data.files?.allFiles,
          attachmentType: "legal_principal",
          label: "business owner document",
          tag: "business owner document",
          merchantId: merchantId,
          resourceID: +businessOwner?.id,
        },
        undefined,
      )) as string[] | "upload_failed";
    }

    if (uploadRes === "upload_failed") return;

    if (onClose) {
      const ownerData = {
        ...data,
        id: businessOwner?.id || uniqueId(),
        ...(!businessOwner?.createdAt &&
          Array.isArray(uploadRes) && {
            idImageUrl: uploadRes[0],
          }),
      };

      onClose(ownerData);
      reset(businessOwner?.id ? ownerData : (defaultValues as any));
      modal.remove();
      return;
    }

    if (!legalEntityID) return;

    const payloadOnConrfimedMatch = {
      ownershipPercentage: data.ownership ? +data.ownership : null,
    };
    const d1 = new Date(data.dob);
    const d2 = new Date(defaultValues.dob);
    const isDateChanged = !(
      moment(d1).isSame(d2, "day") && !moment(d1).isSame(d2, "minute")
    );
    const defaultPayload = {
      ...(dirtyFields.firstName && {
        firstName: data.firstName,
      }),
      ...(dirtyFields.lastName && {
        lastName: data.lastName,
      }),
      ...(dirtyFields.phone && {
        phoneNumber: data.phone ? convertPhoneNumber(data.phone) : null,
      }),
      ...(dirtyFields.email && {
        email: data.email || null,
      }),
      ...(isDateChanged && {
        dateOfBirth: data.dob ? toUnixDateFormat(new Date(data.dob)) : null,
      }),

      ...((dirtyFields.citizenship || dirtyFields.isNotUSResident) && {
        citizenship: data?.isNotUSResident ? data.citizenship : "",
      }),

      ...((dirtyFields.countryOfResidence ||
        dirtyFields.isNotResidentInCitizenshipCountry) && {
        countryOfResidence: data.isNotResidentInCitizenshipCountry
          ? data.countryOfResidence
          : "",
      }),
      ...(!data.isBusinessAddress &&
        data.address &&
        (dirtyFields.address || dirtyFields.isBusinessAddress) && {
          address: {
            name: data.address.line1,
            line1: data.address.line1,
            line2: "",
            city: data.address.city,
            state: data.address.state,
            zip: data.address.zip,
            country: data.address.country,
          },
        }),
      ...(dirtyFields.isBusinessAddress && {
        useEntityGeoAddress: data.isBusinessAddress,
      }),
      ownershipPercentage: data.ownership ? +data.ownership : null,
    } as any;

    const tinType = watch("tinType");

    if (tinType === "ein") {
      if (dirtyFields.ein) {
        defaultPayload.ein = data.ein;
      }
    } else if (tinType === "ssn") {
      if (dirtyFields.ssn) {
        defaultPayload.taxIDNumber = data.ssn;
      }
    }

    const payload = isConfirmMatch ? payloadOnConrfimedMatch : defaultPayload;

    createBusinessOwnerMutation.mutate(
      {
        leID: legalEntityID,
        data: {
          ...payload,
          ...(!businessOwner?.createdAt &&
            Array.isArray(uploadRes) && {
              idImageUrl: uploadRes[0],
            }),
        },
      },
      {
        onSuccess: () => {
          queryClient.refetchQueries("get-merchant-preview");
          queryClient.invalidateQueries("get-merchant-msp-status");
          queryClient.invalidateQueries("list-business-owners");
          queryClient.invalidateQueries("pep-checks-history");
          queryClient.invalidateQueries("list-ofac-checks");
          queryClient.invalidateQueries(["get-bo-files", merchantId]);
          modal.remove();
        },
        onError: (error: unknown) => {
          const defaultMessage = "An error occured. Please try again";
          const errorObj = (error as any)?.response?.data;
          const apiError = errorObj?.input?.length
            ? errorObj?.input[0]?.message
            : errorObj?.message;

          showMessage("Error", apiError || defaultMessage);
        },
      },
    );
  };

  return {
    open,
    methods,
    handleCancel,
    onSubmit,
    isLoading:
      createBusinessOwnerMutation.isLoading ||
      uploadLoading ||
      loadingPresignedDocument,
    handleChangeAddress,
  };
};

const defaultFormValues = {
  firstName: "",
  lastName: "",
  email: "",
  ownership: "10",
  tinType: "ssn" as TinType,
  ssn: "",
  ein: "",
  dob: "",
  phone: "",
  isNotUSResident: false,
  citizenship: "",
  isNotResidentInCitizenshipCountry: false,
  countryOfResidence: "",

  isBusinessAddress: false,
  address: {
    country: "US",
    line1: "",
    city: "",
    state: "",
    zip: "",
  },
  files: {
    allFiles: [],
  },
};

const schema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Not valid email").required("Email is required "),
  ownership: Yup.string().test(
    "is-greater-than-0",
    "Ownership must be greater than 0",
    (value) => value !== undefined && Number(value || 0) > 0,
  ),
  tinType: Yup.mixed<TinType>().oneOf(["ssn", "ein"]),
  ssn: Yup.string().when("tinType", {
    is: "ssn",
    then: ssnSchema("ssn"),
  }),
  ein: Yup.string().when("tinType", {
    is: "ein",
    then: ssnSchema("ein"),
  }),
  dob: dateOfBirthSchema(),
  phone: phoneSchema(),
  isBusinessAddress: Yup.boolean(),
  address: addressSchema("isBusinessAddress"),
  ...nonResidentInputsSchema,
  files: Yup.object().shape({
    fileWithMeta: Yup.object().unknown(true),
    status: Yup.string(),
    allFiles: Yup.array()
      .of(Yup.object())
      .min(1, "Business Owner ID is required"),
  }),
});

export const formatOwnersDateUTC = (date: Date, isMobile: boolean) => {
  if (isMobile) {
    return moment(date).format("MMM D, YYYY");
  } else {
    return moment(date).utc().format("MMM D, YYYY, h:mm:ss A [UTC]");
  }
};

const generateDefaultValues = (data?: TBusinessOwner) => {
  return {
    ...data,
    tinType: data?.tinType === "ein" ? "ein" : ("ssn" as TinType),
    ssn: data?.tinType === "ssn" ? data?.ssn : "",
    ein: data?.tinType === "ein" ? data?.ein : "",
    dob: data?.dob ? data?.dob : "",
    phone: isEmptyPhone(data?.phone)
      ? ""
      : `+${convertPhoneNumber(data?.phone || "")}`,
    files: {
      allFiles: [],
    },
  };
};
