import React, { useState, useEffect } from "react";
import { QRCodeSVG } from "qrcode.react";
import { CameraIcon } from "@assets/icons";
import { Box, Grid } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { Button } from "@common/Button";
import ArrowsCounterClockwise from "@assets/icons/RebrandedIcons/ArrowsCounterClockwise";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import { base64ToFile } from "@utils/helpers";
import placeholder from "assets/images/Placeholder.png";
import { Image } from "@components/common/StyledImage/Image";
import { CameraFlow } from "./CameraFlow";
import { useGetCurrentMerchantId } from "@hooks/common";
import { showMessage } from "@common/Toast/ShowToast";
import { useQueryClient } from "react-query";
import { uploadDocuments } from "@components/Merchants/MerchantPreview/hooks/useUpdateMerchantInfo";
import {
  useGetUploadedSelfie,
  useGetSelfieOTC,
  useCamera,
  useGetIdentificationFiles,
} from "../hooks/useCamera";
import { isEmpty } from "lodash";
import { deleteDocumentsInLoop } from "@components/Settings/Business/Documents/utils";
import { challengeSlugs } from "@constants/challengeSlugs";
import { QKEY_GET_MERCHANT_BY_ID } from "@constants/queryKeys";
import { useUploadFiles } from "@hooks/upload-api/uploadHooks";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";

export interface SelfieProps {
  isSelfieStepActive?: boolean;
  isLoggedInPAH?: boolean;
  completed?: boolean;
  onsubmit?: () => void;
  updateProgressBar: (e: number) => void;
  setCompleted?: (
    state: boolean,
  ) => void | React.Dispatch<React.SetStateAction<boolean>>;
  merchant?: any;
  setSelfieUrl?: React.Dispatch<React.SetStateAction<string | null>>;
  setIsUploading?: React.Dispatch<React.SetStateAction<boolean>>;
  handleReset?: () => void;
  onBack?: () => void;
}

function SelfieComponent({
  isSelfieStepActive = false,
  isLoggedInPAH = true,
  completed,
  updateProgressBar,
  setCompleted,
  merchant,
  setSelfieUrl,
  setIsUploading,
  onBack,
}: SelfieProps) {
  const [startingMobileFlow, setStartingMobileFlow] = useState(false);
  const queryClient = useQueryClient();
  const [selfie, setSelfie] = useState<string | null>(null);
  const { data: files, refetch } = useGetIdentificationFiles();
  const { merchantId } = useGetCurrentMerchantId();

  const {
    cameraStatus,
    isCameraAvailable,
    webcamRef,
    checkCamera,
    setCamera,
    destroyCamera,
  } = useCamera();
  useEffect(() => {
    checkCamera();
    return () => {
      destroyCamera({ cameraStatus });
    };
  }, []);

  const retakePhoto = () => {
    setSelfie(null);
  };

  const { uploadedSelfie, isLoading: gettingSelfie } = useGetUploadedSelfie();
  const { handleUpload } = useUploadFiles();
  const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();
  const { data: otcData, isLoading } = useGetSelfieOTC({
    enabled: isLoggedInPAH,
  });

  const startMobileFlow = () => {
    setCamera(null);
    retakePhoto();
    setStartingMobileFlow(true);
  };
  const LINK = `https://${window.location.hostname}/make_selfie?token=${otcData?.token}&exp_date=${otcData?.expiresdAt}`;
  const restartComputerFlow = () => {
    setStartingMobileFlow(false);
  };
  const showError = () => {
    showMessage(
      "Error",
      "Scan QR code with your mobile device to verify the scan",
    );
  };
  const showPendingMsg = () => {
    showMessage(
      "Info",
      "Please try again later",
      true,
      "Image is being processed",
    );
  };
  const handleRefetch = () => {
    refetch().then((res) => {
      const latestSelfieData = res?.data?.data?.reduce(
        (latest: any, current: any) => {
          if (
            current.attTypeName === "account_owner_selfie" &&
            current.updatedAt > latest.updatedAt
          ) {
            return current;
          }
          return latest;
        },
        { updatedAt: 0 },
      );
      const latestSelfieURL = latestSelfieData?.fileURL;

      if (!latestSelfieURL) return showError();
      if (!latestSelfieData?.isUploaded) return showPendingMsg();

      if (setIsUploading) {
        setIsUploading(false);
      }
      if (setSelfieUrl) {
        setSelfieUrl(latestSelfieURL);
      } else {
        setCompleted && setCompleted(true);
      }
      queryClient.invalidateQueries(QKEY_GET_MERCHANT_BY_ID);
    });
  };
  const checkSelfieUpdate = async () => {
    try {
      handleRefetch();
    } catch (e) {
      //If not uploaded on the mobile
      showError();
    }
  };
  const onSnap = () => {
    if (cameraStatus) {
      if (webcamRef.current) {
        const imageSrc = webcamRef.current.getScreenshot();

        setSelfie(imageSrc);
        updateProgressBar(100);

        destroyCamera({ cameraStatus });
      }
    } else {
      setCamera();
    }
  };
  const onSubmitSelfie = async () => {
    if (!selfie) return;
    if (cameraStatus) {
      destroyCamera({ cameraStatus });
    }
    setIsUploading && setIsUploading(true);
    const selfieFile = base64ToFile(selfie);
    const fileArr = files?.data?.filter(
      (doc: any) => doc?.attTypeName === "account_owner_selfie",
    );

    const hideDeleteMessage = true;
    !isEmpty(fileArr) &&
      !isEmpty(merchant) &&
      (await deleteDocumentsInLoop(
        fileArr,
        merchant?.accID || merchantId,
        hideDeleteMessage,
      ));
    if (isFileUploadRefactorEnabled) {
      await handleUpload(
        {
          list: [{ file: selfieFile }],
          merchantId: merchant?.accID || merchantId,
          resourceID: merchant?.accID || merchantId,
          attachmentType: "account_owner_selfie",
          label: "",
          tag: `merchant upload`,
        },
        challengeSlugs.PRIMARY_5,
      );
      handleRefetch();

      queryClient.refetchQueries([QKEY_GET_MERCHANT_BY_ID, merchantId]);
    } else {
      uploadDocuments(
        merchant?.accID || merchantId,
        [
          {
            ...selfieFile,
            attachmentType: "account_owner_selfie",
            fileName: selfieFile?.name,
            label: "",
            tag: `merchant upload`,
            resourceID: merchant?.accID || merchantId,
          },
        ],
        [selfieFile],
        () => {
          handleRefetch();

          showMessage("Success", "Selfie uploaded successfully");
          queryClient.refetchQueries([QKEY_GET_MERCHANT_BY_ID, merchantId]);
        },
        challengeSlugs.PRIMARY_5,
      );
    }
  };

  let image = (
    <RenderQR
      binary={LINK}
      checkSelfieUpdate={checkSelfieUpdate}
      condition={!cameraStatus}
      defaultComponent={<CameraFlow data={selfie} webcamRef={webcamRef} />}
    />
  );
  if (!isLoggedInPAH) {
    image = (
      <Box width="300px">
        <Image
          width="100%"
          height="100%"
          alt="uploaded-selfie"
          src={uploadedSelfie ? uploadedSelfie.fileURL : placeholder}
        />
      </Box>
    );
  }
  return (
    <>
      <Grid
        container
        width={300}
        height={300}
        margin="auto"
        bgcolor={isLoggedInPAH ? "#F1F1F1" : undefined}
        borderRadius="4px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        position="relative"
      >
        {isLoading || gettingSelfie ? <LoadingSpinner /> : image}
      </Grid>
      <>
        {isSelfieStepActive && !completed ? (
          <>
            {selfie !== null && <Actions.Retake retakePhoto={retakePhoto} />}
            {cameraStatus !== null && selfie === null && (
              <Actions.Reset onClick={startMobileFlow} />
            )}
            {selfie === null && !startingMobileFlow && (
              <Actions.Snap disabled={!isCameraAvailable} onClick={onSnap} />
            )}

            {selfie !== null && <Actions.Submit onClick={onSubmitSelfie} />}
            {startingMobileFlow && (
              <Actions.RetakeComputerFlow onClick={restartComputerFlow} />
            )}
          </>
        ) : null}
      </>
    </>
  );
}

export default SelfieComponent;

function RenderQR({
  condition,
  defaultComponent,
  binary,
  checkSelfieUpdate,
}: any) {
  if (condition) {
    return (
      <>
        <Grid
          py="10px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Text
            fontWeight="regular"
            lineHeight="120%"
            textAlign="center"
            color={palette.neutral[80]}
            paddingLeft={1}
          >
            Allow access to your camera or scan QR Code with your phone
          </Text>
        </Grid>
        <Grid paddingY="32px">
          <QRCodeSVG value={binary} />
        </Grid>

        <Actions.CheckSelfieUpload onClick={checkSelfieUpdate} />
      </>
    );
  }

  return defaultComponent;
}

const Actions = {
  CheckSelfieUpload: ({ onClick }: any) => {
    return (
      <Button
        size="small"
        sx={{
          padding: "12px 32px",
          margin: "auto",
          marginTop: "16px",
          background: palette.neutral[10],
          "&:hover": {
            background: palette.neutral[20],
          },
        }}
        onClick={onClick}
      >
        <Text color={palette.givebox[600]}>Check upload</Text>
        <ArrowsCounterClockwise fill={palette.givebox[600]} />
      </Button>
    );
  },
  Retake: ({ retakePhoto }: any) => {
    return (
      <Button
        size="small"
        startIcon={<CameraIcon fill={palette.neutral[70]} />}
        background="text"
        sx={{
          padding: "16px 16px",
          margin: "auto",
          marginTop: "16px",
        }}
        onClick={retakePhoto}
      >
        Retake selfie
      </Button>
    );
  },
  RetakeComputerFlow: ({ onClick }: any) => {
    return (
      <Button
        size="small"
        background="text"
        sx={{
          padding: "12px 32px",
          margin: "auto",
          marginTop: "16px",
        }}
        onClick={onClick}
      >
        Use computer
      </Button>
    );
  },
  Snap: ({ disabled, onClick }: any) => {
    return (
      <Button
        size="medium"
        background="primary"
        disabled={disabled}
        onClick={onClick}
        sx={{
          padding: "12px 32px",
          margin: "auto",
          marginTop: "16px",
        }}
      >
        <CameraIcon fill={palette.background.newWhite} />
      </Button>
    );
  },
  Reset: ({ onClick }: any) => {
    return (
      <Button
        size="small"
        background="text"
        sx={{
          padding: "12px 32px",
          margin: "auto",
          marginTop: "16px",
        }}
        onClick={onClick}
      >
        Use your phone
      </Button>
    );
  },
  Submit: ({ onClick }: any) => {
    return (
      <Button
        size="medium"
        background="primary"
        sx={{
          padding: "12px 32px",
          margin: "auto",
          marginTop: "16px",
        }}
        onClick={onClick}
      >
        Submit
      </Button>
    );
  },
};
