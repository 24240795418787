import { NameInput } from "@common/BusinessProfileInputs";
import WebsiteInput from "@common/BusinessProfileInputs/WebsiteInput";
import { Button } from "@common/Button";
import { RHFCheckbox } from "@common/Checkbox";
import { RHFInput, RHFTelInput } from "@common/Input";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";
import { RHFSelect } from "@common/Select";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import EditMerchantBaseModal from "@components/Merchants/MerchantPreview/components/EditMerchantBaseModal";
import { TMerchantInfo } from "@components/Merchants/MerchantPreview/data.types";
import { useUpdateMerchantInfo } from "@components/Merchants/MerchantPreview/hooks/useUpdateMerchantInfo";
import {
  TClassificationsAPIResponse,
  useBusinessClassification,
} from "@components/Signup/Forms/hooks/useBusinessClassification";
import {
  BILLING_DESCRIPTOR_MAX_LENGTH,
  MERCHANT_PROVIDER_MAX_CHARS,
  SLUG_MAX_CHARACTER_LENGTH,
} from "@constants/constants";
import RESOURCE_BASE, {
  EDIT_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { HiddenComponent } from "@containers/HiddenComponent";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Stack } from "@mui/material";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { removeSpecialChars } from "@utils/slug";
import { urlSchema } from "@utils/validation.helpers";
import { useAccessControl } from "features/Permissions/AccessControl";
import { namespaces } from "localization/resources/i18n.constants";
import { matchIsValidTel } from "mui-tel-input";
import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export const formatBillingDescriptor = (value?: string) => {
  if (!value) return "";
  return value
    .replace(/[^a-zA-Z0-9]/g, "")
    ?.slice(0, BILLING_DESCRIPTOR_MAX_LENGTH)
    .toUpperCase();
};

type FormInputs = {
  merchantName: string;
  category: number;
  merchantSlug: string;
  websiteURL: string;
  billingDescriptor: string;
  servicePhoneNumber: string;
  enterprise?: number;
  description: string;
  isOutsideUSA: boolean;
  countriesOutside: string;
  classification: string;
  classification_description: string;
};

interface ModalProps {
  id: number;
  data: TMerchantInfo;
}

const EditEnterpriseInfoModal = NiceModal.create(({ id, data }: ModalProps) => {
  const { t } = useTranslation(namespaces.pages.enterprise.createMerchant);
  const { isMobileView } = useCustomTheme();
  const modal = useModal();
  const open = modal.visible;

  const isEditAllowed = useAccessControl({
    resource: RESOURCE_BASE.ENTERPRISE,
    operation: OPERATIONS.UPDATE,
  });

  const { handleSubmit, isLoading, isSuccess } = useUpdateMerchantInfo(id);

  const methods = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const classificationsParser = (res: TClassificationsAPIResponse) =>
    res?.data?.map(({ name, displayName }) => ({
      value: name,
      label: `${displayName}`,
    }));

  const { data: classificationOptions } = useBusinessClassification(
    "manage_submerchants",
    classificationsParser,
  );

  const {
    reset,
    watch,
    setValue,
    formState: { isDirty },
  } = methods;
  const values = watch();
  useEffect(() => {
    reset({
      merchantName: data.merchantName,
      merchantSlug: data.merchantSlug,
      websiteURL: data.websiteURL,
      servicePhoneNumber: `+${data.servicePhoneNumber}`,
      description: data.description || data.businessPurpose,
      isOutsideUSA: data?.serviceCountriesOutUSCanada || false,
      countriesOutside: data?.countriesServicedOutside || "",
      classification: data?.classification?.name || "",
      classification_description: data?.classCustomDescription || "",
    });
  }, [id, data]);

  useEffect(() => {
    if (!isDirty) return;

    const descriptor = formatBillingDescriptor(values.merchantName);
    const slug = removeSpecialChars(
      values.merchantName,
      SLUG_MAX_CHARACTER_LENGTH,
    );

    setValue("billingDescriptor", descriptor);
    setValue("merchantSlug", slug);
  }, [values.merchantName]);

  useEffect(() => {
    if (!isDirty) return;

    const slug = removeSpecialChars(
      values.merchantSlug,
      SLUG_MAX_CHARACTER_LENGTH,
    );
    setValue("merchantSlug", slug);
  }, [values.merchantSlug]);

  useEffect(() => {
    if (!isDirty) return;

    const descriptor = formatBillingDescriptor(values.billingDescriptor);
    setValue("billingDescriptor", descriptor);
  }, [values.billingDescriptor]);

  const handleCancel = () => {
    reset();
    modal.hide();
  };

  const onSubmit: SubmitHandler<FormInputs> = (customData) => {
    handleSubmit(
      "merchant_info",
      { ...customData, parentSlug: data.parentSlug },
      handleCancel,
    );
  };
  const inputList = [
    {
      input: (
        <NameInput
          label="Provider name"
          name="merchantName"
          placeholder="Enter Provider Name"
          isMerchantName
          disabled={!isEditAllowed}
        />
      ),
      sm: 12,
    },
    {
      input: (
        <RHFSelect
          label="Classification"
          name="classification"
          options={classificationOptions}
          disabled={!isEditAllowed}
        />
      ),
      sm: 12,
    },
    {
      input: (
        <RHFInput
          name="classification_description"
          label={"Describe your business"}
          multiline
          rows={6}
          fullWidth
        />
      ),
      sm: 12,
      hidden: values.classification !== "other",
    },
    {
      input: (
        <WebsiteInput
          name="merchantSlug"
          label={t("Permalink")}
          placeholder="generated-Permalink"
          inputSuffix=".givepayments.com"
          disabled={!isEditAllowed}
        />
      ),
    },
    {
      input: (
        <RHFTelInput
          label={`Customer Service Phone Number`}
          name="servicePhoneNumber"
          fullWidth
          disabled={!isEditAllowed}
        />
      ),
    },
    {
      input: (
        <WebsiteInput
          name="websiteURL"
          label={t("Website URL")}
          disabled={!isEditAllowed}
        />
      ),
      sm: 12,
    },
    {
      input: (
        <RHFInput
          name="description"
          label="Purpose or Mission of Provider"
          placeholder="Purpose or Mission of Provider"
          multiline
          rows={6}
          fullWidth
          disabled={!isEditAllowed}
          inputProps={{
            maxLength: "600",
          }}
          helperText={`${600 - (values.description?.length || 0)} ${t(
            "characters_remaining",
            { ns: namespaces.common },
          )}`}
        />
      ),
      sm: 12,
    },
    {
      input: (
        <Stack gap={1}>
          <RHFCheckbox
            name="isOutsideUSA"
            label="My Business services countries outside of the USA/Canada."
            disabled={!isEditAllowed}
            sx={{
              pl: 0.5,
            }}
          />
          {values.isOutsideUSA && (
            <RHFInput
              name="countriesOutside"
              label="Countries Serviced Outside USA/Canada"
              placeholder="Please enter the countries separated by commas"
              disabled={!isEditAllowed}
              multiline
              rows={4}
              fullWidth
            />
          )}
        </Stack>
      ),
      sm: 12,
    },
  ];

  return (
    <EditMerchantBaseModal
      title="Edit Provider Info"
      open={open}
      handleCancel={handleCancel}
      actions={
        <>
          <Button
            size="medium"
            background="tertiary"
            onClick={handleCancel}
            disabled={!isDirty || isLoading || isSuccess}
            sx={{
              padding: "8px 24px",
              whiteSpace: "nowrap",
              ...(isMobileView && {
                width: "50%",
              }),
            }}
          >
            Discard changes
          </Button>
          <Button
            size="medium"
            background="primary"
            type="submit"
            form="edit-merchant-info-form"
            disabled={!isDirty || isLoading || isSuccess}
            sx={{
              padding: "8px 24px",
              ...(isMobileView && {
                width: "50%",
              }),
            }}
          >
            Save
          </Button>
        </>
      }
    >
      <FormProvider {...methods}>
        <Box
          component="form"
          id="edit-merchant-info-form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Grid container spacing={1}>
            {inputList.map(({ input, sm, hidden }, index) => (
              <HiddenComponent hidden={hidden ? hidden : false} key={index}>
                <Grid key={index} item xs={12} sm={sm || 6}>
                  <FadeUpWrapper delay={50 * (index + 1)}>
                    <WithTooltipWrapper
                      hasTooltip={!isEditAllowed}
                      tooltipProps={{
                        message: EDIT_DENY_MESSAGE,
                        show: !isEditAllowed,
                      }}
                    >
                      {input}
                    </WithTooltipWrapper>
                  </FadeUpWrapper>
                </Grid>
              </HiddenComponent>
            ))}
          </Grid>
        </Box>
      </FormProvider>
    </EditMerchantBaseModal>
  );
});

export default EditEnterpriseInfoModal;

const defaultValues = {
  merchantName: "",
  merchantSlug: "",
  websiteURL: "",
  servicePhoneNumber: "",
  description: "",
  isOutsideUSA: false,
  countriesOutside: "",
  classification: "",
  classification_description: "",
};

const schema = Yup.object().shape({
  merchantName: Yup.string()
    .required("A name is required")
    .matches(/^[a-zA-Z0-9,.'\s]+$/)
    .matches(
      /^(?=(.*[a-zA-Z]){3}).*$/,
      "Please provide a valid name with at least 3 letters",
    )
    .max(
      MERCHANT_PROVIDER_MAX_CHARS,
      `Name can not contain more than ${MERCHANT_PROVIDER_MAX_CHARS} characters`,
    )
    .trim(),
  merchantSlug: Yup.string().required("Permalink can’t be empty"),
  servicePhoneNumber: Yup.string().when({
    is: (exists: string) => !!exists,
    then: (schema) =>
      schema.test(
        "is-valid-number",
        "Please enter a valid phone number",
        function (value) {
          const phoneNumber = value as string;
          return matchIsValidTel(phoneNumber);
        },
      ),
  }),
  websiteURL: urlSchema({
    isRequired: true,
    requiredMessage: "Merchant URL is required",
  }),
  description: Yup.string(),
  isOutsideUSA: Yup.boolean(),
  countriesOutside: Yup.string(),
  classification: Yup.string(),
  classification_description: Yup.string().when("classification", {
    is: "other",
    then: Yup.string().required("This field is required"),
    otherwise: Yup.string(),
  }),
});
