import SendGradientIcon from "@assets/icons/RebrandedIcons/SendGradientIcon";
import { Button } from "@common/Button";
import { Text } from "@common/Text";

import { Box, Stack } from "@mui/material";

import React from "react";

function InvitingComponent({ onClick }: { onClick: () => void }) {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      height="100%"
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <SendGradientIcon />
        <Text fontWeight="light" fontSize="18px" color="#292727" my="24px">
          Sending invitations in progress
        </Text>
        <Button onClick={onClick}>Check Progress</Button>
      </Box>
    </Stack>
  );
}

export default InvitingComponent;
