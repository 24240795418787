import { CloseIcon } from "@assets/icons";
import { HorizontalMoreIcon } from "@assets/icons/RebrandedIcons";
import CustomArrowRight from "@assets/icons/RebrandedIcons/ArrowRight";
import XCircle from "@assets/icons/RebrandedIcons/XCircle";
import { Button } from "@common/Button";
import { useReportMenuActions } from "@common/Campaigns/hooks";
import { CampaignType } from "@common/Campaigns/hooks/useReportMenuActions";
import { IconButton } from "@common/IconButton";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";
import { capitalizeFirstLetter } from "@common/Table/helpers";
import { TagType, TransactionTableTag } from "@common/Tag/TransactionTableTag";
import { Text } from "@common/Text";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import { WarningPlaceholderBase } from "@components/Merchants/MerchantPreview/components/WarningPlaceholdersSection";
import {
  MobileActionItemProps,
  useMobileDrawerActions,
} from "@components/MobileDrawerActions/hooks/useMobileDrawerActions";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { shouldBeHidden } from "@constants/constants";
import RESOURCE_BASE, {
  ACTION_DENY_MESSAGE,
  FEATURE_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Box, Grid, Stack, Theme, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useMobileProcessingActions } from "@pages/AcquirerPortal/Processing/ProcessingMenu";
import { palette } from "@palette";
import { ClockClockwise } from "@phosphor-icons/react";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { checkPortals } from "@utils/routing";
import { format } from "date-fns";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import { RiskProfileLevel } from "features/TransactionsRiskProfile/components";
import { namespaces } from "localization/resources/i18n.constants";
import { defaultTo } from "lodash";
import {
  EXPEDITE_TRANSFER_MODAL,
  MERCHANT_PORTAL_CANCEL_MODAL,
  MERCHANT_PORTAL_MARK_AS_FRAUD_MODAL,
  MERCHANT_PORTAL_REFUND_MODAL,
  MERCHANT_PORTAL_SEND_RECEIPT_MODAL,
  MERCHANT_VIEW_CUSTOMER,
  TRANSACTION_RISK_MODAL,
} from "modals/modal_names";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { accumulateFees, getSourceAccount } from "../transactions.helpers";
import { ActionsComponent } from "./ActionsComponent";
import { InformationsAmountComponent } from "./InformationsAmountComponent";
import { InformationsComponent } from "./InformationsComponent";
import OverviewFooter from "./OverviewFooter";
import { Purchases } from "./Purchases";
import { Separator } from "./Separator";
import TransactionInfoHeader from "./TransactionInfoHeader";
import { TransactionStatus } from ".";

type Props = {
  data: any;
  isMobile?: boolean;
  items?: MobileActionItemProps[];
  compaignType?: CampaignType;
  DMenu?: any;
  hideRefund: boolean;
  hideSendReceipt: boolean;
  onCloseDrawer?(): void;
  noModal?: boolean;
  tabs: TagType[];
  tabsData?: any[];
  currentTab: number;
  setCurrentTab: React.Dispatch<React.SetStateAction<number>>;
  isTransfer?: boolean;
  setSelectedRow?: React.Dispatch<any>;
  isFirst?: boolean;
  isLast?: boolean;
};

const TransactionInfoBody = ({
  data,
  isMobile,
  compaignType,
  DMenu,
  onCloseDrawer,
  hideRefund,
  hideSendReceipt,
  noModal,
  tabs,
  tabsData,
  currentTab,
  setCurrentTab,
  isFirst,
  isLast,
  setSelectedRow,
}: Props) => {
  const hasRiskProfile = useMediaQuery("(min-width:800px)");
  const [resetAnimation, setResetAnimation] = useState<boolean>(false);
  const { BottomActions } = useMobileDrawerActions();
  const { isDesktopView } = useCustomTheme();
  const { t } = useTranslation(namespaces.common);
  const modal = useModal();
  const { menuActions } = useReportMenuActions(
    data,
    (modalName, args) => NiceModal.show(modalName, args ?? {}),
    compaignType as CampaignType,
    isFirst,
    isLast,
    setSelectedRow,
  );

  const originalSettledTransactionFeeItems = tabsData?.find(
    (item: any) => item.displayStatus === TransactionStatus.SETTLED,
  )?.feeItems;

  const dateFormated =
    typeof data?.createdAt === "number"
      ? formatTimestamp(defaultTo<number, number>(data?.createdAt, 0) * 1000)
      : formatTimestamp(
          defaultTo<number, number>(data?.createdAtTimestamp, 0) * 1000,
        );

  const settlementDateFormatted = formatTimestamp(
    (data?.settlementDate || 0) * 1000,
  );

  const isTransfer = data?.displayType === "Money Transfer";
  const isPendingTransfer =
    data && isTransfer && data?.displayStatus === "Requested";

  const isCanceledTransfer = isTransfer && data?.displayStatus === "Canceled";
  const isDeclinedTransfer = isTransfer && data?.displayStatus === "Declined";
  const isErrorTransfer = isTransfer && data?.displayStatus === "Error";
  const isChargeback = data?.status === "Chargeback";
  const isRefund = data?.status === "Refunded";
  const isVoided = data?.displayStatus === "Voided";
  const isChargebackReversal = data?.status === "Chargeback Reversal";
  const isPurchase = data?.transactionType === "purchase";
  const isSettled = data?.displayStatus === TransactionStatus.SETTLED;

  const shouldRefundDisplayed =
    ["captured", "settled"].includes(data?.processingState) && isPurchase;

  const originalTransactionIsBlocked = data?.originalTransactionIsBlocked;
  const isMarkAsFraud = data?.isBlocked;
  const showFraud = ["captured", "settled", "authorized"].includes(
    data?.processingState,
  );

  const FACTOR = isChargeback ? 15 : 0;

  const {
    isAcquirerPortal,
    isEnterprisePortal,
    isMerchantPortal,
    isTransfersPage,
  } = checkPortals();
  const classes = useStyles();
  const { getMobileActions } = useMobileProcessingActions();
  const { risk_monitoring } = useEnterprisePermissions();

  const hideMarkAsFraud =
    !isPurchase ||
    !showFraud ||
    isMarkAsFraud ||
    originalTransactionIsBlocked ||
    !isAcquirerPortal ||
    data?.reversalState === "fully_reversed";

  let mobileActions: MobileActionItemProps[] = [];
  if (isAcquirerPortal || isEnterprisePortal) {
    mobileActions = getMobileActions(data);
  }

  useEffect(() => {
    setResetAnimation(true);
    const t = setTimeout(() => {
      setResetAnimation(false);
    }, 50);

    return () => clearTimeout(t);
  }, [currentTab]);

  const sharedData = {
    merchantName:
      isAcquirerPortal || isEnterprisePortal
        ? {
            title: "Merchant",
            value: (
              <Stack>
                <Text>
                  {!isChargeback && !isRefund
                    ? data?.destinationAccount?.merchant?.name
                    : data?.sourceAccount?.user?.name}
                </Text>
                <Text color={palette.gray[300]}>
                  {!isChargeback && !isRefund
                    ? data?.destinationAccount?.id
                    : data?.sourceAccount?.id}
                </Text>
              </Stack>
            ),
            verticalAlign: "flex-start",
          }
        : {},
    ...(isEnterprisePortal && {
      enterpriseDivider: {
        title: "",
        value: "divider",
      },
    }),
    enterpriseName: isAcquirerPortal
      ? {
          title: "Provider",
          value: (
            <Stack>
              <Text>
                {!isChargeback && !isRefund
                  ? data?.destinationAccount?.merchant?.parentName
                  : data?.sourceAccount?.user?.parentName}
              </Text>
              <Text color={palette.gray[300]}>
                {!isChargeback && !isRefund
                  ? data?.destinationAccount?.merchant?.parentAccID
                  : data?.sourceAccount?.user?.parentAccID}
              </Text>
            </Stack>
          ),
          verticalAlign: "flex-start",
        }
      : {},
    ...(isAcquirerPortal && {
      acquirerDivider: {
        title: "",
        value: "divider",
      },
    }),
  };
  const dots = `\u2022\u2022\u2022\u2022`;

  const columnData = {
    ...sharedData,
    thxDate: {
      title: "Transaction date",
      value: `${dateFormated.formattedDate}, ${dateFormated.formattedTime}`,
    },
    thxId: {
      title: "Transaction ID",
      value:
        data?.paymentDetails?.merchantTaxId ?? data?.transactionId ?? data?.id,
    },
    customerName: {
      title: "Customer",
      value: data?.sorceAccountFullName ?? data?.customerName,
    },
    cardholderName: {
      title: "Name on card",
      value:
        !isChargeback && !isRefund
          ? data?.sourceMethod?.method?.cardholderName
          : data?.destinationMethod?.card?.cardholderName, // TODO: Dragos P. will ask Aaron to remove it from chargebacks
    },
    cardType: {
      title: "Card type",
      value: capitalizeFirstLetter(
        !isChargeback && !isRefund
          ? data?.sourceMethod?.method?.brand
          : data?.destinationMethod?.card?.brand || "",
      ),
    },
    card: {
      title: "Card number",
      value: `${dots} ${
        !isChargeback && !isRefund
          ? data?.sourceMethod?.method?.numberLast4
          : data?.destinationMethod?.card?.numberLast4 || ""
      }`,
    },
    email: {
      title: "Email",
      value: data?.email ?? data?.customer?.email,
    },
  };

  const transferColumnData = {
    ...sharedData,
    thxId: {
      title: `${isTransfer ? "Transaction " : ""}ID`,
      value: data?.paymentDetails?.merchantTaxId ?? data?.id,
    },
    recipient: {
      title: "Recipient",
      value: data?.recipientAccountName,
    },
    bankName: {
      title: "Bank",
      value: data?.destinationMethod?.bankAccount?.bankName,
    },
    accountNo: {
      title: "Account number",
      value: `${dots} ${data?.destinationMethod?.bankAccount?.numberLast4}`,
    },
    routingNumber: {
      title: "Routing number",
      value: `${dots} ${data?.destinationMethod?.bankAccount?.RoutingNumber?.slice(
        -4,
      )}`,
    },
  };

  const isCancelTransferAllowed = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.BANK_TRANSFER,
    ),
    operation: OPERATIONS.UPDATE,
  });

  const isRefundAllowed = useAccessControl({
    resource: composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.REFUND),
    operation: OPERATIONS.CREATE,
  });

  const isMarkAsFraudAllowed = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.TRANSACTION,
    ),
    operation: OPERATIONS.BLOCK,
  });

  const actions = {
    [MERCHANT_PORTAL_REFUND_MODAL]: {
      hidden: hideRefund || Boolean(data?.hideRefund),
      label: "Refund",
      disabled: !isRefundAllowed,
      tooltipProps: {
        show: !isRefundAllowed,
        message: FEATURE_DENY_MESSAGE,
      },
      onClick: () => {
        NiceModal.show(MERCHANT_PORTAL_REFUND_MODAL, {
          sourceAccount: getSourceAccount(data),
          ids: {
            transactionIDs: [data?.transactionID || data?.id],
          },
          isFirst,
          isLast,
          setSelectedRow,
        });
      },
    },
    [MERCHANT_PORTAL_SEND_RECEIPT_MODAL]: {
      hidden: hideSendReceipt,
      label: isRefund ? "Resend refund receipt" : "Resend receipt",
      onClick: () => {
        NiceModal.show(MERCHANT_PORTAL_SEND_RECEIPT_MODAL, {
          transactionID: data?.transactionId || data?.id,
          emails: [getSourceAccount(data)?.email],
          sourceAccount: getSourceAccount(data),
          customerID:
            !isChargeback && !isRefund
              ? data?.destinationAccount?.id
              : data?.sourceAccount?.id,
        });
      },
    },
    [MERCHANT_VIEW_CUSTOMER]: {
      hidden: isTransfer || isVoided,
      label: "View customer",
      onClick: () => {
        const customerId = data?.customer?.id;
        if (customerId)
          NiceModal.show(MERCHANT_VIEW_CUSTOMER, {
            id: customerId,
            merchantId:
              !isChargeback && !isRefund
                ? data?.destinationAccount?.id
                : data?.sourceAccount?.id,
          });
      },
    },
    [MERCHANT_PORTAL_MARK_AS_FRAUD_MODAL]: {
      hidden: hideMarkAsFraud,
      label: "Mark as fraud",
      disabled: !isMarkAsFraudAllowed,
      tooltipProps: {
        show: !isMarkAsFraudAllowed,
        message: ACTION_DENY_MESSAGE,
      },
      onClick: () => {
        NiceModal.show(MERCHANT_PORTAL_MARK_AS_FRAUD_MODAL, {
          shouldRefundDisplayed: shouldRefundDisplayed,
          transactionId: [data?.transactionID || data?.id],
          customerID: data?.destinationAccount.id,
          isFirst,
          isLast,
          setSelectedRow,
        });
      },
    },
  };

  const extraActions = useMemo(
    () => [
      {
        title: t("transactionTable.RefundTransaction", {
          ns: namespaces.common,
        }),
        disabled: shouldBeHidden.refund || data?.hideRefund || hideRefund,
        hidden: shouldBeHidden.refund || data?.hideRefund || hideRefund,
        onClick: () => {
          NiceModal.show(MERCHANT_PORTAL_REFUND_MODAL, {
            sourceAccount: getSourceAccount(data),
            ids: {
              transactionIDs: [data?.transactionID || data?.id],
            },
            isFirst,
            isLast,
            setSelectedRow,
          });
        },
      },
      {
        title: t("transactionTable.SendReceipt", {
          ns: namespaces.common,
        }),
        hidden: hideSendReceipt,
        onClick: () => {
          NiceModal.show(MERCHANT_PORTAL_SEND_RECEIPT_MODAL, {
            transactionID: data?.transactionId || data?.id,
            emails: [data?.sourceAccount?.user?.email],
            sourceAccount: getSourceAccount(data),
            customerID:
              !isChargeback && !isRefund
                ? data?.destinationAccount.id
                : data?.sourceAccount.id,
          });
        },
        testId: "transaction-table-send-receipt-action-button",
      },
      {
        title: "Cancel Transfer",
        disabled: !isCancelTransferAllowed,
        hidden: !isPendingTransfer,
        onClick: () => {
          NiceModal.show(MERCHANT_PORTAL_CANCEL_MODAL, {
            transactionId: data?.id,
            cancel: true,
            sourceAccount: getSourceAccount(data),
            customerID: data?.destinationAccount.id,
          });
        },
      },
      {
        title: t("transactionTable.ViewCustomer", {
          ns: namespaces.common,
        }),
        disabled: false,
        hidden: isTransfer || isVoided,
        onClick: () => {
          const customerId = data?.customer?.id;
          if (customerId)
            NiceModal.show(MERCHANT_VIEW_CUSTOMER, {
              id: customerId,
              merchantId:
                !isChargeback && !isRefund
                  ? data?.destinationAccount?.id
                  : data?.sourceAccount?.id,
            });
        },
      },
    ],
    [data],
  );

  const manageMoneyItems: MobileActionItemProps[] = extraActions.map(
    ({ title, hidden, onClick, disabled, testId }) => ({
      label: title,
      onSelect: onClick,
      hidden: hidden,
      labelProps: {
        disabled,
      },
      testId,
    }),
  );

  const menuItems: MobileActionItemProps[] = menuActions?.map(
    ({ title, onClick, hidden }) => ({
      label: title,
      onSelect: onClick,
      hidden: hidden,
    }),
  );

  const riskLevel = data?.ipProfileRiskLevel;
  const ipProfileID = data?.ipProfileID;

  const isRiskLevelHidden = !isChargeback && !isChargebackReversal && !isRefund; //risk level hidden according status

  const canViewIP = useAccessControl({
    resource: RESOURCE_BASE.IPPROFILE,
    operation: OPERATIONS.LIST,
    withPortal: true,
  });

  const canViewTransactionList = useAccessControl({
    resource: RESOURCE_BASE.IPPROFILE_TRANSACTION,
    operation: OPERATIONS.LIST,
    withPortal: true,
  });

  const isRiskHeaderVisible =
    !shouldBeHidden.riskProfile &&
    (shouldBeHidden.enterpriseConfiguration
      ? isAcquirerPortal
      : risk_monitoring && !isMerchantPortal) &&
    !!ipProfileID &&
    isRiskLevelHidden;

  const isRiskProfileVisible = isRiskHeaderVisible && hasRiskProfile;

  const warningInfo = useMemo(() => {
    if (isRefund && originalTransactionIsBlocked)
      return {
        title: "Refund Issued",
        message: data?.originalTransactionBlockReason,
      };

    if (isMarkAsFraud)
      return {
        title: "Marked as fraud",
        message: data?.blockedReason,
      };

    if (isCanceledTransfer && data?.canceledReason)
      return {
        title: "Canceled",
        message: `Reason: "${data?.canceledReason}"`,
      };

    if (isDeclinedTransfer && data?.declinedReason)
      return {
        title: "Declined",
        message: `Reason: "${data?.declinedReason}"`,
        type: "Error",
      };

    if (isErrorTransfer && data?.errorReason)
      return {
        title: "Error",
        message: `Reason: "${data?.errorReason}"`,
        type: "Error",
      };

    return { title: "", message: "" };
  }, [isRefund, originalTransactionIsBlocked, data]);

  const showWarningInfo =
    isMarkAsFraud ||
    isCanceledTransfer ||
    (isRefund && originalTransactionIsBlocked);

  const openRiskModal = () => {
    if (!isDesktopView || (!canViewIP && !canViewTransactionList)) return;
    modal.hide();
    NiceModal.show(TRANSACTION_RISK_MODAL, {
      ipProfileID,
    });
  };

  const transferActions = useMemo(
    () => [
      {
        title: "Expedite Transfer",
        Icon: ClockClockwise,
        hidden: !isAcquirerPortal,
        onClick: () => {
          NiceModal.show(EXPEDITE_TRANSFER_MODAL, {
            transactionId: data?.id,
            customerID: data?.destinationAccount?.id,
          });
        },
      },
      {
        title: "Cancel Transfer",
        Icon: CloseIcon,
        disabled: !isCancelTransferAllowed,
        tooltipProps: {
          show: !isCancelTransferAllowed,
          message: FEATURE_DENY_MESSAGE,
        },
        onClick: () => {
          NiceModal.show(MERCHANT_PORTAL_CANCEL_MODAL, {
            transactionId: data?.id,
            customerID: data?.sourceAccount?.id,
          });
        },
      },
    ],
    [
      data?.id,
      data?.destinationAccount?.id,
      data?.sourceAccount?.id,
      isCancelTransferAllowed,
      isAcquirerPortal,
    ],
  );

  const chargebackFee = accumulateFees(
    data?.feeItems,
    (curr: any) => curr.type === "chargeback_fee",
  );

  return (
    <Grid
      width="100%"
      sx={{
        ...(noModal && {
          padding: "16px",
        }),
      }}
      className={classes.bodyWrapper}
      data-testid="transaction-info-body"
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        mb={onCloseDrawer && noModal ? 1 : 0}
      >
        {onCloseDrawer && noModal && (
          <IconButton
            onClick={onCloseDrawer}
            size="small"
            className={classes.iconBtn}
          >
            <CustomArrowRight />
          </IconButton>
        )}
      </Stack>
      <Box className={classes.tagWrapper}>
        {tabs.map((tab, index) => {
          const isSelected = index === currentTab;
          const isLast = tabs.length > 1 && index === tabs?.length - 1;
          const tabType = tab === "failed" && isTransfer ? "error" : tab;

          return (
            <FadeUpWrapper delay={120} key={index} width="max-content">
              <WithTooltipWrapper
                /* Tooltip is only displayed for settled transaction, not chargeback / chargeback reversal
                   which also contains settled transaction
                */
                hasTooltip={isSettled && tabs.length === 1}
                tooltipProps={{
                  show: isSettled,
                  message: `${settlementDateFormatted.formattedDate}, ${settlementDateFormatted.formattedTime}`,
                  placement: "bottom",
                }}
              >
                <TransactionTableTag
                  isTextCenter={false}
                  sx={{
                    maxWidth: "fit-content !important",
                    display: "flex",
                    flexDirection: "column",
                    padding: "8px !important",
                  }}
                  textProps={{
                    fontSize: "14px",
                    lineHeight: "16.8px",
                  }}
                  isOriginal={isLast}
                  type={tabType}
                  active={isSelected}
                  onClick={() => setCurrentTab(index)}
                />
              </WithTooltipWrapper>
            </FadeUpWrapper>
          );
        })}
      </Box>
      {isPendingTransfer && (
        <FadeUpWrapper delay={130} reset={resetAnimation}>
          <Stack direction="row" spacing={2}>
            {transferActions.map(
              ({ title, Icon, onClick, hidden, disabled, tooltipProps }) => (
                <Fragment key={title}>
                  {!hidden && (
                    <WithTooltipWrapper
                      hasTooltip={disabled || false}
                      tooltipProps={tooltipProps}
                    >
                      <Button
                        background="tertiary"
                        sx={subButtonsStyle}
                        onClick={onClick}
                        disabled={disabled}
                        endIcon={
                          <Icon
                            stroke={palette.neutral[70]}
                            width={25}
                            height={25}
                          />
                        }
                      >
                        {title}
                      </Button>
                    </WithTooltipWrapper>
                  )}
                </Fragment>
              ),
            )}
          </Stack>
        </FadeUpWrapper>
      )}
      {showWarningInfo && warningInfo.title && (
        <FadeUpWrapper delay={130} reset={resetAnimation}>
          <WarningPlaceholderBase
            title={warningInfo.title}
            message={warningInfo.message}
            containerStyle={{
              marginBottom: "8px",
              marginTop: "4px",
              border: "none",
              backgroundColor:
                warningInfo?.type === "Error"
                  ? palette.tag.error.bg
                  : palette.tag.warning.bg,
            }}
            titleColor={
              warningInfo?.type === "Error"
                ? palette.filled.red
                : palette.filled.orange
            }
            titleStyle={{ fontSize: 18, lineHeight: "21.6px" }}
            messageColor={
              warningInfo?.type === "Error"
                ? palette.tag.error.text
                : palette.warning.text
            }
            icon={
              warningInfo?.type === "Error" ? (
                <XCircle height={20} width={20} />
              ) : undefined
            }
          />
        </FadeUpWrapper>
      )}
      <Grid>
        {!isMobile && !isVoided && !isTransfer && (
          <Box display="flex" justifyContent="space-between">
            <FadeUpWrapper delay={140} reset={resetAnimation}>
              <ActionsComponent actions={actions} />
            </FadeUpWrapper>

            {noModal && (
              <Box display="inline-block">
                <TransactionInfoHeader
                  data={data}
                  hideRefund={hideRefund || data.hideRefund}
                  hideSendReceipt={hideSendReceipt}
                  onCloseDrawer={onCloseDrawer}
                  actionsOnly
                />
              </Box>
            )}
          </Box>
        )}
        <FadeUpWrapper delay={130} containerProps={{ paddingBottom: "16px" }}>
          <Grid className={classes.overviewWrapper}>
            <FadeUpWrapper delay={130} reset={resetAnimation}>
              <Stack
                flexGrow={1}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text
                  className={classes.overviewHeader}
                  data-testid="transaction-info-summary-title"
                >
                  Summary
                </Text>
                {isRiskHeaderVisible && (
                  <RiskProfileLevel level={riskLevel} onClick={openRiskModal} />
                )}
              </Stack>
            </FadeUpWrapper>
            <FadeUpWrapper delay={130} reset={resetAnimation}>
              <Separator marginBottom={0} />
            </FadeUpWrapper>
            <InformationsComponent
              columnData={isTransfer ? transferColumnData : columnData}
              isMobile={isMobile}
              isTransfer={isTransfer}
              resetAnimation={resetAnimation}
            />
            <FadeUpWrapper delay={140} reset={resetAnimation}>
              <Separator marginBottom={0} />
            </FadeUpWrapper>
            <InformationsAmountComponent
              cost={data?.cost ?? data?.price}
              fees={isChargeback ? data?.fees - FACTOR : data?.fees}
              charged={data?.charged}
              isChargeback={isChargeback}
              isTransfer={isTransfer}
              status={data?.displayStatus}
              recipient={data?.destinationAccount?.merchant?.name}
              chargebackFee={Number(chargebackFee)}
              isMobile={isMobile}
              passFees={data?.passFees}
              isRefund={isRefund}
              resetAnimation={resetAnimation}
              balanceAdjustment={data?.balanceAdjustment}
              feeItems={data?.feeItems}
              originalSettledTransactionFeeItems={
                originalSettledTransactionFeeItems
              }
            />
            <FadeUpWrapper delay={150} reset={resetAnimation}>
              <Separator marginBottom={0} />
            </FadeUpWrapper>
            <FadeUpWrapper delay={160} reset={resetAnimation}>
              <OverviewFooter
                balanceAdjustment={
                  isTransfersPage
                    ? data?.cost || 0
                    : data?.balanceAdjustment || 0
                }
                endingBalance={data?.endingBalance || 0}
                transactionStatus={data?.displayStatus}
                isTransfer={isTransfer}
                transactionType={data?.transactionType}
                processingState={data?.processingState}
                isCanceledTransfer={isCanceledTransfer}
              />
            </FadeUpWrapper>
          </Grid>
        </FadeUpWrapper>
        {data?.items?.length && !isTransfer && (
          <Grid paddingBlock={isRiskProfileVisible ? 0 : "16px"}>
            <FadeUpWrapper delay={170} reset={resetAnimation}>
              <>
                <Text className={classes.overviewHeader}>Purchases</Text>
                <Purchases
                  transactionStatus={data?.displayStatus}
                  sourceAccount={getSourceAccount(data)}
                  items={data?.items}
                  hideRefund={hideRefund || Boolean(data?.hideRefund)}
                />
              </>
            </FadeUpWrapper>
          </Grid>
        )}
      </Grid>
      {isMobile && manageMoneyItems.filter((x) => !x.hidden).length > 0 && (
        <BottomActions
          sx={{
            "& .MuiPaper-root": {
              padding: 0,
              bottom: "100px",
            },
          }}
          label={
            <HorizontalMoreIcon
              height={41}
              width={41}
              fill={palette.background.dimmedWhite}
            />
          }
          items={[...(DMenu ? menuItems : manageMoneyItems), ...mobileActions]}
          buttonSx={{
            position: "fixed",
            bottom: "15px",
            left: 0,
            right: 0,
          }}
        />
      )}
    </Grid>
  );
};

const subButtonsStyle = {
  padding: 0,
  fontSize: 14,
  fontWeight: "400",
  "& span": {
    marginBottom: "3px !important",
  },
};

const useStyles = makeStyles<Theme>((theme) => ({
  iconBtn: {
    border: "none",
    boxShadow: "none",
    background: "inherit",
    width: "fit-content !important",
    "&:hover": {
      background: palette.neutral[20],
    },
    "&:disabled": {
      background: "transparent",
      boxShadow: "none",
    },
  },
  bodyWrapper: {
    flexGrow: 1,
    overflowY: "auto",
    scrollbarGutter: "stable both-edges",
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      paddingBottom: "89px",
    },
  },
  tagWrapper: {
    paddingBlock: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "8px",
    flexGrow: 1,
    overflowX: "auto",
    overflowY: "hidden",
    whiteSpace: "nowrap",

    "&::-webkit-scrollbar-track": {
      background: "inherit",
      display: "none",
    },
    "&::-webkit-scrollbar-thumb": {
      display: "none",
      width: "1px",
    },
    [theme.breakpoints.down("sm")]: {
      WebkitOverflowScrolling: "touch",
    },
  },
  overviewWrapper: {
    width: "inherit",
    background: palette.neutral[10],
    padding: "16px",
    borderRadius: "12px",
    gap: "16px",
    display: "flex",
    flexDirection: "column",
  },
  overviewHeader: {
    fontSize: "24px",
    fontWeight: "350",
    lineHeight: "135%",
    letterSpacing: "-0.24px",
  },
}));

function formatTimestamp(timestamp: number) {
  const date = new Date(timestamp);

  const formattedDate = format(date, "MMM d, yyyy");
  const formattedTime = format(date, "HH:mm");

  return {
    formattedDate,
    formattedTime,
  };
}

export default TransactionInfoBody;
