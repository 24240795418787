import { ScrollIcon, SignpostIcon } from "@assets/icons/RebrandedIcons";
import { ProvinceInput, ZipCodeInput } from "@common/AddressInputs";
import { NameInput } from "@common/BusinessProfileInputs";
import BusinessTypeSelect from "@common/BusinessProfileInputs/BusinessTypeSelect";
import CustomTaxSsnInput from "@common/BusinessProfileInputs/CustomTaxSsnInput";
import OwnershipTypeSelect from "@common/BusinessProfileInputs/OwnershipTypeSelect";
import { Button } from "@common/Button";
import ErrorCatcher from "@common/Error/ErrorCatcher";
import { RHFInput, RHFTelInput } from "@common/Input";
import { RHFStreetAddress } from "@common/RHFStreetAddress";
import { RHFSelect } from "@common/Select";
import { RHFSwitch } from "@common/Switch";
import { Text } from "@common/Text";
import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import RHFDateInput from "@components/Analytics/SidePanels/CampaignSidePanel/components/RHFDateInput";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import EditMerchantBaseModal from "@components/Merchants/MerchantPreview/components/EditMerchantBaseModal";
import NiceModal from "@ebay/nice-modal-react";
import useCountryCodes from "@hooks/common/useCountryCodes";
import { Box, Divider, Grid, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { subDays } from "date-fns";
import { useEffect, useRef } from "react";
import { FormProvider } from "react-hook-form";
import MerchantExistingProfiles from "../components/MerchantExistingProfiles";
import ProfileModalPreviewInfo from "./components/ProfileModalPreviewInfo";
import useBusinessProfileModal, {
  IUseBusinessProfileModal,
} from "./useBusinessProfileModal";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";
import { EDIT_DENY_MESSAGE } from "@constants/permissions";

export enum FormType {
  CREATE_ENTERPRISE = "CREATE_ENTERPRISE",
  CREATE_MERCHANT = "CREATE_MERCHANT",
  EDIT_ENTERPRISE = "EDIT_ENTEPRISE",
}

type SectionType = "business_profile" | "business_address";
export type EditBusinessHandler = (section: SectionType) => void;

type CreateBusinessProfileModalProps = IUseBusinessProfileModal & {
  section?: SectionType;
};

const ModalContent = ({
  data,
  formType = FormType.CREATE_MERCHANT,
  isLegalEntityApproved = false,
  isEdit = false,
  section = "business_profile",
  merchantName,
  ...rest
}: CreateBusinessProfileModalProps) => {
  const { selectCountriesList } = useCountryCodes();
  const { isMobileView } = useCustomTheme();
  const {
    open,
    isLoading,
    canEdit,
    canEditWithLegalEntity,
    showAccessDeniedTooltip,
    handleCancel,
    handleClose,
    onSubmit,
    methods,
    parsedSelectedProfile,
    setSelectedProfile,
  } = useBusinessProfileModal({
    data,
    formType,
    isLegalEntityApproved,
    isEdit,
    ...rest,
  });
  const addressInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (section !== "business_address" || !addressInputRef.current) return;
      addressInputRef.current.scrollIntoView();
      addressInputRef.current.focus();
    }, 600);

    return () => clearTimeout(timer);
  }, []);

  const {
    watch,
    formState: { isDirty, isValid },
    setValue,
  } = methods;
  const values = watch();

  const { modify_merchant, b_profile_linking } = useEnterprisePermissions();

  const isEditableByStatus =
    data?.statusName === "suspended" || data?.statusName === "pending";

  const inputList = [
    {
      input: (
        <LinkProfileContainer>
          <RHFSwitch
            label="Link to an existing profile"
            name="isLinkBusinessProfile"
            size="small"
            data-testid="link-business-profile-switch"
            disabled={!canEdit && !isEditableByStatus}
          />
          <Text
            fontWeight="book"
            color={palette.neutral[70]}
            lineHeight="16.8px"
          >
            If the {formType === "CREATE_MERCHANT" ? "Merchant" : "Provider"}{" "}
            belongs to an existing business use this to link it to that profile
          </Text>
        </LinkProfileContainer>
      ),
      hidden: (isEdit && isLegalEntityApproved) || !b_profile_linking,
      hasTooltip: true,
    },
    {
      input: (
        <MerchantExistingProfiles
          merchantLoading={isLoading}
          selectedProfile={parsedSelectedProfile}
          onNewItemSelected={setSelectedProfile}
        />
      ),
      hidden: !values.isLinkBusinessProfile || !b_profile_linking,
    },
    {
      input: (
        <NameInput
          name="legalName"
          label="Business legal name"
          placeholder="Enter Legal Name"
          isLegalName
          disabled={!canEditWithLegalEntity && !isEditableByStatus}
        />
      ),
      hidden: values.isLinkBusinessProfile || !modify_merchant,
      hasTooltip: true,
    },
    {
      input: (
        <RHFInput
          name="DBA"
          label="Doing Business As (Optional)"
          placeholder="Alternative Name"
          fullWidth
          disabled={!canEdit && !isEditableByStatus}
        />
      ),
      hidden: values.isLinkBusinessProfile || !modify_merchant,
      hasTooltip: true,
    },
    {
      input: (
        <RHFDateInput
          name="businessOpenedAt"
          label={`Business creation date ${
            !isMobileView ? "(dd/mm/yyyy)" : ""
          }`}
          maxDate={subDays(new Date(), 1)}
          disabled={!canEdit && !isEditableByStatus}
        />
      ),
      hidden: values.isLinkBusinessProfile || !modify_merchant,
      hasTooltip: true,
    },
    {
      input: (
        <BusinessTypeSelect
          name="businessType"
          label="Business type"
          disabled={!canEditWithLegalEntity && !isEditableByStatus}
        />
      ),
      hidden: values.isLinkBusinessProfile || !modify_merchant,
      hasTooltip: true,
    },
    {
      input: (
        <CustomTaxSsnInput
          ssnName="ssn"
          taxIdName="taxID"
          businessTypeName="businessType"
          tinType="tinType"
          disabled={!canEditWithLegalEntity && !isEditableByStatus}
        />
      ),
      hidden: values.isLinkBusinessProfile || !modify_merchant,
      hasTooltip: true,
    },
    {
      input: (
        <OwnershipTypeSelect
          name="ownershipType"
          label="Business ownership type"
          businessTypeName="businessType"
          initialValue={data.ownershipType as any}
          disabled={!canEdit && !isEditableByStatus}
        />
      ),
      hidden: values.isLinkBusinessProfile || !modify_merchant,
      hasTooltip: true,
    },
    {
      input: (
        <RHFTelInput
          name="contactPhone"
          label="Contact phone"
          fullWidth
          disabled={!canEdit && !isEditableByStatus}
        />
      ),
      hidden: values.isLinkBusinessProfile || !modify_merchant,
      hasTooltip: true,
    },
    {
      input: <Divider sx={{ marginBlock: "16px" }} flexItem />,
      sm: 12,
      hidden: values.isLinkBusinessProfile || !modify_merchant,
    },
    {
      input: (
        <Stack direction="row" spacing={1} alignItems="center" mb={1}>
          <SignpostIcon />
          <Text
            fontSize={18}
            color={palette.neutral[80]}
            lineHeight="21.6px"
            letterSpacing="-0.18px"
          >
            Business Address
          </Text>
        </Stack>
      ),
      sm: 12,
      hidden: values.isLinkBusinessProfile || !modify_merchant,
    },
    {
      input: (
        <RHFSelect
          name="address.country"
          label="Country"
          placeholder="Country"
          disabled
          options={selectCountriesList}
        />
      ),
      sm: 12,
      hidden: values.isLinkBusinessProfile || !modify_merchant,
    },
    {
      input: (
        <RHFStreetAddress
          name="address.address"
          label="Street address"
          placeholder="Enter Street Address..."
          fullWidth
          inputRef={addressInputRef}
          disabled={!canEdit && !isEditableByStatus}
        />
      ),
      sm: 12,
      hidden: values.isLinkBusinessProfile || !modify_merchant,
      hasTooltip: true,
    },
    {
      input: (
        <RHFInput
          name="address.city"
          label="City"
          placeholder="Enter city..."
          fullWidth
          disabled={!canEdit && !isEditableByStatus}
        />
      ),
      md: 4,
      hidden: values.isLinkBusinessProfile || !modify_merchant,
      hasTooltip: true,
    },
    {
      input: (
        <ProvinceInput
          name="address.state"
          isUS={values.address?.country === "US"}
          disabled={!canEdit && !isEditableByStatus}
        />
      ),
      md: 4,
      hidden: values.isLinkBusinessProfile || !modify_merchant,
      hasTooltip: true,
    },
    {
      input: (
        <ZipCodeInput
          name="address.zip"
          label="ZIP"
          placeholder="Enter Zip Code"
          letterSpacing="4px"
          disabled={!canEdit && !isEditableByStatus}
        />
      ),
      md: 4,
      hidden: values.isLinkBusinessProfile || !modify_merchant,
      hasTooltip: true,
    },
  ];

  useEffect(() => {
    !data?.legalName && merchantName && setValue("legalName", merchantName);
  }, [data]);

  return (
    <EditMerchantBaseModal
      title={`${isEdit ? "Edit" : "Add"} Business Profile (legal entity)`}
      icon={<ScrollIcon />}
      open={open}
      maxWidth={"600px"}
      handleCancel={handleClose}
      actions={
        <ModalActions
          isEdit={isEdit}
          primaryButton={{
            label: "Save",
            disabled: (!isEdit && !isValid) || !isDirty || isLoading,
            isLinkBusinessProfile: values?.isLinkBusinessProfile,
          }}
          secondaryButton={{
            label: "Discard",
            onClick: isEdit ? handleCancel : handleClose,
            disabled: (isEdit && !isDirty) || isLoading,
          }}
        />
      }
    >
      <FormProvider {...methods}>
        <Box
          component="form"
          id="create-business-profile"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Grid container spacing={"12px"} id="create-business-profile-form">
            {inputList
              .filter(({ hidden }) => !hidden)
              .map(({ input, sm, md, hasTooltip }, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={sm || 12}
                  md={md || sm || 12}
                >
                  <FadeUpWrapper delay={50 * (index + 1)}>
                    <WithTooltipWrapper
                      hasTooltip={!!hasTooltip && !isEditableByStatus}
                      tooltipProps={{
                        message: EDIT_DENY_MESSAGE,
                        show: showAccessDeniedTooltip,
                      }}
                    >
                      {input}
                    </WithTooltipWrapper>
                  </FadeUpWrapper>
                </Grid>
              ))}
          </Grid>
          {values.isLinkBusinessProfile && (
            <ProfileModalPreviewInfo selectedProfile={parsedSelectedProfile} />
          )}
        </Box>
      </FormProvider>
    </EditMerchantBaseModal>
  );
};

const CreateBusinessProfileModal = NiceModal.create(
  (props: CreateBusinessProfileModalProps) => (
    <ErrorCatcher errorID="business-profile-modal">
      <ModalContent {...props} />
    </ErrorCatcher>
  ),
);

type ActionButtonProps = {
  label: string;
  disabled: boolean;
  onClick?: () => void;
  isLinkBusinessProfile?: boolean;
};

const ModalActions = ({
  isEdit,
  secondaryButton,
  primaryButton,
}: {
  isEdit: boolean;
  secondaryButton: ActionButtonProps;
  primaryButton: ActionButtonProps;
}) => {
  const { isMobileView } = useCustomTheme();
  const disableTooltip = !(
    primaryButton.disabled && primaryButton.isLinkBusinessProfile
  );
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent={isMobileView && !isEdit ? "stretch" : "flex-end"}
      gap={isMobileView && !isEdit ? 0 : 2}
      width="100%"
    >
      <Button
        background="tertiary"
        size="medium"
        disabled={secondaryButton.disabled}
        onClick={secondaryButton.onClick}
        sx={{
          fontSize: "14px",
          lineHeight: "14px",
          fontWeight: 400,
          textDecoration: "none",
          color: palette.neutral[70],
          ...(isMobileView && {
            flexGrow: 1,
          }),
          "&:hover": {
            textDecoration: "none",
          },
        }}
      >
        {secondaryButton.label}
      </Button>
      <Button
        disabled={primaryButton.disabled}
        size="medium"
        background="primary"
        type="submit"
        form="create-business-profile"
        sx={{
          fontSize: "14px",
          lineHeight: "14px",
          fontWeight: 400,
          padding: "8px 32px",
          ...(isMobileView && {
            width: "100%",
          }),
        }}
        tooltipProps={{
          show: !disableTooltip,
          message: "Please connect an approved business profile",
        }}
      >
        {primaryButton.label}
      </Button>
    </Stack>
  );
};

const LinkProfileContainer = styled(Box)(() => ({
  display: "flex",
  padding: "16px",
  flexDirection: "column",
  gap: "16px",
  borderRadius: "8px",
  background: palette.background.dimmedWhite,
  boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.05) inset",
  "& p": {
    margin: 0,
  },
}));

export default CreateBusinessProfileModal;
