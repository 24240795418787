import { getJsonValue } from "../utils";
import { convertHexToRGB } from "@utils/theme";
import { ThemeMode } from "@theme/v2/types";
import { IconButtonProps } from "@mui/material";

declare module "@mui/material/IconButton" {
  interface IconButtonOwnProps {
    variant?: "filled" | "ghost";
  }

  interface IconButtonPropsColorOverrides {
    ghost: true;
    darken: true;
    solidBlack: true;
    solidWhite: true;
  }

  interface IconButtonPropsSizeOverrides {
    extraSmall: true;
  }
}

const getFilledVariantColors = (mode: ThemeMode) => [
  {
    props: (props: IconButtonProps) => props.variant === "filled",
    style: {
      backgroundColor: getJsonValue(
        `tokens.${mode}.primitive.transparent.darken-5`,
      ),
      "&:hover": {
        backgroundColor: getJsonValue(
          `tokens.${mode}.primitive.transparent.darken-10`,
        ),
      },
      "&:active": {
        backgroundColor: getJsonValue(
          `tokens.${mode}.primitive.transparent.darken-10`,
        ),
        color: convertHexToRGB(
          getJsonValue(`tokens.${mode}.colour.icon.icon-primary`),
          0.5,
        ),
      },
    },
  },
  {
    props: (props: IconButtonProps) =>
      props.variant === "filled" && props.color === "solidBlack",
    style: {
      color: getJsonValue(`tokens.${mode}.primitive.neutral.0`),
      backgroundColor: getJsonValue(`tokens.${mode}.colour.buttons.default`),
      "&:hover": {
        backgroundColor: getJsonValue(
          `tokens.${mode}.colour.buttons.default-hover`,
        ),
      },
      "&:active": {
        backgroundColor: getJsonValue(`tokens.${mode}.colour.buttons.default`),
        color: convertHexToRGB(
          getJsonValue(`tokens.${mode}.primitive.neutral.0`),
          0.5,
        ),
      },
    },
  },
  {
    props: (props: IconButtonProps) =>
      props.variant === "filled" && props.color === "solidWhite",
    style: {
      color: getJsonValue(`tokens.${mode}.primitive.neutral.100`),
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: getJsonValue(
          `tokens.${mode}.colour.surface.secondary`,
        ),
      },
      "&:active": {
        backgroundColor: getJsonValue(`tokens.${mode}.colour.surface.tertiary`),
        color: convertHexToRGB(
          getJsonValue(`tokens.${mode}.primitive.neutral.100`),
          0.5,
        ),
      },
    },
  },
];

export const getCustomIconButtonStyle = (mode: ThemeMode) => {
  return {
    defaultProps: {
      disableRipple: true,
      disableTouchRipple: true,
      disableFocusRipple: true,
      variant: "ghost",
    },
    styleOverrides: {
      root: {
        padding: "6px",
        borderRadius: "8px",
        color: getJsonValue(`tokens.${mode}.colour.icon.icon-primary`),
        width:"fit-content",
        variants: [
          {
            props: (props: IconButtonProps) => props.variant === "ghost",
            style: {
              "&:hover": {
                backgroundColor: getJsonValue(
                  `tokens.${mode}.primitive.transparent.darken-5`,
                ),
              },
              "&:active": {
                backgroundColor: getJsonValue(
                  `tokens.${mode}.colour.surface.tertiary`,
                ),
                color: convertHexToRGB(
                  getJsonValue(`tokens.${mode}.colour.icon.icon-primary`),
                  0.5,
                ),
              },
            },
          },
          ...getFilledVariantColors(mode),
          {
            props: (props: IconButtonProps) => props.disabled,
            style: {
              color: convertHexToRGB(
                getJsonValue(`tokens.${mode}.colour.icon.icon-primary`),
                0.5,
              ),
            },
          },
          {
            props: (props: IconButtonProps) => props.size === "large",
            style: {
              padding: "10px",
            },
          },
          {
            props: (props: IconButtonProps) => props.size === "extraSmall",
            style: {
              padding: "4px",
              borderRadius: "38px",
            },
          },
        ],
      },
    },
  };
};
