import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";
import React, { useRef, useState } from "react";
import NiceModal from "@ebay/nice-modal-react";
import { TInviteElement } from "../types";
import { Box, Stack } from "@mui/material";
import { Text } from "@common/Text";
import { Button } from "@common/Button";
import { TrashSimple } from "@phosphor-icons/react";
import { Grid } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { RHFInput } from "@common/Input";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty } from "lodash";
import { palette } from "@palette";

const defaultValues = {
  pahEmail: "",
  merchantName: "",
};

const filterIfExists = (prev: TInviteElement[], newItem: TInviteElement) =>
  prev?.filter((item) => item.merchantName !== newItem.merchantName);
function Add({ listItems, onSubmit }: any) {
  const { open, onClose, SlideProps, TransitionProps } = useNiceModal();
  const { isMobileView } = useCustomTheme();
  const [merchantsNewList, setMerchantsNewList] = useState<TInviteElement[]>(
    [],
  );
  const scrollRef = useRef<HTMLDivElement>(null);

  const scheme = yup.object({
    pahEmail: yup.string().email("Invalid email").required("Email is required"),
    merchantName: yup
      .string()
      .required("Merchant name is required")
      .test("unique", "Merchant name must be unique", function (value) {
        if (!value) return true;
        const arr = [...merchantsNewList, ...listItems];
        const doestNameExist = arr?.some(
          (item) =>
            item?.merchantName?.toLocaleLowerCase() ===
            value?.toLocaleLowerCase(),
        );
        return !doestNameExist;
      }),
  });
  const methods = useForm<any>({
    mode: "onChange",
    resolver: yupResolver(scheme),
    defaultValues,
  });

  const onHandleSubmit = (data: any) => {
    setMerchantsNewList((prev) => {
      const newItem = { ...data, checked: true };
      const updatedArray = [...filterIfExists(prev, newItem), newItem];
      return updatedArray;
    });
    methods.reset(defaultValues);
  };

  const handleRemoveMerchant = (merchant: TInviteElement) =>
    setMerchantsNewList((prev) => filterIfExists(prev, merchant));
  const handleSelect = (merchant: TInviteElement) => {
    setMerchantsNewList((prev) => filterIfExists(prev, merchant));
    methods.reset(merchant);
  };

  const onFinalSubmit = () => {
    onSubmit(merchantsNewList);
    methods.reset(defaultValues);
    onClose();
  };
  return (
    <ModalFactory
      variant="dialog"
      modalProps={{
        open,
        onClose,
        width: "648px",
        SlideProps,
        DialogProps: {
          PaperProps: {
            padding: "0 0 16px 0",
          },
          TransitionProps,
        },
      }}
    >
      <Box
        padding="24px 24px 16px"
        // boxShadow={titleShadow ? "0px 3px 15px 0px #02020226" : "none"}
      >
        <ModalTitle
          title="Add Invitation"
          description="Please enter the email address to send the invitation and provide the merchant's name."
          textStyles={{
            title: {
              fontSize: "18px",
              lineHeight: "21.6px",
              letterSpacing: "-0.01em",
            },
          }}
          closeButtonSize={22}
          onClose={onClose}
        />
      </Box>

      <Box
        sx={{
          overflowY: "auto",
        }}
        maxHeight="70dvh"
        mt="20px"
        padding="0 24px 16px"
      >
        {merchantsNewList?.map((item) => (
          <AddedItem
            handleDelete={handleRemoveMerchant}
            handleSelect={handleSelect}
            key={item?.merchantName}
            item={item}
          />
        ))}
      </Box>
      <Stack padding="0 24px 16px">
        <Box
          maxWidth="100%"
          p={"24px"}
          borderRadius="16px"
          border={`2px dashed ${palette?.gray[100]}`}
          marginLeft={0}
          mt="20px"
        >
          <FormProvider {...methods}>
            <Box
              component="form"
              flexGrow={1}
              id="business-owner-form"
              onSubmit={methods.handleSubmit(onHandleSubmit)}
            >
              <Grid
                container
                columnSpacing={isMobileView ? 0 : "12px"}
                rowSpacing="12px"
              >
                <Grid item xs={12} sm={12} md={6}>
                  <FadeUpWrapper delay={50}>
                    <RHFInput
                      name="pahEmail"
                      label="Email"
                      placeholder="johndoe@example.com"
                      fullWidth
                    />
                  </FadeUpWrapper>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <FadeUpWrapper delay={100}>
                    <RHFInput
                      name="merchantName"
                      label="Merchant Name"
                      placeholder="Merchant Name"
                      fullWidth
                    />
                  </FadeUpWrapper>
                </Grid>
              </Grid>
              <Button
                size="small"
                sx={{ margin: "0 auto", mt: "16px" }}
                background="secondary"
                type="submit"
                disabled={!methods?.formState?.isValid}
              >
                Add Merchant
              </Button>
            </Box>
          </FormProvider>
        </Box>
      </Stack>
      <ModalActions
        animationDelay={80}
        containerProps={{
          sx: {
            padding: isMobileView ? "0 24px 16px" : "20px",
            ...(isMobileView
              ? { flexDirection: "column-reverse" }
              : { paddingBottom: "20px", paddingRight: "24px" }),
          },
        }}
        secondaryAction={{
          label: "Cancel",
          onClick: onClose,
          sx: {
            fontSize: "18px",
            lineHeight: "21.6px",
            padding: "12px 24px",
          },
        }}
        primaryAction={{
          type: "button",
          onClick: onFinalSubmit,
          disabled: isEmpty(merchantsNewList),
          label: "Save",
          sx: {
            fontSize: "18px",
            lineHeight: "21.6px",
            padding: "12px 24px",
          },
        }}
      />
    </ModalFactory>
  );
}

const AddInviteModal = NiceModal.create(Add);
export default AddInviteModal;

const AddedItem = ({
  item,
  handleDelete,
  handleSelect,
}: {
  item: TInviteElement;
  handleDelete: (e: TInviteElement) => void;
  handleSelect: (e: TInviteElement) => void;
}) => {
  return (
    <Stack
      flexDirection="row"
      bgcolor="#FFFFFF"
      p="8px 16px"
      borderRadius="8px"
      alignItems="center"
      justifyContent="space-between"
      boxShadow="1px 1px 15px 0px #A1AFB41A"
      mb="12px"
      onClick={() => handleSelect(item)}
    >
      <Stack>
        <Text
          mb="8px"
          fontWeight="regular"
          fontSize="14px"
          color={palette?.black[100]}
        >
          {item?.pahEmail}
        </Text>
        <Text fontWeight="book" fontSize="12px" color={palette?.filled.grey}>
          {item?.merchantName}
        </Text>
      </Stack>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          handleDelete(item);
        }}
        background="text"
        data-testid="delete-item-button"
      >
        <TrashSimple size={20} fill={palette?.filled.red} />
      </Button>
    </Stack>
  );
};
