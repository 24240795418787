import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  AmexCardIcon,
  DiscoverCardIcon,
  MasterCardIcon,
  VisaCardIcon,
} from "@assets/builderIcons";
import { RHFInput } from "@common/Input";
import { InputAdornment, Stack } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { formatCardNumber } from "@utils/index";
import { useAppDispatch } from "@redux/hooks";
import { setCheckoutCardType } from "@redux/slices/checkout";
import { Text } from "@common/Text";

type CardNumberInputProps = {
  setCardFees?: Dispatch<SetStateAction<number>>;
  handleCardNumberCheck: (cardNumber: string, setError: any) => Promise<void>;
  cardObject: any;
};

const CardNumberInput = ({
  setCardFees,
  cardObject,
  handleCardNumberCheck,
}: CardNumberInputProps) => {
  const { setError } = useFormContext();
  const [cardType, setCardType] = useState<string>("");
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(setCheckoutCardType(cardType));
  }, [cardType]);

  const maxCardNumberLength = cardType && cardType === "AMEX" ? 17 : 19;
  const normalizeInput = (value: string) => {
    if (!value) return value;
    const card = formatCardNumber(value);
    const allowedLength = card?.cardType && card?.cardType === "AMEX" ? 17 : 19;
    if (card?.formattedNumber?.length === allowedLength) {
      handleCardNumberCheck(value, setError);
    }
    setCardType(card.cardType);
    return card.formattedNumber;
  };

  useEffect(() => {
    if (cardObject) setCardFees && setCardFees(+cardObject.fees);
  }, [cardObject]);

  const getCardIcon = React.useCallback(() => {
    const _type = cardType.toUpperCase();
    return getCardBrandIcon(_type);
  }, [cardType]);

  return (
    <RHFInput
      name="cardNumber"
      label="Card number"
      handleNormalizeInput={normalizeInput}
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="start" sx={{ alignItems: "center" }}>
            {getCardIcon()}
          </InputAdornment>
        ),
      }}
      inputProps={{
        maxLength: maxCardNumberLength,
      }}
      placeholder="•••• •••• •••• ••••"
      sx={{
        letterSpacing: "2px",
        "& .MuiFormHelperText-root": {
          letterSpacing: "0px",
        },
      }}
    />
  );
};

export const getCardBrandIcon = (type: string, isPaymentForm = true) => {
  switch (type) {
    case "VISA":
      return <VisaCardIcon width={34} height={24} />;
    case "MASTERCARD":
      return <MasterCardIcon width={34} height={24} />;
    case "AMERICAN EXPRESS":
      return <AmexCardIcon width={34} height={24} />;
    case "AMEX":
      return <AmexCardIcon width={34} height={24} />;
    case "DISCOVER":
      return <DiscoverCardIcon width={34} height={24} />;
    default:
      return isPaymentForm ? (
        <Stack
          direction="row"
          spacing={1}
          sx={{ filter: "drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.05));" }}
        >
          <VisaCardIcon width={34} height={24} />
          <MasterCardIcon width={34} height={24} />
          <AmexCardIcon width={34} height={24} />
          <DiscoverCardIcon width={34} height={24} />
        </Stack>
      ) : (
        <Text>{type}</Text>
      );
  }
};

export default CardNumberInput;
