import {
  QKEY_LIST_ACQUIRER_MERCHANTS,
  QKEY_LIST_ENTERPRISE_MERCHANTS,
  QKEY_LIST_ENTERPRISE_STATS,
  QKEY_LIST_ENTERPRISES,
} from "@constants/queryKeys";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  selectConversationsGeneralSearch,
  selectConversationTopic,
  selectReplies,
  setThreads,
  setTopicName,
} from "@redux/slices/conversations";
import { customInstance } from "@services/api";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { parseInput } from "../utils/functions";

const getMessages = ({
  merchantId,
  topicId,
  queryString,
}: {
  isReply: boolean;
  merchantId: number;
  topicId: number;
  queryString: string;
}) => {
  const computedString = queryString.startsWith("#")
    ? parseInput(queryString, { isUrl: true })
    : queryString;

  let queryBuilder = `/merchants/${merchantId}/topics/${topicId}/threads?sort=createdAt`;
  if (queryString) {
    queryBuilder += `&q="${computedString}"`;
  }

  return customInstance({
    url: queryBuilder,
    method: "GET",
  });
};

export const useListThreads = ({
  merchantId,
  hasUnreadMessages,
  topicId,
}: {
  merchantId: number;
  hasUnreadMessages: boolean;
  topicId: number;
}) => {
  const { isRepliesOpen, threadId } = useAppSelector(selectReplies);
  const { queryString, queryKey } = useAppSelector(
    selectConversationsGeneralSearch,
  );

  const [enableReadEndpoint, setEnableReadEnpoint] = useState<boolean>(false);

  const {
    queryObject: { id, name, challengeId },
  } = useAppSelector(selectConversationTopic);

  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const condition =
    (queryKey === "Threads" || queryKey === "Replies") && queryString;

  useEffect(() => {
    if (hasUnreadMessages) {
      const customData = isRepliesOpen
        ? {
            threadID: threadId,
          }
        : {
            topicID: topicId,
          };
      const unread = async () =>
        await customInstance({
          url: `/merchants/${merchantId}/messages/read`,
          method: "POST",
          data: customData,
        }).then(() => {
          queryClient.invalidateQueries(QKEY_LIST_ENTERPRISE_MERCHANTS);
          queryClient.invalidateQueries(QKEY_LIST_ACQUIRER_MERCHANTS);
          queryClient.invalidateQueries(QKEY_LIST_ENTERPRISES);
          queryClient.invalidateQueries(QKEY_LIST_ENTERPRISE_STATS);
        });

      if (enableReadEndpoint || isRepliesOpen) unread();
    }
  }, [enableReadEndpoint, isRepliesOpen]);

  const { data, isLoading, isSuccess, isError, isRefetching, isFetching } =
    useQuery(
      ["get-conversation-messages", id, isRepliesOpen, condition],
      async () => {
        const data = await getMessages({
          isReply: isRepliesOpen,
          topicId: topicId,
          merchantId,
          queryString: queryString,
        });
        return data;
      },
      {
        onSuccess: async (res) => {
          if (!res.data) return;
          dispatch(setThreads(res.data));
          setEnableReadEnpoint(true);

          if (!name) {
            dispatch(setTopicName(res.data[0].topicName));
          }
        },
        enabled: Boolean(merchantId && topicId) && !isRepliesOpen,
      },
    );

  return { data, isLoading, isSuccess, isError, isRefetching, isFetching };
};
