import { Check, Minus } from "@phosphor-icons/react";
import { getJsonValue } from "../utils";
type ThemeMode = "light" | "dark";

export const getCustomBaseCheckboxRootStyles = (
  mode: ThemeMode
) => ({
  borderRadius: "4px",
  padding: "0px",
  backgroundColor: getJsonValue(`tokens.${mode}.colour.surface.primary`),
  border: `1.5px solid ${getJsonValue(`tokens.${mode}.colour.border.tertiary`)}`,
  transition: "all 0.3s ease",
  "&:hover": {
    border: `1.5px solid ${getJsonValue(`tokens.${mode}.colour.text.secondary`)}`,
  },
  svg: {
    fill: "transparent",
  },
  "&.Mui-checked, &.MuiCheckbox-indeterminate": {
    backgroundColor: getJsonValue(`tokens.${mode}.colour.buttons.default`),
    border: "none",
    svg: {
      fill: getJsonValue(`tokens.${mode}.primitive.neutral.0`),
    },
    "&:hover": {
      backgroundColor: getJsonValue(`tokens.${mode}.colour.buttons.default-hover`),
      svg: {
        fill: getJsonValue(`tokens.${mode}.primitive.neutral.0`),
        opacity: 0.7,
      },
    },
    "&.Mui-disabled": {
      opacity: 0.3,
      backgroundColor: getJsonValue(`tokens.${mode}.colour.buttons.default`),
      border: "none",
      svg: {
        fill: getJsonValue(`tokens.${mode}.primitive.neutral.0`),
      },
    }
  },
  "&.Mui-disabled": {
    backgroundColor: getJsonValue(`tokens.${mode}.colour.surface.secondary`),
    border: `1.5px solid ${getJsonValue(`tokens.${mode}.colour.border.secondary`)}`,
  }

});

export const getInitialCheckboxProps = () => ({
  checkedIcon: <Check fill={"transparent"} />,
  indeterminateIcon: <Minus fill={"transparent"} />,
  disableRipple: true,
  disableTouchRipple: true,
  disableFocusRipple: true
});

export const getLargeCheckboxStyle = () => [
  {
    props: (props: any) => props.size === "medium",
    style: {
      width: "20px",
      height: "20px",
      "& .MuiSvgIcon-root": {
        width: "20px",
        height: "20px",
      }
    },
  },
];

export const getSmallCheckboxStyle = () => [
  {
    props: (props: any) => props.size === "small",
    style: {
      width: "16px",
      height: "16px",
      "& .MuiSvgIcon-root": {
        width: "16px",
        height: "16px",
      }
    },
  },
];
