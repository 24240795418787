import { BreakpointsOptions } from "@mui/material";

export const breakpoints: BreakpointsOptions = {
  values: {
    //new breakpoints
    new_xs: 0,
    new_sm: 768,
    new_sm_md: 768,
    new_md: 1339,
    new_lg: 1440,
    new_xl: 2560,
    //old breakpoints
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
};
