import { createTheme } from "@mui/material";
import { getJsonValue } from "./utils";
import { CustomTheme } from "./palette.interface";
import {
  getLargeButtonStyle,
  getSmallButtonStyle,
  getFilledVariants,
  getOutlinedVariants,
  getGhostVariants,
} from "./customStyles/customButtonStyle";
import { getCustomBaseInputStyle } from "./customStyles/customBaseInputStyle";
import { typography } from "./typography";
import { getTextFieldOverrides } from "./customStyles/getTextFieldOverrides";

import {
  getCustomBaseCheckboxRootStyles,
  getInitialCheckboxProps,
  getLargeCheckboxStyle,
  getSmallCheckboxStyle,
} from "./customStyles/customCheckboxStyle";
import { getCustomBaseSwitchRootStyles } from "./customStyles/customBaseSwitchStyle";
import { getCustomLinkStyle } from "@theme/v2/customStyles/customLinkStyle";
import { getCustomIconButtonStyle } from "./customStyles/customIconButtonStyle";
import { ThemeMode } from "@theme/v2/types";
import {
  getCustomBaseRadioRootStyles,
  getSmallRadioStyle,
} from "@theme/v2/customStyles/customRadioStyle";
import {
  getDefaultStyles,
  getSuccessStyles,
  getWarningStyles,
  getBlueStyles,
  getErrorStyles,
  getLargeChipStyle,
  getSmallChipStyle,
} from "./customStyles/customChipStyle";
import { breakpoints } from "@theme/v2/breakpoints";
import { getCustomDrawerStyle } from "@theme/v2/customStyles/customDrawerStyle";

const makePalette = (mode: "light" | "dark") =>
  ({
    givebox: {
      "600": "#326ec5ff",
      blue: "#6d9cf8ff",
    },
    primitive: {
      blue: {
        "10": getJsonValue(`tokens.${mode}.primitive.blue.10`),
        "25": getJsonValue(`tokens.${mode}.primitive.blue.25`),
        "50": getJsonValue(`tokens.${mode}.primitive.blue.50`),
        "100": getJsonValue(`tokens.${mode}.primitive.blue.100`),
      },
      "citrus-peel": {
        "100": getJsonValue(`tokens.${mode}.primitive.citrus-peel.100`),
      },
      error: {
        "25": getJsonValue(`tokens.${mode}.primitive.error.25`),
        "50": getJsonValue(`tokens.${mode}.primitive.error.50`),
        "100": getJsonValue(`tokens.${mode}.primitive.error.100`),
      },
      "moon-purple": {
        "100": getJsonValue(`tokens.${mode}.primitive.moon-purple.100`),
      },
      neutral: {
        "0": getJsonValue(`tokens.${mode}.primitive.neutral.0`),
        "5": getJsonValue(`tokens.${mode}.primitive.neutral.5`),
        "10": getJsonValue(`tokens.${mode}.primitive.neutral.10`),
        "20": getJsonValue(`tokens.${mode}.primitive.neutral.20`),
        "30": getJsonValue(`tokens.${mode}.primitive.neutral.30`),
        "40": getJsonValue(`tokens.${mode}.primitive.neutral.40`),
        "50": getJsonValue(`tokens.${mode}.primitive.neutral.50`),
        "60": getJsonValue(`tokens.${mode}.primitive.neutral.60`),
        "70": getJsonValue(`tokens.${mode}.primitive.neutral.70`),
        "80": getJsonValue(`tokens.${mode}.primitive.neutral.80`),
        "90": getJsonValue(`tokens.${mode}.primitive.neutral.90`),
        "95": getJsonValue(`tokens.${mode}.primitive.neutral.95`),
        "100": getJsonValue(`tokens.${mode}.primitive.neutral.100`),
      },
      success: {
        "25": getJsonValue(`tokens.${mode}.primitive.success.25`),
        "50": getJsonValue(`tokens.${mode}.primitive.success.50`),
        "100": getJsonValue(`tokens.${mode}.primitive.success.100`),
      },
      transparent: {
        "darken-10": getJsonValue(
          `tokens.${mode}.primitive.transparent.darken-10`,
        ),
        "darken-5": getJsonValue(
          `tokens.${mode}.primitive.transparent.darken-5`,
        ),
      },
      warning: {
        "25": getJsonValue(`tokens.${mode}.primitive.warning.25`),
        "50": getJsonValue(`tokens.${mode}.primitive.warning.50`),
        "100": getJsonValue(`tokens.${mode}.primitive.warning.100`),
      },
    },
    gradient: {
      "ocean-blue": {
        default: `linear-gradient(224deg, ${getJsonValue(
          `tokens.${mode}.primitive.gradients.ocean-blue.start`,
        )} 0%, ${getJsonValue(
          `tokens.${mode}.primitive.gradients.ocean-blue.end`,
        )} 100%)`,
        highlight: `linear-gradient(223.56deg, ${getJsonValue(
          `tokens.${mode}.primitive.gradients.ocean-blue.start-light`,
        )} 0%, ${getJsonValue(
          `tokens.${mode}.primitive.gradients.ocean-blue.end-light`,
        )} 100%)`,
        disabled: `linear-gradient(85.95deg, rgba(79, 134, 213, 0.5) 0%, rgba(97, 199, 232, 0.5) 100%),linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));`,
        border: `linear-gradient(${getJsonValue(
          `tokens.${mode}.colour.surface.primary`,
        )}, ${getJsonValue(
          `tokens.${mode}.colour.surface.primary`,
        )}) padding-box, linear-gradient(223.56deg, #28C0D9 0%, #4D449E 100%) border-box`,
      },
      "citrus-peel": {
        default: `linear-gradient(224deg, ${getJsonValue(
          `tokens.${mode}.primitive.gradients.citrus-peel.start`,
        )} 0%, ${getJsonValue(
          `tokens.${mode}.primitive.gradients.citrus-peel.end`,
        )} 100%));`,
        highlight: `linear-gradient(224deg, rgba(255, 170, 108, 0.20) 0%, rgba(224, 77, 121, 0.20) 100%)`,
      },
      "moon-purple": {
        default: `linear-gradient(224deg, ${getJsonValue(
          `tokens.${mode}.primitive.gradients.moon-purple.start`,
        )} 0%, ${getJsonValue(
          `tokens.${mode}.primitive.gradients.moon-purple.end`,
        )} 100%)`,
        highlight: `linear-gradient(224deg, rgba(137, 164, 234, 0.20) 0%, rgba(116, 89, 233, 0.20) 100%)`,
      },
      "aqua-horizon": {
        border: `linear-gradient(85.95deg, #4F86D5 0%, #61C7E8 100%)`,
      },
    },
  } as const);

export type TMakePalette = ReturnType<typeof makePalette>;
const makeCtx = (mode: "light" | "dark") => ({
  text: {
    primary: getJsonValue(`tokens.${mode}.colour.text.primary`),
    secondary: getJsonValue(`tokens.${mode}.colour.text.secondary`),
    tertiary: getJsonValue(`tokens.${mode}.colour.text.tertiary`),
  },
  surface: {
    primary: getJsonValue(`tokens.${mode}.colour.surface.primary`),
    secondary: getJsonValue(`tokens.${mode}.colour.surface.secondary`),
    tertiary: getJsonValue(`tokens.${mode}.colour.surface.tertiary`),
    "primary-transparent": getJsonValue(
      `tokens.${mode}.colour.surface.primary-transparent`,
    ),
    "secondary-transparent": getJsonValue(
      `tokens.${mode}.colour.surface.secondary-transparent`,
    ),
    overlay: getJsonValue(`tokens.${mode}.colour.surface.overlay`),
    "tertiary-transparent": getJsonValue(
      `tokens.${mode}.colour.surface.tertiary-transparent`,
    ),
  },
  border: {
    primary: getJsonValue(`tokens.${mode}.colour.border.primary`),
    secondary: getJsonValue(`tokens.${mode}.colour.border.secondary`),
    tertiary: getJsonValue(`tokens.${mode}.colour.border.tertiary`),
  },
  buttons: {
    default: getJsonValue(`tokens.${mode}.colour.buttons.default`),
    "default-hover": getJsonValue(
      `tokens.${mode}.colour.buttons.default-hover`,
    ),
  },
  analytics: {
    blue: getJsonValue(`tokens.${mode}.colour.analytics.blue`),
    red: getJsonValue(`tokens.${mode}.colour.analytics.red`),
    purple: getJsonValue(`tokens.${mode}.colour.analytics.purple`),
    yellow: getJsonValue(`tokens.${mode}.colour.analytics.yellow`),
  },
  icon: {
    "remain-dark": getJsonValue(`tokens.${mode}.colour.icon.remain-dark`),
    "remain-light": getJsonValue(`tokens.${mode}.colour.icon.remain-light`),
    "icon-primary": getJsonValue(`tokens.${mode}.colour.icon.icon-primary`),
    "icon-secondary": getJsonValue(`tokens.${mode}.colour.icon.icon-secondary`),
  },
});

export const createThemeBuilder = (mode: ThemeMode): CustomTheme => {
  const basePallete = makePalette(mode);
  const palette = makeCtx(mode);

  return createTheme({
    typography,
    breakpoints,
    customs: {
      spacing: {
        grid: {
          "margin-page-desktop": 64,
          "margin-page-mobile": 20,
          "margin-panel-desktop": 20,
          "margin-panel-mobile": 20,
        },
      },
      radius: {
        small: 12,
        medium: 20,
        large: 32,
      },
    },
    palette: {
      mode,
      ...basePallete,
      ...palette,
    },
    components: {
      MuiButton: {
        defaultProps: {
          variant: "filled",
        },
        styleOverrides: {
          root: {
            fontSize: 14,
            fontWeight: 400,
            lineHeight: "20px",
            textTransform: "none",
            minWidth: "74px",
            variants: [
              ...getLargeButtonStyle(),
              ...getSmallButtonStyle(),
              ...getFilledVariants(mode, basePallete),
              ...getOutlinedVariants(mode, basePallete),
              ...getGhostVariants(mode),
            ],
          },
        },
      },
      MuiLink: getCustomLinkStyle(mode),
      MuiIconButton: getCustomIconButtonStyle(mode),
      MuiInput: {
        ...getCustomBaseInputStyle(mode, basePallete, palette),
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            bodyS: "p",
            bodyXS: "p",
            bodyXXS: "p",
          },
        },
        styleOverrides: {
          root: {
            color: getJsonValue(`tokens.${mode}.colour.text.primary`),
          },
        },
      },
      MuiTextField: {
        ...getTextFieldOverrides(palette, basePallete),
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            ".MuiFormHelperText-root": {
              margin: 0,
              textTransform: "none",
              paddingInline: "12px",
              fontSize: "12px",
              marginTop: "4px",
              color: palette.text.tertiary,
              lineHeight: "14px",
              fontFamily: "Give Whyte",
              fontWeight: 400,
              fontStyle: "normal",
              paddingLeft: 0,
              marginLeft: 0,
            },
            ".MuiFormLabel-root": {
              marginBottom: "8px",
              fontSize: "14px",
              color: palette.text.primary,
            },
            ".MuiFormHelperText-root.Mui-error, .MuiFormLabel-root.Mui-error": {
              color: basePallete.primitive.error["100"],
            },
            "& .MuiFormLabel-root.Mui-disabled": {
              color: palette.text.primary,
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            ...getCustomBaseCheckboxRootStyles(mode),
            variants: [...getLargeCheckboxStyle(), ...getSmallCheckboxStyle()],
          },
        },
        defaultProps: {
          ...getInitialCheckboxProps(),
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            ...getCustomBaseSwitchRootStyles(mode),
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            ...getCustomBaseRadioRootStyles(mode),
            variants: [...getSmallRadioStyle()],
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: "none",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            "& .MuiPaper-root-MuiPopover-paper-MuiMenu-paper": {
              background: palette.surface.primary,
              "& .MuiList-root-MuiMenu-list": {
                borderRadius: "16px",
                "& option": {
                  padding: "6px",
                  "&:hover": {
                    background: basePallete.primitive.transparent["darken-5"],
                  },
                },
              },
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            variants: [
              ...getSmallChipStyle(),
              ...getLargeChipStyle(),
              ...getDefaultStyles(mode),
              ...getSuccessStyles(mode),
              ...getWarningStyles(mode),
              ...getBlueStyles(mode),
              ...getErrorStyles(mode),
            ],
          },
        },
      },
      MuiDrawer: getCustomDrawerStyle(mode),
    },
  } as any);
};
