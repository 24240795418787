import { getJsonValue } from "@theme/v2/utils";

declare module "@mui/material/Link" {
  interface LinkOwnProps {
    hasIcon?: boolean;
  }

  interface LinkPropsColorOverrides {
    destructive: true;
  }
}

export const getCustomLinkStyle = (mode: "light" | "dark") => {
  return {
    styleOverrides: {
      root: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        fontSize: 14,
        fontWeight: 400,
        lineHeight: "20px",
        gap: "6px",
        color: getJsonValue(`tokens.${mode}.colour.text.primary`),
        "&:hover": {
          textUnderlineOffset: "3px",
        },
        "&:active": {
          color: getJsonValue(`tokens.${mode}.colour.text.secondary`),
        },
        variants: [
          {
            props: (props: any) => props.color === "secondary",
            style: {
              color: getJsonValue(`tokens.${mode}.primitive.blue.100`),
              "&:active": {
                color: getJsonValue(`tokens.${mode}.primitive.blue.50`),
              },
            },
          },
          {
            props: (props: any) => props.color === "destructive",
            style: {
              color: getJsonValue(`tokens.${mode}.primitive.error.100`),
              "&:active": {
                color: getJsonValue(`tokens.${mode}.primitive.error.50`),
              },
            },
          },
          {
            props: (props: any) => props.disabled,
            style: {
              color: getJsonValue(`tokens.${mode}.colour.text.tertiary`),
              cursor: "default",
              "&:active": {
                color: getJsonValue(`tokens.${mode}.colour.text.tertiary`),
              },
            },
          },
          {
            props: (props: any) =>
              props.underline === "none" && !props.disabled,
            style: {
              borderBottom: "1px solid transparent",
              "&:hover": {
                borderColor: "currentColor",
              },
            },
          },
        ],
      },
    },
  };
};
