import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { toUnixDateFormat } from "@utils/date.helpers";
import { MerchantData } from "@hooks/enterprise-api/account/useGetMerchants";
import { getRenamedBankAccountStatus } from "@hooks/merchant-api/manage-money/useListBankAccounts";
import { MerchantsQueryType } from "@redux/types/enterprise/merchants";
import { TInviteElement } from "@components/Merchants/BulkInvite/types";
import createDateFilter, { TDate } from "@common/Filter/utils/createDateFilter";

type LevelType = {
  title: string;
  count: number;
  description: string;
  level: number;
  nbMerchants: number;
  requirements: string[];
  selected?: boolean;
};

export type BankAccountType = {
  id?: number;
  name: string;
  accountType: string;
  accountNumber: string;
  routingNumber: string;
  notes: string;
  status: string;
  bank?: string;
  isLocal?: boolean;
  statements?: string[];
  files?: File[];
  created?: number;
};

export interface MerchantState {
  query: MerchantsQueryType;
  levels: LevelType[];
  invitationsEntriesDraft: Record<number, [string, TInviteElement][]>;
  statusDisplayName: string;
  names: {
    type: string[];
    conversion: number[];
    amount: (string | number)[];
    transactions: (string | number)[];
    visitors: (string | number)[];
    created: (string | number)[];
    status: string[];
    "Underwriting Status": string[];
    "Instant Fundraising Status": string[];
    enterprise: string[];
    processingVolume: string[];
    mcc: string[];
  };
  watchlist: MerchantData[];
  masquerade: {
    name: string | null;
    location: string;
    skipModal: boolean;
    masqueradeID: string | null;
    enterpriseId?: string | null;
  };
  businessOwners: {
    list: any[];
    removedList: number[];
    addedList: number[];
  };
  isBusinessOwnerAddress: boolean;
  bankAccounts: {
    list: BankAccountType[];
    removedList: number[];
    addedList: number[];
  };
  legalEntityID: number | null;
  isDisabledSubmit: boolean;
  markedMerchants: {
    all: boolean;
    exclude: number[];
    include: number[];
  };
}

type SwitchAndSelect = {
  type:
    | "type"
    | "status"
    | "Underwriting Status"
    | "Instant Fundraising Status";
  value: string;
};

export const initialLevels = Array.from({ length: 4 }, (_, i) => ({
  selected: true,
  title: "",
  count: 0,
  nbMerchants: 0,
  description: "",
  level: i + 1,
  requirements: [],
}));

const initialState: MerchantState = {
  query: {
    created: "",
    enterprise: "",
    processingVolume: "",
    mcc: "",
    status: "",
  },
  levels: initialLevels,
  invitationsEntriesDraft: {},
  statusDisplayName: "",
  names: {
    type: [],
    amount: [],
    conversion: [],
    transactions: [],
    visitors: [],
    created: [],
    status: [],
    "Underwriting Status": [],
    "Instant Fundraising Status": [],
    enterprise: [],
    processingVolume: [],
    mcc: [],
  },
  watchlist: [],
  masquerade: {
    name: localStorage.getItem("enterprise-masquerade-session"),
    skipModal:
      localStorage.getItem("enterprise-skipMasqueradeModal") === "true",
    location: "",
    masqueradeID: localStorage.getItem("enterprise-masquerade-id"),
  },
  businessOwners: {
    list: [],
    addedList: [],
    removedList: [],
  },
  isBusinessOwnerAddress: true,
  bankAccounts: {
    list: [],
    removedList: [],
    addedList: [],
  },
  legalEntityID: null,
  isDisabledSubmit: false,
  markedMerchants: {
    all: false,
    include: [],
    exclude: [],
  },
};

const merchantsSlice = createSlice({
  name: "merchants",
  initialState,
  reducers: {
    setMerchantLevels: (
      state: MerchantState,
      action: PayloadAction<LevelType[]>,
    ) => {
      state.levels = action.payload;
    },
    addFilter: (
      state: MerchantState,
      action: PayloadAction<SwitchAndSelect>,
    ) => {
      const { value, type } = action.payload;
      state.names[type] = [...state.names[type], value];
    },
    removeFilter: (
      state: MerchantState,
      action: PayloadAction<SwitchAndSelect>,
    ) => {
      const { value, type } = action.payload;
      state.names[type] = state.names[type].filter((val) => val !== value);
    },
    disableFilter: (
      state: MerchantState,
      action: PayloadAction<{
        type: "type";
      }>,
    ) => {
      state.names[action.payload.type] = [];
    },
    addDateFilter: (state: MerchantState, action: PayloadAction<TDate>) => {
      const { name, query } = createDateFilter(action.payload);
      state.names.created = name;
      state.query.created = query;
    },
    disableDateFilter: (state: MerchantState) => {
      state.names.created = [];
      state.query = initialState.query;
    },
    addRangeFilter: (state: MerchantState, action: PayloadAction<number[]>) => {
      const range = action.payload;
      state.names.conversion = range;
    },
    disableRangeFilter: (state: MerchantState) => {
      state.names.conversion = [];
    },
    clearFilters: (state: MerchantState) => {
      state.query = initialState.query;
      state.names = initialState.names;
    },
    addToWatchlist: (
      state: MerchantState,
      action: PayloadAction<MerchantData>,
    ) => {
      if (state.watchlist.some((item) => item.accID === action.payload.accID))
        return;

      state.watchlist = [...state.watchlist, action.payload];
    },
    removeFromWatchlist: (
      state: MerchantState,
      action: PayloadAction<{ accID: number }>,
    ) => {
      state.watchlist = state.watchlist.filter(
        (item) => item.accID !== action.payload.accID,
      );
    },
    setMasqueradeName: (
      state: MerchantState,
      action: PayloadAction<string>,
    ) => {
      state.masquerade.name = action.payload;
      localStorage.setItem("enterprise-masquerade-session", action.payload);
    },
    setMasqueradeSkipModal: (
      state: MerchantState,
      action: PayloadAction<boolean>,
    ) => {
      state.masquerade.skipModal = action.payload;
      localStorage.setItem(
        "enterprise-skipMasqueradeModal",
        `${action.payload}`,
      );
    },
    setMasqueradeEnterpriseId: (
      state: MerchantState,
      action: PayloadAction<string | null>,
    ) => {
      state.masquerade.enterpriseId = action.payload;
    },
    setMasqueradeID: (state: MerchantState, action: PayloadAction<string>) => {
      state.masquerade.masqueradeID = action.payload;
      localStorage.setItem("enterprise-masquerade-id", action.payload);
    },
    setMasqueradeLocation: (
      state: MerchantState,
      action: PayloadAction<string>,
    ) => {
      state.masquerade.location = action.payload;
    },
    setBusinessOwnersList: (
      state: MerchantState,
      action: PayloadAction<any[]>,
    ) => {
      state.businessOwners.list = action.payload;
    },
    setBusinessOwners: (state: MerchantState, action: PayloadAction<any>) => {
      const id = state.businessOwners.list.length;
      state.businessOwners.addedList.push(id);
      state.businessOwners.list = [
        ...state.businessOwners.list,
        { ...action.payload, id },
      ];
    },
    updateBusinessOwner: (
      state: MerchantState,
      action: PayloadAction<{ owner: any; index: number }>,
    ) => {
      const updatedOwners = state.businessOwners.list.map((o, i) => {
        if (i === action.payload.index) {
          return action.payload.owner;
        }
        return o;
      });
      state.businessOwners.list = updatedOwners;
    },
    deleteBusinessOwner: (
      state: MerchantState,
      action: PayloadAction<number>,
    ) => {
      state.businessOwners.removedList.push(
        state.businessOwners.list[action.payload].id || action.payload,
      );
      const updatedOwners = state.businessOwners.list.filter(
        (_, index) => index !== action.payload,
      );
      state.businessOwners.list = updatedOwners;
    },
    setIsBusinessOwnerAddress: (
      state: MerchantState,
      action: PayloadAction<boolean>,
    ) => {
      state.isBusinessOwnerAddress = action.payload;
    },
    setMerchantBankAccounts: (
      state: MerchantState,
      action: PayloadAction<BankAccountType[]>,
    ) => {
      state.bankAccounts.list = action.payload;
    },
    addMerchantBankAccount: (
      state: MerchantState,
      action: PayloadAction<BankAccountType>,
    ) => {
      const id = state.bankAccounts.list.length + 1;
      state.bankAccounts.list.push({
        ...action.payload,
        id,
        // status: "upload",
        isLocal: true,
        created: toUnixDateFormat(new Date()),
      });
      state.bankAccounts.addedList.push(id);
    },
    editMerchantBankAccount: (
      state: MerchantState,
      action: PayloadAction<BankAccountType>,
    ) => {
      const updatedBankAccounts = state.bankAccounts.list.map((bankAccount) => {
        if (action.payload.id === bankAccount.id)
          return { ...bankAccount, ...action.payload };
        return bankAccount;
      });
      state.bankAccounts.list = updatedBankAccounts;
    },
    updateMerchantBankAccountDocument: (
      state: MerchantState,
      action: PayloadAction<{ id: number; statements: string[] }>,
    ) => {
      const updatedBankAccounts = state.bankAccounts.list.map((bankAccount) => {
        if (action.payload.id === bankAccount.id)
          return {
            ...bankAccount,
            statements: action.payload.statements,
            status: "pending_review",
          };
        return bankAccount;
      });
      state.bankAccounts.list = updatedBankAccounts;
    },
    removeMerchantBankAccount: (
      state: MerchantState,
      action: PayloadAction<number>,
    ) => {
      const updatedBankAccounts = state.bankAccounts.list.filter(
        (bankAccount) => bankAccount.id !== action.payload,
      );
      state.bankAccounts.removedList.push(action.payload);
      state.bankAccounts.list = updatedBankAccounts;
    },
    setRemovedBankAccounts: (state: MerchantState) => {
      state.bankAccounts.removedList = [];
    },
    setLegalEntityID: (
      state: MerchantState,
      action: PayloadAction<number | null>,
    ) => {
      state.legalEntityID = action.payload;
    },
    setIsDisabledMerchantSubmit: (
      state: MerchantState,
      action: PayloadAction<boolean>,
    ) => {
      state.isDisabledSubmit = action.payload;
    },
    setResetMerchantBankAccountsOwners: (state: MerchantState) => {
      state.bankAccounts.list = [];
      state.bankAccounts.removedList = [];
      state.bankAccounts.addedList = [];
      state.businessOwners.list = [];
      state.businessOwners.removedList = [];
      state.businessOwners.addedList = [];
      state.isDisabledSubmit = false;
    },
    setMerchantStatusName: (
      state: MerchantState,
      action: PayloadAction<string>,
    ) => {
      state.statusDisplayName = action.payload;
    },
    setAllMerchantsMarked: (
      state: MerchantState,
      action: PayloadAction<boolean>,
    ) => {
      state.markedMerchants.all = action.payload;
    },

    setMerchantIncluded: (
      state: MerchantState,
      action: PayloadAction<{ id: number; mark: boolean }>,
    ) => {
      if (action.payload.mark) {
        state.markedMerchants.include = [
          ...state.markedMerchants.include,
          action.payload.id,
        ];
      } else {
        state.markedMerchants.include = state.markedMerchants.include.filter(
          (merchantId) => merchantId != action.payload.id,
        );
      }
    },
    setMerchantExcluded: (
      state: MerchantState,
      action: PayloadAction<{ id: number; mark: boolean }>,
    ) => {
      if (action.payload.mark) {
        state.markedMerchants.exclude = state.markedMerchants.exclude.filter(
          (merchantId) => merchantId != action.payload.id,
        );
      } else {
        state.markedMerchants.exclude = [
          ...state.markedMerchants.exclude,
          action.payload.id,
        ];
      }
    },
    resetMarkedMerchants: (state: MerchantState) => {
      state.markedMerchants = { ...initialState.markedMerchants };
    },
    saveInvitationsDraft: (
      state: MerchantState,
      action: PayloadAction<{
        merchantId: number;
        entries: [string, TInviteElement][];
      }>,
    ) => {
      const { merchantId, entries } = action.payload;
      state.invitationsEntriesDraft[merchantId] = entries;
    },
  },
});

export const {
  setMerchantLevels,
  addDateFilter,
  addFilter,
  addRangeFilter,
  disableDateFilter,
  disableFilter,
  disableRangeFilter,
  removeFilter,
  clearFilters,
  addToWatchlist,
  removeFromWatchlist,
  setMasqueradeName,
  setMasqueradeSkipModal,
  setMasqueradeEnterpriseId,
  deleteBusinessOwner,
  setBusinessOwners,
  updateBusinessOwner,
  setMasqueradeID,
  setMasqueradeLocation,
  setIsBusinessOwnerAddress,
  setMerchantBankAccounts,
  addMerchantBankAccount,
  editMerchantBankAccount,
  removeMerchantBankAccount,
  setRemovedBankAccounts,
  setBusinessOwnersList,
  setLegalEntityID,
  setResetMerchantBankAccountsOwners,
  setIsDisabledMerchantSubmit,
  updateMerchantBankAccountDocument,
  setMerchantStatusName,
  setAllMerchantsMarked,
  setMerchantExcluded,
  setMerchantIncluded,
  resetMarkedMerchants,
  saveInvitationsDraft,
} = merchantsSlice.actions;

export const selectFilters = (state: RootState) => state.merchants.names;
export const selectMerchantLevels = (state: RootState) =>
  state.merchants.levels;
export const selectMerchantsNumber = (state: RootState) => {
  let nbMerchants = 0;
  state.merchants.levels.forEach((level) => {
    if (level.selected) {
      nbMerchants += level.nbMerchants;
    }
  });

  return nbMerchants;
};
export const selectWatchlist = (state: RootState) => state.merchants.watchlist;
export const selectMasquerade = (state: RootState) =>
  state.merchants.masquerade;
export const selectIsBusinessOwnerAddress = (state: RootState) =>
  state.merchants.isBusinessOwnerAddress;

export const selectBusinessOwners = (state: RootState) =>
  state.merchants.businessOwners.list;
export const selectRemovedBusinessOwners = (state: RootState) =>
  state.merchants.businessOwners.removedList;
export const selectAddedBusinessOwners = (state: RootState) =>
  state.merchants.businessOwners.addedList;

export const selectBusinessOwner =
  (index: number | null) => (state: RootState) => {
    if (index === null) return undefined;
    return state.merchants.businessOwners.list[index];
  };
export const selectMerchantBankAccounts = (state: RootState) =>
  state.merchants.bankAccounts.list.map((bankAccount) => ({
    id: bankAccount.id,
    name: bankAccount.name,
    bank: bankAccount.bank,
    statements: bankAccount.statements,
    status: getRenamedBankAccountStatus(bankAccount.status || "pending"),
    nickname: "",
    isLocal: bankAccount.isLocal || false,
    isDefault: false,
    details: {
      created: bankAccount.created,
      accountType: bankAccount.accountType,
      routingNumber: bankAccount.routingNumber,
      notes: bankAccount.notes,
      accountName: bankAccount.name,
      accountNumber: bankAccount.accountNumber,
      bankName: bankAccount.bank,
    },
  }));

export const selectQueryFilters = (state: RootState) => state.merchants.query;
export const selectRemovedBankAccounts = (state: RootState) =>
  state.merchants.bankAccounts.removedList;
export const selectAddedBankAccounts = (state: RootState) =>
  state.merchants.bankAccounts.addedList;
export const selectLegalEntityID = (state: RootState) =>
  state.merchants.legalEntityID;
export const selectIsDisabledMerchantSubmit = (state: RootState) =>
  state.merchants.isDisabledSubmit;
export const selectMerchantStatusName = (state: RootState) =>
  state.merchants.statusDisplayName;
export const selectMarkedMerchants = (state: RootState) =>
  state.merchants?.markedMerchants;

const selectMerchantDraft = (state: RootState, merchantId: number) =>
  state.merchants.invitationsEntriesDraft[merchantId];
export const selectInvitationsDraft = createSelector(
  [selectMerchantDraft],
  (entries) => entries || [],
);

export default merchantsSlice.reducer;
