import { palette } from "@palette";
import { AxiosError } from "axios";
import React, { useMemo } from "react";
// @mui
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// form
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import * as Yup from "yup";
// components
import { Button } from "@common/Button";
import { RHFCheckbox } from "@common/Checkbox";
import { DatePicker } from "@common/DatePickers";
import { Modal } from "@common/Modal";
import { RHFSelect } from "@common/Select";
import { Text } from "@common/Text";
import { showMessage } from "@common/Toast/ShowToast";
// assets
import { RHFCustomIntegerInput } from "@common/CustomIntegerInput";
import { WithMissingPermissionModule } from "@common/WithMissingPermissionModule";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { RecurringItem } from "@customTypes/recurring.items.types";
import NiceModal, { muiDialogV5, useModal } from "@ebay/nice-modal-react";
import { useAppSelector } from "@redux/hooks";
import {
  useFindRecurringItem,
  useUpdateRecurring,
} from "@services/api/products/transactions";
import { toUnixDateFormat } from "@utils/date.helpers";
import { fromUnixTime } from "date-fns";
import { UseMutateAsyncFunction, useQueryClient } from "react-query";
import { fakeSourceAccountData } from "./Refund";

type ModifyRecurringProps = {
  title?: string;
  orderRecurringItemID: string;
  sourceAccount: any;
  data?: RecurringItem;
  isLoading: boolean;
  mutateAsync: UseMutateAsyncFunction<any, unknown, any, unknown>;
  isCustomersAction?: boolean;
  merchantId?: number;
};

type IFormInputs = {
  recurrence: string;
  date: number | Date;
  maxNumberOfPayments: string;
  cancelRecurring: boolean;
};

const mPoperStyle = {
  background: palette.neutral[5],
};

const ModifyRecurringView = ({
  sourceAccount = fakeSourceAccountData,
  data,
  isLoading,
  mutateAsync,
  isCustomersAction = false,
}: ModifyRecurringProps) => {
  const modal = useModal();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const schema = Yup.object({
    recurrence: Yup.string().required("This field is required"),
    date: Yup.date()
      .typeError("Please provide a valid date")
      .nullable()
      .when("cancelRecurring", {
        is: true,
        then: Yup.date()
          .typeError("Please provide a valid date")
          .nullable()
          .required("This field is required"),
      }),
    maxNumberOfPayments: Yup.string().when({
      is: (exists: string) => !!exists,
      then: (schema) =>
        schema.test({
          message: "Max # of payments should be between 7 and 999",
          test: (value) => {
            if (!value) return true;
            const integer = parseInt(value);
            if (Number.isNaN(integer)) return false;
            return integer >= 7 && integer <= 999;
          },
        }),
    }),
    cancelRecurring: Yup.bool(),
  });

  const defaultValues = {
    recurrence: "monthly",
    date: new Date(),
    maxNumberOfPayments: "13",
    cancelRecurring: false,
  };

  const methods = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    watch,
    control,
    formState: { isDirty },
    reset,
  } = methods;
  const values = watch();
  const cancelRecurring = watch("cancelRecurring");

  React.useEffect(() => {
    if (data && !isLoading) {
      reset({
        recurrence: data?.recurringInterval,
        cancelRecurring: false,
        maxNumberOfPayments: data?.recurringMax ? `${data?.recurringMax}` : "7",
        date: new Date(fromUnixTime(data?.nextAt)),
      });
    }
  }, [isLoading]);

  const client = useQueryClient();

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    const obj = {
      nextAt:
        typeof data.date === "number" ? data.date : toUnixDateFormat(data.date),
    };

    const maxNumberOfPayments = data.maxNumberOfPayments;
    const isCanceled = data.cancelRecurring;

    if (isCanceled) {
      (obj as any).isCancelled = isCanceled;
    }

    if (!Number.isNaN(Number(maxNumberOfPayments))) {
      (obj as any).recurringMax = Number(maxNumberOfPayments);
    }

    if (!isDirty || Object.keys(obj).length === 0) {
      showMessage("Success", "Changes succesfully applied", isDesktop);
      showMessage("Info", "Recurrence changed", isDesktop);
      return modal.hide();
    }

    await mutateAsync(obj, {
      onSuccess: (data) => {
        const queryKeysToInvalidate = client
          .getQueryCache()
          .findAll("get-all-transactions")
          .map((query) => query.queryKey);

        const queryFundraisersItemsKeysToInvalidate = client
          .getQueryCache()
          .findAll("get-fundraiser-transactions-by-id")
          .map((query) => query.queryKey);

        const queryEventsItemsKeysToInvalidate = client
          .getQueryCache()
          .findAll("get-events-transactions-by-id")
          .map((query) => query.queryKey);

        const queryInvoicesItemsKeysToInvalidate = client
          .getQueryCache()
          .findAll("get-invoices-transactions-by-id")
          .map((query) => query.queryKey);

        const queryMembershipsItemsKeysToInvalidate = client
          .getQueryCache()
          .findAll("get-memberships-transactions-by-id")
          .map((query) => query.queryKey);

        const querySweepstakesItemsKeysToInvalidate = client
          .getQueryCache()
          .findAll("get-sweepstakes-transactions-by-id")
          .map((query) => query.queryKey);

        queryKeysToInvalidate.forEach((x) => client.refetchQueries(x));
        queryFundraisersItemsKeysToInvalidate.forEach((x) =>
          client.refetchQueries(x),
        );
        queryEventsItemsKeysToInvalidate.forEach((x) =>
          client.refetchQueries(x),
        );
        queryInvoicesItemsKeysToInvalidate.forEach((x) =>
          client.refetchQueries(x),
        );
        queryMembershipsItemsKeysToInvalidate.forEach((x) =>
          client.refetchQueries(x),
        );
        querySweepstakesItemsKeysToInvalidate.forEach((x) =>
          client.refetchQueries(x),
        );
        client.refetchQueries("get-recurring-item");
        client.refetchQueries("list-all-customers");
        if (cancelRecurring) {
          showMessage(
            "Info",
            "The recurrence of this order has been cancelled",
            isDesktop,
          );
        } else {
          showMessage("Success", "Changes succesfully applied", isDesktop);
          showMessage("Info", "Recurrence changed", isDesktop);
        }
        modal.hide();
      },
      onError: (error: unknown) => {
        const axiosError = error as AxiosError;
        const errorMessage = axiosError.response?.data;
        showMessage(
          "Error",
          "Whoops.. an error occured, please retry the operation",
          isDesktop,
        );
      },
    });
  };

  const title = {
    fullName: isCustomersAction
      ? data?.cardholderName
      : sourceAccount.fullName ?? "",
    email: isCustomersAction ? data?.userEmail : sourceAccount.email ?? "",
    brand: isCustomersAction
      ? data?.cardBrand
      : sourceAccount.card?.brand ?? "",
    numberLast4: isCustomersAction
      ? data?.cardNumberLast4
      : sourceAccount.card?.numberLast4 ?? "",
  };

  const formGrid = useMemo(() => {
    const arr = [
      {
        sm: 6,
        component: (
          <RHFSelect
            InputProps={{
              readOnly: true,
              disabled: true,
              sx: {
                cursor: "default",
              },
            }}
            name="recurrence"
            disabled={true}
            label="Recurrence"
            options={["daily", "weekly", "monthly", "quarterly", "yearly"].map(
              (period) => ({
                value: period,
                label: period.charAt(0).toUpperCase() + period.slice(1),
                hidden: values.recurrence === period,
              }),
            )}
          />
        ),
      },
      {
        sm: 6,
        component: (
          <Controller
            name="date"
            control={control}
            render={({
              field: { ref, value, ...rest },
              fieldState: { error },
            }) => (
              <DatePicker
                {...rest}
                inputRef={ref}
                inputFormat="MM/dd/yyyy"
                value={value}
                openTo="day"
                renderInput={({ inputProps, ...params }) => (
                  <TextField
                    inputRef={ref}
                    {...params}
                    label="Date"
                    fullWidth
                    error={!!error}
                    value={value}
                    helperText={error?.message}
                    inputProps={{
                      ...inputProps,
                      placeholder: "MM/DD/YYYY",
                    }}
                    sx={{
                      "& .MuiInputLabel-root": {
                        left: "28px",
                      },
                      "& .MuiIconButton-root": {
                        marginRight: "2px !important",
                      },
                    }}
                  />
                )}
              />
            )}
          />
        ),
      },
      {
        sm: 6,
        component: (
          <RHFCustomIntegerInput
            name="maxNumberOfPayments"
            placeholder="13"
            label="Max # of Payments"
          />
        ),
      },
      {
        sm: 12,
        component: (
          <RHFCheckbox
            name="cancelRecurring"
            label="Cancel this Recurring Order"
            helperText="If checked the next transaction will be cancelled"
          />
        ),
      },
    ];
    return arr;
  }, [values.recurrence]);
  return (
    <>
      <FadeUpWrapper delay={100}>
        <Stack
          gap={2}
          direction={isDesktop ? "row" : "column"}
          alignItems={isDesktop ? "center" : "flex-start"}
        >
          <Stack gap={1}>
            <Text
              variant="h6"
              fontWeight="semibold"
              color={palette.neutral[800]}
            >
              {title.fullName}
              {title.email && `, ${title.email}`}
              {title.brand &&
                `, ${title.brand?.toUpperCase()} ${title.numberLast4}`}
            </Text>
            <Text
              variant="body"
              fontWeight="regular"
              color={palette.neutral[600]}
            >
              Fill the fields below and click “update”
            </Text>
          </Stack>
        </Stack>
      </FadeUpWrapper>

      <FormProvider {...methods}>
        <Grid
          mt={3}
          container
          spacing={2}
          component="form"
          id="modify-recurring-form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {formGrid.map((c, idx) => (
            <Grid key={idx} item xs={12} sm={c.sm}>
              <FadeUpWrapper delay={150 + idx * 50}>
                {c.component}
              </FadeUpWrapper>
            </Grid>
          ))}
        </Grid>
      </FormProvider>
    </>
  );
};

const ModifyRecurring = NiceModal.create(
  ({
    title,
    orderRecurringItemID,
    sourceAccount = fakeSourceAccountData,
    isCustomersAction,
    merchantId,
  }: ModifyRecurringProps) => {
    const modal = useModal();

    const { data, isLoading } = useFindRecurringItem(
      orderRecurringItemID,
      merchantId,
    );

    const { mutateAsync, isLoading: isLoadingUpdate } = useUpdateRecurring(
      orderRecurringItemID,
      data?.merchAccID as number,
    );

    const hasNotModifyRecurringPermission = useAppSelector(
      (state) => state.app.permissions?.modify_recurring_view,
    );

    return (
      <Modal
        {...muiDialogV5(modal)}
        width="650px"
        title={`Modify Recurring ${
          title === "Purchase" ? "Purchase" : "transaction"
        }`}
        onClose={() => modal.hide()}
        closeAfterTransition
        PaperProps={{
          style: mPoperStyle,
        }}
        sx={{
          "& .MuiDialogTitle-root": {
            background: palette.neutral[5],
          },
          "& .CustomIconButton": {
            background: "transparent",
          },
        }}
        data-testid="modify-recurring-modal"
        actions={
          <>
            <Button
              style={{
                background: "transparent",
                border: "1.5px solid #8F8F8F",
                borderRadius: "32px",
                color: palette.neutral[80],
              }}
              variant="text"
              onClick={() => modal.hide()}
            >
              Cancel
            </Button>
            {hasNotModifyRecurringPermission ? null : (
              <Button
                variant="primary"
                form="modify-recurring-form"
                type="submit"
                disabled={isLoading || isLoadingUpdate}
                data-testid="modify-recurring-submit-button"
              >
                Update
              </Button>
            )}
          </>
        }
      >
        <WithMissingPermissionModule
          isLoading={isLoading}
          notAuthorized={hasNotModifyRecurringPermission}
          message="Modify recurring view hidden"
          wrapperProps={{
            marginTop: 0,
            padding: "24px",
          }}
        >
          <ModifyRecurringView
            orderRecurringItemID={orderRecurringItemID}
            sourceAccount={sourceAccount}
            data={data}
            isCustomersAction={isCustomersAction}
            isLoading={isLoading}
            mutateAsync={mutateAsync}
          />
        </WithMissingPermissionModule>
      </Modal>
    );
  },
);

export default ModifyRecurring;
