import { CheckedFilledCircleIcon } from "@assets/icons";
import { Box, Divider, Stack, styled, SxProps } from "@mui/material";
import { Text } from "@common/Text";
import { palette } from "@palette";
import InfoFillIcon from "@assets/icons/InfoFillIcon";
import { CircleDashed } from "@phosphor-icons/react";
import { Button } from "@common/Button";
import { Tooltip } from "@common/Tooltip";
import { DisputeCaseType } from "../data.types";
import { format } from "date-fns";
import NiceModal from "@ebay/nice-modal-react";
import { EVIDENCE_PROVIDED_MODAL } from "modals/modal_names";

const DisputeCircleDashedIcon = () => {
  return (
    <Box
      width={24}
      height={24}
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="32px"
      bgcolor={palette.filled.orange}
    >
      <CircleDashed size={12} color={palette.neutral.white} />
    </Box>
  );
};

const ToolTipText = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <Text
      color={palette.neutral[40]}
      fontSize={12}
      fontWeight="book"
      lineHeight="14.4px"
    >
      {title}: <span style={{ color: palette.neutral[5] }}>{description}</span>
    </Text>
  );
};

interface IDisputeHistoryCardProps {
  data: DisputeCaseType;
  issuingBankName: string;
  isLast?: boolean;
}

const DisputeCaseHistoryCard = ({
  data,
  isLast,
  issuingBankName,
}: IDisputeHistoryCardProps) => {
  const statusIcons = {
    done: <CheckedFilledCircleIcon width={24} height={24} />,
    ongoing: <DisputeCircleDashedIcon />,
  };

  const handleViewEvidence = () => {
    NiceModal.show(EVIDENCE_PROVIDED_MODAL);
  };

  return (
    <Stack gap={1} data-testid="dispute-history-card">
      <Text color={palette.neutral[70]} lineHeight="16.8px">
        {format(new Date(data?.createdAt * 1000), "MMM d, yyyy, HH:mm")}
      </Text>
      <StyledCardContainer>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" gap={2}>
            {["pending", "open"]?.includes(data?.status)
              ? statusIcons["ongoing"]
              : statusIcons["done"]}
            <Stack spacing={0.5}>
              <Text color={palette.neutral[70]} lineHeight="16.8px">
                {data?.status === "submitted"
                  ? "Merchant (You)"
                  : issuingBankName || "Issuing Bank"}
              </Text>
              <Text fontSize={16} color={palette.neutral[80]} lineHeight="16px">
                {data?.notes}
              </Text>
              <Text color={palette.neutral[80]} lineHeight="16.8px">
                {data?.reason}
              </Text>
            </Stack>
          </Stack>
          <Tooltip
            placement="top"
            title={<ToolTipText title="Case ID" description={data?.id} />}
            description={
              <ToolTipText title="Reason Code" description="{reason_code}" />
            }
            toolTipSx={toolTipSx}
          >
            <IconWrapper data-testid="info-icon">
              <InfoFillIcon width={20} height={20} fill="#A8A8A8" />
            </IconWrapper>
          </Tooltip>
        </Stack>
        {data?.fileEvidence && (
          <>
            <Divider color={palette.neutral[30]} />
            <StyledButton
              size="medium"
              background="tertiary"
              onClick={handleViewEvidence}
            >
              View Evidence
            </StyledButton>
          </>
        )}
      </StyledCardContainer>
      {!isLast && <StyledVerticalLine />}
    </Stack>
  );
};

const StyledCardContainer = styled(Stack)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  backgroundColor: palette.neutral[10],
  gap: theme.spacing(2),
}));

const StyledButton = styled(Button)({
  width: "fit-content",
  height: "fit-content",
  padding: 0,
  color: palette.neutral[80],
  fontWeight: "350",
});

const StyledVerticalLine = styled(Box)(({ theme }) => ({
  height: 28,
  width: 1,
  backgroundColor: palette.gray[100],
  marginLeft: theme.spacing(2),
}));

const IconWrapper = styled(Box)({
  "&:hover ": {
    cursor: "pointer",
  },
});

const toolTipSx: SxProps = {
  right: "8px",
  top: "8px",
  borderRadius: "8px",
  alignItems: "flex-start",
  paddingTop: "4px",
  paddingBottom: "4px",
  backgroundColor: palette.neutral[80],
  maxWidth: "unset",
};

export default DisputeCaseHistoryCard;
