import { useState } from "react";
import { Text, TruncateText } from "@common/Text";
import { Box, ClickAwayListener, Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { StyledIconButton } from "@common/IconButton";
import { TrashBin } from "@assets/icons/RebrandedIcons";
import { DownloadIcon } from "@assets/icons";
import { formatOwnersDateUTC } from "../../hooks/useCreateMerchantBusinessOwner";
import moment from "moment";
import { useDocumentHandlers } from "@hooks/common/documents";
import { TMerchantDocument } from "../../data.types";
import { useQueryClient } from "react-query";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { CustomToolTip } from "@common/BusinessOwners/CustomToolTip";
import { DELETE_DENY_MESSAGE } from "@constants/permissions";
import { truncateFilenameWithExtension } from "@utils/helpers";
import { Eye } from "@phosphor-icons/react";
import { checkPortals } from "@utils/routing";

type TBusinessOwnerDocument =
  | TMerchantDocument
  | {
      updatedAt: string;
      fileName: string;
      fileURL: any;
      id: string;
    };

type Props = {
  uploadList?: TBusinessOwnerDocument[];
  deleteDocument: (file: TMerchantDocument) => void;
  merchantID: number;
  loadingFile?: string;
  tag?: string;
  bankAccountId: number | undefined;
};

const BusinessOwnerUploadList = ({
  uploadList,
  deleteDocument,
  merchantID,
  loadingFile,
  tag,
  bankAccountId,
}: Props) => {
  return (
    <Stack direction="column" gap={1}>
      {loadingFile && <LoadingFile fileName={loadingFile} />}
      {uploadList?.map((document) => (
        <DocumentItem
          key={document.id}
          uploadList={uploadList}
          merchantID={merchantID}
          deleteDocument={deleteDocument}
          {...document}
          tag={tag}
          bankAccountId={bankAccountId}
        />
      ))}
    </Stack>
  );
};

const DocumentItem = ({
  merchantID,
  deleteDocument,
  tag,
  uploadList,
  bankAccountId,
  ...props
}: {
  merchantID: number;
  deleteDocument: (file: TMerchantDocument) => void;
  tag?: string;
  uploadList: TBusinessOwnerDocument[];
  bankAccountId: number | undefined;
} & TBusinessOwnerDocument) => {
  const { fileName, updatedAt } = props;
  const queryClient = useQueryClient();
  const { isMobileView } = useCustomTheme();
  const { isEnterprisePortal } = checkPortals();
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const refetcher = () => {
    queryClient.refetchQueries("get-bank-files");
    queryClient.refetchQueries("get-merchant-preview");
  };
  const { deleteHandler, downloadHandler, isDeleteAllowed, previewHandler } =
    useDocumentHandlers(
      merchantID,
      uploadList as TMerchantDocument[],
      isEnterprisePortal,
      refetcher,
    );

  const download = (file: TMerchantDocument) => {
    if (typeof props.updatedAt === "number") {
      downloadHandler(file);
      return;
    }
    const link = document.createElement("a");
    link.href = file.fileURL;
    link.download = file.fileName;
    link.target = "_blank";
    link.click();
  };

  const remove = (file: TMerchantDocument) => {
    if (typeof props.updatedAt === "number") {
      deleteHandler(file, () => {
        refetcher();
        deleteDocument(file);
      });
      return;
    }
    deleteDocument(file);
  };

  const actions = [
    {
      label: "view",
      children: <Eye width={18} height={18} color="#8F8F8F" />,
      onClick: () => previewHandler(props as TMerchantDocument),
      hide: !bankAccountId || !(props as TMerchantDocument).isUploaded,
    },
    {
      label: "download",
      children: <DownloadIcon width={18} height={18} stroke="#8F8F8F" />,
      onClick: () => download(props as TMerchantDocument),
    },
    {
      label: "delete",
      children: <TrashBin />,
      disabled: !isDeleteAllowed,
      onClick: () => remove(props as TMerchantDocument),
      tooltipProps: {
        show: !isDeleteAllowed,
        message: DELETE_DENY_MESSAGE,
      },
    },
  ];

  const formattedDate =
    typeof updatedAt === "number"
      ? formatOwnersDateUTC(new Date(updatedAt * 1000), isMobileView)
      : updatedAt;

  return (
    <ClickAwayListener onClickAway={() => setIsFocused(false)}>
      <ItemContainer
        sx={{
          ...(isFocused && {
            backgroundColor: palette.neutral.white,
            "& .document-item-actions": {
              display: "flex",
            },
          }),
        }}
      >
        {isMobileView ? (
          <>
            <TruncateText
              lineClamp={1}
              color={palette.black[100]}
              variant="body"
              lineHeight="16.8px"
              fontWeight="book"
            >
              {truncateFilenameWithExtension(fileName)}
            </TruncateText>
            <Stack direction="row" alignItems="center" gap={1}>
              <Tag>{tag || "Business document"}</Tag>
              <Text
                color={palette.gray[300]}
                variant="caption"
                lineHeight="14.4px"
                fontWeight="book"
              >
                {formattedDate}
              </Text>
            </Stack>
          </>
        ) : (
          <Box display="flex" justifyContent="space-between" width="100%">
            <Stack spacing={1}>
              <TruncateText
                lineClamp={1}
                color={palette.black[100]}
                variant="body"
                lineHeight="16.8px"
                fontWeight="book"
              >
                {truncateFilenameWithExtension(fileName)}
              </TruncateText>
              <Text
                color={palette.gray[300]}
                variant="caption"
                lineHeight="14.4px"
                fontWeight="book"
              >
                {formattedDate}
              </Text>
            </Stack>
            <Tag>{tag || "Business document"}</Tag>
          </Box>
        )}
        <ActionsContainer className="document-item-actions">
          {actions.map((action) => {
            const { label, tooltipProps, hide, ...rest } = action;
            if (hide) return;
            if (!rest.onClick) return;
            if (!tooltipProps) return <ActionButton key={label} {...rest} />;

            return (
              <CustomToolTip
                key={label}
                showToolTip={tooltipProps?.show}
                message={tooltipProps?.message}
              >
                <ActionButton key={label} {...rest} />
              </CustomToolTip>
            );
          })}
        </ActionsContainer>
      </ItemContainer>
    </ClickAwayListener>
  );
};

const ActionsContainer = styled(Stack)(() => ({
  display: "none",
  position: "absolute",
  flexDirection: "row",
  alignItems: "center",
  padding: "4px 8px",
  gap: "12px",
  top: "8px",
  right: "4px",
  borderRadius: "4px",
  backgroundColor: palette.neutral.white,
  boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.08)",
}));

const ItemContainer = styled(Stack)(({ theme }) => ({
  padding: "8px 4px",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "8px",
  borderRadius: "4px",
  position: "relative",

  ":hover": {
    backgroundColor: palette.neutral.white,
    "& .document-item-actions": {
      display: "flex",
    },
  },
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
}));

const ActionButton = styled(StyledIconButton)(() => ({
  maxWidth: "18px",
  maxHeight: "18px",
  backgroundColor: "inherit",
  borderRadius: "4px",
  padding: "0 !important",
}));

export const Tag = styled(Text)(() => ({
  padding: "2px 8px",
  borderRadius: "4px",
  background: "#EBEBEB",
  height: "22px",
  color: palette.neutral[80],
  fontSize: 12,
  fontWeight: "350",
  whiteSpace: "nowrap",
}));

const LoadingFile = ({ fileName }: { fileName: string }) => {
  return (
    <ItemContainer>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        maxHeight="24px"
        width="100%"
      >
        <TruncateText
          lineClamp={1}
          color={palette.neutral[70]}
          variant="body"
          lineHeight="16.8px"
          fontWeight="book"
        >
          {fileName}
        </TruncateText>
        <LoadingSpinner
          color={palette.neutral[70]}
          spinnerProps={{
            size: "22px",
          }}
          sx={{
            height: "100%",
            alignItems: "flex-start",
          }}
        />
      </Stack>
    </ItemContainer>
  );
};

const formatUTC = (date: number) =>
  moment.unix(date).utc().format(`MMM D, YYYY, h:mm:ss A [UTC]`);

export default BusinessOwnerUploadList;
