import { capitalizeFirstLetter } from "@common/Table/helpers";
import React from "react";

type Merchant = "merchant" | "provider";
type User = "underwriter" | "merchant";
type MessageGenerator = (
  fields: string,
  role?: Merchant,
) => Record<User, React.ReactNode>;

export const createChallengeMessage: Record<string, MessageGenerator> = {
  enterprise_classification: (fields: string, role = "merchant") => ({
    underwriter: `Confirm that the Classification aligns with the provider's information.`,
    merchant: `Please provide required ${role} information: ${fields}.`,
  }),
  enterprise_info1: (fields: string, role = "merchant") => ({
    underwriter: `Collect required ${role} information: ${fields}.`,
    merchant: `Please provide required ${role} information: ${fields}.`,
  }),
  merchant_info1: (fields: string, role = "merchant") => ({
    underwriter: `Collect required ${role} information: ${fields}.`,
    merchant: `Please provide required ${role} information: ${fields}.`,
  }),
  merchant_info2: (fields: string, role = "merchant") => ({
    underwriter: `Verify that the ${role}'s purpose or mission matches their website, social media, search results, news articles etc..`,
    merchant: `Please provide optional ${role} information: ${fields}.`,
  }),
  enterprise_info2: (fields: string, role = "merchant") => ({
    underwriter: `Verify that the ${role}'s purpose or mission matches their website, social media, search results, news articles etc..`,
    merchant: `Please provide optional ${role} information: ${fields}.`,
  }),
  enterprise_info3: (fields: string, role = "merchant") => ({
    underwriter: `Verify if the documents uploaded matches the information provided by the provider and match against IRS databases, Public or government business resources to verify the company.`,
    merchant: `Please provide optional ${role} information: ${fields}.`,
  }),
  merchant_info3: (fields: string, role = "merchant") => ({
    underwriter: `Verify if the documents uploaded matches the information provided by the ${role} and match against IRS databases, Public or government business resources to verify the company.`,
    merchant:
      "Please upload documented proof that your company is domiciled in the United States.",
  }),
  merchant_info4: (fields: string, role = "merchant") => ({
    underwriter:
      "Verify that the countries serviced outside the USA/Canada are allowed per OFAC.",
    merchant: "None.",
  }),
  primary1: (fields: string, role = "merchant") => ({
    underwriter: `Collect required Primary Account Holder information: ${fields}.`,
    merchant: `Please provide required Primary Account Holder information: ${fields}.`,
  }),
  primary3: (fields: string, role = "merchant") => ({
    underwriter: "Primary Account Holder needs to verify their email.",
    merchant: "Please verify Primary Account Holder email.",
  }),
  primary4: (fields: string, role = "merchant") => ({
    underwriter: "Primary Account Holder needs to set password.",
    merchant: "Primary Account Holder needs to set password.",
  }),
  primary5: (fields: string, role = "merchant") => ({
    merchant:
      "Primary Account Holder needs to upload a government issued, non-expired ID and a selfie.",
    underwriter:
      "Verify the Primary Account Holder ID matches the selfie and other information provided.",
  }),
  primary6: (fields: string, role = "merchant") => ({
    merchant: `Please upload evidence that the Primary Account Holder has authority to manage this ${role} account.`,
    underwriter: `Verify the Primary Account Holder has managerial authority to manage the ${role}.`,
  }),
  business_profile1: (fields: string, role = "merchant") => ({
    underwriter: `Collect required business profile information: ${fields}.`,
    merchant: `Please provide required business profile information: ${fields}.`,
  }),
  business_profile3: (fields: string, role = "merchant") => ({
    underwriter: `Confirm that the documents uploaded by the ${role} align with the information they have provided. Cross-reference these details with IRS databases and public or government business resources or websites to accurately verify the business ownership of the company. If sole-proprietorship make sure Tax ID & SSN of Business Owner match. Look up and verify SSN.`,
    merchant:
      "Please provide documented proof of your business establishment. Acceptable documents include, but are not limited to, the following: Articles of Incorporation, IRS Employer Identification Number Notice (CP-575), Corporate Bylaws or Operating Agreement, Partnership Agreement, DBA (Doing Business As) Certificate, Business Plan, Secretary of State Records, and any relevant Licenses and Permits.",
  }),
  business_profile4: (fields: string, role = "merchant") => ({
    underwriter:
      "Manually run the OFAC check if the system hasn't automatically ran it.",
    merchant: "None.",
  }),
  business_profile5: (fields: string, role = "merchant") => ({
    underwriter: `Review the OFAC match with the Business Profile of the ${role} and either confirm or reject the match and set the status accordingly. The reason for confirm or rejection must be documented in the merchant profile.`,
    merchant: "None.",
  }),
  business_profile6: (fields: string, role = "merchant") => ({
    underwriter: (
      <span data-testid="business-profile6-msg">
        {`Set ${role} status to suspended and document the reason in the ${role} profile. Refer to the`}{" "}
        <Link
          url="https://docs.google.com/spreadsheets/d/1smNe49_5WIJafs5KZZRVYiwhyP2hblay3aJ8EhOGL38/edit?usp=sharing"
          displayUrl={`${capitalizeFirstLetter(role)} Risk Response`}
        />{" "}
        {`playbook for further action to take.`}
      </span>
    ),
    merchant: "None.",
  }),
  business_owner1: (fields: string, role = "merchant") => ({
    underwriter: `Collect required business owner information: ${fields}.`,
    merchant:
      "Please provide required business owner information. First Name, Last Name, Email, Date of Birth, Tax ID, Ownership %, Street Address, City, State, Zip, Country.",
  }),
  business_owner3: (fields: string, role = "merchant") => ({
    underwriter: `Confirm that the documents uploaded by the ${role} align with the information they have provided. Cross-reference these details with IRS databases and public or government business resources or websites to accurately verify the business ownership of the company.`,
    merchant:
      "Please provide documented proof of business owners. Acceptable documents include, but are not limited to, the following: Articles of Incorporation, Operating Agreement, Partnership Agreement, Stock Certificates, Meeting Minutes, Schedule K-1 (Form 1065), Tax Returns (Sole Proprietorship), DBA Filing, Shareholder Agreement, Secretary of State Records.",
  }),
  business_owner4: (fields: string, role = "merchant") => ({
    underwriter: `Review the PEP match with the Business Owner of the ${role} and either confirm or reject the match and set the status accordingly. The reason for confirm or rejection must be documented in the ${role} profile.`,
    merchant: "None.",
  }),
  business_owner5: (fields: string, role = "merchant") => ({
    underwriter: `Examine the PEP (Politically Exposed Persons) match in relation to the Business Owner of the ${role}. Decide whether to confirm or reject the match, and update the status appropriately. Ensure that the rationale for confirmation or rejection is thoroughly documented in the ${role}'s profile.`,
    merchant: "None.",
  }),
  business_owner6: (fields: string, role = "merchant") => ({
    underwriter: `If the PEP is confirmed, additional justification must be provided and documented in the ${role} profile for reason to move the ${role} forward and remove this challenge.`,
    merchant:
      "Please upload a detailed, documented explanation that validates the legitimacy and business rationale for a Politically Exposed Person (PEP) being a business owner.",
  }),
  bank_account1: (fields: string, role = "merchant") => ({
    underwriter: `${
      role.charAt(0).toUpperCase() + role.slice(1)
    } must add or connect a bank account: ${fields}.`,
    merchant: `Please add or connect a bank account: ${fields}.`,
  }),
  bank_account2: (fields: string, role = "merchant") => ({
    underwriter: `Ensure that the documents uploaded by the ${role} correspond with the information they have provided. This process involves matching the account name with the name on the bank statement or Plaid information. If necessary, this may also entail contacting the bank to confirm account ownership. For account verification, record a documented reason in the ${role}'s profile and set the bank account status to 'approved'.`,
    merchant:
      "Please upload bank account statements that clearly display the following details: the account holder's name, account number, routing number, business address, and any other relevant information. This will serve as evidence that the linked bank account is associated with your business.",
  }),
  bank_account3: (fields: string, role = "merchant") => ({
    underwriter: `${
      role.charAt(0).toUpperCase() + role.slice(1)
    } connects a bank account with Plaid.`,
    merchant:
      "For an easier verification process please connect your bank account with Plaid.",
  }),
  bank_account4: (fields: string, role = "merchant") => ({
    underwriter: `Further verification is needed when the name on the account does not align with the legal name in the ${role} or business profile. Any justification for this discrepancy must be clearly documented in the ${role}'s profile to resolve this issue.`,
    merchant:
      "Please upload a detailed, documented explanation why the account holder name does not match the legal name of the business.",
  }),
  general_website1: (fields: string, role = "merchant") => ({
    underwriter: `Verify the Website URL matches the information provided by the ${role}.`,
    merchant: "None.",
  }),
  general_mcc1: (fields: string, role = "merchant") => ({
    underwriter: `Confirm that the MCC aligns with the ${role}'s information, and review the ${role}'s website and payment forms to ensure they correspond to the ${role} category.`,
    merchant:
      "Please upload a detailed, documented explanation why there are discrepancies between the merchant category you selected and the content, services, or products offered on your website. If you mistakenly chose the wrong category, please update it to better align with your business.",
  }),
  general_match1: (fields: string, role = "merchant") => ({
    underwriter: `Perform a MATCH screening of the merchant and business profile.`,
    merchant: "None.",
  }),
  general_match2: (fields: string, role = "merchant") => ({
    underwriter: `Examine the MATCH against the ${role} and Business Profile, and then either confirm or reject the match, adjusting the status accordingly. Ensure that the reason for confirmation or rejection is documented in the ${role} profile.`,
    merchant: "None.",
  }),
  general_match3: (fields: string, role = "merchant") => ({
    underwriter: `If the MATCH is confirmed, please provide additional justification and document it in the ${role} profile as the reason for advancing the ${role} and resolving this challenge.`,
    merchant:
      "Please uploaded a detailed, documented explanation for the confirmed MATCH screening result related to your business and state the reasons for proceeding with your application.",
  }),
  merchant_info5: (fields: string, role = "merchant") => ({
    underwriter: `Merchant needs to set a ${fields}.`,
    merchant: `Please update your ${fields} information.`,
  }),
  negative_news: (fields: string, role = "merchant") => ({
    underwriter: `Review various sources such as news articles, social media posts, blogs, court records, judgments, Better Business Bureau reports, and other sources that may indicate any adverse information that may suggest potential risks such as involvement in criminal activities, fraud, or unethical behavior. Document any findings and assess their impact on the ${role}’s risk profile.`,
    merchant: "None.",
  }),
  expected_activity: (fields: string, role = "merchant") => ({
    underwriter: `Review the ${role}’s expected activity metrics, focusing on annual sales volume, average ticket, and high ticket amount, to ensure they align with industry norms, annual sales, and expectations. Analyze these metrics to identify any anomalies or deviations that could indicate potential risks, such as fraud, financial instability, or regulatory non-compliance. Pay particular attention to unusual spikes or patterns in transaction sizes and volumes that might signal irregular or suspicious activity. Document any findings.`,
    merchant: "None.",
  }),
  ofac1: (fields: string, role = "merchant") => ({
    underwriter:
      "Manually run the OFAC check if the system hasn't automatically ran it.",
    merchant: "None.",
  }),
  ofac2: (fields: string, role = "merchant") => ({
    underwriter: `Review the OFAC match of the ${role} and either confirm or reject the match and set the status accordingly. The reason for confirm or rejection must be documented in the merchant profile.`,
    merchant: "None.",
  }),
  ofac3: (fields: string, role = "merchant") => ({
    underwriter: (
      <span data-testid="ofac3-msg">
        {`Set ${role} status to suspended and document the reason in the ${role} profile. Refer to the`}{" "}
        <Link
          url="https://docs.google.com/spreadsheets/d/1smNe49_5WIJafs5KZZRVYiwhyP2hblay3aJ8EhOGL38/edit?usp=sharing"
          displayUrl={`${capitalizeFirstLetter(role)} Risk Response`}
        />{" "}
        {`playbook for further action to take.`}
      </span>
    ),
    merchant: "None.",
  }),
  underwriting_match_check: (fields: string, role = "merchant") => ({
    underwriter: `Manually verify the ${role}'s business profile against the MasterCard database to ensure accurate risk and compliance checks.`,
    merchant: "None.",
  }),
};

const Link = ({ url, displayUrl }: { url: string; displayUrl?: string }) => (
  <a
    target="_blank"
    rel="noreferrer"
    href={url}
    style={{ wordBreak: "break-all" }}
  >
    {displayUrl || url}
  </a>
);
