import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { getCountryCode } from "@utils/country_dial_codes";
import { useAppSelector, useAppDispatch } from "@redux/hooks";
import NiceModal from "@ebay/nice-modal-react";
import { CHECKOUT_MODAL, THANK_YOU_MODAL } from "modals/modal_names";
import { useMutation, useQueryClient } from "react-query";
import { customInstance } from "@services/api";
import { showMessage } from "@common/Toast";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { selectCardType } from "@redux/slices/checkout";
import { convertPhoneNumber } from "@utils/date.helpers";
import { setCartItems } from "@redux/slices/cart";

type IFormInputs = {
  cardNumber: string;
  expiration: string;
  cvv: string;
  nameOnCard: string;
  email: string;
  country: string;
  phone: string;
  address: string;
  province: string;
  city: string;
  zipCode: string;
  employer: string;
  occupation: string;
  customField: string;
  processingFee: boolean;
  termsConditions: boolean;
};

const getErrorParamName = (param: string) => {
  switch (param) {
    case "user.firstName":
      return "First name";
    case "user.lastName":
      return "Last name";
    case "user.email":
      return "Email";
    case "user.phone":
      return "Phone number";
    case "paymentMethodParams.paymentMethod.card.number":
      return "Card number";
    case "paymentMethodParams.paymentMethod.card.cvv":
      return "cvv";
    case "paymentMethodParams.paymentMethod.card.cardholderName":
      return "Card holder name";
    case "paymentMethodParams.paymentMethod.card.expiryMonth":
      return "Expiry month";
    case "paymentMethodParams.paymentMethod.card.expiryYear":
      return "Expiry year";
    case "paymentMethodParams.paymentMethod.address.name":
      return "Card holder name";
    case "paymentMethodParams.paymentMethod.address.line1":
      return "address";
    case "paymentMethodParams.paymentMethod.address.city":
      return "city";
    case "paymentMethodParams.paymentMethod.address.state":
      return "state";
    case "paymentMethodParams.paymentMethod.address.country":
      return "country";
    default:
      return "Input";
  }
};

type Props = {
  destinationAccountMerchantName?: string;
  closeCheckoutDrawer?(): void;
  isDebit: boolean;
};

export const useCheckout = ({
  destinationAccountMerchantName,
  closeCheckoutDrawer,
  isDebit,
}: Props) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const checkoutCardType = useAppSelector(selectCardType);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const [isDeclined, setIsDeclined] = useState(false);

  const CVVLimit = React.useMemo(() => {
    switch (checkoutCardType) {
      case "AMEX":
        return 4;
      default:
        return 3;
    }
  }, [checkoutCardType]);

  const isAmex = (cardNumber: string) =>
    cardNumber.startsWith("37") || cardNumber.startsWith("34");

  const isNonValidCardNumber = (cardNumber: string) => {
    if (cardNumber.length < 2) return true;
    if (isAmex(cardNumber) && cardNumber.length < 15) return true;
    if (!isAmex(cardNumber) && cardNumber.length < 19) return true;
    return false;
  };

  const isNonValidExpirationDate = (expiration: string) => {
    const month = expiration.substring(0, 2);
    const year = expiration.substring(5, 7);
    if (month.length < 2 || year.length < 2) return true;

    const today = new Date();

    //Credit cards are usually valid until the last day of the specified month
    const expiryDate = new Date(
      parseInt(today.getFullYear().toString().substring(0, 2) + year),
      +month,
      0, // Day 0 of the next month gives the last day of the current month
    );

    if (expiryDate < today) return true;

    return false;
  };

  const schema = Yup.object().shape({
    cardNumber: Yup.string()
      .required("Required")
      .test({
        name: "validator-custom-card-number",
        test: function (value) {
          return isNonValidCardNumber(value || "")
            ? this.createError({
                message: "Please enter a valid card number",
                path: "cardNumber",
              })
            : true;
        },
      }),
    expiration: Yup.string()
      .required("Required")
      .min(7, "Please enter a valid expiration")
      .test({
        name: "validator-custom-expiration",
        test: function (value) {
          return isNonValidExpirationDate(value || "")
            ? this.createError({
                message: "Please enter a valid expiration",
                path: "expiration",
              })
            : true;
        },
      }),
    cvv: Yup.string().required("Required").min(3, "Please enter a valid CVV"),
    nameOnCard: Yup.string()
      .trim()
      .required("Required")
      .matches(/^[a-zA-Z ]*$/, "Please enter a valid Name"),
    email: Yup.string().email("Not a valid format").required("Required"),
    country: Yup.string(),
    phone: Yup.string(),
    address: Yup.string(),
    province: Yup.string(),
    city: Yup.string(),
    zipCode: Yup.string().when({
      is: (exists: string) => !!exists,
      then: Yup.string().matches(
        /^[0-9]{5}(?:-?[0-9]{4})?$/,
        "Invalid ZIP format",
      ),
    }),
    employer: Yup.string(),
    occupation: Yup.string(),
    processingFee: Yup.boolean(),
    termsConditions: Yup.boolean(),
  });

  const methods = useForm<IFormInputs>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      cardNumber: "",
      expiration: "",
      cvv: "",
      nameOnCard: "",
      email: "",
      country: "United States",
      phone: "",
      address: "",
      province: "",
      city: "",
      zipCode: "",
      employer: "",
      occupation: "",
      processingFee: false,
      termsConditions: true,
    },
  });

  const { reset, watch, setError, clearErrors } = methods;
  const values = watch();

  useEffect(() => {
    if (values.cardNumber && isDeclined) {
      setIsDeclined(false);
      clearErrors("cardNumber");
      clearErrors("cvv");
      clearErrors("expiration");
    }
  }, [values.cardNumber, values.cvv, values.expiration]);

  const cartCheckoutMutation = useMutation(async (query: any) => {
    return customInstance({
      url: `/cart/checkout `,
      method: "POST",
      data: query,
    });
  });

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    const [expMonth, expYearShort] = data?.expiration?.split("/") || [];
    const [firstname, lastname] = data.nameOnCard?.split(" ") || [];

    const customData = {
      user: {
        firstName: firstname,
        lastName: lastname,
        email: data.email,
        phone: convertPhoneNumber(data.phone),
      },
      paymentMethodParams: {
        paymentMethodID: null,
        paymentMethod: {
          card: {
            address: {
              name: data.nameOnCard,
              line1: data.address,
              city: data.city,
              state: data.province,
              country: getCountryCode(data.country),
              zip: data.zipCode,
            },
            notes: data.customField,
            cvv: data.cvv,
            cardholderName: data.nameOnCard,
            number: data.cardNumber?.replaceAll(" ", ""),
            expiryMonth: parseInt(expMonth),
            expiryYear: 2000 + parseInt(expYearShort),
            isDebit,
          },
          isDefault: false,
        },
      },
      passFees: !data.processingFee,
    };

    cartCheckoutMutation.mutate(customData, {
      onError: (error: unknown) => {
        const axiosError = error as any;
        const errorMessage = axiosError.response?.data?.message;

        if ((error as any)?.response?.data.input) {
          showMessage(
            "Error",
            (error as any)?.response?.data.input[0].message ||
              `${getErrorParamName(
                (error as any)?.response?.data.input[0].param,
              )} is not valid`,
            isDesktop,
          );
        } else {
          showMessage("Error", errorMessage);
        }
      },
      onSuccess: (res: any) => {
        if (
          res?.transactionProcessingState === "declined" ||
          !res?.transactionProcessingState
        ) {
          setIsDeclined(true);
          setError("cardNumber", { message: "" });
          setError("expiration", { message: "" });
          setError("cvv", { message: "" });

          return;
        }

        queryClient.refetchQueries("find-sweepstake-by-id");
        queryClient.refetchQueries("find-membership-by-id");
        queryClient.refetchQueries("find-event-by-id");
        reset();
        closeCheckoutDrawer && closeCheckoutDrawer();
        NiceModal.hide(CHECKOUT_MODAL);
        dispatch(setCartItems([]));
        NiceModal.show(THANK_YOU_MODAL, {
          destinationAccountMerchantName:
            destinationAccountMerchantName?.toUpperCase(),
          email: data.email,
          amount: res.cost / 100 + (res.passFees ? 0 : res.fees / 100),
          notes: res.notes,
          cardType: checkoutCardType,
        });
      },
    });
  };

  return {
    methods,
    onSubmit,
    CVVLimit,
    isLoading: cartCheckoutMutation.isLoading,
    isDeclined,
  };
};
