import { Text } from "@common/Text";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { Stack } from "@mui/material";
import { palette } from "@palette";
import EvidenceDocumentList from "./EvidenceDocumentList";
import { TMerchantDocument } from "@components/Merchants/MerchantPreview/data.types";
import evidenceImage from "@assets/images/main-canvas-img.png";

const EvidenceProvidedBody = () => {
  const documentList = [
    {
      fileName: "transactions_image.png",
      notes:
        "Review the transactions highlighted on pages 3 and 4. There are discrepancies that need to be addressed, particularly the charges from March 15 and March 22 which appear to be duplicates.",
      fileType: "png",
      fileURL: evidenceImage,
    },
  ] as TMerchantDocument[];

  const sectionsList = [
    { title: "Case Action", value: "Service Cats of America" },
    {
      title: "Case Notes",
      value: "Customer verified via OTP sent to registered mobile number",
    },
    {
      title: "Documents",
      value: <EvidenceDocumentList documentList={documentList} />,
    },
  ];

  return (
    <FadeUpWrapper delay={50}>
      <Stack px={3} pb={3} spacing={1.5}>
        {sectionsList.map((section) => (
          <Section
            key={section.title}
            title={section.title}
            value={section.value}
          />
        ))}
      </Stack>
    </FadeUpWrapper>
  );
};

type SectionProps = {
  title: string;
  value: string | React.ReactNode;
};

const Section = ({ title, value }: SectionProps) => {
  return (
    <Stack spacing={0.5}>
      <Text color={palette.neutral[70]} fontWeight="light" lineHeight="16.8px">
        {title}
      </Text>
      {typeof value === "string" ? (
        <Text color={palette.neutral[90]} lineHeight="16.8px">
          {value}
        </Text>
      ) : (
        value
      )}
    </Stack>
  );
};

export default EvidenceProvidedBody;
