import {
  BANK_ACCOUNTS_MODAL,
  DISPUTE_PREVIEW_PANEL,
  ENTERPRISE_PREVIEW_PANEL,
  MERCHANT_PREVIEW_PANEL_MODAL,
  NOTIFICATION_SIDE_PANEL,
  TRANSACTION_INFO_MODAL,
} from "modals/modal_names";
import useRedirect from "@hooks/common/router/useRedirect";
import { checkPortals } from "@utils/routing";
import { DMenu as AcquirerDMenu } from "@pages/AcquirerPortal/Processing/ProcessingMenu";
import { DMenu as EnterpriseDMenu } from "@pages/EnterprisePortal/Processing/ProcessingMenu";
import {
  CurrentPortalKey,
  RedirectURLKeys,
  redirectPageUrls,
  getIdFromTString,
} from "../utils/redirects";
import { getTransaction } from "@components/ManageMoney/TransactionTable/TransactionInfoModal/utils";
import { TMerchantInitialSection } from "@components/Merchants/MerchantPreview/MerchantPreviewModal";
import { scrollElIntoView } from "@components/Merchants/MerchantPreview/hooks/useSectionFocusView";
import { TConversationParams } from "../types";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import NiceModal from "@ebay/nice-modal-react";

const useNotificationsRedirect = () => {
  const { redirectToModal, redirectToPage } = useRedirect();
  const {
    currentPortal,
    isAcquirerPortal,
    isEnterprisePortal,
    isMerchantPortal,
  } = checkPortals();
  const { openConversationHandler } = useConversationsModal();

  const buildUrl = (name: RedirectURLKeys) =>
    `${currentPortal}/${
      redirectPageUrls[name][currentPortal as CurrentPortalKey]
    }`;

  const openMerchantModal = (
    merchantId: number,
    isEnterprise: boolean,
    initialSection?: TMerchantInitialSection | VoidFunction,
  ) => {
    const url = buildUrl(isEnterprise ? "providers" : "merchants");
    redirectToPage(url);
    if (!merchantId) return;

    const modal = isEnterprise
      ? ENTERPRISE_PREVIEW_PANEL
      : MERCHANT_PREVIEW_PANEL_MODAL;
    redirectToModal(modal, {
      id: merchantId,
      initialSection,
    });
  };

  const notificationsRedirects = {
    /**
     * Redirect to the settings page of the current portal
     * @memberof notificationsRedirects
     * @method settings
     */
    settings: (sectionElId?: string) => {
      const url = buildUrl("settings");
      redirectToPage(url, { sectionElId });
    },
    /**
     * Redirects to onboarding - verify identity modal/page based on the current portal
     * @memberof notificationsRedirects
     * @method verify_identity
     */
    verify_identity: () => {
      const url = buildUrl("verify_identity");
      redirectToPage(url, { subpath: "verify_identity" });
    },
    /**
     * Redirects to the merchant page and opens the merchant preview panel
     * @memberof notificationsRedirects
     * @method merchant_panel
     */
    merchant_panel: (merchantId: number) => {
      openMerchantModal(merchantId, false);
    },
    /**
     * Redirects to the provider page and opens the provider preview panel
     * @memberof notificationsRedirects
     * @method enterprise_panel
     */
    enterprise_panel: (merchantId: number) => {
      openMerchantModal(merchantId, true);
    },
    /**
     * Redirects to the processing page and opens the bank accounts modal
     * @memberof notificationsRedirects
     * @method bank_accounts
     */
    bank_accounts: () => {
      const url = buildUrl("processing");
      redirectToPage(url);
      if (isAcquirerPortal) return;
      redirectToModal(BANK_ACCOUNTS_MODAL);
    },
    /**
     * Redirects to the processing page
     * @memberof notificationsRedirects
     * @method processing
     */
    processing: () => {
      const url = buildUrl("processing");
      redirectToPage(url);
    },
    /**
     * Redirects to the processing page, fetches the transaction and opens the side panel
     * @memberof notificationsRedirects
     * @method transaction_panel
     */
    transaction_panel: async (transactionId: string) => {
      const url = buildUrl("processing");
      redirectToPage(url);

      if (!transactionId) return;
      const data = await getTransaction(transactionId);
      redirectToModal(TRANSACTION_INFO_MODAL, {
        data,
        DMenu: transactionDMenus[currentPortal],
      });
    },
    /**
     * Redirects to the team member page
     * @memberof notificationsRedirects
     * @method team_members
     */
    team_members: () => {
      const url = buildUrl("manage_team");
      redirectToPage(url);
    },
    /**
     * Redirects to the disputes page
     * @memberof notificationsRedirects
     * @method disputes
     */
    disputes: async (id: string) => {
      const url = buildUrl("disputes");
      redirectToPage(url);

      if (!id) return;
      redirectToModal(DISPUTE_PREVIEW_PANEL, {
        id,
        isFirst: true,
        isLast: true
      });
    },
  };

  const challengesRedirect = (challengeSlug: string) => {
    if (challengeSlug.includes("bank_account")) {
      notificationsRedirects["bank_accounts"]();
    } else if (challengeSlug.includes("primary")) {
      notificationsRedirects["verify_identity"]();
    } else {
      notificationsRedirects["settings"]();
    }
  };

  const conversationRedirect = (conversationParams?: TConversationParams) => {
    const isEmpty =
      !conversationParams || Object.values(conversationParams).some((p) => !p);
    if (isEmpty) return;

    const {
      targetMerchantId,
      targetMerchantType,
      threadId,
      topicId,
      messageId,
    } = conversationParams;
    openMerchantModal(
      targetMerchantId,
      targetMerchantType === "enterprise",
      () =>
        openConversationHandler({
          id: topicId,
          name: "",
          forceTopicOpen: true,
          openThread: {
            isRepliesOpen: false,
            threadId: threadId,
            commentId: messageId,
            index: 0,
          },
          paths: [],
        }),
    );
  };

  const merchantPanelRedirect = ({
    title,
    description,
    targetMerchantId,
    alertName,
  }: {
    title: string;
    alertName: string;
    description: string;
    targetMerchantId: number;
  }) => {
    const merchantId = targetMerchantId
      ? targetMerchantId
      : getIdFromTString(description);
    if (!merchantId) return;

    let isEnterprise = false;
    if (alertName === "underwriter_assignement") {
      isEnterprise = title.toLowerCase().includes("provider");
    } else if (["new_merchant", "new_enterprise"].includes(alertName)) {
      isEnterprise = alertName === "new_enterprise";
    } else {
      isEnterprise = alertName === "enterprises_ready_for_approval";
    }
    const panel: Extract<
      keyof typeof notificationsRedirects,
      "merchant_panel" | "enterprise_panel"
    > = isEnterprise ? "enterprise_panel" : "merchant_panel";

    notificationsRedirects[panel](merchantId);
  };

  const transactionPanelRedirect = (transactionID: string) => {
    if (!transactionID) return;
    notificationsRedirects["transaction_panel"](transactionID);
  };

  const riskMonitorRedirect = ({ description }: Record<string, string>) => {
    const merchantId = getIdFromTString(description);
    if (!merchantId) return;
    openMerchantModal(merchantId, false, "riskProfile");
  };

  const ofacRedirect = (merchantId: number) => {
    if (!merchantId) return;
    openMerchantModal(merchantId, false, "ofac");
  };

  const merchantBankAccountsRedirect = ({
    description,
  }: Record<string, string>) => {
    const merchantId = getIdFromTString(description);

    if (!merchantId && (isEnterprisePortal || isMerchantPortal)) {
      notificationsRedirects["bank_accounts"]();
      return;
    } else if (!merchantId) {
      return;
    }
    const isEnterprise = description.includes("provider");

    openMerchantModal(merchantId, isEnterprise, () =>
      scrollElIntoView("bank_account"),
    );
  };

  const ownerApprovalRedirect = ({
    description,
    targetMerchantId,
  }: {
    description: string;
    targetMerchantId: number;
  }) => {
    if (!targetMerchantId) return;
    const isEnterprise = description.includes("provider");

    openMerchantModal(targetMerchantId, isEnterprise, () =>
      scrollElIntoView("primary_account_holder"),
    );
  };

  const midIssueRedirect = ({
    alertName,
    targetMerchantId,
  }: {
    alertName: string;
    targetMerchantId: number;
  }) => {
    if (!targetMerchantId) return;
    const isEnterprise = alertName.includes("enterprise");
    openMerchantModal(targetMerchantId, isEnterprise);
  };

  const openNotificationSidePanel = ({ id }: { id?: number }) => {
    if (!id) return;
    NiceModal.show(NOTIFICATION_SIDE_PANEL, {
      notificationMessageID: id,
    });
  };

  return {
    notificationsRedirects,
    challengesRedirect,
    merchantPanelRedirect,
    transactionPanelRedirect,
    riskMonitorRedirect,
    ofacRedirect,
    merchantBankAccountsRedirect,
    ownerApprovalRedirect,
    midIssueRedirect,
    conversationRedirect,
    openNotificationSidePanel,
  };
};

export default useNotificationsRedirect;

const transactionDMenus: Record<string, any> = {
  acquirer: AcquirerDMenu,
  enterprise: EnterpriseDMenu,
};
