import {
  Box,
  Table as MuiTable,
  TableRow as MuiTableRow,
  Stack,
  TableCell,
  TableHead,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import { makeStyles } from "@mui/styles";
import { palette } from "@palette";
import React, { useCallback, useEffect } from "react";
// components
import { Text } from "@common/Text";
import Pagination from "../pagination";
// helpers
import { TableProps } from "./helpers";
// redux
import ErrorCatcher from "@common/Error/ErrorCatcher";
import ExportFilterButtons from "@common/ExportView/components/ExportFilterButtons";
import { SearchBar } from "@common/SearchBar_V2";
import { useModal } from "@ebay/nice-modal-react";
import { ROWS_PER_PAGE } from "@hooks/common/usePagination";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { TRANSACTION_INFO_MODAL } from "modals/modal_names";
import { useSelector } from "react-redux";
import { SortComponent } from "./components/SortComponent";
import TableBody from "./components/TableBody";
import { useIterator } from "./hooks";
import { useTableSort } from "./hooks/useTableSort";
import MarkAllCheckBox from "./MarkAllCheckBox";

export const currencyColumns = new Set(["Amount", "Total Processed"]);

const Table: React.FC<TableProps> = ({
  columns,
  expandable,
  small = false,
  data,
  maxHeight,
  rowHeight,
  renderExpandedRow,
  iconButton,
  selectable,
  disabled,
  isLoading,
  setSorting,
  tableTitle,
  searchBar,
  queryKey,
  withBackground = true,
  filters,
  tabs,
  initialSortingProperty = "id",
  downloadButton,
  rowRenderKey,
  onOpenSidePanel,
  page,
  numberOfPages,
  BodyRowStyle,
  openedModal = TRANSACTION_INFO_MODAL,
  itemsPerPage,
  isFetching = false,
  handleRowClick,
  emptyState,
  lastItemRef,
  hideTableView = false, //all hideTableView checks can be removed once we implement table views accross all platform
  showExportFilterBtns = true,
  leftSideSearchBar,
  totalRecords,
  defaultSort,
  hideLeftSideFilter = false,
  ...props
}) => {
  const classes = useStyles();
  const { isDesktopView } = useCustomTheme();
  const { handleOpenMenu, orderBy, sortKey, order } = useTableSort({
    small,
    setSorting,
    initialSortingProperty,
    defaultSort,
  });
  const boxStyle = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginBottom: isDesktopView ? 0 : "40px",
    gap: "16px",
    flexGrow: 1,
    borderImageSource:
      "linear-gradient(267.62deg, rgba(214, 217, 243, 0.46) 8.2%, rgba(255, 255, 255, 0.46) 82.68%)",
    "& th": {
      borderBottom: "none",
      padding: small ? "8px 16px" : "10px 16px",
    },
    boxShadow: "none",
    ...(!small && {
      padding: withBackground ? "56px 0" : "0",
      borderRadius: withBackground ? "50px" : "54px",
    }),
  };

  const containerStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    background: "transparent",
    overflow: "visible",
    ...(small && {
      mb: 2.5,
      padding: "0 4px 4px",
    }),
    ...(maxHeight && {
      maxHeight: maxHeight,
    }),

    ...(disabled && {
      position: "relative",

      "&::after": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "#f8f8f8",
        zIndex: 1000,
        opacity: 0.5,
      },
    }),
  };
  const modal = useModal(openedModal);

  const hideTableFilter = useSelector<any, boolean>(
    (state: any) => state.app.hideTableFilter,
  );

  const { onIterator, selectedRowIdx, setSelectedRowIdx } = useIterator({
    dataLen: data.length,
  });

  useEffect(() => {
    if (isLoading && modal.visible) {
      onOpenSidePanel({});
      return;
    }

    if (onOpenSidePanel && data[selectedRowIdx]) {
      onOpenSidePanel({
        rowData: data[selectedRowIdx],
        id: data[selectedRowIdx]?.accID,
        setSelectedRow: onIterator,
        isFirst: data[selectedRowIdx - 1] === undefined && page === 1,
        isLast:
          data[selectedRowIdx + 1] === undefined && page === numberOfPages,
      });
    }
  }, [selectedRowIdx, isLoading]);

  useEffect(() => {
    if (!modal.visible) {
      setSelectedRowIdx(-1);
    }
  }, [modal.visible]);

  const onClickRow = useCallback(
    (itemRow: any, idx: number) => {
      if (onOpenSidePanel) {
        onOpenSidePanel({
          rowData: itemRow,
          setSelectedRow: onIterator,
          isFirst: idx === 0 && page === 1,
          isLast: data[idx + 1] === undefined && page === numberOfPages,
        });
        setSelectedRowIdx(idx);
      }
    },
    [page, data, numberOfPages],
  );

  return (
    <Box className="table-container" sx={boxStyle}>
      {tabs && (
        <Box mb="12px" sx={{ width: "100%" }}>
          {tabs}
        </Box>
      )}
      {(tableTitle || searchBar || queryKey || downloadButton) && (
        <Box
          pl={tabs ? "12px" : "0px"}
          flexDirection={{
            xs: "column-reverse",
            sm: "row",
          }}
          display="flex"
          alignItems={{ xs: "flex-start", sm: "center" }}
          gap={{ xs: "16px", sm: 0 }}
          justifyContent="space-between"
        >
          {filters && (
            <Box
              sx={{
                width: hideTableView ? "100%" : undefined,
              }}
            >
              {filters}
            </Box>
          )}
          {!hideLeftSideFilter && (
            <Stack
              direction="row"
              alignItems="center"
              gap="8px"
              justifyContent={
                leftSideSearchBar && isDesktopView
                  ? "space-between"
                  : hideTableView || hideTableFilter
                  ? "flex-end"
                  : "space-between"
              }
              sx={{
                marginLeft:
                  hideTableView || hideTableFilter
                    ? { xs: 0, sm: "auto" }
                    : "undefined",

                width:
                  leftSideSearchBar && isDesktopView
                    ? "100%"
                    : hideTableView || hideTableFilter
                    ? { xs: "100%", sm: "undefined" }
                    : "auto",
              }}
            >
              {searchBar
                ? searchBar
                : queryKey && <SearchBar queryKey={queryKey} />}
              <SearchContainer
                containChildren={Boolean(leftSideSearchBar && isDesktopView)}
              >
                {tableTitle && // remove this check when implement export view for all tables
                  ["Merchants", "Merchants list"].includes(tableTitle) &&
                  showExportFilterBtns && (
                    <ExportFilterButtons
                      hideTableView={hideTableView || hideTableFilter}
                      totalRows={
                        props.totalExportRecords
                          ? props.totalExportRecords
                          : totalRecords || 0
                      }
                      totalFilteredRows={totalRecords}
                    />
                  )}
                {downloadButton}
              </SearchContainer>
            </Stack>
          )}
        </Box>
      )}
      <TableContainer sx={containerStyle}>
        <MuiTable
          sx={{
            ...props.sx,
            borderCollapse: "separate",
            borderRadius: "8px",
            tableLayout: "fixed",
          }}
          {...props}
        >
          {!small && (
            <colgroup>
              {columns.map((column, index) => {
                if (column?.hideColumn) return null;
                const length = columns.length + 1;
                let columnWidth = 1 / length;
                if (index === 0) columnWidth = 2.2 / length;
                if (column.key === "actions") columnWidth = 0.8 / length;

                return (
                  <col
                    key={column.key}
                    style={{
                      width:
                        typeof column.columnWidth !== "number"
                          ? column.columnWidth
                          : `${
                              ((column.columnWidth ?? 0) / length ||
                                columnWidth) * 100
                            }%`,
                    }}
                  />
                );
              })}
            </colgroup>
          )}
          <TableHead
            sx={{
              "& th": {
                padding: "8px 8px",
              },
            }}
          >
            <MuiTableRow>
              {columns.map((column, index) => {
                if (column?.hideColumn) return null;
                const isCurrencyColumn = currencyColumns.has(column.title);
                const title = isCurrencyColumn
                  ? `${column.title} (USD)`
                  : column.title;

                if (column.title) {
                  return (
                    <TableCell
                      component="th"
                      key={`head-${column.key}`}
                      className={`tableHeader-${column.key}`}
                      sortDirection={orderBy === column.key ? order : false}
                      sx={column.sx}
                    >
                      <Box
                        onClick={
                          column.sortable ? handleOpenMenu(column) : () => null
                        }
                        className={`${classes.tableHead} ${
                          column.sortable ? classes.tableHeadSortable : ""
                        }`}
                        sx={{
                          justifyContent:
                            column?.headerPosition === "right"
                              ? "flex-end"
                              : column?.headerPosition === "center"
                              ? "center"
                              : "flex-start",
                        }}
                      >
                        {index === 0 && selectable && <MarkAllCheckBox />}
                        <Text noWrap color={palette.neutral.black}>
                          {title}
                        </Text>
                        {column.sortable && (
                          <>
                            <SortComponent
                              sortedCol={sortKey === column.key}
                              order={order}
                              sortKey={column.key}
                            />
                          </>
                        )}
                      </Box>
                    </TableCell>
                  );
                }
                return <TableCell component="th" key={`head-${column.key}`} />;
              })}
            </MuiTableRow>
          </TableHead>

          <TableBody
            data={data}
            page={page}
            columns={columns}
            isLoading={isLoading}
            isFetching={isFetching}
            queryKey={queryKey}
            renderExpandedRow={renderExpandedRow}
            rowHeight={rowHeight}
            expandable={expandable}
            small={small}
            iconButton={iconButton}
            rowRenderKey={rowRenderKey}
            selectable={selectable}
            BodyRowStyle={BodyRowStyle}
            selectedRowIdx={selectedRowIdx}
            itemsPerPage={itemsPerPage}
            onClickRow={handleRowClick || onClickRow}
            emptyState={emptyState}
          />
          {data?.length && !isDesktopView && lastItemRef ? (
            <div
              ref={lastItemRef}
              style={{
                width: 1,
                height: "100%",
                paddingBottom: 5,
                visibility: "hidden",
              }}
            ></div>
          ) : null}
        </MuiTable>
      </TableContainer>
    </Box>
  );
};

export const Table_V2 = ({
  data,
  handlePageChange,
  withPagination,
  hasPaginationWhenEmpty = true,
  page = 1,
  totalRecords,
  noTrailing,
  currentRowsPerPage = ROWS_PER_PAGE,
  downloadButton,
  alwaysShowDownloadBtn,
  emptyBox,
  defaultSort,
  ...rest
}: TableProps) => {
  const numberOfPages = Math.ceil(
    Number(totalRecords ?? 0) / currentRowsPerPage,
  );

  const sumPageAmount =
    data?.reduce((acc, v) => acc + (v.totalAmount ?? v.charged), 0) ?? 0;
  const downloadBtn = alwaysShowDownloadBtn
    ? downloadButton
    : data.length > 0 && downloadButton;
  return (
    <>
      <ErrorCatcher errorID="Table">
        <Table
          {...rest}
          defaultSort={defaultSort}
          page={page}
          data={data}
          totalRecords={totalRecords || data?.length}
          numberOfPages={numberOfPages}
          downloadButton={downloadBtn}
          itemsPerPage={currentRowsPerPage}
        />
        {withPagination && hasPaginationWhenEmpty && (
          <Box paddingY="25px" paddingX={0} width="85%" margin="0 auto">
            <Pagination
              page={page}
              onChange={handlePageChange}
              totalRecords={totalRecords || data?.length}
              currentRowsPerPage={data.length}
              noTrailing={noTrailing}
              numberOfPages={numberOfPages}
              sumPageAmount={sumPageAmount}
              emptyBox={emptyBox}
            />
          </Box>
        )}
      </ErrorCatcher>
    </>
  );
};

const useStyles = makeStyles({
  tableBody: {
    position: "relative",
    zIndex: "1",
  },
  tableHead: {
    display: "inline-flex",
    alignItems: "center",
    gap: "8px",
    verticalAlign: "middle",
    userSelect: "none",
    padding: "4px 8px",
    width: "100%",
  },
  tableHeadSortable: {
    "&:hover": {
      cursor: "pointer",
      borderRadius: "32px",
    },
  },
  pinkTableBody: {
    position: "relative",
    borderRadius: "8px",
    zIndex: "1",
    borderSize: "3px !important",
  },
  pinkRowBorder: {
    position: "relative",
    borderRadius: "8px",
    zIndex: "1",
    border: "2px solid rgba(234, 221, 253, 1)",
  },
});

const SearchContainer = ({
  children,
  containChildren,
}: {
  children: React.ReactNode;
  containChildren: boolean;
}) =>
  containChildren ? (
    <Stack
      direction="row"
      alignItems="center"
      gap="8px"
      justifyContent="flex-end"
    >
      {children}
    </Stack>
  ) : (
    <>{children}</>
  );
