import {
  BankIcon,
  BusinessOwnershipIcon,
  MedalIcon,
  ScrollIcon,
} from "@assets/icons/RebrandedIcons";
import {
  CREATE_BUSINESS_PROFILE_MODAL,
  CREATE_MERCHANT_AGREEMENT_MODAL,
  CREATE_PRIMARY_ACCOUNT_HOLDER_MODAL,
  EDIT_BANK_ACCOUNT_MODAL,
  EDIT_BUSINESS_OWNER_MODAL,
} from "modals/modal_names";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  TCreateBusinessProfile,
  TCreatePrimaryAccountHolder,
} from "../../types";
import {
  IMerchantBankAccount,
  TBusinessOwner,
} from "@components/Merchants/MerchantPreview/data.types";
import SectionPlaceholders from "./SectionPlaceholders";
import { CertificateIcon } from "@assets/icons";
import {
  TMerchantAgreementPayload,
  merchantAgreementDefaultValues,
} from "../../hooks/useAddMerchantAgreement";
import { isEmpty } from "lodash";
import { EnterpriseConfiguration } from "@components/AcquirerEnterprises/CreateEnterprise/types";

const AddSectionPlaceholders = ({
  enterpriseConfiguration,
}: {
  enterpriseConfiguration: EnterpriseConfiguration;
}) => {
  const { setValue, watch } = useFormContext();
  const values = watch();

  const handleClosePrimaryAccountHolder = (
    data: TCreatePrimaryAccountHolder,
  ) => {
    setValue("primaryAccountHolder", data);
  };

  const handleCloseBusinessProfile = useCallback(
    (data: {
      businessProfile: TCreateBusinessProfile;
      businessOwners?: TBusinessOwner[];
    }) => {
      setValue("businessProfile", data.businessProfile);
      setValue("businessOwners", data.businessOwners || []);
    },
    [values.businessProfile, values.businessOwners],
  );

  const handleCloseBusinessOwner = (data: TBusinessOwner) => {
    setValue("businessOwners", [data]);
  };

  const handleCloseBankAccount = (data: IMerchantBankAccount) => {
    setValue("bankAccounts", [data]);

    if (data?.isLinked && data?.id) {
      setValue("linkedBankAccountID", data?.id);
    }
  };

  const handleCloseMerchantAgreement = (data: TMerchantAgreementPayload) => {
    setValue("merchantAgreement", data);
  };

  const placeholdersList = useMemo(
    () => [
      {
        icon: <MedalIcon />,
        title: "Primary Account Holder",
        description:
          "The Primary Account Holder is the person who has managerial control of the merchant.",
        hidden:
          values?.primaryAccountHolder?.email ||
          values?.primaryAccountHolder?.assignToMe ||
          !enterpriseConfiguration.modify_merchant,
        modal: CREATE_PRIMARY_ACCOUNT_HOLDER_MODAL,
        onClose: handleClosePrimaryAccountHolder,
        data: values.primaryAccountHolder,
      },
      {
        icon: <ScrollIcon />,
        title: "Business Profile",
        description:
          "The business profile is the legal entity the merchant is operating under.",
        hidden:
          values.businessProfile.taxID ||
          values.businessProfile.ssn ||
          (!enterpriseConfiguration.modify_merchant &&
            !enterpriseConfiguration.b_profile_linking),
        modal: CREATE_BUSINESS_PROFILE_MODAL,
        onClose: handleCloseBusinessProfile,
        data: values.businessProfile,
        merchantName: values.merchantInfo.merchantName,
      },
      {
        icon: <BusinessOwnershipIcon />,
        title: "Business Ownership",
        description: `Add any individual who owns 10% or more of this business.`,
        hidden:
          values.businessOwners?.length !== 0 ||
          (!values.businessProfile.taxID && !values.businessProfile.ssn) ||
          !enterpriseConfiguration.modify_merchant,
        isIndividualSoleProprietorship:
          values.businessProfile.businessType ===
          "individual_sole_proprietorship",
        modal: EDIT_BUSINESS_OWNER_MODAL,
        onClose: handleCloseBusinessOwner,
        businessType: values?.businessProfile?.businessType,
      },
      {
        icon: <BankIcon />,
        title: "Bank Accounts",
        description: `The DDA account of the Merchant.`,
        hidden:
          values.bankAccounts?.length !== 0 ||
          !enterpriseConfiguration.modify_merchant,
        modal: EDIT_BANK_ACCOUNT_MODAL,
        isDefault: true,
        onClose: handleCloseBankAccount,
        parentAccID: values.merchantInfo.enterpriseID,
      },
      {
        icon: <CertificateIcon />,
        title: "Merchant Agreement",
        description:
          "The merchant agreement outlines the terms and conditions governing the contractual relationship between the merchant and the payment processor.",
        hidden:
          Object.keys(values.merchantAgreement).length > 0 ||
          !enterpriseConfiguration.agreement_signing,

        modal: CREATE_MERCHANT_AGREEMENT_MODAL,
        onClose: handleCloseMerchantAgreement,
        data: isEmpty(values.merchantAgreement)
          ? merchantAgreementDefaultValues
          : values.merchantAgreement,
      },
    ],
    [values],
  );

  useEffect(() => {
    if (!values.businessProfile?.linkedBusinessProfile) {
      setValue(
        "businessOwners",
        (
          values.businessOwners as TBusinessOwner & { linked?: boolean }[]
        ).filter((businessOwner) => !businessOwner.linked),
      );
    }
  }, [values.businessProfile?.linkedBusinessProfile]);

  return <SectionPlaceholders placeholdersList={placeholdersList} />;
};

export default AddSectionPlaceholders;
