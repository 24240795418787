import { TAccessControlOperations } from "@redux/slices/accessControl/types";

const RESOURCE_BASE = {
  USER: "user",
  ACQUIRER: "acquirer",
  ENTERPRISE: "enterprise",
  MERCHANT: "submerchant",
  ADDRESS: "address",
  PAYMENT_METHOD: "payment-method",
  RECURRING_ITEM: "recurring-item",
  ACCESS_CONTROL: "masquerade",
  INVITE: "invite",
  OWNER: "owner",
  MEMBER: "member",
  FILES: "file",
  ACCESS_POLICIES: "access-policy",
  GROUP: "group",
  BACKGROUND_TASK: "background-task",
  BANK_ACCOUNT: "bank/account",
  PLAID: "plaid",
  LEGAL_ENTITY: "legal/entity",
  PRINCIPAL: "principal",
  CUSTOMER: "customer",
  CATEGORY_CODE: "category-code",
  PRODUCT: "product",
  MEDIA_ITEM: "media-item",
  TRANSACTION: "transaction",
  BANK_TRANSFER: "bank/transfer",
  AMOUNT: "amount",
  UNDERWRITING_CHECKLIST: "underwriting/checklist",
  SNAPSHOT: "snapshot",
  OFAC: "ofac",
  UNDERWRITING: "underwriting",
  UNDERWRITING_COMMENTS: "underwriting/comments",
  TRANSACTION_STATS: "stat",
  RECEIPT: "transaction/receipt",
  REFUND: "transaction/refund",
  CHARGEBACK: "transaction/chargeback",
  CHARGEBACK_REVERSAL: "transaction/chargeback-reversal",
  IPPROFILE: "ipprofiles",
  IPPROFILE_TRANSACTION: "ipprofiles/transaction",
  IPPROFILE_ESCALATION: "ipprofiles/escalation",
  TRANSACTION_BLOCK: "transaction",
  TRANSACTION_UNBLOCK: "transaction",
  MSP: "msp",
  PEP: "pep",
  CHANGELOG: "changelog",
  RISK_PROFILE_ESCALATION: "risk/profile:escalation",
  RISK_PROFILE_FACTOR: "risk/profile:factor",
  RISK_PROFILE: "risk/profile",
  CUSTOM_DOMAIN: "custom-domain",
  CONFIGURATION: "configuration",
  CHALLENGE: "underwriting/challenge",
  CONVERSATION_THREAD: "conversation/thread",
  CONVERSATION_MESSAGE: "conversation/message",
  MATCH_REPORT: "underwriting/match-report",
  SPONSOR_STATUS: "sponsor-status",
  DEVELOPER: "developer",
};

export default RESOURCE_BASE;

export const OPERATIONS: Record<string, TAccessControlOperations> = {
  READ: "read",
  LIST: "list",
  CREATE: "create",
  UPDATE: "update",
  MANAGE: "manage",
  DELETE: "delete",
  EXPORT: "export",
  SUBMIT: "submit",
  CANCEL: "cancel",
  LINK: "link",
  UNLINK: "unlink",
  BLOCK: "block",
};

export const CREATE_DENY_MESSAGE =
  "You do not have the rights to create this resource";
export const EDIT_DENY_MESSAGE =
  "You do not have the rights to edit this resource";
export const DELETE_DENY_MESSAGE =
  "You do not have the rights to delete this resource";
export const FEATURE_DENY_MESSAGE =
  "You do not have the rights to access this feature";
export const INVITE_DENY_MESSAGE =
  "You do not have the rights to send invitation";
export const ACTION_DENY_MESSAGE =
  "You do not have the right permission to perform this action";
export const UPLOAD_DENY_MESSAGE =
  "You do not have the rights to upload content";
