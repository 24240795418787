import NiceModal from "@ebay/nice-modal-react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  resetMerchantSectionFocus,
  selectMerchantPreview,
  setMerchantSectionFocus,
} from "@redux/slices/merchantPreview";
import {
  CREATE_BUSINESS_PROFILE_MODAL,
  EDIT_BANK_ACCOUNT_MODAL,
  EDIT_BUSINESS_OWNER_MODAL,
  EDIT_ENTERPRISE_INFO_MODAL,
  EDIT_MERCHANT_INFO_MODAL,
  EDIT_PRIMARY_ACCOUNT_HOLDER,
  MANAGE_BUSINESS_OWNERS_MODAL,
  MATCH_REPORT_MODAL,
  OFAC_CHECK_MODAL,
} from "modals/modal_names";
import { useCallback, useEffect } from "react";
import { IParsedData } from "../data.types";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { ReportType } from "../MATCH/types";

export type SECTIONS =
  | "general"
  | "enterprise_info"
  | "bank_account"
  | "merchant_info"
  | "documents"
  | "merchant-agreement"
  | "merchant-fees"
  | "business_owner"
  | "business_owners"
  | "business_profile"
  | "identity-verification"
  | "primary_account_holder"
  | "edit-merchant-modal"
  | "edit-primary-account-holder-modal"
  | "edit-business-owner-modal"
  | "ofac"
  | "merchant_due_diligence";

export type SUB_SECTIONS =
  | "business_profile_ofac_check"
  | "bank_account_connected_with_plaid"
  | "primary_account_holder_id_verification"
  | "underwriting_match_check";

export type FocuseOnSectionParams = {
  section: SECTIONS;
  isEnterprise: boolean;
  field?: string;
  scrollOptions?: boolean | ScrollIntoViewOptions;
  subSection?: SUB_SECTIONS;
  MATCHData?: ReportType[];
};

export const scrollElIntoView = (
  section: string,
  scrollOptions: boolean | ScrollIntoViewOptions = { block: "center" },
) => {
  return document.getElementById(section)?.scrollIntoView(scrollOptions);
};

const OFACSections = ["ofac_check", "ofac_match", "ofac_match_confirmed"];
const oldOfacSections = [
  "business_profile_ofac_check",
  "business_profile_ofac_match",
  "business_profile_ofac_match_confirmed",
];

export const useSectionFocusView = (id: any, data: IParsedData) => {
  const dispatch = useAppDispatch();
  const { sectionFocus } = useAppSelector(selectMerchantPreview);
  const { isNewOfacTabsEnabled } = useGetFeatureFlagValues();
  const getFocusedElement = useCallback(() => {
    return document.getElementById(sectionFocus.section as string);
  }, [sectionFocus]);

  const handleOpenOFAC = (isEnterprise?: boolean) => {
    NiceModal.show(OFAC_CHECK_MODAL, {
      headerData: data.header,
      merchantId: id,
      profileData: data.businessProfile,
      PAH: data?.primaryAccountHolder,
      businessOwners: data?.businessOwnersList,
      isEnterprisePanel: isEnterprise,
    });
  };

  const handleOpenBusinessProfileSection = (
    subSection?: SUB_SECTIONS,
    isEnterprise?: boolean,
  ) => {
    const ofacSectionToUse = isNewOfacTabsEnabled
      ? OFACSections
      : oldOfacSections;
    if (subSection && ofacSectionToUse.includes(subSection)) {
      handleOpenOFAC(isEnterprise);
    } else {
      NiceModal.show(CREATE_BUSINESS_PROFILE_MODAL, {
        merchantId: id,
        data: { ...data.businessProfile, address: data.businessAddress },
        legalEntityID: data.businessProfile.id,
        isLegalEntityApproved:
          data.header.businessProfileStatus.status?.toLowerCase() ===
          "approved",
        isEdit: true,
      });
    }
  };

  const handleBankAccount = (
    subSection?: SUB_SECTIONS,
    isEnterprise?: boolean,
  ) => {
    const bankAccount =
      subSection === "bank_account_connected_with_plaid"
        ? data.bankAccountList.find((account) => account.plaid)
        : data.bankAccountList[0];
    NiceModal.show(EDIT_BANK_ACCOUNT_MODAL, {
      merchantId: data.merchantInfo.merchantID,
      ...((data.bankAccountList.length === 0 ||
        (subSection === "bank_account_connected_with_plaid" &&
          Boolean(bankAccount))) && {
        isDefault: true,
      }),
      isEnterprise: isEnterprise,

      ...(data.bankAccountList.length > 0 && {
        data: bankAccount,
        bankAccountId: bankAccount?.id,
      }),
    });
  };

  const focusOnSection = (params: FocuseOnSectionParams) => {
    const {
      section,
      isEnterprise,
      field,
      scrollOptions = { block: "center" },
      subSection,
      MATCHData,
    } = params;
    dispatch(
      setMerchantSectionFocus({
        section:
          section === "general"
            ? isEnterprise
              ? "enterprise_info"
              : "merchant_info"
            : section,
        ...(field && { field }),
      }),
    );

    if (subSection === "underwriting_match_check") {
      NiceModal.show(MATCH_REPORT_MODAL, {
        merchantID: data.merchantInfo.merchantID,
        report: MATCHData && MATCHData.length > 0 ? MATCHData[0] : undefined,
      });
      return;
    }

    switch (section) {
      case "business_profile":
        handleOpenBusinessProfileSection(subSection, isEnterprise);
        break;
      case "ofac":
        handleOpenOFAC(isEnterprise);
        break;
      case "edit-merchant-modal":
      case "enterprise_info":
      case "general":
      case "merchant_info":
      case "merchant_due_diligence":
        NiceModal.show(
          isEnterprise ? EDIT_ENTERPRISE_INFO_MODAL : EDIT_MERCHANT_INFO_MODAL,
          {
            id,
            data: data.merchantInfo,
          },
        );
        break;
      case "primary_account_holder":
        NiceModal.show(EDIT_PRIMARY_ACCOUNT_HOLDER, {
          id,
          data: data.primaryAccountHolder,
          ownerAccId: data.ownerId,
        });

        break;

      case "business_owners":
        if (
          data.businessOwnersList.length === 0 ||
          (!data.businessProfile.ssn && !data.businessProfile.taxID)
        ) {
          NiceModal.show(EDIT_BUSINESS_OWNER_MODAL, {
            legalEntityID: data.businessProfile.id,
            merchantId: data.merchantInfo.merchantID,
            businessType: data.businessProfile.businessType,
            isIndividualSoleProprietorship:
              data.businessProfile.businessType ===
              "individual_sole_proprietorship",
            stakeSum:
              data.businessOwnersList?.reduce((acc, owner) => {
                const stake = owner.ownership ? +owner.ownership : 0;

                return acc + stake;
              }, 0) || 0,
          });
        } else {
          NiceModal.show(MANAGE_BUSINESS_OWNERS_MODAL, {
            ownersList: data.businessOwnersList,
            profileData: data.businessProfile,
            isApproved:
              data?.header?.businessProfileStatus?.status?.toLowerCase() ===
              "approved",
            merchantId: data.merchantInfo.merchantID,
            isEnterprise: isEnterprise,
          });
        }
        break;

      case "bank_account":
        handleBankAccount(subSection, isEnterprise);
        break;
      default:
        scrollElIntoView(isEnterprise ? "enterprise_info" : "merchant_info");
        break;

      // document.getElementById(field as string)?.scrollIntoView(scrollOptions);
    }
  };

  useEffect(() => {
    const focusedElement = getFocusedElement();
    const handleOutSideClick = (event: MouseEvent) => {
      if (!focusedElement?.contains(event.target as Node)) {
        dispatch(resetMerchantSectionFocus());
      }
    };

    window.addEventListener("mousedown", handleOutSideClick);

    return () => {
      window.removeEventListener("mousedown", handleOutSideClick);
    };
  }, [getFocusedElement()]);

  return { focusOnSection };
};
