import { OwnershipTabsInput, SSNInput } from "@common/BusinessProfileInputs";
import { RHFCheckbox } from "@common/Checkbox";
import { RHFInput, RHFTelInput } from "@common/Input";
import { RHFSelect } from "@common/Select";
import { Text } from "@common/Text";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import useCountryCodes from "@hooks/common/useCountryCodes";
import { Box, Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { ProvinceInput, ZipCodeInput } from "@common/AddressInputs";
import { Button } from "@common/Button";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import BriefcaseBrandedIcon from "@assets/icons/RebrandedIcons/BriefcaseBrandedIcon";
import { BirthDatePicker } from "@common/DatePickers";
import { CustomToolTip } from "@common/BusinessOwners/CustomToolTip";
import { RHFStreetAddress } from "@common/RHFStreetAddress";
import { gridItemsRenderer } from "@utils/rendering/nodesRenderers";
import { NonResidentInputs } from "@common/NonResidentInput/NonResidentInputs";
import BusinessOwnerIDUploader from "@common/BusinessOwnerIDUploader/BusinessOwnerIDUploader";
import { useGetCurrentMerchantId } from "@hooks/common";
import { TMerchantDocument } from "@components/Merchants/MerchantPreview/data.types";

type Props = {
  isSoleProprietorship?: boolean;
  selectedOwnersPEP?: string;
  isLoading?: boolean;
  leType?: string;
  canEdit: boolean;
  idFile?: TMerchantDocument;
  tooltipProps: {
    show: boolean;
    message: string;
  };
};

const AddOwnerInputFields = ({
  isSoleProprietorship,
  selectedOwnersPEP,
  isLoading = false,
  leType,
  canEdit,
  idFile,
  tooltipProps,
}: Props) => {
  const {
    watch,
    formState: { isDirty, isValid },
  } = useFormContext();
  const { merchantId } = useGetCurrentMerchantId();
  const { selectCountriesList } = useCountryCodes();
  const values = watch();
  const { isMobileView } = useCustomTheme();
  const isConfirmed = selectedOwnersPEP === "confirmed_match";

  const disabled = isConfirmed || !canEdit;

  const roleDisable = isSoleProprietorship || (leType && ["tax_exempt_organization", "government_agency"].includes(leType));

  const formFields = [
    {
      node: (
        <CustomToolTip showToolTip={isConfirmed}>
          <RHFInput
            disabled={disabled}
            name="firstName"
            label="First Name"
            placeholder="eg john"
            fullWidth
          />
        </CustomToolTip>
      ),
      md: 6,
    },
    {
      md: 6,
      node: (
        <CustomToolTip showToolTip={isConfirmed}>
          <RHFInput
            disabled={disabled}
            name="lastName"
            label="Last Name"
            placeholder="eg Doe"
            fullWidth
          />
        </CustomToolTip>
      ),
    },
    {
      node: (
        <CustomToolTip showToolTip={isConfirmed}>
          <RHFInput disabled={disabled} name="email" label="Email" fullWidth />
        </CustomToolTip>
      ),
      md: 12,
    },
    {
      node: (
        <CustomToolTip showToolTip={isConfirmed}>
          <SSNInput
            disabled={disabled}
            name="ssn"
            label="SSN"
            placeholder="xxx xx xxxx"
          />
        </CustomToolTip>
      ),
      md: 6,
    },
    {
      md: 6,
      node: (
        <CustomToolTip showToolTip={isConfirmed}>
          <BirthDatePicker
            disabled={disabled}
            name="DOB"
            label="Date of Birth"
          />
        </CustomToolTip>
      ),
    },
    {
      md: 12,
      node: (
        <CustomToolTip showToolTip={isConfirmed}>
          <RHFTelInput
            name="contactPhone"
            label="Contact phone"
            fullWidth
            disabled={disabled}
          />
        </CustomToolTip>
      ),
    },
    {
      md: 12,
      node: (
        <BusinessOwnerIDUploader
          idFile={idFile}
          merchantId={merchantId}
          disabled={disabled}
        />
      ),
    },
    {
      md: 12,
      node: <NonResidentInputs isBusinessOwner inputsDisabled={disabled} />,
    },

    {
      md: 12,
      node: (
        <OwnershipTabsInput
          name="ownership"
          label="Custom percentage"
          placeholder="0-100"
          isFullyDisabled={roleDisable || disabled}
          leType={leType}
          labelProps={{
            textAlign: "left",
            fontWeight: 350,
          }}
        />
      ),
    },
    {
      md: 12,
      hideTooltip: true,
      node: (
        <Text fontSize="18px" color="#8F8F8F" textAlign="left">
          Owner address
        </Text>
      ),
    },
    {
      md: 12,
      node: (
        <CustomToolTip showToolTip={isConfirmed}>
          <Box display="flex" alignItems="flex-end" width="100%">
            <RHFCheckbox
              disabled={disabled}
              name="useBusinessAddress"
              label="Use Business Address"
            />
          </Box>
        </CustomToolTip>
      ),
    },
    {
      md: 12,
      node: (
        <RHFSelect
          name="country"
          label="Country"
          options={selectCountriesList}
          disabled
        />
      ),
      hidden: values?.useBusinessAddress,
    },
    {
      md: 12,
      node: (
        <CustomToolTip showToolTip={isConfirmed}>
          <RHFStreetAddress
            name="street"
            label="Street Address"
            placeholder="Enter Street Address..."
            disabled={disabled}
            fullWidth
          />
        </CustomToolTip>
      ),
      hidden: values?.useBusinessAddress,
    },
    {
      md: 4,
      node: (
        <CustomToolTip showToolTip={isConfirmed}>
          <RHFInput
            name="city"
            label="City"
            placeholder="Enter city..."
            disabled={disabled}
            fullWidth
          />
        </CustomToolTip>
      ),
      hidden: values?.useBusinessAddress,
    },
    {
      md: 4,
      node: (
        <CustomToolTip showToolTip={isConfirmed}>
          <ProvinceInput isUS name="state" disabled={disabled} />
        </CustomToolTip>
      ),
      hidden: values?.useBusinessAddress,
    },
    {
      md: 4,
      node: (
        <CustomToolTip showToolTip={isConfirmed}>
          <ZipCodeInput
            name="zip"
            label="Zip Code"
            placeholder="Enter Zip Code"
            letterSpacing="4px"
            disabled={disabled}
          />
        </CustomToolTip>
      ),
      hidden: values?.useBusinessAddress,
    },
  ];

  return (
    <>
      {isMobileView && (
        <Box>
          <Box mb="13px" gap="8px" alignItems="center" display="flex">
            <BriefcaseBrandedIcon />
            <Text fontWeight="regular" fontSize="18px" color="#575353">
              Add Business Owner
            </Text>
          </Box>
          <Text fontWeight="book" fontSize="14px" color="#8F8F8F">
            Business Owners may also be a Managing Director in the case of a
            Nonprofit or Government Agency.
          </Text>
        </Box>
      )}
      <Grid
        container
        columnSpacing={isMobileView ? 0 : "12px"}
        rowSpacing="12px"
        maxWidth="100%"
        p={isMobileView ? 0 : "24px"}
        borderRadius="16px"
        border={isMobileView ? "none" : "2px dashed #B8B8B8"}
        marginLeft={0}
      >
        {gridItemsRenderer(formFields, tooltipProps)}
        <AddButton
          disabled={isLoading || !isDirty || !isValid || !canEdit}
          isEdit={!!values.id}
        />
      </Grid>
    </>
  );
};

export default AddOwnerInputFields;

const AddButton = ({
  disabled,
  isEdit,
}: {
  disabled: boolean;
  isEdit: boolean;
}) => {
  const { isMobileView } = useCustomTheme();
  const label = isEdit
    ? "Save"
    : isMobileView
    ? "Add"
    : "Add new business owner";

  return (
    <FadeUpWrapper delay={200}>
      <Button
        disabled={disabled}
        type="submit"
        form="business-owner-form"
        size="medium"
        background={isMobileView ? "primary" : "secondary"}
        sx={{ minWidth: "80px", margin: "auto", marginTop: "24px" }}
      >
        {label}
      </Button>
    </FadeUpWrapper>
  );
};
