import { getJsonValue } from "@theme/v2/utils";
import { ThemeMode } from "@theme/v2/types";

export const getCustomDrawerStyle = (mode:ThemeMode)=>{
  return {
    defaultProps: {
      PaperProps: {
        sx: {
          width: "116px",
          padding: "32px",
          backgroundColor: getJsonValue(
            `tokens.${mode}.colour.surface.primary`,
          ),
          transition: "width 195ms ease-in",
          borderRight: `1px solid ${getJsonValue(
            `tokens.${mode}.colour.border.primary`,
          )}`,
          "@media (max-width:768px)": {
            width: "100%",
          },
        },
      },
    },
    styleOverrides: {
      root: {
        variants: [
          {
            props: (props: any) => {
              return props.open && props.variant === "permanent";
            },
            style: ({ theme }: any) => {
              return {
                [theme.breakpoints.between("new_sm", "new_md")]: {
                  ".MuiPaper-root": {
                    width: 320,
                  },
                },
                [theme.breakpoints.up("new_md")]: {
                  ".MuiPaper-root": {
                    width: 264,
                  },
                },
              };
            },
          },
        ],
      },
    },
  }
}