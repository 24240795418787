import { FormSectionName } from "../hooks/useBusinessDetailsData";
import { toUnixDateFormat, convertPhoneNumber } from "@utils/date.helpers";
import { parseAmountValue } from "@components/Merchants/CreateMerchant/utils/functions";
import { TOptions as TOptionsBusinessDetails } from "../hooks/useBusinessDetailsData";

export const generatePayload = (
  section: FormSectionName,
  values: any,
  options?: TOptionsBusinessDetails,
) => {
  if (section === "businessOwners") return values;

  const { dirtyFields, allowEmpty } = options || {};

  return attributes[section].reduce((acc, v) => {
    const [name] = v;
    if (dirtyFields && !dirtyFields[name]) return acc;
    return {
      ...acc,
      ...addAttribute([values, ...v], allowEmpty),
    };
  }, {});
};

const attributes: Record<
  FormSectionName,
  [name: string, options?: TOptions][]
> = {
  businessDetails: [
    ["businessType", { apiKey: "type" }],
    ["legalName", { apiKey: "name" }],
    ["DBA", { apiKey: "doingBusinessAs" }],
    ["ssn"],
    ["taxIDNumber"],
    ["phoneNumber", { parser: (v: string) => v?.slice(1) }],
    [
      "businessOpenedAt",
      { parser: (v: string) => toUnixDateFormat(new Date(v)) },
    ],
    ["ownershipType"],
  ],
  businessAddress: [
    ["address", { apiKey: "line1" }],
    ["city"],
    ["state"],
    ["zip"],
    ["country"],
  ],
  businessOwners: [],
  merchantInfo: [
    ["websiteURL", { parser: (v: string) => (v ? "https://" + v : null) }],
    ["servicePhoneNumber", { parser: convertPhoneNumber }],
    [
      "annualCreditCardSalesVolume",
      { parser: (v: string) => parseAmountValue(v) * 100 },
    ],
    [
      "averageTicketAmount",
      { parser: (v: string) => parseAmountValue(v) * 100 },
    ],
    ["highTicketAmount", { parser: (v: string) => parseAmountValue(v) * 100 }],
    ["serviceCountriesOutUSCanada"],
    ["countriesServicedOutside"],
    ["name"],
    ["slug"],
    ["billingDescriptor", { parser: (v: string) => v.toUpperCase() }],
  ],
  enterpriseInfo: [
    ["name"],
    ["permalink", { apiKey: "slug" }],
    ["purpose", { parser: (v: string) => btoa(v), apiKey: "description" }],
    ["websiteURL", { parser: (v: string) => (v ? "https://" + v : null) }],
    ["servicePhoneNumber", { parser: convertPhoneNumber }],
    ["serviceCountriesOutUSCanada"],
    ["countriesServicedOutside"],
  ],
};

type TOptions = {
  apiKey?: string;
  parser?: (value: any) => any;
};

type TArgs = [data: any, name: string, options?: TOptions];

const addAttribute = (
  args: TArgs,
  allowEmpty?: Record<string, boolean | undefined> | undefined,
) => {
  const [data, name, options] = args;

  if (!Object.prototype.hasOwnProperty.call(allowEmpty, name) && !data[name]) {
    return undefined;
  }

  const attribute = options?.apiKey || name;
  const value = options?.parser ? options.parser(data[name]) : data[name];
  return {
    [attribute]: value,
  };
};
