import {
  BankIcon,
  CertificateIcon,
  MedalIcon,
  ScrollIcon,
} from "@assets/icons/RebrandedIcons";
import { NewTicketsIcon } from "@assets/icons";
import {
  CREATE_BUSINESS_PROFILE_MODAL,
  CREATE_MERCHANT_AGREEMENT_MODAL,
  CREATE_PRIMARY_ACCOUNT_HOLDER_MODAL,
  EDIT_BANK_ACCOUNT_MODAL,
  EDIT_BUSINESS_OWNER_MODAL,
  EDIT_ENTERPRISE_MCC_MODAL,
  ENTERPRISE_CONFIGURATION_MODAL,
} from "modals/modal_names";
import { useCallback, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import {
  IMerchantBankAccount,
  TBusinessOwner,
  TCategoryCode,
} from "@components/Merchants/MerchantPreview/data.types";

import SectionPlaceholders, {
  SectionPlaceholderProps,
} from "@components/Merchants/CreateMerchantPanel/components/AddSections/SectionPlaceholders";
import { NewBusinessOwnershipIcon } from "@assets/icons";
import { FormType } from "@components/Merchants/CreateMerchantPanel/modals/CreateBusinessProfileModal";
import { useGetCurrentMerchantId } from "@hooks/common";
import { TCreateBusinessProfile } from "@components/Merchants/CreateMerchantPanel/types";
import GearSixIcon from "@assets/rebrandIcons/GearSixIcon";
import { shouldBeHidden } from "@constants/constants";
import {
  TMerchantAgreementPayload,
  merchantAgreementDefaultValues,
} from "@components/Merchants/CreateMerchantPanel/hooks/useAddMerchantAgreement";
import { isEmpty } from "lodash";

type Props = {
  parentCategories: TCategoryCode[];
};

const CreateEnterprisePlaceholders = ({ parentCategories }: Props) => {
  const { merchantId } = useGetCurrentMerchantId();
  const { setValue, watch } = useFormContext();
  const values = watch();

  const handleClosePrimaryAccountHolder = (data: any) => {
    setValue("primary_account_holder", data);
  };

  const handleCloseBusinessProfile = useCallback(
    (data: {
      businessProfile: TCreateBusinessProfile;
      businessOwners?: TBusinessOwner[];
    }) => {
      setValue("businessProfile", {
        ...data.businessProfile,
        linkedOwnerId: data.businessOwners?.[0]?.id,
      });
      if (data.businessProfile.linkedBusinessProfile) {
        setValue("businessOwners", data.businessOwners || []);
      }
    },
    [values.businessProfile, values.businessOwners],
  );

  const handleCloseBusinessOwner = (data: TBusinessOwner) => {
    setValue("businessOwners", [data]);
  };

  const handleCloseBankAccount = (data: IMerchantBankAccount) => {
    setValue("bankAccounts", [data]);
  };

  const handleCloseMCC = (data: any) => {
    setValue("categories", data);
  };

  const handleCloseConfiguration = (data: { [key: string]: boolean }) => {
    setValue("permissions", data);
  };
  const handleCloseAgreement = (data: TMerchantAgreementPayload) => {
    setValue("agreement", data);
  };

  const isSoleProprietorship =
    "individual_sole_proprietorship" === values?.businessProfile?.businessType;

  const placeholdersList = useMemo<SectionPlaceholderProps["placeholdersList"]>(
    () => [
      {
        icon: <MedalIcon />,
        title: "Primary Account Holder",
        description:
          "A primary account holder is the first person who will access this merchant directly and be in charge with its management.",
        hidden: values?.primary_account_holder?.email,
        modal: CREATE_PRIMARY_ACCOUNT_HOLDER_MODAL,
        onClose: handleClosePrimaryAccountHolder,
        data: values.primary_account_holder,
        isEnterprise: true,
      },
      {
        icon: <ScrollIcon />,
        title: "Business Profile",
        description: "Also known as Legal Entity",
        hidden: values.businessProfile.taxID || values?.businessProfile?.ssn,
        modal: CREATE_BUSINESS_PROFILE_MODAL,
        onClose: handleCloseBusinessProfile,
        data: values.businessProfile,
        formType: FormType.CREATE_ENTERPRISE,
      },
      {
        icon: <NewBusinessOwnershipIcon />,
        title: "Business Ownership",
        description:
          "Add any individual who owns 10% or more of this business.",
        hidden:
          values.businessOwners?.length !== 0 ||
          values.businessProfile.linkedBusinessProfile ||
          (!values.businessProfile.taxID && !values?.businessProfile?.ssn),
        modal: EDIT_BUSINESS_OWNER_MODAL,
        onClose: handleCloseBusinessOwner,
        formType: FormType.CREATE_ENTERPRISE,
        isIndividualSoleProprietorship: isSoleProprietorship,
        businessType: values?.businessProfile?.businessType,
      },
      {
        icon: <BankIcon />,
        title: "Bank Accounts",
        description: "Add a Bank Account",
        hidden: values.bankAccounts?.length !== 0,
        modal: EDIT_BANK_ACCOUNT_MODAL,
        onClose: handleCloseBankAccount,
        testId: "bank-accounts-placeholder",
        isEnterprise: true,
      },
      {
        icon: <NewTicketsIcon />,
        title: "Merchant Category Codes (MCC)",
        description:
          "Choose the categories this Provider can assign to its merchants",
        hidden: values.categories.length !== 0,
        modal: EDIT_ENTERPRISE_MCC_MODAL,
        onClose: handleCloseMCC,
        merchantId,
        categories: [],
        parentCategories,
        testId: "mcc-placeholder",
      },
      {
        icon: <CertificateIcon />,
        title: "Agreement",
        description:
          "The provider agreement outlines the terms and conditions governing the contractual relationship between the provider and the payment processor.",
        hidden: values?.agreement && Object.keys(values?.agreement)?.length > 0,
        modal: CREATE_MERCHANT_AGREEMENT_MODAL,
        onClose: handleCloseAgreement,
        data: {
          ...(isEmpty(values.agreement)
            ? merchantAgreementDefaultValues
            : values.agreement),
          isEnterPrise: true,
        },
        testId: "agreement-placeholder",
      },
      {
        icon: <GearSixIcon />,
        title: "Configuration",
        description: "Customize provider fees and permissions",
        hidden: shouldBeHidden.enterpriseConfiguration,
        modal: ENTERPRISE_CONFIGURATION_MODAL,
        onClose: handleCloseConfiguration,
        data: values?.permissions,
      },
    ],
    [values],
  );

  useEffect(() => {
    if (!values.businessProfile?.linkedBusinessProfile) {
      setValue("businessOwners", []);
    }
  }, [values.businessProfile?.linkedBusinessProfile]);

  return <SectionPlaceholders placeholdersList={placeholdersList} />;
};

export default CreateEnterprisePlaceholders;
