import { getProcessingAmount } from "@common/TableFilters/utils";
import { FilterObjectTypes, TypedFunction } from "@customTypes/utils.types";
import {
  BILLING_DESCRIPTOR_MAX_LENGTH,
  isValidEmailRegex,
} from "@constants/constants";
import { store } from "@redux/store";
import { forEach, isEqual, keys, union } from "lodash";
import moment from "moment";

export const getMerchantId = () => {
  const currentValue = store.getState().accounts.selectedAccount;

  const { id: masqId, name: masqName } = store.getState().app.masqueradeMode;
  const merchantId =
    masqName && typeof masqId === "number" ? masqId : currentValue || 0;

  return merchantId;
};

export const stopEventPropagation: TypedFunction<
  [(TypedFunction<any> | undefined)?],
  TypedFunction<any>
> = (func) => {
  return (e) => {
    e?.stopPropagation();
    e?.preventDefault();
    func?.(e);
  };
};

export function getRandomNumber(lower: number, upper: number) {
  const min = Math.min(lower, upper);
  const max = Math.max(lower, upper);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const isDefined = (val: string | number | null | undefined) =>
  Boolean(val);

export const isMobileSafari = () => {
  return (
    navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
    navigator.userAgent.match(/AppleWebKit/)
  );
};

export const isValidEmail = (email: string) => {
  return isValidEmailRegex.test(email);
};
export const convertDateFromAmericanToApiFormat = (date: string | null) => {
  const updatedDate = moment(date, "MM-DD-YYYY").format("YYYY-MM-DD");
  return updatedDate === "Invalid date" ? "" : updatedDate;
};

export function base64ToFile(base64String: string, filename = "selfie"): File {
  const arr: any = base64String.split(",");
  const mime = arr[0].match(/:(\w+\/[\w+.-]+);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  // If the image is not jpg or jpeg, convert it to jpg
  const outputMime =
    mime.includes("jpeg") || mime.includes("jpg") ? mime : "image/jpeg";
  filename = filename + "." + outputMime.split("/")[1];

  return new File([u8arr], filename, { type: outputMime });
}

export const formatAddress = (addressObj?: { [key: string]: string }) => {
  const { city, line1, country, zip, address, state } = addressObj || {};
  if (city) {
    return `${address || line1} ${city}, ${state} ${zip}, ${country}`;
  }
  return "";
};

//accepts 12925559233 and return +1 292-555-9233
export const formatUSPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return "";
  return phoneNumber?.length === 11
    ? phoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "+$1 $2-$3-$4")
    : phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "+$1 $1-$2-$3");
};

export const generateBillingDescriptor = (descriptor: string) =>
  descriptor
    .replace(/[^a-zA-Z0-9&*,-.# ]/g, "")
    ?.replaceAll(" ", "-")
    ?.slice(0, BILLING_DESCRIPTOR_MAX_LENGTH); // the billing descriptor can contain max 21 character letters,numbers and some special characters

export function truncateFilenameWithExtension(
  filename: string,
  maxLength = 40,
) {
  if (filename.length <= maxLength) {
    return filename;
  } else {
    // Split the filename into name and extension
    const nameParts = filename.split(".");
    const name = nameParts.slice(0, -1).join(".");
    const extension = nameParts[nameParts.length - 1];

    const truncatedLength = maxLength - extension.length - 1; // accounting for the dot in the extension
    if (truncatedLength <= 0) {
      return "..." + extension;
    } else {
      const truncatedName = name.substring(0, truncatedLength);
      return truncatedName + "..." + extension;
    }
  }
}

export function countTopLevelDifferences(
  obj1: Record<string, any>,
  obj2: Record<string, any>,
  keysToIgnore = ["date", "createdAt"],
): number {
  let differentCount = 0;
  const allKeys = union(keys(obj1), keys(obj2));

  forEach(allKeys, (key) => {
    if (!keysToIgnore.includes(key) && !isEqual(obj1[key], obj2[key])) {
      differentCount++;
    }
  });

  return differentCount;
}

export const truncateString = (text: string, maxLength: number) => {
  return `${text.slice(0, maxLength)}${text.length > maxLength ? "..." : ""}`;
};

export function findByIdAndModify(
  arr: FilterObjectTypes[],
  newObj: FilterObjectTypes,
) {
  const index = arr.findIndex((obj) => obj.value === newObj.value);

  if (index !== -1) {
    arr.splice(index, 1);
  } else {
    arr.push(newObj);
  }
  return arr;
}

export const getAmountArray = (prefix: "inflow" | "outflow") => [
  {
    label: "0 USD",
    value: getProcessingAmount({
      amount: 0,
      modifier: "is equal to or less than",
      prefix,
    }),
  },
  {
    label: "1 - 500 USD",
    value: getProcessingAmount({
      amount: 100,
      secondAmount: 50000,
      modifier: "is between",
      prefix,
    }),
  },
  {
    label: "> 500 USD",
    value: getProcessingAmount({
      amount: 50000,
      modifier: "is equal to or greater than",
      prefix,
    }),
  },
  {
    label: "Custom",
    value: "custom",
  },
];
