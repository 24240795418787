import { memo } from "react";
import { Text } from "@common/Text";
import { palette } from "@palette";

export type BusinessProfileStatusType =
  | "pending"
  | "pending_review"
  | "move_back_to_pending"
  | "approved"
  | "declined"
  | "suspended"
  | "ready_for_verification"
  | "declined_by_msp"
  | "pending_review_issue"
  | "In progress"
  | "Delivered"
  | "Undeliverable";

interface Props {
  statusCode?: BusinessProfileStatusType;
}
function BusinessProfileTag({ statusCode }: Props) {
  if (!statusCode || !BusinessProfileStatus[statusCode]) return <></>;
  const { label, color, backgroundColor } = BusinessProfileStatus[statusCode];

  return (
    <Text
      fontSize="14px"
      fontWeight="regular"
      bgcolor={backgroundColor}
      color={color}
      borderRadius="16px"
      p="2px 16px"
    >
      {label}
    </Text>
  );
}

export default memo(BusinessProfileTag);

type StatusObject = { label: string; color: string; backgroundColor: string };
const BusinessProfileStatus: Record<BusinessProfileStatusType, StatusObject> = {
  pending_review: {
    label: "Pending Review",
    color: palette.filled.orange,
    backgroundColor: palette.tag.warning.bg,
  },
  move_back_to_pending: {
    label: "Pending",
    color: palette.neutral[80],
    backgroundColor: palette.neutral[10],
  },
  approved: {
    label: "Approved",
    color: palette.filled.success,
    backgroundColor: palette.tag.success.bg,
  },
  declined: {
    label: "Declined",
    color: palette.filled.red,
    backgroundColor: palette.tag.error.bg,
  },
  suspended: {
    label: "Suspended",
    color: palette.filled.orange,
    backgroundColor: palette.tag.warning.bg,
  },
  ready_for_verification: {
    label: "Ready for Verification",
    color: palette.accent[3],
    backgroundColor: "#EAEFF8",
  },
  pending: {
    label: "Pending",
    color: palette.neutral[80],
    backgroundColor: palette.neutral[10],
  },
  declined_by_msp: {
    label: "Pending",
    color: palette.neutral[80],
    backgroundColor: palette.neutral[10],
  },
  pending_review_issue: {
    label: "Pending",
    color: palette.neutral[80],
    backgroundColor: palette.neutral[10],
  },
  "In progress": {
    color: palette.filled?.orange,
    backgroundColor: palette.tag.warning.bg,
    label: "In progress",
  },
  Delivered: {
    color: palette.neutral[80],
    backgroundColor: palette.neutral[10],
    label: "Delivered",
  },
  Undeliverable: {
    color: palette.error.hover,
    backgroundColor: palette.tag.error.bg,
    label: "Undeliverable",
  },
};
