import { PaletteMode, ThemeProvider, styled as styledd } from "@mui/material";
import React, { useMemo, useState } from "react";
import { CustomTheme } from "./palette.interface";
import { useTheme } from "@emotion/react";
import { createThemeBuilder } from "./theme.builders";
import { CreateMUIStyled } from "@mui/material";

export const ColorModeContext = React.createContext({
  toggleColorMode: (mode: "light" | "dark") => {
    return;
  },
});

export const ThemeWrapper = ({ children }: any) => {
  const [mode, setMode] = useState<PaletteMode>("dark");

  const colorMode = useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) =>
          prevMode === "light" ? "dark" : "light",
        );
      },
    }),
    [],
  );

  const theme = useMemo(() => createThemeBuilder(mode), [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export const useAppTheme = (): CustomTheme => {
  const theme = useTheme();

  return theme as CustomTheme;
};

export const styled: CreateMUIStyled<CustomTheme> = styledd;
