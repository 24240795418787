import { ErrorType_bulk_merchant, TInviteElement } from "../types";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { Warning } from "@phosphor-icons/react";
import { Tooltip as MuiTooltip, TooltipProps } from "@mui/material";
import { Tooltip } from "@common/Tooltip";
import React, { useRef, useState } from "react";
import InviteListItemLayout from "./InviteListItemLayout";
import { TextItem } from "./panel.atoms";
import { Stack } from "@mui/material";
import { Box } from "@mui/material";

interface InvitesListItemProps extends TInviteElement {
  itemKey: string;
  onSelect: VoidFunction;
  onClick: VoidFunction;
  isUnique: boolean;
  isDisabled: boolean;
}

const InvitesListItem = ({
  itemKey,
  pahEmail,
  merchantName,
  checked,
  isUnique,
  onSelect,
  onClick,
  isDisabled,
  errorType,
}: InvitesListItemProps) => {
  const { isMobileView } = useCustomTheme();
  const handleSelect = () => {
    if (isDisabled) return;
    onSelect();
  };
  const handleClick = () => {
    if (isDisabled) return;
    onClick();
  };

  const isEmailMissing = ErrorType_bulk_merchant.EMAIL_REQUIRED === errorType;
  const isEmailInvalid = ErrorType_bulk_merchant.EMAIL_INVALID === errorType;
  const isNameMissing =
    ErrorType_bulk_merchant.MERCHANT_NAME_REQUIRED === errorType;
  const isNameInvalid =
    ErrorType_bulk_merchant.MERCHANT_NAME_EXISTS === errorType ||
    ErrorType_bulk_merchant.SLUG_EXISTS === errorType;

  const emailErrorMessage =
    !pahEmail || isEmailMissing
      ? "Provide email address"
      : isEmailInvalid
      ? "Provide a valid email address"
      : "";
  const merchantNameErrorMessage =
    !merchantName || isNameMissing
      ? "Provide merchant name"
      : isNameInvalid
      ? "Provide unique merchant name"
      : "";
  return (
    <InviteListItemLayout
      checked={checked}
      disabled={isDisabled}
      name={itemKey}
      onSelect={handleSelect}
      onClick={handleClick}
      isMobileLayout={isMobileView}
      dataTestId={`invites-list-item`}
      sx={{
        container: {
          borderBottom: `1px solid ${palette.liftedWhite[200]}`,
          transition: "all 50ms ease-in",
          "&:hover": {
            backgroundColor: palette.common.white,
            cursor: "pointer",
          },
        },
      }}
    >
      <OverflowTooltip
        title={pahEmail}
        value={pahEmail}
        warning={{
          show: !!emailErrorMessage,
          message: emailErrorMessage,
        }}
      />
      <OverflowTooltip
        title={merchantName}
        isCaption={isMobileView}
        value={merchantName}
        warning={{
          show: Boolean(merchantNameErrorMessage),
          message: merchantNameErrorMessage,
        }}
      />
    </InviteListItemLayout>
  );
};

export default React.memo(InvitesListItem);

const WarningWithTooltip = ({ title }: { title: string }) => {
  const { isMobileView } = useCustomTheme();
  if (isMobileView) return <Warning size={18} color={palette.filled.orange} />;
  return (
    <CustomTooltip title={title}>
      <Warning
        size={18}
        color={palette.filled.orange}
        data-testid={`warning-${title}`}
      />
    </CustomTooltip>
  );
};

const CustomTooltip = ({
  title,
  children,
}: {
  title: string;

  children: React.ReactNode;
}) => {
  return (
    <Tooltip
      placement="top"
      titleContainerSx={{
        padding: "4px 16px 4px 16px",
        borderRadius: "6px",
        background: palette.black[100],
      }}
      titleSx={{
        textTransform: "none",
        fontSize: "12px",
        fontWeight: 350,
        lineHeight: "14.4px",
        color: palette.liftedWhite.main,
      }}
      sx={{
        "& .MuiTooltip-tooltip": {
          maxWidth: "350px",
          background: "transparent !important",
          boxShadow: "none",
          padding: 0,
          top: 0,
        },
      }}
      title={title}
    >
      <>{children}</>
    </Tooltip>
  );
};

const OverflowTooltip = ({
  title,
  isCaption = false,
  value,
  warning,
}: Omit<TooltipProps, "children"> & {
  title: string;
  isCaption?: boolean;
  value: string;
  warning: {
    show: boolean;
    message: string;
  };
}) => {
  const [enableTooltip, setEnableTooltip] = useState(false);
  const { isMobileView } = useCustomTheme();
  const containerRef = useRef<HTMLDivElement>(null);

  const updateHoverStatus = () => {
    if (!containerRef.current) return;
    const { scrollWidth, offsetWidth } = containerRef.current;
    setEnableTooltip(scrollWidth > offsetWidth);
  };

  return (
    <Stack
      flexGrow={1}
      flexBasis={0}
      direction="row"
      alignItems="center"
      gap="4px"
      overflow="hidden"
      minHeight="18px"
    >
      <MuiTooltip
        title={title}
        placement="top"
        disableHoverListener={!enableTooltip || isMobileView}
        componentsProps={{
          tooltip: {
            sx: {
              maxWidth: "400px",
              padding: "4px 16px 4px 16px",
              borderRadius: "6px",
              background: palette.black[100],
              fontSize: "12px",
              fontWeight: 350,
              lineHeight: "14.4px",
              color: palette.liftedWhite.main,
            },
          },
        }}
      >
        <Box overflow="hidden" ref={containerRef}>
          <TextItem
            isCaption={isCaption}
            onMouseEnter={updateHoverStatus}
            sx={{ whiteSpace: "nowrap" }}
          >
            {value}
          </TextItem>
        </Box>
      </MuiTooltip>

      {warning.show && <WarningWithTooltip title={warning.message} />}
    </Stack>
  );
};
