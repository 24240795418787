import { useAppSelector, useAppDispatch } from "@redux/hooks";
import {
  resetConversationsState,
  selectConversation,
  selectConversationTopic,
  setConversationTopic,
  setModalOpenConversation,
  setReplies,
  setThreads,
} from "@redux/slices/conversations";
import { customInstance } from "@services/api";
import { useState } from "react";
import { useQuery } from "react-query";
import { TThreadInfo, Replies } from "../types";

export const GlobalTopics: Record<string, string> = {
  "Merchant Info": "merchant_info",
  "Bank Accounts": "bank_accounts",
  "Primary Account Holder": "primary_account_holder",
  "Business Profile": "business_profile",
  "Business Address": "business_address",
  "Business Owners": "business_owners",
  "PEP Check": "pep_check",
  "OFAC Check": "ofac_check",
  "Risk Activity": "risk_activity",
  "Risk Monitor": "risk_monitor",
  Underwriting: "underwriting",
  Snapshots: "snapshots",
  Documents: "documents",
  "Merchant Fees": "merchant_fees",
  "Merchant Agreement": "merchant_agreement",
  "MATCH Check": "match_check",

  // TODO: this might change when BE is ready
  "Provider Info": "enterprise_info",
  "Provider Fees": "enterprise_fees",
  "Provider Agreement": "enterprise_agreement",
  Sponsor: "sponsor",
};
const cache: { [key: string]: any } = {};

type Props = {
  topicName?: string;
  merchantId?: number;
};
export const getGlobalTopic = async ({ topicName, merchantId }: Props) => {

  const cacheKey = topicName ? `/topics?q="${topicName}"` : "/topics";
  if(merchantId) `/merchants/${merchantId}${cacheKey}`

  if (cache[cacheKey]) {
    return cache[cacheKey];
  }

  const response = await customInstance({
    method: "GET",
    url: cacheKey,
  });

  cache[cacheKey] = response;
  return response;
};

export const getGlobalTopicLabel = (value: string): string | undefined =>
  Object.keys(GlobalTopics).find((key) => GlobalTopics[key] === value);

export const useGetGlobalTopics = () => {
  const dispatch = useAppDispatch();
  const {
    isOpenedFromSidePanel,
    disableGlobalLauncher,
    queryObject: {
      name: topicName,
      paths,
      challengeId,
      escalationIds,
      factorId,
      defaultMessage,
      shouldHideMentionIcon,
    },
  } = useAppSelector(selectConversationTopic);

  return useQuery(
    ["get-global-topics", topicName, GlobalTopics[topicName]],
    async () => {
      const data = await getGlobalTopic({ topicName: GlobalTopics[topicName] });
      return data;
    },
    {
      enabled: !!topicName && isOpenedFromSidePanel && !disableGlobalLauncher,
      onSuccess: (data: any) => {
        if (!data.data) return;
        const { ID, DisplayName, Name } = data.data[0];
        if (disableGlobalLauncher) return;
        const name = getGlobalTopicLabel(Name) || DisplayName;
        dispatch(
          setConversationTopic({
            isOpen: true,
            isOpenedFromSidePanel: undefined,
            queryObject: {
              id: ID,
              name,
              paths: paths.length > 0 ? paths : [],
              challengeId,
              factorId,
              escalationIds,
              defaultMessage,
              shouldHideMentionIcon,
            },
          }),
        );
      },
    },
  );
};

export const useConversationsModal = () => {
  const {
    isOpen: isThereOpenTopic,
    queryObject: { id: topicId },
  } = useAppSelector(selectConversationTopic);
  const [isTopicModalOpen, setIsTopicModalOpen] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const {
    modal: { isOpen },
  } = useAppSelector(selectConversation);

  const updateThreads = (threads: Array<TThreadInfo>) => {
    dispatch(setThreads(threads));
  };

  const openConversationHandler = ({
    name,
    paths,
    id,
    defaultMessage = "",
    challengeId,
    escalationIds,
    factorId,
    openThread,
    forceTopicOpen,
    isOpenedFromSidePanel,
    shouldHideMentionIcon,
    disableGlobalLauncher,
  }: {
    name: string;
    paths?: Array<{ avatars: string[]; pathName: string }>;
    id?: number;
    defaultMessage?: string;
    challengeId?: number;
    escalationIds?: number[];
    factorId?: number;
    openThread?: Replies;
    forceTopicOpen?: boolean;
    isOpenedFromSidePanel?: boolean;
    shouldHideMentionIcon?: boolean;
    disableGlobalLauncher?: boolean;
  }) => {
    // first we dispatch a partial object
    dispatch(
      setConversationTopic({
        isOpen: forceTopicOpen || isThereOpenTopic || false,
        isOpenedFromSidePanel,
        disableGlobalLauncher,
        queryObject: {
          id: topicId && topicId > 0 ? topicId : id,
          name,
          paths: paths || [],
          defaultMessage,
          challengeId,
          factorId,
          escalationIds,
          shouldHideMentionIcon,
        },
      }),
    );

    if (openThread)
      dispatch(
        setReplies({
          ...openThread,
        }),
      );

    handleOpenConversationsModal(true);
  };

  const dispatch = useAppDispatch();

  const handleOpenTopicModal = () => {
    setIsTopicModalOpen((v) => !v);
  };

  const handleOpenConversationActivityThread = ({
    isEnterprise,
  }: {
    isEnterprise: boolean;
  }) => {
    dispatch(
      setConversationTopic({
        isOpen: true,
        isOpenedFromSidePanel: false,
        numberOfUnreadMessages: 0,
        queryObject: {
          tabs: "Activity",
          id: topicId,
          name: `Notify ${isEnterprise ? "Provider" : "Merchant"} `,
          paths: [
            {
              isConversation: true,
              pathName: `Notify ${isEnterprise ? "Provider" : "Merchant"} `,
              pathID: "new",
              avatars: [],
              hideInputs: ["none"],
            },
          ],
        },
      }),
    );
  };

  const handleExpandModal = () => {
    setIsExpanded((v) => !v);
  };

  const handleOpenConversationsModal = (open?: boolean) => {
    dispatch(setModalOpenConversation(!!open));
    if (!open) {
      if (isTopicModalOpen) handleOpenTopicModal();
      dispatch(resetConversationsState());
    }
  };

  return {
    handleOpenTopicModal,
    isOpen,
    isTopicModalOpen,
    handleOpenConversationsModal,
    handleExpandModal,
    openConversationHandler,
    updateThreads,
    isExpanded,
    handleOpenConversationActivityThread,
  };
};
