import { TListWrapperSection } from "@containers/types";
import { TableProps as MuiTableProps, TableRowProps } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";

export type ColumnBaseType =
  | "text"
  | "merchant-first"
  | "assignment"
  | "phase"
  | "in-out"
  | "processing-merchant"
  | "status"
  | "status-round"
  | "member"
  | "actions"
  | "empty";

export type TableColumnType = {
  /**
   * Title of the column head
   */
  title?: string | any;
  /**
   * If table can be sorted with the column values
   */
  sortable?: boolean;

  rightSort?: boolean;
  hideColumn?: boolean;
  /**
   * Keyname of row item to access the value
   */
  key: string | any;
  /**
   * Custom component to show the column value
   */
  renderColumn?: (item: any, open?: boolean) => React.ReactNode;
  /**
   * If is to be shown expand button
   */
  hasExpandButton?: boolean;

  pinkBorder?: boolean;

  iconButton?: boolean;

  columnWidth?: number | string;

  sx?: TableRowProps["sx"];

  headerPosition?: "left" | "right" | "center";

  columnType?: ColumnBaseType;
};

export type RenderExpandedRow = (rowData: any) => React.ReactNode;

export interface TableData {
  id?: string | number;

  [key: string]: any;
}

export interface TableColumnTypeCustom extends TableColumnType {
  hideColumn?: boolean;
}

export type TableProps = MuiTableProps & {
  lastItemRef?: (node?: Element | null | undefined) => void;
  small?: boolean;
  page?: number;
  maxHeight?: string | number;
  rowHeight?: string | number;
  expandable?: boolean | ((row: any) => boolean);
  renderExpandedRow?: RenderExpandedRow;
  shouldRenderTable?: boolean;
  data: TableData[];
  columns: TableColumnTypeCustom[];
  totalRecords?: number;
  withPagination?: boolean;
  hasPaginationWhenEmpty?: boolean;
  emptyTableMessage?: string;
  iconButton?: boolean;
  noTrailing?: boolean;
  order?: any;
  orderBy?: any;
  onRequestSort?: any;
  pinkBorder?: boolean;
  pinkRowBorder?: boolean;
  count?: number;
  sortOptionOne?: "Sort A-Z" | "Ascending Date" | "Ascending Amount";
  sortOptionTwo?: "Sort Z-A" | "Descending Date" | "Descending Amount";
  total?: boolean;
  disabled?: boolean;
  selectable?: boolean;
  currentRowsPerPage?: number;
  isLoading?: boolean;
  sorting?: string;
  setSorting?: Dispatch<SetStateAction<string>>;
  handlePageChange?: (event: React.ChangeEvent<unknown>, value: number) => void;
  tableTitle?: string;
  searchBar?: React.ReactNode;
  downloadButton?: React.ReactNode;
  withBackground?: boolean;
  filters?: React.ReactNode;
  tabs?: React.ReactNode;
  initialSortingProperty?: string;
  rowRenderKey?: (row: any) => any;
  onOpenSidePanel?: any;
  openedModal?: string;
  numberOfPages?: number;
  BodyRowStyle?: React.CSSProperties;
  queryKey?: string;
  itemsPerPage?: number;
  isFetching?: boolean;
  handleRowClick?: (itemRow: any, idx: number) => void;
  alwaysShowDownloadBtn?: boolean;
  emptyBox?: boolean;
  emptyState?: TListWrapperSection;
  hideTableView?: boolean;
  totalExportRecords?: ExportRecordsTotal;
  showExportFilterBtns?: boolean;
  leftSideSearchBar?: boolean;
  defaultSort?: { key: string; isDesc?: boolean };
  hideLeftSideFilter?: boolean;
};

export type ExportRecordsTotal = {
  portfolio: number;
  underwriting: number;
  risk: number;
};

export type TableOrder = "asc" | "desc";

export function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) {
  const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const getProperty = <T>(object: T, path: string[] | (keyof T)[]) => {
  let result: any = object;
  for (let i = 0; i < path.length; i++) {
    result = result[path[i]];
    if (result === undefined) {
      break;
    }
  }
  return result;
};

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const path = typeof orderBy === "string" ? orderBy.split(".") : [orderBy];
  let firstOperator: any = getProperty(a, path);
  let secondOperator: any = getProperty(b, path);

  if (typeof firstOperator === "string" && typeof secondOperator === "string") {
    firstOperator = firstOperator.toLowerCase();
    secondOperator = secondOperator.toLowerCase();
  }

  if (firstOperator < secondOperator) {
    return -1;
  }
  if (firstOperator > secondOperator) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: TableOrder,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const SortLabelAsc = (label: string | undefined) => {
  if (
    label === "date" ||
    label === "time" ||
    label === "createdAt" ||
    label === "updatedAt" ||
    label === "joined"
  ) {
    return "Ascending Date";
  } else if (label === "amount" || label === "total") {
    return "Ascending Amount";
  } else {
    return "Sort A-Z";
  }
};

export const SortLabelDsc = (label: string | undefined) => {
  if (
    label === "date" ||
    label === "time" ||
    label === "createdAt" ||
    label === "updatedAt" ||
    label === "joined"
  ) {
    return "Descending Date";
  } else if (label === "amount" || label === "total") {
    return "Descending Amount";
  } else {
    return "Sort Z-A";
  }
};

export function capitalizeFirstLetter(str: string) {
  if (!str) return "";
  const lower = str.toLowerCase();
  return lower[0].toUpperCase() + lower.slice(1);
}
