import { SxProps } from "@mui/material";
import { FilterObjectTypes as RiskLevelType } from "@customTypes/utils.types";
import { Theme } from "@mui/system";
import { FilterDates } from "@redux/slices/tableFilters";
import { replaceValues, withAppendedKey } from "@services/filtering";
import { toUnixDateFormat } from "@utils/date.helpers";
import { sub } from "date-fns";
import { isBoolean, isEmpty } from "lodash";
import moment from "moment";

export type CustomSections = {
  portfolio: FilterSectionItemType[];
  underwriting: FilterSectionItemType[];
  risk: FilterSectionItemType[];
};

export type FilterSectionItemType = {
  startIcon?: React.ReactElement;
  title: string;
  description?: string;
  endElement?: React.ReactElement;
  children?: React.ReactElement;
  containerStyle?: SxProps<Theme>;
  hidden?: boolean;
  dataTestId?: string;
};

export type FormValues = {
  watchlist?: boolean | string;
  parentName?: SelectorOption[];
  totalProcessed?: string | number;
  statusDisplayName?: Partial<SelectorOption>[];
  categoryCode?: SelectorOption[];
  underwriterEmail?: SelectorOption[];
  createdAt?: string | number;
  lastEscalationAt?: string | number;
  lastRiskActivityAt?: string | number;
  approvedAt?: string | number;
  riskLevelLabelDisplayName?: Partial<SelectorOption>[];
  canProcessMoney?: boolean;
  canTransferMoney?: boolean;
  ownerMembershipStatus?: Partial<SelectorOption>[];
  underwritingChallengeScorePercentage?: string;
  filterDates?: FilterDates;
  riskStatusDisplayName?: Partial<SelectorOption>[];
  underwritingScorePercentage?: string;
  merchantRiskStatusDisplayName?: Partial<SelectorOption>[];
};

export type TransactionFilterValues = {
  emailStatus?: Array<RiskLevelType>;
  riskLevel?: Array<RiskLevelType>;
  createdAt?: string;
  outFlow?: string;
  inFlow?: string;
  cardInfoType?: string;
  txnDisplayStatus?: Array<RiskLevelType>;
  isFalsePositive?: boolean;
  isBlocked?: boolean;
  type?: Array<RiskLevelType>;
  processingState?: Array<RiskLevelType>;
  displayStatus?: Array<RiskLevelType>;
  displayName?: Array<RiskLevelType>;
  "class.displayName"?: Array<RiskLevelType>;
  processed?: string;
  transactionsCount?: string;
  approvedMerchants?: string;
};

export type SelectorOption = {
  label: string;
  imageURL?: string;
  id: string | number;
  description?: string;
  value: string | number;
  Icon?: any;
};

export type CustomRenderoption = {
  item: SelectorOption;
  selected: boolean;
};

export type FormFields =
  | "watchlist"
  | "parentName"
  | "totalProcessed"
  | "statusDisplayName"
  | "categoryCode"
  | "underwriterEmail"
  | "createdAt"
  | "approvedAt"
  | "lastRiskActivityAt"
  | "lastEscalationAt"
  | "canProcessMoney"
  | "canTransferMoney"
  | "riskLevelLabelDisplayName"
  | "underwritingScorePercentage"
  | "riskStatusDisplayName"
  | "ownerMembershipStatus"
  | "underwritingChallengeScorePercentage"
  | "merchantRiskStatusDisplayName";

const excludeKeys = [
  "createdAt",
  "totalProcessed",
  "underwritingChallengeScorePercentage",
  "filterDates",
];

export const defaultFormValues: FormValues = {
  watchlist: undefined,
  parentName: [],
  totalProcessed: undefined,
  statusDisplayName: [],
  ownerMembershipStatus: undefined,
  categoryCode: [],
  createdAt: "",
  lastRiskActivityAt: "",
  lastEscalationAt: "",
  approvedAt: "",
  underwriterEmail: [],
  riskStatusDisplayName: [],
  underwritingScorePercentage: undefined,
  underwritingChallengeScorePercentage: undefined,
  canProcessMoney: undefined,
  canTransferMoney: undefined,
  merchantRiskStatusDisplayName: [],
};

interface customizeProps {
  joinKey?: string;
  extraJoinKey?: string; //to be use to handle a relationship specifically with the item BEFORE the 'beforeKey' value
  beforeKey?: string;
}

export function formatQueryParamsList(
  formValues: Partial<FormValues | TransactionFilterValues>,
  { joinKey = "%3B", extraJoinKey, beforeKey }: customizeProps = {},
) {
  const queryObjectList = [
    ...Object.entries(formValues).map(([key, value]) => ({
      [key]: Array.isArray(value) ? value.map((val) => val.value) : value,
    })),
  ];
  const formattedParamsList = queryObjectList.map((obj) => {
    const params = [];

    for (const [key, value] of Object.entries(obj)) {
      if (Array.isArray(value) && !isEmpty(value)) {
        value.forEach((val) => {
          const keyName = isSuspendedSelectedInRiskLevel(key, val)
            ? "statusDisplayName"
            : key;
          const isNot =
            typeof val === "string" && val[0] === "!" && val[1] === "=";
          const isNull = typeof val === "string" && val === "null";
          const paramString = isNot
            ? `${keyName}:!"${val.substring(2)}"`
            : isNull
            ? `${keyName}:null`
            : `${keyName}:"${val}"`;
          params.push(paramString);
        });
      } else if (isValueUseful(key, value)) {
        // Check if the value is a string, if so, include it directly
        params.push(
          excludeKeys.includes(key) || typeof value === "string"
            ? value
            : `${key}:"${value}"`,
        );
      }
    }

    return !isEmpty(params) ? `(${params.join(",")})` : "";
  });
  joinKey;
  const filteredList = formattedParamsList.filter(Boolean);
  const extraJoinKeyIndex = beforeKey
    ? filteredList.findIndex((i) => i.includes(beforeKey)) - 1
    : undefined;
  return customJoinList(filteredList, joinKey, extraJoinKey, extraJoinKeyIndex);
}

export const customJoinList = (
  list: string[],
  joinKey: string,
  extraJoinKey?: string,
  extraJoinKeyIndex?: number,
) => {
  // Initialize the resulting array
  const result = [];

  // Iterate through the list and push each item to the results
  for (let i = 0; i < list.length; i++) {
    // Add the current item
    // the item before the extraJoinKey operator should be nested together with the one that has the extraJoinKey
    if (
      extraJoinKey &&
      extraJoinKeyIndex !== undefined &&
      i === extraJoinKeyIndex
    )
      result.push("(");
    result.push(list[i]);

    // If we are at the specified index, add the join key, otherwise if it's index before the last item add base join key
    if (extraJoinKey && extraJoinKeyIndex !== undefined) {
      if (i === extraJoinKeyIndex) result.push(extraJoinKey);
      else if (i === extraJoinKeyIndex + 1) result.push(`)${joinKey}`);
      //nesting should be finished after the extraJoinKey usage
      else if (i < list.length - 1) result.push(joinKey);
    } else if (i < list.length - 1) result.push(joinKey);
  }

  // Join the result array into a string without any additional keys
  return result.join("");
};

// for watchlist only: filter to be applied only when it's true but not for false
// for able to transfer/process: filter to be applied for true,false both but not for null
const isValueUseful = (keyName: any, value: any) => {
  if (keyName === "watchlist") {
    return value;
  }
  return !isEmpty(value) || isBoolean(value);
};

export const getDateValue = ({
  modifier,
  range,
  days,
  date,
  prefix = "createdAt",
}: {
  modifier:
    | "on"
    | "is on or after"
    | "is on or before"
    | "is between"
    | "in the last";
  range?: { startDate?: Date; endDate?: Date };
  days?: number;
  date?: Date;
  prefix?: string;
}) => {
  let value = "";
  if (modifier === "on" && date) {
    date.setHours(0, 0, 0, 0);
    value = replaceValues(
      withAppendedKey.created["is on or after"].replace("createdAt", prefix),
      toUnixDateFormat(new Date(date.getTime())),
    );
  } else if (
    date &&
    (modifier === "is on or after" || modifier === "is on or before")
  ) {
    value = replaceValues(
      withAppendedKey.created[modifier].replace("createdAt", prefix),
      toUnixDateFormat(new Date(date.getTime() + 86400000)),
    );
  } else if (modifier === "is between" && range) {
    range?.startDate && range.startDate.setHours(0, 0, 0, 0);
    range?.endDate && range.endDate.setHours(23, 59, 59, 0);
    const first = range?.startDate
      ? replaceValues(
          withAppendedKey.created["is on or after"].replace(
            "createdAt",
            prefix,
          ),
          toUnixDateFormat(new Date(range.startDate)),
        )
      : undefined;
    const second = range?.endDate
      ? replaceValues(
          withAppendedKey.created["is on or before"].replace(
            "createdAt",
            prefix,
          ),
          toUnixDateFormat(new Date(range.endDate)),
        )
      : undefined;
    if (first && second) value = `${first}%3B${second}`;
    else if (first && !second) value = `${first}`;
    else if (!first && second) value = `${second}`;
  } else if (modifier === "in the last") {
    value = replaceValues(
      withAppendedKey.created[modifier].replace("createdAt", prefix),
      toUnixDateFormat(sub(new Date(), { days: days })),
    );
  }
  return value;
};

export const getProcessingAmount = ({
  amount,
  secondAmount,
  modifier,
  prefix = "totalProcessed",
}: {
  amount?: number;
  secondAmount?: number;
  modifier:
    | "is between"
    | "greater than"
    | "is equal to or greater than"
    | "is equal to or less than";
  prefix?: string;
}) => {
  let value = "";
  if (modifier === "is between") {
    const first = replaceValues(
      withAppendedKey.totalProcessed["is equal to or greater than"].replace(
        "totalProcessed",
        prefix,
      ),
      amount,
    );
    const second = replaceValues(
      withAppendedKey.totalProcessed["is equal to or less than"].replace(
        "totalProcessed",
        prefix,
      ),
      secondAmount,
    );

    const hasBothAmounts =
      typeof amount === "number" && typeof secondAmount === "number";
    const hasOnlyFirstAmount =
      typeof amount === "number" && typeof secondAmount !== "number";
    const hasOnlySecondAmount =
      typeof amount !== "number" && typeof secondAmount === "number";

    if (hasBothAmounts) value = `${first}%3B${second}`;
    else if (hasOnlyFirstAmount) value = `${first}`;
    else if (hasOnlySecondAmount) value = `${second}`;
  } else if (amount !== undefined)
    value = replaceValues(
      withAppendedKey.totalProcessed[modifier].replace(
        "totalProcessed",
        prefix,
      ),
      amount,
    );
  return value;
};

export const getCustomProcessingLabel = (from?: number, to?: number) => {
  const hasFromAndTo = typeof from === "number" && typeof to === "number";
  const hasOnlyFrom = typeof from === "number" && typeof to !== "number";
  const hasOnlyTo = typeof from !== "number" && typeof to === "number";

  if (hasFromAndTo) return `Custom: ${from} - ${to}`;
  else if (hasOnlyFrom) return `Custom: > ${from}`;
  else if (hasOnlyTo) return `Custom: < ${to}`;
  else return "Custom";
};

export const generateCustomLabel = ({
  startDate,
  endDate,
  showColon = true,
}: {
  startDate: number | Date | undefined;
  endDate: number | Date | undefined;
  showColon?: boolean;
}) => {
  const formatDate = (date: number | Date) => {
    if (typeof date === "number") {
      return moment.unix(date).format("DD MMM YYYY");
    } else if (date instanceof Date) {
      return moment(date).format("DD MMM YYYY");
    } else {
      return "";
    }
  };

  const starterCharacter = showColon ? ": " : "";

  if (endDate && startDate && formatDate(startDate) === formatDate(endDate)) {
    return `${starterCharacter}${formatDate(startDate)}`;
  } else if (endDate && startDate) {
    return `${starterCharacter}${formatDate(startDate)} - ${formatDate(
      endDate,
    )}`;
  } else if (startDate && !endDate) {
    return `${starterCharacter}${formatDate(startDate)}`;
  }

  return "";
};
export const UnderwritingScoreValues = {
  belowEighty: "underwritingChallengeScorePercentage:<80",
  overEighty: "underwritingChallengeScorePercentage:>80",
};

const isSuspendedSelectedInRiskLevel = (key: string, value: string) =>
  key === "riskLevelLabelDisplayName" && value === "Suspended";

export const mapRange = (rangeString: string): string => {
  if (rangeString === "0 USD") {
    return "0";
  }

  const match = rangeString.match(/^(\d+)\s+-\s+(\d+) USD$/);

  if (match) {
    const min = parseInt(match[1], 10);
    const max = parseInt(match[2], 10);
    return `>${min - 1};<${max + 1}`;
  }

  const greaterMatch = rangeString.match(/>(\d+) USD/);

  if (greaterMatch) {
    const min = parseInt(greaterMatch[1], 10);
    return `>${min}`;
  }

  return "custom";
};
