import React from "react";
import CustomArrowRight from "@assets/icons/RebrandedIcons/ArrowRight";
import { IconButton } from "@common/IconButton";
import { Text } from "@common/Text";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { palette } from "@palette";
import {
  MERCHANT_PORTAL_CANCEL_MODAL,
  MERCHANT_PORTAL_REFUND_MODAL,
  MERCHANT_PORTAL_SEND_RECEIPT_MODAL,
  MERCHANT_PREVIEW_PANEL_MODAL,
  MERCHANT_VIEW_CUSTOMER,
} from "modals/modal_names";
import { getSourceAccount } from "../transactions.helpers";
import { MoreActionButton } from "./MoreActionButton";
import { ActionsMenu } from "./ActionsMenu";
import { useTranslation } from "react-i18next";
import { namespaces } from "localization/resources/i18n.constants";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  FEATURE_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { checkPortals } from "@utils/routing";

type Props = {
  data: any;
  onCloseDrawer: any;
  DMenu?: any;
  setSelectedRow?: any;
  isFirst?: boolean;
  isLast?: boolean;
  hideRefund: boolean;
  hideSendReceipt: boolean;
  actionsOnly?: boolean;
};

const TransactionInfoHeader = ({
  onCloseDrawer,
  data,
  DMenu,
  setSelectedRow,
  isFirst,
  isLast,
  hideRefund,
  hideSendReceipt,
  actionsOnly,
}: Props) => {
  const { t } = useTranslation(namespaces.common);
  const classes = useStyles();

  const modal = useModal();
  const isChargeback = data?.status === "Chargeback";
  const isRefund = data?.status === "Refunded";
  const isTransfer = data?.displayType === "Money Transfer";
  const isPendingTransfer = isTransfer && data?.displayStatus === "Requested";

  const isCancelTransferAllowed = useAccessControl({
    resource: composePermission(
      RESOURCE_BASE.MERCHANT,
      RESOURCE_BASE.BANK_TRANSFER,
    ),
    operation: OPERATIONS.UPDATE,
  });

  const isRefundAllowed = useAccessControl({
    resource: composePermission(RESOURCE_BASE.MERCHANT, RESOURCE_BASE.REFUND),
    operation: OPERATIONS.CREATE,
  });

  const actions = [
    {
      title: t("transactionTable.RefundTransaction", {
        ns: namespaces.common,
      }),
      disabled: !isRefundAllowed,
      tooltipProps: {
        show: !isRefundAllowed,
        message: FEATURE_DENY_MESSAGE,
      },
      hidden: hideRefund || isTransfer,
      onClick: () => {
        NiceModal.show(MERCHANT_PORTAL_REFUND_MODAL, {
          sourceAccount: getSourceAccount(data),
          ids: {
            transactionIDs: [data?.transactionID || data.id],
          },
          isFirst,
          isLast,
          setSelectedRow,
        });
      },
    },
    {
      title: t("transactionTable.SendReceipt", {
        ns: namespaces.common,
      }),
      hidden: hideSendReceipt || isTransfer,
      onClick: () => {
        NiceModal.show(MERCHANT_PORTAL_SEND_RECEIPT_MODAL, {
          transactionID: data.id,
          emails: [data.sourceAccount.user.email],
          sourceAccount: getSourceAccount(data),
          customerID: data.sourceAccount.id,
        });
      },
    },

    {
      title: "Cancel Transfer",
      hidden: !isPendingTransfer,
      disabled: !isCancelTransferAllowed,
      tooltipProps: {
        show: !isCancelTransferAllowed,
        message: FEATURE_DENY_MESSAGE,
      },
      onClick: () => {
        NiceModal.show(MERCHANT_PORTAL_CANCEL_MODAL, {
          transactionId: data.id,
          cancel: true,
          sourceAccount: getSourceAccount(data),
          closeOnSuccess: () => modal && modal.hide(),
          customerID: data?.destinationAccount.id,
        });
      },
    },
    {
      title: "View Merchant",
      hidden: !isTransfer,
      onClick: () => {
        NiceModal.show(MERCHANT_PREVIEW_PANEL_MODAL, {
          id: data?.sourceAccount?.id,
        });
        modal.hide();
      },
    },
    {
      title: t("transactionTable.ViewCustomer", {
        ns: namespaces.common,
      }),
      hidden: isTransfer,
      onClick: () => {
        const customerId = data.customer?.id;
        if (customerId)
          NiceModal.show(MERCHANT_VIEW_CUSTOMER, {
            id: customerId,
            merchantId:
              !isChargeback && !isRefund
                ? data?.destinationAccount?.id
                : data?.sourceAccount?.id,
          });
      },
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const onOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Grid container alignItems="center" gap="16px" mb={3}>
        {!actionsOnly && (
          <>
            <IconButton
              onClick={onCloseDrawer}
              size="small"
              className={classes.iconBtn}
            >
              <CustomArrowRight />
            </IconButton>
            <div
              style={{
                width: 1,
                height: "12px",
                borderLeft: `1px solid ${palette.neutral[30]}`,
              }}
            />
            <IconButton
              size="small"
              className={`${classes.iconBtn} ${classes.iconBtnText}`}
              onClick={() => {
                setSelectedRow("prev");
              }}
              disabled={isFirst}
              data-testid="transaction-header-prev-button"
            >
              <ArrowUpward width="20px" height="20px" />
              <Text sx={{ paddingLeft: "8px" }}>Previous</Text>
            </IconButton>
            <IconButton
              size="small"
              className={`${classes.iconBtn} ${classes.iconBtnText}`}
              onClick={() => {
                setSelectedRow("next");
              }}
              disabled={isLast}
              data-testid="transaction-header-next-button"
            >
              <Text sx={{ paddingRight: "8px" }}>Next</Text>
              <ArrowDownward width="20px" height="20px" />
            </IconButton>
          </>
        )}
        {actions.filter((x) => !x.hidden).length ? (
          <MoreActionButton onClick={onOpenMenu} />
        ) : null}
      </Grid>
      {DMenu ? (
        <DMenu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          selectedRow={{ ...data, isHideRefund: hideRefund, hideSendReceipt }}
        />
      ) : (
        <ActionsMenu
          anchorEl={anchorEl}
          onClose={onCloseMenu}
          actions={actions}
        />
      )}
    </>
  );
};

const useStyles = makeStyles(() => ({
  iconBtn: {
    border: "none",
    boxShadow: "none",
    background: "inherit",
    width: "fit-content !important",
    "&:hover": {
      background: palette.neutral[20],
    },
    "&:disabled": {
      background: "transparent",
      boxShadow: "none",
    },
  },
  iconBtnText: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      background: "transparent",
    },
  },
}));

export default TransactionInfoHeader;
