import { TrashBin } from "@assets/icons/RebrandedIcons";
import Popup from "@common/Popup";
import { Text } from "@common/Text";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Box, Divider, Skeleton, Stack } from "@mui/material";
import { palette } from "@palette";
import { Laptop } from "@phosphor-icons/react";
import { formatDistanceToNow, fromUnixTime } from "date-fns";
import UAParser from "ua-parser-js";
import { useDeleteTrustedDevice } from "../../hooks/useDeleteTrustedDevice";
import { useListTrustedDevices } from "../../hooks/useListTrustedDevices";

type TDevices = Array<{
  ID: number;
  UserID: number;
  UserAgent: string;
  IPAddress: string;
  CreatedAt: number;
  UpdatedAt: number;
}>;

const DeleteTrustedDeviceModal = NiceModal.create(({ id }: { id: number }) => {
  const { handleDelete } = useDeleteTrustedDevice();
  const modal = useModal();

  return (
    <Popup
      open={true}
      title={
        (
          <Text fontSize="24px" sx={{ fontWeight: "normal" }}>
            Delete trusted device
          </Text>
        ) as any
      }
      onCancel={() => modal.remove()}
      onSubmit={() => {
        handleDelete(id)
        modal.remove();
      }}
      actionLabel="Delete"
    >
      Do you confirm that you want to delete this trusted device?
      <br /> This action is undoable.
    </Popup>
  );
});

const TrustedDevices = () => {
  const { data, isLoading } = useListTrustedDevices();
  // const { handleDelete } = useDeleteTrustedDevice();
  const handleDelete = (id: number) => {
    NiceModal.show(DeleteTrustedDeviceModal, { id: id as any });
  };

  if (isLoading) {
    return (
      <Stack gap={1} py={2}>
        <Stack direction="row" gap={3} alignItems="center">
          <Skeleton
            variant="circular"
            width={60}
            sx={{ minWidth: 60 }}
            height={60}
          />
          <Skeleton variant="rounded" width="100%" height={50} />
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack gap={2}>
      <Text
        mt={2}
        sx={{ fontWeight: 350, fontSize: "18px", lineHeight: "21.6px" }}
      >
        Trusted Devices and Browsers
      </Text>
      {!!data.total === false ? (
        <Text sx={{ fontSize: "14px", color: "#8F8F8F" }}>
          No devices or browsers saved
        </Text>
      ) : (
        <Box>
          {(data.data as TDevices)?.map((device, index) => {
            const parser = new UAParser(device.UserAgent);
            return (
              <>
                <Stack
                  direction="row"
                  key={index}
                  sx={{ mb: 2 }}
                  gap={4}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack direction="row" gap={1} alignItems="center">
                    <Box
                      sx={{
                        height: "48px",
                        width: "48px",
                        backgroundColor: palette.neutral[10],
                        display: "flex",
                        borderRadius: "50%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Laptop color={palette.neutral[70]} size={32} />
                    </Box>
                    <Box width="200px">
                      <Text sx={{ fontSize: "14px", fontWeight: 350 }}>
                        {parser.getBrowser().name}
                      </Text>
                      <Text
                        sx={{
                          fontSize: "12px",
                          fontWeight: 350,
                          color: "#8F8F8F",
                        }}
                      >
                        {device.IPAddress}
                      </Text>
                    </Box>
                  </Stack>
                  <Box
                    sx={{
                      color: "#8F8F8F",
                      fontWeight: 350,
                    }}
                  >
                    <Text sx={{ fontSize: "12px", fontWeight: 350 }}>
                      {parser.getOS().name}
                    </Text>
                    <Text sx={{ fontSize: "12px", fontWeight: 350 }}>
                      Last access:{" "}
                      {formatDistanceToNow(fromUnixTime(device.UpdatedAt), {
                        addSuffix: true,
                      })}
                    </Text>
                  </Box>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => handleDelete(device.ID)}
                  >
                    <TrashBin fill="#D80D0D" />
                  </span>
                </Stack>
                {index < data.total - 1 && (
                  <Divider sx={{ marginBottom: "16px" }} />
                )}
              </>
            );
          })}
        </Box>
      )}
    </Stack>
  );
};

export default TrustedDevices;
