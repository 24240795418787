/** material UI components */
import { Box, Stack, styled } from "@mui/material";
/** Static assets */
import { RightArrowBigger } from "@assets/icons";
/** Custom UI components */
import { Text } from "@common/Text";
import { Button } from "@common/Button";
/** Custom theme */
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { palette } from "@palette";
/** Types */
import { TStatusReview } from "@components/Merchants/MerchantPreview/data.types";
/** Components */
import UnderwritingAssignment, {
  TUnderwritingAssignmentData,
} from "../UnderwritingAssignment";
import { useQueryClient } from "react-query";
import { QKEY_LIST_ENTERPRISE_STATS } from "@constants/queryKeys";

type Props = {
  data: TStatusReview;
  handleManageUnderwriting?: () => void;
  handleManageRiskMonitor?: () => void;
  isEnterprise?: boolean;
  isRefactored?: boolean;
};

const StatusReviewAssignmetComponent = (props: Props) => {
  const queryClient = useQueryClient();
  const {
    isRefactored,
    data: {
      accID,
      parentAccID,
      assignee,
      underwriterSetByAcquirer,
      statusName,
      underwriterName,
      underwriterImageURL,
      underwriterEmail,
    },
    isEnterprise,
    handleManageUnderwriting,
    handleManageRiskMonitor,
  } = props;

  const underwritingAssignmentProps: TUnderwritingAssignmentData = {
    accID,
    parentAccID,
    underwriterID: assignee || 0,
    underwriterSetByAcquirer,
    statusName,
    underwriterName,
    underwriterImageURL,
    underwriterEmail,
  };
  const { isMobileView } = useCustomTheme();

  return (
    <Container
      isRefactored={isRefactored}
      statusName={statusName}
      isMobileView={isMobileView}
    >
      {isRefactored ? (
        <UnderwritingAssignment
          data={underwritingAssignmentProps}
          action={() => {
            queryClient.refetchQueries(QKEY_LIST_ENTERPRISE_STATS);
          }}
        />
      ) : (
        <AssignmentContainer isMobile={isMobileView}>
          <AssigneeStyleText isMobile={isMobileView}>
            Assignee
          </AssigneeStyleText>

          <UnderwritingAssignment data={underwritingAssignmentProps} />
        </AssignmentContainer>
      )}
      {handleManageUnderwriting && (
        <UnderwritingButton
          onClick={handleManageUnderwriting}
          statusName={statusName}
        />
      )}
      {handleManageRiskMonitor && (
        <RiskMonitorButton onClick={handleManageRiskMonitor} />
      )}
    </Container>
  );
};

type UnderwritingButtonType = {
  onClick: () => void;
  statusName: string;
};

type RiskMonitorButtonType = {
  onClick: () => void;
};

const UnderwritingButton = (props: UnderwritingButtonType) => {
  const { onClick, statusName } = props;
  const { isMobileView } = useCustomTheme();
  if (statusName !== "declined" && !isMobileView) {
    return (
      <Button
        size="medium"
        endIcon={
          <RightArrowBigger
            stroke={palette.neutral.white}
            height={24}
            width={24}
          />
        }
        onClick={onClick}
        sx={{ ml: 1, minWidth: "150px" }}
        data-testid="account-status-overview-underwriting-button"
      >
        Underwriting
      </Button>
    );
  } else return null;
};

const RiskMonitorButton = (props: RiskMonitorButtonType) => {
  const { onClick } = props;
  const { isMobileView } = useCustomTheme();
  if (!isMobileView) {
    return (
      <Button
        size="medium"
        endIcon={
          <RightArrowBigger
            stroke={palette.neutral.white}
            height={24}
            width={24}
          />
        }
        onClick={onClick}
        sx={{ ml: 1, minWidth: "150px" }}
        data-testid="account-status-overview-risk-monitor-button"
      >
        Risk Monitor
      </Button>
    );
  } else return null;
};

/**Styling components */
const StatusRevireAssignmetContainer = styled(Stack, {
  shouldForwardProp: (prop) =>
    prop !== "isCanonic" && prop !== "isMobile" && prop !== "isEnterprise",
})((props: { isCanonic: boolean; isMobile: boolean; isEnterprise?: boolean }) =>
  props.isMobile
    ? {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        borderBottom: props.isCanonic
          ? `1px solid ${palette.neutral[20]}`
          : "none",
      }
    : {
        display: "flex",
        alignItems: "center",
        justifyContent: props.isEnterprise ? "flex-end" : "space-between",
        flexDirection: "row",
        position: "absolute",
        top: -19,
        width: "calc( 100% - 48px )",
      },
);

const AssignmentContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})((props: { isMobile: boolean }) =>
  props.isMobile
    ? {
        display: "flex",
        flexDirection: "column",
        paddingBottom: "16px",
      }
    : {
        minHeight: "32px",
        padding: "2px 24px",
        borderRadius: "100px",
        border: `1px solid ${palette.neutral[30]}`,
        background: palette.background.dimmedWhite,
        display: "flex",
        alignItems: "center",
        gap: "16px",
      },
);

const AssigneeStyleText = styled(Text, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})((props: { isMobile: boolean }) => ({
  paddingBottom: props.isMobile ? "8px" : "0px",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "22.4px",
  fontFamily: "Give Whyte",
  color: palette.neutral[70],
}));

function Container({ children, isRefactored, isMobileView, statusName }: any) {
  const isCanonic = ["declined", "suspended", "approved"].includes(statusName);

  return isRefactored ? (
    <>{children}</>
  ) : (
    <StatusRevireAssignmetContainer
      isMobile={isMobileView}
      isCanonic={isCanonic}
    >
      {children}
    </StatusRevireAssignmetContainer>
  );
}

export default StatusReviewAssignmetComponent;
