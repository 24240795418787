import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { TMerchantInfo } from "../data.types";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid } from "@mui/material";
import { Button } from "@common/Button";
import { useTranslation } from "react-i18next";
import { namespaces } from "localization/resources/i18n.constants";
import { MerchantSlugInput, NameInput } from "@common/BusinessProfileInputs";
import { checkPortals } from "@utils/routing";
import { RHFInput, RHFTelInput } from "@common/Input";
import { getEditMerchantInfoSchema } from "./EditMerchantInfoModal.schema";
import { RHFSelect } from "@common/Select";
import { useListEnterpriseCategoryCodes } from "@hooks/enterprise-api/merchants/useListEnterpriseCategoryCodes";
import { useEffect, useMemo } from "react";
import { useUpdateMerchantInfo } from "../hooks/useUpdateMerchantInfo";
import EditMerchantBaseModal from "../components/EditMerchantBaseModal";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { removeSpecialChars } from "@utils/slug";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import {
  BILLING_DESCRIPTOR_MAX_LENGTH,
  SLUG_MAX_CHARACTER_LENGTH,
} from "@constants/constants";
import { RHFCustomAmountInput } from "@common/CustomAmountInput";
import { RHFCheckbox } from "@common/Checkbox";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";
import { useAppSelector } from "@redux/hooks";
import { selectMerchantPreview } from "@redux/slices/merchantPreview";
import {
  TClassificationsAPIResponse,
  useBusinessClassification,
} from "@components/Signup/Forms/hooks/useBusinessClassification";
import WebsiteInput from "@common/BusinessProfileInputs/WebsiteInput";
import RiskStatusChip, { TRiskLevelName } from "@common/Tag/RiskStatusChip";
import { useGetMerchantRiskStatus } from "@components/Signup/Forms/hooks/useGetMerchantRiskStatus";

export const formatBillingDescriptor = (value?: string) => {
  if (!value) return "";
  return value.replace(/[^a-zA-Z0-9]/g, "").toUpperCase();
};

type FormInputs = {
  averageTicketAmount: string | null;
  highTicketAmount: string | null;
  merchantName: string;
  category: string;
  merchantSlug: string;
  websiteURL: string;
  billingDescriptor: string;
  servicePhoneNumber: string;
  enterprise?: number;
  description: string;
  estimatedAnnualRevenue: string | null;
  isOutsideUSA: boolean;
  countriesOutside: string;
  classification: string;
  merchantRiskStatus: string;
};

interface ModalProps {
  id: number;
  data: TMerchantInfo;
}

const EditMerchantInfoModal = NiceModal.create(({ id, data }: ModalProps) => {
  const { sectionFocus } = useAppSelector(selectMerchantPreview);
  const { t } = useTranslation(namespaces.pages.enterprise.createMerchant);
  const { isAcquirerPortal } = checkPortals();
  const { isMobileView } = useCustomTheme();
  const modal = useModal();
  const open = modal.visible;

  const { handleSubmit, isLoading } = useUpdateMerchantInfo(id);
  const schema = getEditMerchantInfoSchema(isAcquirerPortal);

  const isEditAllowed = useAccessControl({
    resource: RESOURCE_BASE.MERCHANT,
    operation: OPERATIONS.UPDATE,
  });

  const classificationsParser = (res: TClassificationsAPIResponse) =>
    res?.data?.map(({ name, displayName }) => ({
      value: name,
      label: `${displayName}`,
    }));

  const { data: classificationOptions } = useBusinessClassification(
    "accept_payments",
    classificationsParser,
  );

  const { categories } = useListEnterpriseCategoryCodes(data.enterpriseID);
  const categoriesOptions = useMemo(() => {
    return categories.map((category: any) => ({
      id: category.id,
      value: category.code,
      label: `${category.code} - ${category.title}`,
    }));
  }, [categories]);

  const defaultValues = {
    merchantName: "",
    category: "",
    merchantSlug: "",
    websiteURL: "",
    billingDescriptor: "",
    servicePhoneNumber: "",
    enterprise: 0,
    description: "",
    averageTicketAmount: "",
    highTicketAmount: "",
    estimatedAnnualRevenue: "",
    isOutsideUSA: false,
    countriesOutside: "",
    classification: "",
    merchantRiskStatus: "",
  };

  const methods = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    formState: { isDirty },
  } = methods;
  const values = watch();

  useEffect(() => {
    reset({
      merchantName: data.merchantName,
      category: `${data.category || ""}`,
      merchantSlug: data.merchantSlug,
      websiteURL: data.websiteURL,
      billingDescriptor: data.billingDescriptor,
      servicePhoneNumber: data?.servicePhoneNumber
        ? `+${data.servicePhoneNumber}`
        : "",
      enterprise: data.enterpriseID,
      description: data.description,
      averageTicketAmount: +data.averageTicketAmount
        ? data.averageTicketAmount
        : null,
      highTicketAmount: +data.highTicketAmount ? data.highTicketAmount : null,
      isOutsideUSA: data.serviceCountriesOutUSCanada,
      countriesOutside: data.countriesServicedOutside,
      estimatedAnnualRevenue: +data.estimatedAnnualRevenue
        ? data.estimatedAnnualRevenue
        : null,
      classification: data.classification?.name,
      merchantRiskStatus: data.merchantRiskStatus,
    });
  }, [id, data]);

  const enterpriseOptions = useMemo(() => {
    return [
      {
        label: data?.enterprise,
        value: data?.enterpriseID,
      },
    ];
  }, [data]);

  useEffect(() => {
    if (!isDirty) return;

    const descriptor = formatBillingDescriptor(
      values.merchantName.slice(0, BILLING_DESCRIPTOR_MAX_LENGTH),
    );
    const slug = removeSpecialChars(
      values.merchantName,
      SLUG_MAX_CHARACTER_LENGTH,
    );

    setValue("billingDescriptor", descriptor);
    setValue("merchantSlug", slug);
  }, [values.merchantName]);

  useEffect(() => {
    if (!isDirty) return;

    const slug = removeSpecialChars(
      values.merchantSlug,
      SLUG_MAX_CHARACTER_LENGTH,
    );
    setValue("merchantSlug", slug);
  }, [values.merchantSlug]);

  useEffect(() => {
    if (!isDirty) return;

    const descriptor = formatBillingDescriptor(values.billingDescriptor);
    setValue("billingDescriptor", descriptor);
  }, [values.billingDescriptor]);

  const handleCancel = () => {
    reset();
    modal.hide();
  };

  const { data: merchantRiskStatuses, isLoading: isLoadingRiskStatus } =
    useGetMerchantRiskStatus(values.category);
  const riskStatusOptions = useMemo(
    () =>
      merchantRiskStatuses?.map((status) => ({
        ...status,
        label: <RiskStatusChip riskLevelName={status.value} />,
      })) || [],
    [merchantRiskStatuses],
  );
  useEffect(() => {
    const selectedCategory = categories.find((c) => {
      return c.code === values.category;
    });
    const newStatus = selectedCategory?.riskStatus;
    newStatus && setValue("merchantRiskStatus", newStatus);
  }, [values.category]);

  const onSubmit: SubmitHandler<FormInputs> = (customData: any) => {
    const selectedCategory = categoriesOptions.find(
      (category) => category.value === customData.category,
    );
    handleSubmit(
      "merchant_info",
      {
        ...customData,
        category: selectedCategory?.id,
        parentSlug: data.parentSlug,
      },
      handleCancel,
    );
  };

  const disableRiskStatus = !isAcquirerPortal || !values.category;
  const isActionsDisabeled = !isDirty || isLoading || isLoadingRiskStatus;
  const inputList = [
    {
      input: (
        <NameInput
          data-testid="merchant-name-input"
          id="merchantName"
          label={t("merchant_name")}
          name="merchantName"
          placeholder={t("merchant_name_placeholder")}
          isMerchantName
          disabled={!isEditAllowed}
          focusViewColor={
            sectionFocus.field === "merchantName" ? "#6D9CF8" : ""
          }
        />
      ),
    },
    {
      input: (
        <RHFSelect
          label={t("Classification")}
          name="classification"
          id="classification"
          options={classificationOptions}
          disabled={!isEditAllowed}
        />
      ),
    },
    {
      input: (
        <RHFSelect
          id="category"
          label="Merchant Category Code (MCC)"
          name="category"
          placeholder={t("category_placeholder")}
          options={categoriesOptions}
          focusViewColor={sectionFocus.field === "category" ? "#6D9CF8" : ""}
          disabled={!isEditAllowed}
        />
      ),
      sm: 12,
    },
    {
      input: (
        <RHFSelect
          id="merchantRiskStatus"
          label="Merchant Risk Category"
          name="merchantRiskStatus"
          placeholder="Merchant Risk Category"
          options={riskStatusOptions}
          focusViewColor={
            sectionFocus.field === "merchantRiskStatus" ? "#6D9CF8" : ""
          }
          disabled={disableRiskStatus || !isEditAllowed}
          helperText={
            !values.category &&
            isAcquirerPortal &&
            "Select a Merchant Category first. Risk category changes only affect this merchant."
          }
        />
      ),
      sm: 12,
    },
    {
      input: (
        <RHFSelect
          label="Provider"
          name="enterprise"
          id="enterprise"
          options={enterpriseOptions}
          focusViewColor={sectionFocus.field === "enterprise" ? "#6D9CF8" : ""}
          fullWidth
          disabled
        />
      ),
      hidden: !isAcquirerPortal,
    },
    {
      input: (
        <RHFInput
          id="billingDescriptor"
          name="billingDescriptor"
          label={"Billing Descriptor"}
          fullWidth
          inputPrefix={`${data?.billingDescriptorPrefix}*`}
          inputProps={{ maxLength: BILLING_DESCRIPTOR_MAX_LENGTH }}
          disabled={!isEditAllowed}
          focusViewColor={
            sectionFocus.field === "billingDescriptor" ? "#6D9CF8" : ""
          }
        />
      ),
    },
    {
      input: (
        <MerchantSlugInput
          id="merchantSlug"
          name="merchantSlug"
          label={t("Permalink")}
          parentName={data.parentSlug}
          disabled={!isEditAllowed}
          focusViewColor={
            sectionFocus.field === "merchantSlug" ? "#6D9CF8" : ""
          }
        />
      ),
      sm: 12,
    },
    {
      input: (
        <WebsiteInput
          id="websiteURL"
          name="websiteURL"
          label={t("Website URL")}
          disabled={!isEditAllowed}
          focusViewColor={sectionFocus.field === "websiteURL" ? "#6D9CF8" : ""}
        />
      ),
      sm: 12,
    },
    {
      input: (
        <RHFTelInput
          id="servicePhoneNumber"
          label={`Customer Service Phone Number`}
          name="servicePhoneNumber"
          fullWidth
          disabled={!isEditAllowed}
          focusViewColor={
            sectionFocus.field === "servicePhoneNumber" ? "#6D9CF8" : ""
          }
        />
      ),
    },
    {
      input: (
        <RHFCustomAmountInput
          data-testid="estimated-annual-revenue-input"
          id="estimatedAnnualRevenue"
          name="estimatedAnnualRevenue"
          label="Estimated Annual Revenue"
          placeholder="ex. 900"
          bounded={false}
          initialValue={data.estimatedAnnualRevenue}
          currency="usd"
          disabled={!isEditAllowed}
          focusViewColor={
            sectionFocus.field === "estimatedAnnualRevenue" ? "#6D9CF8" : ""
          }
        />
      ),
      sm: 6,
    },
    {
      input: (
        <RHFCustomAmountInput
          data-testid="average-ticket-amount-input"
          id="averageTicketAmount"
          name="averageTicketAmount"
          label="Average Ticket Amount"
          placeholder="ex. 900"
          bounded={false}
          initialValue={data.averageTicketAmount}
          currency="usd"
          disabled={!isEditAllowed}
          focusViewColor={
            sectionFocus.field === "averageTicketAmount" ? "#6D9CF8" : ""
          }
        />
      ),
      sm: 6,
    },
    {
      input: (
        <RHFCustomAmountInput
          data-testid="high-ticket-amount-input"
          id="highTicketAmount"
          name="highTicketAmount"
          label="High Ticket Amount"
          placeholder="ex. 900"
          bounded={false}
          initialValue={data.highTicketAmount}
          currency="usd"
          disabled={!isEditAllowed}
          focusViewColor={
            sectionFocus.field === "highTicketAmount" ? "#6D9CF8" : ""
          }
        />
      ),
      sm: 6,
    },
    {
      input: (
        <RHFInput
          id="description"
          name="description"
          label={"Purpose or Mission of Merchant"}
          placeholder={"Purpose or Mission of Merchant"}
          multiline
          rows={6}
          fullWidth
          disabled={!isEditAllowed}
          inputProps={{
            maxLength: "600",
          }}
          helperText={`${600 - values.description.length} ${t(
            "characters_remaining",
            { ns: namespaces.common },
          )}`}
          focusViewColor={sectionFocus.field === "description" ? "#6D9CF8" : ""}
        />
      ),
      sm: 12,
    },
    {
      sm: 12,
      input: (
        <RHFCheckbox
          name="isOutsideUSA"
          label="My Business services countries outside of the USA/Canada."
          disabled={!isEditAllowed}
          dataTestId="isOutsideUSA-checkbox"
        />
      ),
    },
    {
      input: values.isOutsideUSA ? (
        <RHFInput
          id="countriesOutside"
          name="countriesOutside"
          label="Countries Serviced Outside USA/Canada"
          placeholder="Please enter the countries serviced outside of USA/Canada."
          multiline
          rows={6}
          fullWidth
          disabled={!isEditAllowed}
          focusViewColor={
            sectionFocus.field === "countriesOutside" ? "#6D9CF8" : ""
          }
        />
      ) : (
        <></>
      ),
      sm: 12,
      shouldAnimate: false,
    },
  ];

  return (
    <EditMerchantBaseModal
      title="Edit Merchant Info"
      open={open}
      handleCancel={handleCancel}
      actions={
        <>
          <Button
            size="medium"
            background="tertiary"
            onClick={handleCancel}
            disabled={isActionsDisabeled}
            sx={{
              padding: "8px 24px",
              ...(isMobileView && {
                width: "50%",
              }),
            }}
          >
            Discard changes
          </Button>
          <Button
            data-testid="save-button"
            size="medium"
            background="primary"
            type="submit"
            form="edit-merchant-info-form"
            disabled={isActionsDisabeled}
            sx={{
              padding: "8px 24px",
              ...(isMobileView && {
                width: "50%",
              }),
            }}
          >
            Save
          </Button>
        </>
      }
    >
      <FormProvider {...methods}>
        <Box
          component="form"
          id="edit-merchant-info-form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Grid container spacing={1}>
            {inputList.map(({ input, hidden, sm }, index) => (
              <Grid
                key={index}
                item
                xs={12}
                sm={sm || 6}
                sx={{ ...(hidden && { display: "none" }) }}
              >
                <FadeUpWrapper delay={100 * (index + 1)}>{input}</FadeUpWrapper>
              </Grid>
            ))}
          </Grid>
        </Box>
      </FormProvider>
    </EditMerchantBaseModal>
  );
});

const getDelay = (index: number) =>
  100 * ((index + 1) / 2 === 0 ? index - 1 : index);

export default EditMerchantInfoModal;
