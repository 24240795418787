import { WithMissingPermissionModule } from "@common/WithMissingPermissionModule";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { EditMerchantSection } from "@components/Merchants/MerchantPreview/components/atoms";
import MerchantBankAccounts from "@components/Merchants/MerchantPreview/components/BankAccounts/MerchantBankAccounts";
import MerchantBusinessOwners from "@components/Merchants/MerchantPreview/components/BusinessOwners/MerchantBusinessOwners";
import DocumentsSection from "@components/Merchants/MerchantPreview/components/DocumentsSection";
import MerchantHeader from "@components/Merchants/MerchantPreview/components/MerchantHeader";
import { IParsedData } from "@components/Merchants/MerchantPreview/data.types";
import useGetSectionItems from "@components/Merchants/MerchantPreview/hooks/useGetSectionItems";
import {
  MerchantPreviewHeaderAction,
  TMerchantDoublePanel,
} from "@components/Merchants/MerchantPreview/hooks/useMerchantPreview";
import NiceModal from "@ebay/nice-modal-react";
import { Stack, styled } from "@mui/material";
import { palette } from "@palette";
import { useAppSelector } from "@redux/hooks";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import MerchantAgreement from "@components/Merchants/MerchantPreview/sections/MerchantAgreement/MerchantAgreement";
import {
  CREATE_BUSINESS_PROFILE_MODAL,
  EDIT_ENTERPRISE_INFO_MODAL,
  EDIT_PRIMARY_ACCOUNT_HOLDER,
} from "modals/modal_names";
import { Dispatch, Fragment, SetStateAction, useMemo } from "react";
import useGetEnterpriseSections from "../hooks/useEnterpriseSections";
import EnterpriseFees from "./EnterpriseFees";
import EnterprisePreviewHeader, {
  EditEnterpriseHeaderHandlers,
} from "./EnterprisePreviewHeader";
import EnterpriseStats from "./EnterpriseStats";
import MCCSection from "./MCCSection";
import { FormType } from "@components/Merchants/CreateMerchantPanel/modals/CreateBusinessProfileModal";
import { EditBusinessHandler } from "@components/Merchants/CreateMerchantPanel/modals/CreateBusinessProfileModal";
import { ContentFocusWrapper } from "@components/Merchants/MerchantPreview/PreviewModalBody";
import MerchantPreviewBusinessProfile from "@components/Merchants/MerchantPreview/components/MerchantPreviewBusinessProfile";
import MerchantPrimaryAccountHolder from "@components/Merchants/MerchantPreview/components/MerchantPrimaryAccountHolder";
import OFACPreviewSection from "@components/Merchants/MerchantPreview/OFAC/components/OFACPreviewSection";
import { UnderwritingReportSummary } from "@components/Merchants/MerchantPreview/sections/StatusReview/UnderwritingReportSummary";
import { useComponentVisibilityOnStatus } from "@components/Merchants/MerchantPreview/sections/StatusReview/hooks/useComponentVisibilityOnStatus";
import { useUnderwriterPermissions } from "features/Permissions/AccessControl/hooks";
import { ofacArraySorter } from "@components/Merchants/MerchantPreview/helpers/parsers";
import {
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";
import { businessOwnerParser } from "@components/Settings/BusinessDetails/components/MerchantInfoSection/utils/merchantInfoParser";
import IdentityVerification from "@components/Merchants/MerchantPreview/components/IdentityVerification";
import { TPEPOwner } from "@components/Merchants/MerchantPreview/PEP/types";
import { selectIsAllowedByResource } from "@redux/slices/accessControl/accessControl";

type Props = {
  id: number;
  data: IParsedData;
  actions: MerchantPreviewHeaderAction[];
  handlers: EditEnterpriseHeaderHandlers;
  isControlModeAllowed: boolean;
  doublePanel: TMerchantDoublePanel;
  isAcquirerPortal?: boolean;
  isFirst: boolean;
  isLast: boolean;
  setDoublePanel: Dispatch<
    SetStateAction<
      "" | "manageUnderwriting" | "MerchantAgreement" | "ofac" | "pep"
    >
  >;
  selectedPEPOwner: TPEPOwner;
};

const EnterprisePanelBody = ({
  id,
  data,
  actions,
  handlers,
  isControlModeAllowed,
  isAcquirerPortal,
  setDoublePanel,
  doublePanel,
  isFirst,
  isLast,
  selectedPEPOwner,
}: Props) => {
  const { isDesktopView } = useCustomTheme();
  const { merchantInfoItems } = useGetEnterpriseSections(data);
  const { primaryAccountHolderItems, businessAddressItems } =
    useGetSectionItems(data, true);
  const { isViewUnderwritingAllowed } = useUnderwriterPermissions();

  const { status } = useComponentVisibilityOnStatus({
    data: data.status,
  });

  const hasNotBankAccountsListPermission = useAppSelector(
    (state) => state.app.permissions?.bank_accounts_list,
  );

  const hasNotPrincipalsPermission = useAppSelector(
    (state) => state.app.permissions?.[`get_merchant_${id}_principals`],
  );

  const hasNotDocumentsListPermission = useAppSelector(
    (state) => state.app.permissions?.["list-documents"],
  );

  const hasNotMerchantDataPermission = useAppSelector(
    (state) => state.app.permissions?.merchant_data,
  );

  const acquirerUpdateLegalEntityPermission = useAppSelector((state) =>
    selectIsAllowedByResource(
      state,
      composePermission(RESOURCE_BASE.ENTERPRISE, RESOURCE_BASE.LEGAL_ENTITY),
    ),
  );
  const legalEntityEditableByAcquirer = Boolean(
    isAcquirerPortal && acquirerUpdateLegalEntityPermission.update,
  );
  const acquirerUpdateEnterpriseInfoPermission = useAppSelector((state) =>
    selectIsAllowedByResource(state, RESOURCE_BASE.ENTERPRISE),
  );
  const EnterpriseEditableByAcquirer = Boolean(
    isAcquirerPortal && acquirerUpdateEnterpriseInfoPermission.update,
  );

  const hasEditPahPermission = useAccessControl({
    resource: composePermission(RESOURCE_BASE.ENTERPRISE, RESOURCE_BASE.OWNER),
    operation: OPERATIONS.UPDATE,
  });

  const editMerchantInfo = () =>
    NiceModal.show(EDIT_ENTERPRISE_INFO_MODAL, { id, data: data.merchantInfo });

  const editPrimaryAccountHolder = () =>
    NiceModal.show(EDIT_PRIMARY_ACCOUNT_HOLDER, {
      id,
      data: data.primaryAccountHolder,
      ownerAccId: data?.ownerId,
    });

  const editBusiness: EditBusinessHandler = (section) => {
    const modalProps = {
      merchantId: id,
      section,
      data: {
        ...data.businessProfile,
        address: data.businessAddress,
      },
      legalEntityID: data.businessProfile.id,
      isLegalEntityApproved:
        data.header.businessProfileStatus.status?.toLowerCase() === "approved",
      formType: FormType.CREATE_ENTERPRISE,
      isEdit: true,
    };
    NiceModal.show(CREATE_BUSINESS_PROFILE_MODAL, modalProps);
  };

  const isSoleProprietorship =
    data.businessProfile.businessType === "individual_sole_proprietorship";
  const handleOpenMerchantAgreement = () => {
    setDoublePanel("MerchantAgreement");
  };

  const hideIdentityVerification =
    !data?.primaryAccountHolder?.email ||
    !(
      data?.primaryAccountHolder?.owner?.statusName === "approved" ||
      data?.invitation?.inviteStatus === "joined"
    );
  const hasFullPAH =
    !!data.primaryAccountHolder.email &&
    !!data.primaryAccountHolder.firstName &&
    !!data.primaryAccountHolder.lastName;

  const sectionsList = useMemo(
    () => [
      {
        section: (
          <MerchantHeader
            isEnterprise
            {...data.header}
            merchantId={data.merchantInfo.merchantID}
          />
        ),
        delay: 100,
      },
      {
        section: <EnterpriseStats data={data.stats} />,
      },
      {
        section: (
          <WithMissingPermissionModule
            message="Underwriting informations hidden"
            notAuthorized={hasNotMerchantDataPermission}
            wrapperProps={{
              margin: 0,
              padding: 8,
            }}
          >
            <UnderwritingReportSummary
              status={status}
              data={data.status}
              merchantId={data.status.accID}
              underwritingScorePercentage={
                data.status.underwritingScorePercentage
              }
              handleManageUnderwriting={handlers.handleManageUnderwriting}
            />
          </WithMissingPermissionModule>
        ),
        hidden: !isViewUnderwritingAllowed,
      },
      {
        section: (
          <ContentFocusWrapper id="enterprise_info">
            <EditMerchantSection
              sectionTitle="Provider Info"
              editHandler={
                EnterpriseEditableByAcquirer ? editMerchantInfo : undefined
              }
              items={merchantInfoItems}
              isAcquirer={isAcquirerPortal}
              containerSx={{
                borderRadius: "12px",
                backgroundColor: palette.neutral.white,
                boxShadow: "1px 1px 15px 0px rgba(161, 175, 180, 0.10)",
                padding: "16px",
                border: "none",
              }}
            />
          </ContentFocusWrapper>
        ),
      },
      {
        section: (
          <OFACPreviewSection
            onOpen={handlers.handleOpenOFACPanel}
            id={id}
            disabled={
              (!data?.businessProfile?.taxID &&
                !data.businessProfile.ssn &&
                !hasFullPAH &&
                data?.businessOwnersList?.length < 1) ||
              !isDesktopView
            }
            isAcquirer={isAcquirerPortal}
            opened={doublePanel === "ofac"}
            lastCheckBP={data?.businessProfile?.ofac}
            lastCheckBO={ofacArraySorter(data?.businessOwnersList)[0]?.ofac}
            lastCheckPAH={data?.primaryAccountHolder?.ofac}
          />
        ),
      },
      {
        section: (
          <WithMissingPermissionModule
            message="Bank accounts list hidden"
            notAuthorized={hasNotBankAccountsListPermission}
            wrapperProps={{
              margin: 0,
              padding: "10px",
            }}
          >
            <ContentFocusWrapper id="bank_account">
              <MerchantBankAccounts
                data={data.bankAccountList}
                isAcquirer={isAcquirerPortal}
                merchantId={id}
                parentAccID={data.merchantInfo.enterpriseID}
                bankAccountSettings={data.bankAccountSettings}
                isEnterprise
              />
            </ContentFocusWrapper>
          </WithMissingPermissionModule>
        ),
      },
      {
        section: (
          <ContentFocusWrapper id="primary_account_holder">
            <Stack spacing={2}>
              <MerchantPrimaryAccountHolder
                data={data}
                pendingEmail={data.invitation.pendingEmail}
                id={id}
                isAcquirer={isAcquirerPortal}
                items={primaryAccountHolderItems}
                editHandler={
                  hasEditPahPermission ? editPrimaryAccountHolder : undefined
                }
                isEnterprise
              />

              {/* <EditMerchantSection
                sectionTitle="Primary Account Holder"
                editHandler={editPrimaryAccountHolder}
                items={primaryAccountHolderItems}
              />
              <SendInvitation
                pendingEmail={data.invitation.pendingEmail}
                data={data?.invitation}
                id={id}
              /> */}
            </Stack>
          </ContentFocusWrapper>
        ),
      },
      {
        section: (
          <ContentFocusWrapper id="identity-verification-section">
            <IdentityVerification id={id} data={data?.documents} isEnterprise />
          </ContentFocusWrapper>
        ),
        hidden: hideIdentityVerification,
      },
      {
        section: (
          <ContentFocusWrapper spacing id="business_profile">
            <MerchantPreviewBusinessProfile
              id={id}
              data={data}
              isAcquirer={isAcquirerPortal}
              editHandler={() => editBusiness("business_profile")}
              isEnterprise={true}
            />
          </ContentFocusWrapper>
        ),
      },
      {
        section: (
          <EditMerchantSection
            sectionTitle="Business address"
            editHandler={
              legalEntityEditableByAcquirer
                ? () => editBusiness("business_address")
                : undefined
            }
            items={businessAddressItems}
            isAcquirer={isAcquirerPortal}
          />
        ),
      },
      {
        section: (
          <WithMissingPermissionModule
            message="Business owners hidden"
            notAuthorized={hasNotPrincipalsPermission}
            wrapperProps={{
              margin: 0,
              padding: "10px",
            }}
          >
            <ContentFocusWrapper id="business_owners">
              <MerchantBusinessOwners
                merchantId={id}
                data={data.businessOwnersList}
                isAcquirer={isAcquirerPortal}
                legalEntityID={data.businessProfile.id}
                isApprovedLE={
                  data?.header?.businessProfileStatus?.status?.toLowerCase() ===
                  "approved"
                }
                selectedPEPOwner={selectedPEPOwner}
                selectBusinessOwner={handlers.selectPEPOwner}
                businessType={data.businessProfile.businessType}
                isEnterprise
                withPepStatus
                isIndividualSoleProprietorship={isSoleProprietorship}
                documents={data?.documents}
                primaryAccountHolder={businessOwnerParser(
                  data.primaryAccountHolder,
                )}
              />
            </ContentFocusWrapper>
          </WithMissingPermissionModule>
        ),
      },
      {
        section: (
          <EnterpriseFees
            id={id}
            data={data.fees}
            parentFees={data.parentFees}
          />
        ),
      },
      {
        section: (
          <MerchantAgreement
            id={id}
            isEnterprise
            data={data?.merchantAgreement}
            isAcquirer={isAcquirerPortal}
            handleOpenMerchantAgreement={handleOpenMerchantAgreement}
          />
        ),
      },
      {
        section: (
          <WithMissingPermissionModule
            message="Documents hidden"
            notAuthorized={hasNotDocumentsListPermission}
            wrapperProps={{
              margin: 0,
              padding: "10px",
            }}
          >
            <DocumentsSection
              id={id}
              data={data.documents}
              isEnterprise
              isAcquirer={isAcquirerPortal}
            />
          </WithMissingPermissionModule>
        ),
      },
      {
        section: (
          <MCCSection
            id={id}
            data={data.categoryCodes}
            parentCategories={data.parentCategories}
            isEnterpriseSidePane={true}
          />
        ),
      },
    ],
    [data],
  );

  return (
    <PanelContainer>
      {isDesktopView && (
        <EnterprisePreviewHeader
          actions={actions}
          handlers={handlers}
          isControlModeAllowed={isControlModeAllowed}
          nextPrevState={{ isFirst, isLast }}
        />
      )}
      {sectionsList.map(({ section, delay, hidden }, index) => (
        <Fragment key={index}>
          {!hidden && (
            <FadeUpWrapper delay={(delay || 100) + index * 80}>
              {section}
            </FadeUpWrapper>
          )}
        </Fragment>
      ))}
    </PanelContainer>
  );
};

const PanelContainer = styled(Stack)(({ theme }) => ({
  background: palette.neutral[5],
  width: "100%",
  height: "100%",
  alignItems: "stretch",
  flexDirection: "column",
  justifyContent: "flex-start",
  padding: "16px",
  gap: "16px",
  scrollBehavior: "smooth",

  [theme.breakpoints.up("sm")]: {
    overflowX: "hidden",
    overflowY: "auto",
    scrollbarGutter: "stable both-edges",
  },
  [theme.breakpoints.down("sm")]: {
    padding: 0,
    overflowY: "hidden",
    // "::-webkit-scrollbar-track": {
    //   marginBlock: "16px",
    // },
  },
}));
export default EnterprisePanelBody;
