import { SnackbarFile } from "@components/UploadFile/types";
import { useGetCurrentMerchantId } from "@hooks/common";
import { customInstance } from "@services/api";
import { Message } from "features/Minibuilders/Conversations/Modal/types";
import { useMutation, useQuery } from "react-query";

export type FormFieldsNotification = {
  merchant_message: string;
  files: SnackbarFile[];
  selectedId?: number;
};

export default function useFetchNotificationMessages({
  notificationMessageID,
}: {
  notificationMessageID?: number;
}) {
  const { merchantId } = useGetCurrentMerchantId();
  const messageListResponse = useQuery(
    ["fetch-message-list", notificationMessageID],
    async () => {
      const apiResponse = await customInstance({
        url: `/merchants/${merchantId}/threads/${notificationMessageID}/messages?sort=-createdAt`,
        method: "GET",
      });

      return apiResponse?.data as Message[];
    },
    {
      enabled: !!notificationMessageID,
    },
  );

  const attachmentsPareser = (attachments: SnackbarFile[]) => {
    return attachments.map((file) => ({
      id: file.id,
      name: file.name,
      size: file.size,
      URL: file.identifier,
    }));
  };

  const updatePostMutation = useMutation(
    (data: FormFieldsNotification) => {
      const isEdit = !!data?.selectedId;
      const url = isEdit
        ? `/merchants/${merchantId}/threads/${notificationMessageID}/messages/${data?.selectedId}`
        : `/merchants/${merchantId}/threads/${notificationMessageID}/messages`;
      const payload = {
        body: data?.merchant_message,
        attachments: attachmentsPareser(data?.files) || undefined,
      };
      return customInstance({
        url,
        method: isEdit ? "PATCH" : "POST",
        data: payload,
      });
    },
    {
      onSuccess: () => {
        messageListResponse?.refetch();
      },
    },
  );

  return { messageListResponse, updatePostMutation };
}
