import { format } from "date-fns";
import { capitalizeFirstLetter } from "@common/Table/helpers";
import {
  Transaction,
  SourceAccount,
  Method,
  ParsedTransaction,
  FeeItem,
} from "./transactions.types";
import { TransactionTableRow } from "./TransactionTable";
import { palette } from "@palette";
import { TransactionData } from "./data";
import { parseAmount } from "@utils/index";
import { number } from "prop-types";
import { isEmpty } from "lodash";
import { TransferTableRow } from "@components/Tranfers/utils";

export const sourceAccountParser = ({
  sourceAccount,
  customer,
}: Transaction) => {
  const type = sourceAccount.type as keyof SourceAccount;
  const isUser = type === "user";

  return {
    email: isUser ? sourceAccount?.user?.email || "" : customer?.email || "",
    firstName: isUser
      ? sourceAccount?.user?.firstName
        ? capitalizeFirstLetter(sourceAccount?.user?.firstName)
        : ""
      : sourceAccount?.merchant?.name || "",
    lastName: sourceAccount?.user?.lastName
      ? capitalizeFirstLetter(sourceAccount?.user?.lastName)
      : "",
    name: sourceAccount?.merchant?.name || "",
    phoneNumber: isUser
      ? sourceAccount?.user?.phoneNumber || ""
      : sourceAccount?.merchant?.phoneNumber || "",
    slug: sourceAccount?.merchant?.slug || "",
    type: sourceAccount?.merchant?.type || "",
    parentName: sourceAccount?.merchant?.parentName || "",
    parentAccID: sourceAccount?.merchant?.parentAccID || "",
  };
};

export const sourceMethodParser = ({ sourceMethod }: Transaction) => {
  const type = sourceMethod?.type as keyof Method;
  const isCard = type === "card";

  return {
    id: sourceMethod?.bankAccount?.id || "",
    name: sourceMethod?.bankAccount?.name || "",
    type: sourceMethod?.bankAccount?.type || "",
    nickname: sourceMethod?.bankAccount?.nickname || "",
    bankName: sourceMethod?.bankAccount?.bankName || "",
    numberLast4: isCard
      ? sourceMethod?.card?.numberLast4 || ""
      : sourceMethod?.bankAccount?.numberLast4 || "",
    brand: sourceMethod?.card?.brand || "",
    cardholderName: sourceMethod?.card?.cardholderName || "",
    expiryMonth: sourceMethod?.card?.expiryMonth || 0,
    expiryYear: sourceMethod?.card?.expiryYear || 0,
    isDebit: sourceMethod?.card?.isDebit || false,
  };
};

export function formatDate(
  timestamp: number,
  dateFormat = "MMMM dd yyyy HH:mm",
) {
  return format(new Date(timestamp), dateFormat);
}

export function getSourceAccount(
  row?: TransactionTableRow | ParsedTransaction,
) {
  return {
    ...row?.customer,
    fullName: `${row?.sourceAccount?.user?.firstName} ${row?.sourceAccount?.user?.lastName}`,
    card: row?.sourceMethod?.method,
  };
}

export const textColor = (row: any, baseColor: any) => {
  const isPending = row.displayStatus === "Pending";
  const isVoided = row?.displayStatus === "Voided";
  const isAuthorized = row?.displayStatus === "Authorized";

  return isPending || isVoided || isAuthorized
    ? palette.neutral[60]
    : baseColor;
};

export const getAmountFormatAndStyle = (row: any) => {
  const isPending = row?.displayStatus === "Pending";
  const isVoided = row?.displayStatus === "Voided";
  const isAuthorized = row?.displayStatus === "Authorized";
  const isTransfer = row?.displayType === "Money Transfer";

  const isError = row?.displayStatus === "Error";

  let amount = row?.balanceAdjustment;

  if (isTransfer && isError) {
    amount = 0;
  }

  const formattedAmount = parseAmount(amount);

  if (amount > 0)
    return {
      amount: isVoided ? formattedAmount : `+${formattedAmount}`,
      style: {
        color:
          isPending || isVoided || isAuthorized
            ? palette.gray[200]
            : palette.success.main,
      },
    };

  return {
    amount: formattedAmount,
    style: {
      color: isPending ? palette.gray[200] : "inherit",
    },
  };
};

export const mapCardType = (type: string, isDebit: boolean) => {
  if (type === "card") {
    if (isDebit) {
      return "Debit Card";
    } else {
      return "Credit Card";
    }
  }

  return type;
};

export const parseRow = (row: ParsedTransaction) => {
  const customerFirstname = row.customer?.firstName
    ? capitalizeFirstLetter(row.customer.firstName)
    : "";
  const customerLastname = row.customer?.lastName
    ? capitalizeFirstLetter(row.customer.lastName)
    : "";

  const factor =
    row.type === "chargeback" ||
    row.type === "refund" ||
    row.type === "transfer"
      ? -1
      : 1;

  const time = row?.settledAt || row?.createdAt;

  return {
    ...row,

    createdAtTimestamp: row.createdAt,
    amount: (row.cost / 100) * factor,
    endingBalance: row.endingBalance ? row?.endingBalance / 100 : 0,
    balanceAdjustment: row.balanceAdjustment ? row?.balanceAdjustment / 100 : 0,
    inflow: row.inflow / 100,
    outflow: row.outflow / 100,
    fees: row.fees / 100,
    cost: row.cost / 100,
    charged: row.charged / 100,
    sourceAccount: row.sourceAccount,
    recipientAccountName: row?.destinationMethod?.bankAccount?.name,
    sourceMethod: row.sourceMethod,
    id: row.id,
    title: `${capitalizeFirstLetter(
      row.sourceAccount.user?.firstName,
    )} ${capitalizeFirstLetter(row.sourceAccount.user?.lastName)}`,
    date: row.createdAt,
    time: formatDate(time * 1000, "MMM. dd yyyy HH:mm"),
    type: mapCardType(
      row.sourceMethod.type,
      row.sourceMethod.method?.isDebit ?? false,
    ),
    detailedDescription: row.description?.split(","),
    customerName: `${customerFirstname} ${customerLastname}`,
    status: row.displayStatus,
    paymentDetails: {
      nameOnCard: `${capitalizeFirstLetter(
        row.sourceAccount.user?.firstName,
      )} ${capitalizeFirstLetter(row.sourceAccount.user?.lastName)}`,
      merchantTaxId: row.id,
    },
    originalTransactionID: row.originalTransactionID,
    firstCheckedAt: row.firstCheckedAt,
    firstCheckedBy: row.firstCheckedBy,
    transactionType: row.type,
    isBlocked: row.isBlocked,
    blockedReason: row.blockedReason,
    originalTransactionIsBlocked: row.originalTransactionIsBlocked,
  };
};

type TStatus = {
  displayStatus: string;
};

export const getStatus = (rowData: TransactionTableRow): TStatus => {
  if (isEmpty(rowData)) return { displayStatus: "" };
  if (
    rowData?.displayType === "Money Transfer" &&
    rowData?.processingState === "failed"
  ) {
    return { displayStatus: "Error" };
  }

  return { displayStatus: rowData.displayStatus };
};

export const getType = (rowData: TransactionData | TransactionTableRow) => {
  if (rowData?.status === "Chargeback Reversal") return rowData.type;
  return rowData.displayType;
};

export const parseTransfer = (data: TransferTableRow) => {
  return {
    createdAtTimestamp: data.createdAt,
    amount: data.cost / 100,
    endingBalance: data.endingBalance ? data?.endingBalance / 100 : 0,
    fees: data.fees / 100,
    cost: data.cost / 100,
    charged: data.charged / 100,
    id: data.objID,
    date: data.createdAt,
    time: formatDate(data.createdAt * 1000, "MMM. dd yyyy HH:mm"),
    status: data.displayStatus,
    transactionType: data.transactionTypeName,
    balanceAdjustment: data?.balanceAdjustment
      ? data?.balanceAdjustment / 100
      : 0,
    errorReason: data?.errorReason,
    declinedReason: data?.declinedReason,
    canceledReason: data?.canceledReason,
    merchantName: data?.merchAccName,
    recipientAccountName: data?.recipientAccountName,
    parentName: data?.parentAccName,
    displayType: "Money Transfer",
    displayStatus: data?.displayStatus,
    processingState: data?.processingSatetName,
    updatedAt: data?.updatedAt,
    destinationAccount: {
      id: data?.merchAccID,
      merchant: {
        name: data?.merchAccName,
        parentName: data?.parentAccName,
        parentAccID: data?.parentAccID,
      },
    },
    destinationMethod: {
      bankAccount: {
        bankName: data?.recipientAccountBankName,
        numberLast4: data?.recipientAccountNumberLast4,
        RoutingNumber: data?.recipientAccountRoutingNumber,
      },
    },
    sourceAccount: {
      id: data?.merchAccID,
    },
  };
};

export const accumulateFees = (
  feeItems: FeeItem[],
  condition: (cond: FeeItem) => boolean,
) => {
  const accumulated = feeItems?.reduce((acc: number, curr: FeeItem) => {
    if (condition(curr)) {
      return acc + curr.price / 100;
    }
    return acc;
  }, 0);

  return parseAmount(accumulated || 0);
};
