import * as React from "react";
import { isBefore, subDays, getDaysInMonth, isSameDay } from "date-fns";
// @mui
import Box from "@mui/material/Box";
// form
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
// components
import { ConditionallyRender } from "./FilterDateRenderer";
import FilterMenu from "./FilterMenu";
import FilterButton from "./FilterButton";
import { RHFSelect } from "@common/Select";
import { Button } from "@common/Button";
import { Text } from "@common/Text";
// types
import { Options } from "types/data.types";
// redux
import { useAppDispatch } from "@redux/hooks";
import {
  ActionCreatorWithPayload,
  ActionCreatorWithoutPayload,
} from "@reduxjs/toolkit";
// localization
import { useTranslation } from "react-i18next";
import { namespaces } from "localization/resources/i18n.constants";

type IFormValues = {
  modifier: string;
  count: number;
  dayMonthYear: string;
  startDate: number | Date;
  endDate: number | Date;
  date: number | Date;
};

export default function FilterWithDate({
  title,
  options,
  apply,
  disable,
  filters,
}: {
  title: string;
  options: Options[];
  apply: ActionCreatorWithPayload<any, string>;
  disable: ActionCreatorWithoutPayload<string>;
  filters: {
    [key: string]: any;
  };
}) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(namespaces.common);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const schema = Yup.object({
    count: Yup.number()
      .max(3000, "")
      .transform((value) => (isNaN(value) || !value ? null : value))
      .required("Count is required")
      .nullable(),
    date: Yup.date()
      .required("Date is required")
      .nullable()
      .typeError("Insert a valid ${type}"),
    startDate: Yup.date()
      .required("Start date is required")
      .typeError("Insert a valid ${type}")
      .nullable()
      .test("isBefore-or-same-as-end-date", "", function (value: any) {
        const { endDate }: any = this.parent;
        return isSameDay(value, endDate) || isBefore(value, endDate);
      }),
    endDate: Yup.date()
      .required("End date is required")
      .typeError("Insert a valid ${type}")
      .nullable()
      .test("is-greater-or-equal-than-start-date", "", function (value: any) {
        const { startDate } = this.parent;
        return isSameDay(startDate, value) || isBefore(startDate, value);
      }),
  });

  const NOW = React.useRef(new Date());
  const oneMonthAgo = React.useRef(
    subDays(new Date(), getDaysInMonth(NOW.current)),
  );

  const defaultValues = {
    modifier: options[0].value,
    dayMonthYear: "days",
    count: 7,
    startDate: oneMonthAgo.current,
    endDate: NOW.current,
    date: oneMonthAgo.current,
  };
  const open = Boolean(anchorEl);

  const methods = useForm<IFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
    reValidateMode: "onChange",
    mode: "onChange",
  });
  const { watch, reset } = methods;

  const values = watch();

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget.parentElement);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDisableFilter = () => {
    setAnchorEl(null);
    dispatch(disable());
  };

  const onSubmit: SubmitHandler<IFormValues> = (formValues) => {
    setAnchorEl(null);
    dispatch(apply(formValues));
  };

  React.useEffect(() => {
    reset({
      ...defaultValues,
      modifier: values.modifier,
    });
  }, [values.modifier]);

  React.useEffect(() => {
    return () => {
      dispatch(disable());
    };
  }, []);

  return (
    <>
      <FilterButton
        title={title}
        onClick={handleOpenMenu}
        active={filters[title].length > 0}
        onDisableFilter={handleDisableFilter}
      >
        {filters[title].join(" ")}
      </FilterButton>
      <FilterMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <FormProvider {...methods}>
          <Box
            mb={2}
            component="form"
            id="date-filter"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <Text
              variant="headline"
              sx={{
                marginBottom: 2,
                fontWeight: 350,
                color: "#575353",
                span: { textTransform: "capitalize" },
              }}
            >
              Filter by <span>{title}</span>
            </Text>
            <RHFSelect name="modifier" options={options} fullWidth />
            <ConditionallyRender value={values.modifier} />
          </Box>
        </FormProvider>
        <Button
          type="submit"
          form="date-filter"
          variant="primary"
          size="small"
          fullWidth
          data-testid="date-filter-apply-button"
        >
          {t("filters.apply", { ns: namespaces.common })}
        </Button>
      </FilterMenu>
    </>
  );
}
