export const getTextFieldOverrides = (palette: any, basePallete: any) => ({
  styleOverrides: {
    root: {
      "& .MuiInputBase-root": {
        borderRadius: "12px",
        padding: "7.5px 12px",
        fontSize: "14px",
        boxShadow: "none",
        "& input": {
          padding: 0,
          fontFamily: "Give Whyte",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "17px",
          margin: "5.5px 0",
          "&::placeholder": {
            color: palette.text.secondary,
            opacity: 0.8,
          },
        },
      },

      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
        borderWidth: "1px",
      },
      "& .MuiInputBase-root.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline":
        {
          border: `solid 1px ${basePallete.primitive.error[100]}`,
        },
      "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
        backgroundColor: palette.surface.secondary,
        border: `1px solid ${palette.border.secondary}`,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        zIndex: "-1",
      },
      "& .Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
        border: "solid 1.5px transparent",
        backgroundImage: `linear-gradient(${palette.surface.primary}, ${palette.surface.primary}), ${basePallete.gradient["aqua-horizon"].border}`,
        backgroundOrigin: "border-box",
        backgroundClip: "padding-box, border-box",
      },
    },
  },
});
