import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { TPrimaryAccountHolder } from "../data.types";
import { NameInput, SSNInput } from "@common/BusinessProfileInputs";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { matchIsValidTel } from "mui-tel-input";
import { RHFInput, RHFTelInput } from "@common/Input";
import { Button } from "@common/Button";
import { useEffect } from "react";
import { useUpdateMerchantInfo } from "../hooks/useUpdateMerchantInfo";
import EditMerchantBaseModal from "../components/EditMerchantBaseModal";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { BirthDatePicker } from "@common/DatePickers";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { RHFSelect } from "@common/Select";
import { ProvinceInput, ZipCodeInput } from "@common/AddressInputs";
import useCountryCodes from "@hooks/common/useCountryCodes";
import { selectMerchantPreview } from "@redux/slices/merchantPreview";
import { useAppSelector } from "@redux/hooks";
import { RHFStreetAddress } from "@common/RHFStreetAddress";
import { NonResidentInputs } from "@common/NonResidentInput/NonResidentInputs";
import {
  dateOfBirthSchema,
  nonResidentInputsSchema,
} from "@utils/validation.helpers";
import { subYears } from "date-fns";
import {
  MAXIMUM_REQUIRED_AGE,
  MINIMUM_REQUIRED_AGE,
} from "@constants/constants";
import { WithTooltipWrapper } from "@common/Menu/NewDropdownMenu";
import { useAccessControl } from "features/Permissions/AccessControl";
import RESOURCE_BASE, {
  EDIT_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import { checkPortals } from "@utils/routing";

type FormInputs = {
  firstName: string;
  lastName: string;
  isNotUSResident: boolean;
  citizenship: string;
  isNotResidentInCitizenshipCountry: boolean;
  countryOfResidence: string;
  email: string;
  phoneNumber: string;
  dob: Date | string;
  ssn: string;
  address: {
    country: string;
    city: string;
    address: string;
    state: string;
    zip: string;
  };
};

const schema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Enter account holder email"),
  phoneNumber: Yup.string()
    .nullable()
    .test(
      "is-valid-or-special-case",
      "Please enter a valid phone number",
      function (value) {
        if (value == null || value === "" || value === "+" || value === "+1") {
          return true;
        }
        return matchIsValidTel(value);
      },
    ),
  dob: Yup.date()
    .typeError("please provide a valid date")
    .min(subYears(new Date(), MAXIMUM_REQUIRED_AGE), "Must be 100 or less")
    .max(subYears(new Date(), MINIMUM_REQUIRED_AGE), `Must be 18 or older`)
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value)),
  ...nonResidentInputsSchema,
});

const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  dob: "",
  isNotUSResident: false,
  citizenship: "",
  isNotResidentInCitizenshipCountry: false,
  countryOfResidence: "",
};

type Props = {
  id: number;
  data: TPrimaryAccountHolder;
  ownerAccId: number;
};

const EditPrimaryAccountHolder = NiceModal.create(
  ({ id, data, ownerAccId }: Props) => {
    const { sectionFocus } = useAppSelector(selectMerchantPreview);
    const modal = useModal();
    const open = modal.visible;
    const { isMobileView } = useCustomTheme();
    const { handleSubmit, isLoading } = useUpdateMerchantInfo(id);
    const { selectCountriesList } = useCountryCodes();
    const { isAcquirerEnterprises } = checkPortals();

    const isOFACMatch = data?.ofac?.lastCheckStatusName === "confirmed_match";

    const isEditAllowed = useAccessControl({
      resource: isAcquirerEnterprises
        ? RESOURCE_BASE.ENTERPRISE
        : RESOURCE_BASE.MERCHANT,
      operation: OPERATIONS.UPDATE,
    });

    const methods = useForm<FormInputs>({
      resolver: yupResolver(schema),
      defaultValues,
    });

    const {
      reset,
      formState: { isDirty },
      watch,
    } = methods;

    const values = watch();

    useEffect(() => {
      reset(
        data
          ? {
              ...data,
              dob: data.dateOfBirth ? new Date(data.dateOfBirth * 1000) : "",
              phoneNumber: `+${data.phoneNumber}`,
              isNotUSResident: !!data?.citizenship,
              citizenship: data?.citizenship || "",
              isNotResidentInCitizenshipCountry: !!data?.countryOfResidence,
              countryOfResidence: data?.countryOfResidence || "",
              email: data?.owner?.email || data?.email,
            }
          : defaultValues,
      );
    }, [id, data]);

    const handleCancel = () => {
      reset();
      modal.hide();
    };

    const onSubmit: SubmitHandler<FormInputs> = async (data) => {
      handleSubmit("primary_account_holder", data, handleCancel, ownerAccId);
    };

    const tooltipMessage = !isEditAllowed
      ? EDIT_DENY_MESSAGE
      : "This edit is blocked by a confirmed match on OFAC";

    const disabledField = isOFACMatch || !isEditAllowed

    const inputList = [
      {
        input: (
          <Text
            color={palette.filled.red}
            sx={{
              padding: "2px 16px",
              mb: "4px",
              borderRadius: "16px",
              background: palette.tag.error.bg,
              width: "fit-content",
            }}
          >
            OFAC Confirmed match
          </Text>
        ),
        hidden: !isOFACMatch,
        sm: 12,
      },
      {
        input: (
          <NameInput
            id="firstName"
            name="firstName"
            label="First name"
            placeholder="First name"
            focusViewColor={sectionFocus.field === "firstName" ? "#6D9CF8" : ""}
            disabled={disabledField}
          />
        ),
        hasTooltip: true,
      },

      {
        input: (
          <NameInput
            id="lastName"
            name="lastName"
            label="Last name"
            placeholder="Last name"
            focusViewColor={sectionFocus.field === "lastName" ? "#6D9CF8" : ""}
            disabled={disabledField}
          />
        ),
        hasTooltip: true,
      },
      {
        input: (
          <RHFInput
            id="email"
            name="email"
            label="Email"
            placeholder="Email"
            fullWidth
            focusViewColor={sectionFocus.field === "email" ? "#6D9CF8" : ""}
            disabled={disabledField}
          />
        ),
        hasTooltip: true,
      },
      {
        input: (
          <RHFTelInput
            id="phoneNumber"
            name="phoneNumber"
            label="Contact number"
            fullWidth
            focusViewColor={
              sectionFocus.field === "phoneNumber" ? "#6D9CF8" : ""
            }
            disabled={disabledField}
          />
        ),
        hasTooltip: true,
      },
      {
        input: (
          <BirthDatePicker
            id="dob"
            name="dob"
            fullWidth
            placeholder="MM/DD/YYYY"
            label="Date of Birth"
            focusViewColor={sectionFocus.field === "dob" ? "#6D9CF8" : ""}
            disabled={disabledField}
          />
        ),
        sm: 12,
        hasTooltip: true,
      },
      {
        input: (
          <NonResidentInputs
            inputsDisabled={disabledField}
            hasTooltip
            show={isOFACMatch}
            tooltipMessage={tooltipMessage}
          />
        ),
        sm: 12,
      },
      {
        input: (
          <SSNInput
            id="ssn"
            name="ssn"
            label="SSN"
            placeholder="xxx xx xxxx"
            focusViewColor={sectionFocus.field === "ssn" ? "#6D9CF8" : ""}
            disabled={disabledField}
          />
        ),
        hidden: true,
        hasTooltip: true,
      },
      {
        input: (
          <Text fontSize="18px" mt={2} mb={1.5} color={palette.neutral[750]}>
            Primary Account Holder Address
          </Text>
        ),
        hidden: true,
      },
      {
        sm: 12,
        input: (
          <RHFSelect
            id="contry"
            name="address.country"
            label="Country"
            placeholder="Country"
            disabled
            options={selectCountriesList}
            focusViewColor={sectionFocus.field === "contry" ? "#6D9CF8" : ""}
          />
        ),
        hidden: true,
      },
      {
        sm: 12,
        input: (
          <RHFStreetAddress
            id="line1"
            name="address.line1"
            label="Street address"
            placeholder="Enter Street Address..."
            fullWidth
            focusViewColor={sectionFocus.field === "line1" ? "#6D9CF8" : ""}
            disabled={disabledField}
          />
        ),
        hidden: true,
        hasTooltip: true,
      },
      {
        sm: 4,
        input: (
          <RHFInput
            id="city"
            name="address.city"
            label="City"
            placeholder="Enter city..."
            fullWidth
            focusViewColor={sectionFocus.field === "city" ? "#6D9CF8" : ""}
            disabled={disabledField}
          />
        ),
        hidden: true,
        hasTooltip: true,
      },
      {
        sm: 4,
        input: (
          <ProvinceInput
            id="state"
            name="address.state"
            isUS={values.address?.country === "US"}
            focusViewColor={sectionFocus.field === "state" ? "#6D9CF8" : ""}
            disabled={disabledField}
          />
        ),
        hidden: true,
        hasTooltip: true,
      },
      {
        sm: 4,
        input: (
          <ZipCodeInput
            id="zip"
            name="address.zip"
            label="ZIP"
            placeholder="Enter Zip Code"
            letterSpacing="4px"
            focusViewColor={sectionFocus.field === "zip" ? "#6D9CF8" : ""}
            disabled={disabledField}
          />
        ),
        hidden: true,
        hasTooltip: true,
      },
    ];

    return (
      <EditMerchantBaseModal
        title="Edit Primary Account Holder"
        open={open}
        handleCancel={handleCancel}
        actions={
          <>
            {isDirty ||
              (isMobileView && (
                <Button
                  size="medium"
                  background="tertiary"
                  onClick={handleCancel}
                  sx={{
                    padding: "8px 24px",
                    ...(isMobileView && {
                      width: "50%",
                    }),
                  }}
                >
                  {isMobileView ? "Discard changes" : "Cancel"}
                </Button>
              ))}
            <Button
              size="medium"
              background="primary"
              type="submit"
              form="edit-primary-account-holder"
              disabled={!isDirty || isLoading}
              sx={{
                padding: "8px 24px",
                ...(isMobileView && {
                  width: "50%",
                }),
              }}
            >
              Save
            </Button>
          </>
        }
      >
        <FormProvider {...methods}>
          <Box
            component="form"
            id="edit-primary-account-holder"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <Grid container rowSpacing={1} columnSpacing={2}>
              {inputList
                .filter(({ hidden }) => !hidden)
                .map(({ input, sm, hasTooltip }, index) => (
                  <Grid key={index} item xs={12} sm={sm || 6}>
                    <WithTooltipWrapper
                      hasTooltip={!!hasTooltip}
                      tooltipProps={{
                        show: isOFACMatch || !isEditAllowed,
                        message: tooltipMessage,
                      }}
                    >
                      <FadeUpWrapper delay={100 * (index + 1)}>
                        {input}
                      </FadeUpWrapper>
                    </WithTooltipWrapper>
                  </Grid>
                ))}
            </Grid>
          </Box>
        </FormProvider>
      </EditMerchantBaseModal>
    );
  },
);

export default EditPrimaryAccountHolder;
