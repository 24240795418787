import {
  BulkInviteActions,
  BulkInviteContainer,
  BulkUploadHeader,
  InviteListContainer,
  TextItem,
  InvitesLoadingState,
} from "./panel.atoms";
import useBulkInvite from "../hooks/useBulkInvite";
import NiceModal from "@ebay/nice-modal-react";
import { BULK_IMPORT_MODAL } from "modals/modal_names";
import BulkInviteEmptyState from "./BulkInviteEmptyState";
import InvitesListItem from "./InvitesListItem";
import { Virtuoso } from "react-virtuoso";
import InviteListItemLayout from "./InviteListItemLayout";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { COLUMN_NAMES } from "../constants";
import { checkPortals } from "@utils/routing";
import ProviderSelect from "./ProviderSelect";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { useGetCurrentMerchantId } from "@hooks/common";
import { SortingOrder } from "@redux/types/sort";
import SortingButton from "@common/Button/SortingButton";
import InvitingComponent from "./InvitingComponent";
import { Text } from "@common/Text";

const BulkInviteContent = () => {
  const { isEnterprisePortal } = checkPortals();
  const { merchantId } = useGetCurrentMerchantId();
  const initialProviderId = isEnterprisePortal ? merchantId : 0;

  const {
    data,
    isLoading,
    areAllSelected,
    actions,
    importedRowsInit,
    toggleSelectItem,
    toggleSelectAll,
    onAddInvite,
    editInvite,
    entryIsUnique,
    providerId,
    setProviderId,
    sorting,
    isInvitingMerchants,
    handleCheckInviteDone,
  } = useBulkInvite(initialProviderId);

  const showUploadModal = () =>
    NiceModal.show(BULK_IMPORT_MODAL, {
      handleImport: importedRowsInit,
    });

  const isEmpty = data.length === 0;

  return (
    <BulkInviteContainer paddingReduction={isEnterprisePortal}>
      {!isEnterprisePortal && (
        <FadeUpWrapper
          delay={50}
          containerProps={{ sx: { marginBottom: "20px" } }}
        >
          <ProviderSelect
            providerId={providerId}
            setProviderId={setProviderId}
            isDisabled={isInvitingMerchants}
          />
        </FadeUpWrapper>
      )}
      {isInvitingMerchants && !isLoading && (
        <Text
          borderRadius="12px"
          fontSize="14px"
          bgcolor="#FFF2E9"
          color="#C3641D"
          p="12px 16px"
          my="16px"
        >
          {warningText}
        </Text>
      )}
      <BulkUploadHeader
        total={isInvitingMerchants ? 0 : data.length}
        handleUpload={showUploadModal}
        onAddInvite={onAddInvite}
        isDisabled={isInvitingMerchants}
      />

      {!isEmpty && !isInvitingMerchants && (
        <InvitesListHeader
          checked={areAllSelected}
          onSelect={toggleSelectAll}
          disabled={isLoading || isInvitingMerchants}
          sorting={sorting}
        />
      )}

      <InviteListContainer data-testid="invite-list-container">
        {isEmpty && !isLoading && !isInvitingMerchants && (
          <BulkInviteEmptyState handleUpload={showUploadModal} />
        )}
        {isLoading && <InvitesLoadingState />}

        {isInvitingMerchants && !isLoading ? (
          <InvitingComponent onClick={handleCheckInviteDone} />
        ) : (
          <>
            {" "}
            {!isEmpty && !isLoading && (
              <Virtuoso
                style={{
                  height: "100%",
                  overflowX: "hidden",
                  overflowY: "scroll",
                }}
                data={data}
                initialItemCount={data.length}
                totalCount={data.length}
                itemContent={(index, element) => {
                  if (!Array.isArray(element)) return null;
                  const [key, value] = element;
                  const { merchantName } = value;
                  const isUnique = entryIsUnique(merchantName);

                  return (
                    <InvitesListItem
                      itemKey={key}
                      {...value}
                      isUnique={isUnique}
                      isDisabled={isInvitingMerchants}
                      onSelect={() => toggleSelectItem(key)}
                      onClick={() => editInvite(key, value)}
                    />
                  );
                }}
              />
            )}
          </>
        )}
      </InviteListContainer>

      <BulkInviteActions actions={actions} />
    </BulkInviteContainer>
  );
};

export default BulkInviteContent;

const InvitesListHeader = ({
  checked,
  disabled,
  onSelect,
  sorting,
}: {
  checked: boolean;
  disabled: boolean;
  onSelect: VoidFunction;
  sorting: {
    attribute: string;
    order: SortingOrder;
    toggleSorting: (attribute?: string) => void;
  };
}) => {
  const { isMobileView } = useCustomTheme();
  const { attribute, order, toggleSorting } = sorting;

  return (
    <InviteListItemLayout
      checked={checked}
      disabled={disabled}
      name="select-all"
      onSelect={onSelect}
      dataTestId="bulk-invite-sortable-header"
      sx={{
        container: {
          // adjust width to align items and compensate the scrollbar
          marginRight: "10px",
        },
      }}
    >
      {isMobileView ? (
        <TextItem>{COLUMN_NAMES.join(" / ")}</TextItem>
      ) : (
        COLUMN_NAMES.map((name) => {
          const handleSorting = () => {
            if (disabled) return;
            toggleSorting(name);
          };
          return (
            <TextItem
              key={name}
              gap="8px !important"
              sx={{ userSelect: "none", flexGrow: 1, flexBasis: 0 }}
            >
              {name}{" "}
              <SortingButton
                active={attribute === name}
                order={order}
                onClick={handleSorting}
              />
            </TextItem>
          );
        })
      )}
    </InviteListItemLayout>
  );
};

const warningText = `Processing may take some time. You cannot perform another action until
          it's complete. We'll notify you once it's done.`;
