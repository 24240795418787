import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  replaceArrayTypeValues,
  replaceValues,
  withAppendedKey,
} from "@services/filtering";
import { filteringConst } from "@services/filtering/filtering.constants";
import { AmountKeys } from "@services/filtering/filtering.types";
import { BankAccountType } from "../enterprise/merchants";
import { toUnixDateFormat } from "@utils/date.helpers";
import { PEPStatusType } from "@components/Merchants/MerchantPreview/PEP/types";
import createDateFilter, { TDate } from "@common/Filter/utils/createDateFilter";

type BusinessAddressType = {
  country: string;
  street: string;
  city: string;
  province: string;
  zip: string;
};

type BusinessOwnerType = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  ssn?: string;
  ein?: string;
  tinType?: "ein" | "ssn";
  dob?: number | Date | null;
  phone: string;
  ownership: string;
  useBusinessAddress: boolean;
  businessAddress?: BusinessAddressType;
  pepStatusName: PEPStatusType;
  createdAt?: number;
};

type AcquirerEnterprisesType = {
  id: string | number;
  created: string;
  title: string;
  imageURL: string;
  processed: number;
  transactions: number;
  amount: number;
  status: string;
  merchants: number;
  pendingMerchants: number;
  approvedMerchants: number;
};

export type EntepriseQueryType = {
  created: string;
  processed: string;
  transactions: string;
  merchants: string;
  status: string;
};

export interface EnterprisesState {
  query: EntepriseQueryType;
  names: {
    created: (string | number)[];
    processed: (string | number)[];
    transactions: (string | number)[];
    merchants: number[];
    status: string[];
    type: string[];
    "Underwriting Status": string[];
    "Instant Fundraising Status": string[];
  };
  watchlist: AcquirerEnterprisesType[];
  businessOwners: {
    list: BusinessOwnerType[];
    removedList: number[];
    addedList: number[];
  };
  bankAccounts: {
    list: BankAccountType[];
    removedList: number[];
    addedList: number[];
  };
  isDisabledSubmit: boolean;
}

type SwitchAndSelect = {
  type: "status";
  value: string;
};

type Amount = {
  title: "processed" | "transactions" | "merchants";
  values: {
    modifier: string;
    amount: number;
    amountOne: number;
    amountTwo: number;
  };
};

const initialState: EnterprisesState = {
  query: {
    created: "",
    processed: "",
    transactions: "",
    merchants: "",
    status: "",
  },
  names: {
    processed: [],
    transactions: [],
    merchants: [],
    type: [],
    created: [],
    status: [],
    "Underwriting Status": [],
    "Instant Fundraising Status": [],
  },
  bankAccounts: {
    list: [],
    removedList: [],
    addedList: [],
  },
  isDisabledSubmit: false,
  watchlist: [],
  businessOwners: {
    list: [],
    addedList: [],
    removedList: [],
  },
};

const enterprisesSlice = createSlice({
  name: "enterprises",
  initialState,
  reducers: {
    addFilter: (
      state: EnterprisesState,
      action: PayloadAction<SwitchAndSelect>,
    ) => {
      const { value, type } = action.payload;
      state.query[type] = replaceArrayTypeValues(
        filteringConst[type as "type"].key,
        ...state.names[type].concat(value),
      );
      state.names[type] = [...state.names[type], value];
    },
    removeFilter: (
      state: EnterprisesState,
      action: PayloadAction<SwitchAndSelect>,
    ) => {
      const { value, type } = action.payload;
      state.names[type] = state.names[type].filter((val) => val !== value);
      state.query[type] = replaceArrayTypeValues(
        filteringConst[type as "type"].key,
        ...state.names[type],
      );
    },
    addAmountFilter: (
      state: EnterprisesState,
      action: PayloadAction<Amount>,
    ) => {
      const { modifier, amount, amountOne, amountTwo } = action.payload.values;
      state.names[action.payload.title] = [amountOne, amountTwo];
      if (modifier.includes("greater than") || modifier.includes("less than")) {
        state.names[action.payload.title] = [+modifier, amount];
        state.query[action.payload.title] = replaceValues(
          withAppendedKey.amount[modifier as AmountKeys],
          amount,
        );
      } else {
        state.names[action.payload.title] = [amountOne, amountTwo];
        state.query[action.payload.title] = replaceValues(
          withAppendedKey.amount[modifier as AmountKeys],
          amountOne,
          amountTwo,
        );
      }
    },
    disableAmountFilter: (
      state: EnterprisesState,
      action: PayloadAction<"processed" | "transactions" | "merchants">,
    ) => {
      (state.names as any)[action.payload] = [];
      state.query[action.payload] = "";
    },
    disableFilter: (
      state: EnterprisesState,
      action: PayloadAction<{
        type: "type";
      }>,
    ) => {
      state.names[action.payload.type] = [];
    },
    addDateFilter: (state: EnterprisesState, action: PayloadAction<TDate>) => {
      const { name, query } = createDateFilter(action.payload);
      state.names.created = name;
      state.query.created = query;
    },
    disableDateFilter: (state: EnterprisesState) => {
      state.names.created = [];
      state.query.created = "";
    },
    addRangeFilter: (
      state: EnterprisesState,
      action: PayloadAction<number[]>,
    ) => {
      const range = action.payload;
      state.names.merchants = range;
    },
    disableRangeFilter: (state: EnterprisesState) => {
      state.names.merchants = [];
    },
    clearFilters: (state: EnterprisesState) => {
      state.query = initialState.query;
      state.names = initialState.names;
    },
    setEnterpriseBankAccounts: (
      state: EnterprisesState,
      action: PayloadAction<BankAccountType[]>,
    ) => {
      state.bankAccounts.list = action.payload;
    },
    setResetEnterpriseBankAccountsOwners: (state: EnterprisesState) => {
      state.bankAccounts.list = [];
      state.bankAccounts.removedList = [];
      state.bankAccounts.addedList = [];
      state.isDisabledSubmit = false;
    },
    setRemovedBankAccounts: (state: EnterprisesState) => {
      state.bankAccounts.removedList = [];
    },
    removeEnterpriseBankAccount: (
      state: EnterprisesState,
      action: PayloadAction<number>,
    ) => {
      const updatedBankAccounts = state.bankAccounts.list.filter(
        (bankAccount) => bankAccount.id !== action.payload,
      );
      state.bankAccounts.removedList.push(action.payload);
      state.bankAccounts.list = updatedBankAccounts;
    },
    addEnterpriseBankAccount: (
      state: EnterprisesState,
      action: PayloadAction<BankAccountType>,
    ) => {
      const id = state.bankAccounts.list.length + 1;
      state.bankAccounts.list.push({
        ...action.payload,
        id,
        created: toUnixDateFormat(new Date()),
      });
      state.bankAccounts.addedList.push(id);
    },
    editEnterpriseBankAccount: (
      state: EnterprisesState,
      action: PayloadAction<BankAccountType>,
    ) => {
      const updatedBankAccounts = state.bankAccounts.list.map((bankAccount) => {
        if (action.payload.id === bankAccount.id)
          return { ...bankAccount, ...action.payload };
        return bankAccount;
      });
      state.bankAccounts.list = updatedBankAccounts;
    },
    setIsDisabledEnterpriseSubmit: (
      state: EnterprisesState,
      action: PayloadAction<boolean>,
    ) => {
      state.isDisabledSubmit = action.payload;
    },
  },
});

export const {
  addDateFilter,
  addFilter,
  disableDateFilter,
  setEnterpriseBankAccounts,
  setRemovedBankAccounts,
  setResetEnterpriseBankAccountsOwners,
  removeEnterpriseBankAccount,
  addEnterpriseBankAccount,
  editEnterpriseBankAccount,
  disableFilter,
  addRangeFilter,
  disableRangeFilter,
  removeFilter,
  clearFilters,
  addAmountFilter,
  disableAmountFilter,
  setIsDisabledEnterpriseSubmit,
} = enterprisesSlice.actions;

export const selectFilters = (state: RootState) => state.enterprises.names;
export const selectWatchlist = (state: RootState) =>
  state.enterprises.watchlist;

export const selectQueryFilters = (state: RootState) => state.enterprises.query;

export default enterprisesSlice.reducer;

export const selectEnterpriseBankAccounts = (state: RootState) =>
  state.enterprises.bankAccounts.list.map((bankAccount) => ({
    id: bankAccount.id,
    name: bankAccount.name,
    bank: bankAccount.bank,
    status: "pending",
    nickname: "",
    isDefault: false,
    details: {
      created: bankAccount.created,
      accountType: bankAccount.accountType,
      routingNumber: bankAccount.routingNumber,
      notes: bankAccount.notes,
      accountName: bankAccount.name,
      accountNumber: bankAccount.accountNumber,
      bankName: bankAccount.bank,
    },
  }));

export const selectAddedBankAccounts = (state: RootState) =>
  state.enterprises.bankAccounts.addedList;
export const selectIsDisabledEnterpriseSubmit = (state: RootState) =>
  state.enterprises.isDisabledSubmit;
