import ComponentRegistry from "@common/PeekMode/ComponentRegistry";
import CreateCampaign from "@components/CreateCampaign/CreateCampaign";
import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOnboardingScreen } from "@redux/slices/app";
import { STATUS_ENUM } from "@components/ProfilePage/ProfileSetupHome/HomepageCard";
import { checkPortals } from "@utils/routing";
import { useGetProfileStatus } from "./useGetProfileStatus";
import WelcomePageTitle, {
  CompleteProfileButton,
  CreatePaymentFormButton,
  WelcomePageContainer,
} from "./WelcomePage.atoms";
import { ProfilePageContainer } from "@components/ProfilePage";
import useMasqueradeReducer from "@hooks/Reducers/useMasqueradeReducer";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { useTriggerPathEffect } from "@hooks/common/router/useRedirect";
import { useGetMerchantById } from "@hooks/enterprise-api/account/useGetMerchants";
import { useNavigate } from "react-router-dom";

ComponentRegistry.registerLazy("campaign", () => CreateCampaign);
ComponentRegistry.registerLazy("profile", () => ProfilePageContainer);

type TWelcomePageSection = "" | "profile" | "campaign";

const WelcomePage = () => {
  const [showInitialTitle, setShowInitialTitle] = useState(true);
  const { isMasqueradeMode } = useMasqueradeReducer();
  const { isMobileView } = useCustomTheme();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const showNextPage = useSelector((state: any) => state.app.ui.onboarding);
  const { isMerchantPortal } = checkPortals();

  useTriggerPathEffect(
    () => {
      showNextPage != "profile" && handleClick("profile");
    },
    { delay: 200 },
  );

  const { data: merchant, isLoading: merchantLoading } = useGetMerchantById();

  const setShowNextPage = (screen: TWelcomePageSection) =>
    dispatch(setOnboardingScreen({ screen }));

  useEffect(() => {
    if (location.hash === "#onboarding") setShowNextPage("profile");
    return () => {
      setShowNextPage("");
    };
  }, []);

  const {
    bankAccounts,
    hasPrincipal,
    isCompletedProfile,
    pahStatus,
    agreementStatus,
    bankAccountStatus,
    businessDetailsStatus,
    isUnderReviewProfile,
    legalEntity: { data: legalEntity, isLoading: leIsLoading },
  } = useGetProfileStatus(merchant);

  const isProfileDataLoading =
    leIsLoading || bankAccounts.isLoading || merchantLoading;
  const isFirstCampaignComplete = merchant?.hasCreatedProducts ?? false;

  const handleClick = (show: TWelcomePageSection) => {
    setShowNextPage(show);
    if (!showInitialTitle) setShowInitialTitle(true);
  };

  useEffect(() => {
    if (isCompletedProfile) handleClick("");
  }, [isCompletedProfile]);

  if (showInitialTitle) {
    return (
      <WelcomePageContainer
        isMasqueradeMode={isMasqueradeMode}
        data-testid="welcome-page-container"
        sx={{
          ...(!showNextPage ? "" : unmountedStyle),
          gap: "24px",
          ...(isMobileView && {
            paddingTop: "24%",
          }),
        }}
        onAnimationEnd={() => {
          if (showNextPage) setShowInitialTitle(false);
        }}
      >
        <WelcomePageTitle />
        <Stack spacing={1} justifyContent="center" alignItems="center">
          <CompleteProfileButton
            isCompletedProfile={isCompletedProfile}
            isUnderReviewProfile={isUnderReviewProfile}
            isProfileDataLoading={isProfileDataLoading}
            onClick={() => handleClick("profile")}
          />
          {isMerchantPortal && (
            <CreatePaymentFormButton
              isFirstCampaignComplete={isFirstCampaignComplete}
              onClick={() =>
                navigate("/merchant/payment-forms", {
                  state: { newProduct: true },
                })
              }
            />
          )}
        </Stack>
      </WelcomePageContainer>
    );
  }

  return (
    <WelcomePageContainer
      isMasqueradeMode={isMasqueradeMode}
      sx={{
        ...(showNextPage ? mountedStyle : unmountedStyle),
        justifyContent: "flex-start",
      }}
    >
      {ComponentRegistry.show(showNextPage, {
        className: showNextPage,
        backToWelcomePage: () => {
          setShowNextPage("");
          setShowInitialTitle(true);
        },
        legalEntity,
        isBankAccountsCompleted: bankAccountStatus != STATUS_ENUM.INCOMPLETE,
        matchTaxID: true,
        hasPrincipal: hasPrincipal,
        bankAccountStatus: bankAccountStatus,
        msaStatus: agreementStatus,
        bankAccountsList: bankAccounts.rows,
        businessDetailsStatus: businessDetailsStatus,
        pahStatus: pahStatus,
        ...(showNextPage === "profile" && {
          merchant,
        }),
      })}
    </WelcomePageContainer>
  );
};

const mountedStyle = { animation: "inAnimation 270ms ease-in" };
const unmountedStyle = {
  animation: "outAnimation 270ms ease-out",
  animationFillMode: "forwards",
};

export default WelcomePage;
