import { useParsedData } from "@common/Campaigns/hooks";
import { CampaignType } from "@common/Campaigns/hooks/useReportMenuActions";
import { useIterator } from "@common/Table/hooks";
import { useTransactionsIOC } from "@components/ManageMoney/TransactionTable/hooks";
import { MerchantPreviewHeaderAction } from "@components/Merchants/MerchantPreview/hooks/useMerchantPreview";
import { DELETE_DENY_MESSAGE, EDIT_DENY_MESSAGE } from "@constants/permissions";
import { QKEY_EVENT_TRANSACTIONS } from "@constants/queryKeys";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useGetCurrentMerchantId } from "@hooks/common";
import { useCopyClipboard } from "@hooks/common/useCopyClipboard";
import { ROWS_PER_PAGE } from "@hooks/common/usePagination";
import { customInstance } from "@services/api";
import { useGetTransactionsByProduct } from "@services/api/products/transactions";
import { checkEnd } from "@utils/date.helpers";
import { parseAmount, roundToNearestTenth } from "@utils/index";
import { useProductPermission } from "features/Permissions/AccessControl/hooks";
import { capitalize } from "lodash";
import {
  CAMPAIGN_DETAILS_MODAL,
  DELETE_CONFIRMATION_MODAL,
  MERCHANT_PORTAL_SHARE_MODAL,
  PEEK_MODE_MODAL,
} from "modals/modal_names";
import numeral from "numeral";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetCampaignById } from "./useGetCampaignById";
import { buildMerchantEndpoints } from "@services/api/utils.api";
import { DEADLINE_REACHED_MESSAGE } from "./constants";

type openPanelProps = {
  allRows: any[];
  totalRows: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  isLoading: boolean;
};

export const useOpenCampaignPanel = ({
  allRows,
  isLoading,
  page,
  setPage,
  totalRows,
}: openPanelProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const modal = useModal(CAMPAIGN_DETAILS_MODAL);

  const { onIterator, selectedRowIdx, setSelectedRowIdx } = useIterator({
    dataLen: allRows?.length,
  });
  const numberOfPages = Math.ceil(Number(totalRows ?? 0) / ROWS_PER_PAGE);

  const openSidePanel =
    (id: string | number, index: number, name?: string) => () => {
      if (pathname.includes("/acquirer/providers")) {
        navigate(`/acquirer/providers/${id}`);
        return;
      }
      NiceModal.show(CAMPAIGN_DETAILS_MODAL, {
        id,
        name,
        setSelectedRow: onIterator(setPage, page),
        isFirst: index === 0 && page === 1,
        isLast:
          allRows[selectedRowIdx + 1] === undefined && page === numberOfPages,
      });
      setSelectedRowIdx(index);
    };

  useEffect(() => {
    if (isLoading && modal.visible) {
      return;
    }

    if (allRows[selectedRowIdx]) {
      NiceModal.show(CAMPAIGN_DETAILS_MODAL, {
        id: allRows[selectedRowIdx].id,
        name: allRows[selectedRowIdx].name,
        setSelectedRow: onIterator(setPage, page),
        isFirst: selectedRowIdx === 0 && page === 1,
        isLast:
          allRows[selectedRowIdx + 1] === undefined && page === numberOfPages,
      });
    }
  }, [selectedRowIdx, isLoading]);

  useEffect(() => {
    if (!modal.visible) {
      setSelectedRowIdx(-1);
    }
  }, [modal.visible]);

  return { openSidePanel, selectedRowIdx };
};

type Props = {
  id: number;
  name?: string;
  setSelectedRow: (newIdx: string | number) => void;
};

export const CAMPAIGN_PANEL_WIDTH = 600;

const useGetProductStats = ({
  id,
  merchantId,
}: {
  id: number;
  merchantId: number;
}) => {
  // /merchants/{merchant_id}/products/{product_id}/stats
  return useQuery({
    queryKey: ["specific-product-stats", id],
    queryFn: async () => {
      return await customInstance({
        url: `/merchants/${merchantId}/products/${id}/stats`,
        method: "GET",
      });
    },
  });
};

const useGetProductInventory = ({
  id,
  merchantId,
}: {
  id: number;
  merchantId: number;
}) => {
  return useQuery({
    queryKey: ["specific-product-inventory", id],
    queryFn: async () => {
      return await customInstance({
        url: `/merchants/${merchantId}/products/${id}/variants?sort=displayOrder`,
        method: "GET",
      });
    },
  });
};

export default function useCampaignModal({ id, name, setSelectedRow }: Props) {
  const [doublePanel, setDoublePanel] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState<string | null>(
    null,
  );
  const modal = useModal(CAMPAIGN_DETAILS_MODAL);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { copyToClipboard } = useCopyClipboard();

  const campaign = useMemo(
    () => pathname.split("merchant/")[1]?.slice(0, -1) as CampaignType,
    [pathname],
  );

  const { data, stats, isLoading, isFetching } = useGetCampaignById({
    id,
    campaign,
    enabled: modal.visible,
  });

  const { merchantId } = useGetCurrentMerchantId();

  const { data: campaignStatsData, isLoading: areCampaignStatsLoading } =
    useGetProductStats({ id, merchantId });

  const { data: productInventory, isLoading: isProductInventoryLoading } =
    useGetProductInventory({ id, merchantId });

  const {
    allRows,
    isLoading: transactionLoading,
    invalidateCache,
  } = useTransactionsIOC(
    QKEY_EVENT_TRANSACTIONS,
    useGetTransactionsByProduct(
      `${id}`,
      `${QKEY_EVENT_TRANSACTIONS} ${id} ${campaign}`,
    ),
    "",
    {
      isPurchase: true,
    },
  );
  const VariantData = useParsedData(allRows);

  const hasEnded =
    campaign === "event" || campaign === "sweepstake"
      ? checkEnd(data?.startsAt, data?.endsAt || 0) < Date.now()
      : false;

  const clearDoublePanel = () => {
    setDoublePanel(false);
    setSelectedTransaction(null);
  };

  const handleClose = () => {
    clearDoublePanel();

    invalidateCache();
    modal.hide();
  };

  const handleEdit = () => {
    setSelectedTransaction(null);
    setDoublePanel(true);
  };

  const handleShare = () => {
    NiceModal.show(MERCHANT_PORTAL_SHARE_MODAL, {
      type: dynamicCampaign,
      title: `Share ${campaign}`,
      productId: id,
    });
  };

  const handleCopy = () => {
    copyToClipboard(`${window.location.origin}/${id}`);
  };

  const handlePreview = () => {
    NiceModal.show(PEEK_MODE_MODAL, {
      previewLink:
        campaign === "payment-form"
          ? data?.typeName === "standard"
            ? `${campaign}s/${id}`
            : `${data?.typeName}s/${id}`
          : `${campaign}s/${id}`,
      state: { preview: true },
      title: name,
    });
  };

  const handleExpand = () => {
    clearDoublePanel();
    navigate(
      campaign === "payment-form" && data?.typeName !== "standard"
        ? `merchant/${data?.typeName}s/${id}`
        : `${pathname}/${id}`,
    );
    modal.hide();
  };

  const url = buildMerchantEndpoints(`products/${id}`);
  const handleDelete = () => {
    NiceModal.show(DELETE_CONFIRMATION_MODAL, {
      variant: "product",
      itemName: name,
      productVariant: campaign,
      url: url,
      onSuccess: () => {
        clearDoublePanel();
        modal.hide();
      },
    });
  };

  const handleNext = () => {
    setSelectedRow("next");
    invalidateCache();
  };

  const handlePrevious = () => {
    setSelectedRow("prev");
    invalidateCache();
  };

  const chooseTransaction = (id: string) => {
    setSelectedTransaction(id);
    setDoublePanel(true);
  };

  const { isEditProductAllowed, isDeleteProductAllowed } =
    useProductPermission();

  const actions: MerchantPreviewHeaderAction[] = [
    {
      title: "Edit",
      hidden: false,
      disabled: hasEnded || !isEditProductAllowed,
      onClick: handleEdit,
      tooltipProps: {
        show: !isEditProductAllowed,
        message: !isEditProductAllowed
          ? EDIT_DENY_MESSAGE
          : DEADLINE_REACHED_MESSAGE,
      },
    },
    {
      title: "Share",
      hidden: false,
      onClick: handleShare,
    },
    {
      title: "Copy link",
      hidden: false,
      onClick: handleCopy,
    },
    {
      title: "Preview",
      hidden: false,
      onClick: handlePreview,
    },
    {
      title: "Expand",
      hidden: false,
      onClick: handleExpand,
    },
    {
      title: "Delete",
      hidden: false,
      disabled: !isDeleteProductAllowed,
      onClick: handleDelete,
      sx: {
        color: "red",
      },
      tooltipProps: {
        show: !isDeleteProductAllowed,
        message: DELETE_DENY_MESSAGE,
      },
    },
  ];

  const productStatsData: MerchantProductsStats = {
    totalSoldVariants: campaignStatsData?.totalSoldVariants,
    totalAvailableVariants: campaignStatsData?.totalAvailableVariants,
    totalVariantsPurchasesRate: campaignStatsData?.totalVariantsPurchasesRate,
    averageTotalSoldVariants: Math.round(
      campaignStatsData?.averageTotalSoldVariants,
    ),
  };

  const BodyData = useMemo(() => {
    return {
      campaignEndsAt: data?.endsAt,
      Visitors: data?.visitors,
      name: data?.name,
      description: data?.description,
      usesBackgroundImage: data?.usesBackgroundImage,
      createdAt: data?.createdAt,
      typeName: data?.typeName,
      startsAt: data?.startsAt,
      statsData: stats,
      total: parseAmount(Number(numeral(data?.sumTransactions / 100).value())),
      conversion: numeral(
        roundToNearestTenth(data?.conversionRate ?? 0),
      ).value(),
      averageTransactions: Number(
        numeral(data?.averageTransactions / 100).value(),
      ).toFixed(2),
      purchases: data?.totalTransactions, // to confirm
      hideViewMore: VariantData?.length === 0,
      variantsList: VariantData?.map((d: any) => ({
        id: d?.transactionID,
        name: data?.name,
        createdAt: d.createdAtTimestamp,
        inventory: d.quantity,
        totalPrice: parseAmount(d.charged),
        creator: d.cardholderName,
        ...d,
      }))?.slice(0, 10),
      ...productStatsData,
      variants: productInventory?.data,
    };
  }, [data, VariantData, productInventory]);

  const transactionData = useMemo(() => {
    if (!selectedTransaction) return null;
    return VariantData.find((tr: any) => tr.id === selectedTransaction);
  }, [VariantData, selectedTransaction]);

  useEffect(() => {
    if (!campaign) {
      clearDoublePanel();
      invalidateCache();
      modal.hide();
    }
  }, [campaign]);

  const dynamicCampaign = useMemo(
    () =>
      campaign === "payment-form" && data?.typeName
        ? data.typeName === "standard"
          ? "payment-form"
          : data.typeName
        : campaign,
    [campaign, data?.typeName],
  );

  return {
    handleClose,
    isLoading:
      isLoading || areCampaignStatsLoading || isProductInventoryLoading,
    isFetching,
    modal,
    actions,
    handleNext,
    handlePrevious,
    handlePreview,
    handleExpand,
    imgUrl: data?.imageURL,
    doublePanel,
    setDoublePanel,
    campaign: dynamicCampaign as CampaignType,
    handleEdit,
    handleShare,
    BodyData,
    campaignStatsData,
    transactionLoading,
    chooseTransaction,
    transactionData,
    selectedTransaction,
    hasEnded,
  };
}

export type DATA = {
  id: number;
  typeID: number;
  typeName: string;
  name: string;
  description: string;
  accID: number;
  merchantName: string;
  merchantImageURL: string;
  merchantDescription: string;
  merchTypeName: string;
  merchCanProcessMoney: boolean;
  needsTax: boolean;
  allowFeeChoice: boolean;
  needsShipping: boolean;
  imageURL: string;
  usesBackgroundImage: boolean;
  canBrowseCampaigns: boolean;
  maxPurchaseQuantity: number | null;
  maxEntriesPerPrize: number | null;
  startsAt: number | null;
  endsAt: number | null;
  showMap: boolean | null;
  includeTime: boolean | null;
  allowMultipleWinners: boolean | null;
  locationURL: string | null;
  locationShortAddress: string | null;
  locationAddress: string | null;
  variants: any[] | null; // Adjust the type accordingly when you have more information
  recurringIntervals: string[];
  defaultRecurringIntervalName: string;
  publishedStatus: string;
  totalTransactions: number;
  sumTransactions: number;
  averageTransactions: number;
  visitors: number;
  conversionRate: number;
  createdAt: number;
  updatedAt: number;
  deletedAt: number | null;
};

export interface MerchantProductsStats {
  totalSoldVariants: number;
  totalAvailableVariants: number;
  totalVariantsPurchasesRate: number;
  averageTotalSoldVariants: number;
}

// Define the type for a ticket
export interface Ticket {
  allowCustomPrice: boolean;
  bundle: number;
  bundleFreeze: boolean;
  createdAt: number;
  defaultRecurringIntervalName: string;
  description: string;
  displayOrder: number;
  id: number;
  imageURL: string | null;
  inventory: number;
  isEnabled: boolean;
  maxPrice: number | null;
  minPrice: number | null;
  name: string;
  numSold: number;
  price: number;
  productID: number;
  productName: string;
  productTypeID: number;
  productTypeName: string;
  recurringIntervals: string[];
  showAvailableVariants: boolean;
  updatedAt: number;
}
