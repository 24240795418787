import BoldPlusIcon from "@assets/icons/RebrandedIcons/BoldPlusIcon";
import Placeholder from "assets/images/Placeholder.png";
import { EmptyState, LoadingComponent } from "@common/AssignMenu/AssignMenu";

import SwipeableDrawerMobile from "@components/SwipeableDrawerMobile/SwipeableDrawerMobile";
import {
  Box,
  IconButton,
  Popover,
  Stack,
  styled,
  SxProps,
} from "@mui/material";
import { palette } from "@palette";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import React, { useCallback, useEffect, useRef, useState } from "react";
import SearchBar from "@common/SearchBar/SearchBar";
import { SearchIcon } from "@assets/icons";
import { CustomRenderoption, SelectorOption as TSelectorOption } from "./utils";
import SelectorOption from "./SelectorOption";
import { Button } from "@common/Button";
import lodash from "lodash";
import { useAppSelector } from "@redux/hooks";
import { selectQueryString } from "@redux/slices/search";

interface Props {
  options?: TSelectorOption[];
  queryKey?: string;
  loading?: boolean;
  value?: string | number | TSelectorOption[];
  handleDeselect?: (val: TSelectorOption) => void;
  handleChange: (
    val: string | number | TSelectorOption | TSelectorOption[],
  ) => void;
  handleSearch?: (val: string) => void;
  onScroll?: (e: any) => void;
  multiSelect?: boolean;
  renderCustomParent?: (props: {
    onClick: (event: React.MouseEvent<any>) => void;
  }) => React.ReactElement;
  renderCustomElement?: (option: CustomRenderoption) => React.ReactElement;
  ignoreMobileView?: boolean;
  onClose?: () => void;
  renderFooter?: (props: { onClose: () => void }) => React.ReactElement;
  width?: number;
  searchBarProps?: any;
  isOpen?: (open: boolean) => void;
  closeOnClick?: boolean;
  showApplyAction?: boolean;
  fullWidth?: boolean;
  searchedWord?: string;
  height?: number;
  containerSx?: SxProps;
  scrollerSx?: SxProps;
  dataTestId?: string;
}

export default function Selector({
  options,
  queryKey,
  loading,
  value,
  multiSelect = false,
  ignoreMobileView = false,
  handleChange,
  handleSearch,
  handleDeselect,
  onScroll,
  renderCustomElement,
  renderCustomParent,
  onClose,
  renderFooter,
  width,
  searchBarProps,
  isOpen,
  closeOnClick,
  showApplyAction = true,
  fullWidth = false,
  searchedWord,
  height,
  containerSx,
  scrollerSx,
  dataTestId
}: Props) {
  const HEIGHT = 254;
  const HEIGHT_WITH_FOOTER = 305;
  const WIDTH = width ? width : 277;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const anchorElWidth = anchorEl && anchorEl.getBoundingClientRect().width;
  const [localSelected, setLocalSelected] = useState<TSelectorOption[]>([]);

  const searchText = useAppSelector((state) =>
    selectQueryString(state, queryKey || ""),
  );
  const searchTerm = lodash.isEmpty(options)
    ? searchText || searchedWord
    : value;

  const onSelect = (item: TSelectorOption) => {
    setLocalSelected((prev) => {
      const isSelected = !!prev?.find(
        (selectedItem) => selectedItem.id === item.id,
      );

      if (isSelected) {
        return prev.filter((selectedItem) => selectedItem.id != item.id);
      }
      return [...prev, item];
    });
  };

  const onApply = () => {
    handleChange(localSelected);
    handleClose();
  };

  const { isMobileView } = useCustomTheme();

  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event?.stopPropagation();
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const open = Boolean(anchorEl);
  useEffect(() => {
    if (open) {
      setLocalSelected((value || []) as TSelectorOption[]);
    }
    isOpen && isOpen(open);
  }, [open]);

  const handleClose = () => {
    setAnchorEl(null);
    onClose && onClose();
  };

  const isEmpty = !options || options.length === 0;

  const handlePopoverClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation();
  };

  const search = (
    <SearchBar
      isDebounceSearch
      inputSx={{
        borderRadius: "0px",
        border: "none",
        backgroundColor: "transparent",
        borderBottom: "1px solid #ECECE9",
        padding: "22px 12px ",
        gap: "4px",
        "& input::placeholder": {
          color: "#8F8F8F !important",
          opacity: 1,
          fontSize: "14px",
          fontWeight: 350,
        },
      }}
      startAdornment={<SearchIcon width={25} />}
      isExpandedByDefault
      queryKey={queryKey}
      handleChange={handleSearch}
      searchOnEnter
      slotProps={{
        root: {
          onScroll: onScroll,
        },
      }}
      {...searchBarProps}
    />
  );

  return (
    <>
      {renderCustomParent ? (
        renderCustomParent({ onClick: handleOpenMenu })
      ) : (
        <AddIconButton onClick={handleOpenMenu}>
          <BoldPlusIcon />
        </AddIconButton>
      )}

      <>
        {isMobileView && !ignoreMobileView ? (
          <SwipeableDrawerMobile
            anchor="bottom"
            onOpen={() => undefined}
            open={open}
            onClose={() => handleClose()}
            sx={{
              "& .MuiPaper-root.MuiDrawer-paper": {
                background: "#fff",
                top: "25%",
                padding: "16px 12px",
              },
            }}
          >
            {(handleSearch || queryKey) && search}
            <Stack
              direction="column"
              onScroll={onScroll}
              sx={{
                flex: 1,
                overflowY: "auto",
                " >div": {
                  paddingY: "6px",
                },
              }}
            >
              {isEmpty && !loading && (
                <EmptyState title={`No results for ${searchTerm}`} />
              )}
              {!isEmpty &&
                options?.map((item) => {
                  const val = item.id;
                  const isSelected = Array.isArray(localSelected)
                    ? Boolean(
                        localSelected?.find(
                          (item: TSelectorOption) => item.id === val,
                        ),
                      )
                    : val === value;

                  if (renderCustomElement) {
                    return (
                      <Box
                        key={item.label}
                        onClick={() => {
                          onSelect(item);
                          //if the apply button is hidden, set the item and close the drawer
                          if (!showApplyAction) {
                            handleChange(item);
                            handleClose();
                          }
                        }}
                      >
                        {renderCustomElement({
                          item,
                          selected: isSelected,
                        })}
                      </Box>
                    );
                  }

                  return (
                    <SelectorOption
                      key={val}
                      name={item.label}
                      image={
                        item.imageURL ? `${item.imageURL}/thumb` : Placeholder
                      }
                      selected={isSelected}
                      handleDeselect={() => onSelect(item)}
                      onClick={() => {
                        onSelect(item);
                      }}
                    />
                  );
                })}
              {loading && <LoadingComponent />}
            </Stack>
            {showApplyAction && (
              <Stack justifyContent="center" direction="row" pt="16px" pb="4px">
                <Button sx={{ width: "100px" }} onClick={onApply}>
                  Apply
                </Button>
              </Stack>
            )}
          </SwipeableDrawerMobile>
        ) : (
          <Popover
            id={options?.length?.toString()}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            slotProps={{
              paper: {
                style: {
                  marginTop: "5px",
                  width: fullWidth ? `${anchorElWidth}px` : `${WIDTH}px`,
                  height:
                    height || `${renderFooter ? HEIGHT_WITH_FOOTER : HEIGHT}px`,
                  msOverflowStyle: "none",
                  scrollbarWidth: "none",
                  overflow: "hidden",
                  backgroundColor: "#FAFAFA",
                  boxShadow: "0px 8px 25px 0px #00000026",
                  borderRadius: "8px",
                },
              },
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClick={handlePopoverClick}
          >
            {(handleSearch || queryKey) && search}

            <Box
              pb="20px"
              sx={{ height: "100%", overflow: "hidden", ...scrollerSx }}
              data-testid={dataTestId}
            >
              {isEmpty && !loading && (
                <EmptyState title={`No results for "${searchTerm}"`} />
              )}
              <Box
                sx={{
                  height: "200px",
                  overflow: "auto",
                  ...containerSx,
                }}
                onScroll={onScroll}
              >
                {!isEmpty &&
                  options?.map((item, index) => {
                    const isSelected = Array.isArray(value)
                      ? Boolean(
                          value?.find(
                            (val: TSelectorOption) => val.id === item.id,
                          ),
                        )
                      : item?.id === value;
                    return (
                      <React.Fragment key={index}>
                        {renderCustomElement ? (
                          <Box
                            key={item.label}
                            onClick={() => {
                              handleChange(multiSelect ? item : item.id);
                              closeOnClick && handleClose();
                            }}
                            sx={{
                              ":hover": {
                                background: palette.liftedWhite[100],
                                cursor: "pointer",
                              },
                            }}
                          >
                            {renderCustomElement({
                              item,
                              selected: isSelected,
                            })}
                          </Box>
                        ) : (
                          <SelectorOption
                            name={item.label}
                            image={
                              item.imageURL
                                ? `${item.imageURL}/thumb`
                                : Placeholder
                            }
                            selected={isSelected}
                            handleDeselect={
                              handleDeselect
                                ? () => handleDeselect(item)
                                : () => handleChange("")
                            }
                            onClick={() => {
                              handleChange(multiSelect ? item : item.id);
                              closeOnClick && handleClose();
                            }}
                          />
                        )}
                      </React.Fragment>
                    );
                  })}
                {loading && <LoadingComponent />}
              </Box>
              {renderFooter && renderFooter({ onClose: handleClose })}
            </Box>
          </Popover>
        )}
      </>
    </>
  );
}

const AddIconButton = styled(IconButton)(() => ({
  border: "none",
  boxShadow: "none",
  backgroundColor: palette.neutral[20],
  "&:disabled": {
    boxShadow: "none",
  },
  "&:hover": {
    backgroundColor: palette.neutral[20],
  },
  borderRadius: "100px",
  width: "24px",
  height: "24px",
  padding: 0,
}));
