import { useEnterprisePermissions } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useEnterprisePermissions";
import RESOURCE_BASE, {
  DELETE_DENY_MESSAGE,
  FEATURE_DENY_MESSAGE,
  OPERATIONS,
} from "@constants/permissions";
import {
  QKEY_LIST_ACQUIRER_MERCHANTS,
  QKEY_RISK_MONITOR_TRANSACTIONS,
} from "@constants/queryKeys";
import NiceModal from "@ebay/nice-modal-react";
import { useMasquerade, useWatchlist } from "@hooks/enterprise-api/merchants";
import { SxProps } from "@mui/material";
import { palette } from "@palette";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { resetFilterByKey } from "@redux/slices/dynamicFilterSlice";
import {
  resetMerchantSectionFocus,
  selectMerchantPreview,
  setOpeningOptions,
} from "@redux/slices/merchantPreview";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { checkPortals } from "@utils/routing";
import { useChangelogModal } from "features/ChangeLog/hooks/useChanglelogModal";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { useAccessControl } from "features/Permissions/AccessControl";
import { useUnderwriterPermissions } from "features/Permissions/AccessControl/hooks";
import {
  DELETE_CONFIRMATION_MODAL,
  MATCH_MOBILE_VIEW,
  REMOVE_FROM_WATCHLIST_MODAL,
} from "modals/modal_names";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { TPEPOwner } from "../PEP/types";
import { useMerchantDataPreview } from "./useMerchantDataPreview";
import { usePreviewPanel } from "./usePreviewPanel";
import { OFACTabType } from "../OFAC/helpers";

export type MerchantPreviewHeaderAction = {
  title: string;
  hidden: boolean;
  disabled?: boolean;
  onClick: () => void;
  sx?: SxProps;
  tooltipProps?: { show: boolean; message: string };
};

type Props = {
  id: number;
  setSelectedRow?: (newIdx: string | number) => void;
  onClose?: () => void;
};

export type TMerchantDoublePanel =
  | "manageUnderwriting"
  | "MerchantAgreement"
  | "riskProfile"
  | "agreementSnapshot"
  | "pep"
  | "ofac"
  | "match"
  | "";

export const useMerchantPreview = ({ id, setSelectedRow, onClose }: Props) => {
  const { openingOptions } = useAppSelector(selectMerchantPreview);
  const dispatch = useAppDispatch();

  const [doublePanel, setDoublePanel] = useState<TMerchantDoublePanel>("");
  const [OFACTab, setOFACTab] = useState<OFACTabType | undefined>();
  const [pepOwnerID, setPepOwnerID] = useState<TPEPOwner>(null);

  const queryClient = useQueryClient();
  const { handleOpenConversationsModal } = useConversationsModal();
  const { handleOpenChangelogModal } = useChangelogModal();
  const { control_mode } = useEnterprisePermissions();
  const { isAcquirerPortal } = checkPortals();
  const { data, merchant, isLoading } = useMerchantDataPreview({ id });
  const { addMerchantToWatchlist, removeMerchantFromWatchlist } =
    useWatchlist();
  const { panel1Collapsed, setPanel1Collapsed } = usePreviewPanel({
    doublePanel,
  });
  const { masqueradeHandler } = useMasquerade();
  const { isDesktopView } = useCustomTheme();

  const isControlModeAllowed = useAccessControl({
    resource: RESOURCE_BASE.ACCESS_CONTROL,
    operation: OPERATIONS.UPDATE,
    withPortal: true,
  });
  const isDeleteAllowed = useAccessControl({
    resource: RESOURCE_BASE.MERCHANT,
    operation: OPERATIONS.DELETE,
  });

  useEffect(() => {
    if (openingOptions.underwriter) {
      handleManageUnderwriting();
    }

    if (openingOptions.riskprofile) {
      handleOpenRiskProfile();
    }

    return () => {
      dispatch(setOpeningOptions({ underwriter: false, riskprofile: false }));
    };
  }, []);

  const closeFullPanel = () => {
    setDoublePanel((prev) => {
      if (!prev) {
        queryClient.removeQueries("get-merchant-preview");
      }
      if (onClose) onClose();
      return "";
    });
    /**
     * refetch only the query that's last called but remove all the previous submerchant queries
     *  */

    const submerchantQueries = queryClient
      .getQueryCache()
      .findAll({ queryKey: QKEY_LIST_ACQUIRER_MERCHANTS });
    submerchantQueries.forEach((query, index) => {
      if (index === submerchantQueries.length - 1) {
        queryClient.refetchQueries(query);
      } else {
        queryClient.removeQueries(query);
      }
    });
    handleOpenConversationsModal(false);
    handleOpenChangelogModal(false);
    dispatch(resetMerchantSectionFocus());
    dispatch(
      resetFilterByKey({
        filterKey: QKEY_RISK_MONITOR_TRANSACTIONS,
      }),
    );
  };

  const handleClose = () => {
    if (doublePanel) {
      setPanel1Collapsed(true);
      return;
    }
    closeFullPanel();
  };

  const handleControlMode = () => {
    masqueradeHandler(
      isAcquirerPortal ? "acquirer" : "provider",
      {
        ...merchant,
        nextRoute: "/merchant/welcome",
      },
      handleClose,
    );
  };

  const togglePanel2 = (open = true, panel2: TMerchantDoublePanel) => {
    // if panel1 is collapsed -> close whole panel on pressing x of panel2
    if (doublePanel && panel1Collapsed) {
      closeFullPanel();
      setOFACTab(undefined);
    } else {
      setDoublePanel(open ? panel2 : "");
      if (!open) setOFACTab(undefined);
    }
  };

  const handleOpenRiskProfile = (open?: boolean) => {
    togglePanel2(open, "riskProfile");
  };
  const handleOpenOFACPanel = (open?: boolean, tab?: OFACTabType) => {
    togglePanel2(open, "ofac");
    if (tab) {
      setOFACTab(tab);
    }
  };
  const handleOpenAgreementSnapshot = (open?: boolean) => {
    togglePanel2(open, "agreementSnapshot");
  };

  const handleManageUnderwriting = (open?: boolean) => {
    togglePanel2(open, "manageUnderwriting");
  };

  const handleOpenManageUnderwriting = () => {
    setDoublePanel("manageUnderwriting");
  };

  const handleManageMerchantAgreement = (open?: boolean) => {
    togglePanel2(open, "MerchantAgreement");
  };

  const handleOpenPep = (open?: boolean) => {
    !open && setPepOwnerID(null);
    togglePanel2(open, "pep");
  };

  const handleOpenMATCH = (open?: boolean) => {
    if (isDesktopView) togglePanel2(open, "match");
    else
      NiceModal.show(MATCH_MOBILE_VIEW, {
        isAcquirer: isAcquirerPortal,
        merchantID: data.merchantInfo.merchantID,
        headerData: data.header,
      });
  };

  const handleAddToWatchList = () => {
    if (data.header.watchlist) {
      NiceModal.show(REMOVE_FROM_WATCHLIST_MODAL, {
        title: data.header.name,
        removeAction: () => removeMerchantFromWatchlist(id, data.header.name),
      });
    } else {
      addMerchantToWatchlist(id, data.header.name);
    }
  };

  const selectPEPOwner = (data: TPEPOwner) => {
    setPepOwnerID(data);
    setDoublePanel("pep");
  };

  const handleDeleteMerchant = () => {
    const url = `/merchants/${id}`;
    NiceModal.show(DELETE_CONFIRMATION_MODAL, {
      variant: "merchant",
      itemName: data.header.name,
      url: url,
      onSuccess: handleClose,
    });
  };

  const handleNext = () => {
    if (setSelectedRow) setSelectedRow("next");
  };

  const handlePrevious = () => {
    if (setSelectedRow) setSelectedRow("prev");
  };

  const { isUpdateUnderwriterAllowed } = useUnderwriterPermissions();

  const actions: MerchantPreviewHeaderAction[] = [
    {
      title: "Control mode",
      hidden: !control_mode,
      disabled: !isControlModeAllowed,
      onClick: handleControlMode,
      tooltipProps: {
        show: !isControlModeAllowed,
        message: FEATURE_DENY_MESSAGE,
      },
    },
    {
      title: data.header.watchlist
        ? "Remove from watchlist"
        : "Add to watchlist",
      hidden: false,
      disabled: !isUpdateUnderwriterAllowed,
      onClick: handleAddToWatchList,
      tooltipProps: {
        show: !isUpdateUnderwriterAllowed,
        message: FEATURE_DENY_MESSAGE,
      },
    },
    {
      title: "Manage underwriting",
      hidden:
        !isAcquirerPortal || !isDesktopView || !isUpdateUnderwriterAllowed,
      disabled: false,
      onClick: handleManageUnderwriting,
    },
    {
      title: "Delete merchant",
      hidden: true, // MDD001 asked to hide the delete merchant button
      disabled: !isDeleteAllowed,
      onClick: handleDeleteMerchant,
      sx: { color: palette.error.main },
      tooltipProps: {
        show: !isDeleteAllowed,
        message: DELETE_DENY_MESSAGE,
      },
    },
  ];

  return {
    handleClose,
    data,
    isLoading,
    actions,
    doublePanel,
    isControlModeAllowed,
    pepOwnerID,
    panel1Collapsed,
    OFACTab,
    setPanel1Collapsed,
    closeFullPanel,
    setDoublePanel,
    handlers: {
      handleControlMode,
      handleClose,
      handleManageUnderwriting,
      handleManageMerchantAgreement,
      handleOpenManageUnderwriting,
      handleNext,
      handlePrevious,
      displayRowHandlers: Boolean(setSelectedRow),
      handleOpenRiskProfile,
      handleOpenAgreementSnapshot,
      handleOpenPep,
      selectPEPOwner,
      handleOpenOFACPanel,
      handleOpenMATCH,
    },
  };
};
