import { Stack } from "@mui/material";
import DisputeCaseHistoryCard from "@components/Disputes/DisputePreview/components/DisputeCaseHistoryCard";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { useState } from "react";
import { Button } from "@common/Button";
import { DisputeCaseType } from "../data.types";

const HIDDEN_HISTORY_HEIGHT = 318;
const SHOW_ALL_HISTORY_HEIGHT = 211;

type Props = {
  data: DisputeCaseType[];
  issuingBankName: string;
};

const DisputeCaseHistory = ({ data, issuingBankName }: Props) => {
  const [isHidden, setIsHidden] = useState(data?.length > 2);

  const displayedData = isHidden ? data.slice(0, 2) : data;

  return (
    <Stack
      gap={1}
      sx={{
        overflow: "hidden",
        height: isHidden ? HIDDEN_HISTORY_HEIGHT : "auto",
        position: "relative",
      }}
    >
      {displayedData?.map((value, index) => (
        <FadeUpWrapper key={`history-card-${index}`} delay={(index + 1) * 150}>
          <DisputeCaseHistoryCard
            data={value}
            issuingBankName={issuingBankName}
            isLast={index === data.length - 1}
          />
        </FadeUpWrapper>
      ))}

      {isHidden && (
        <Stack
          alignItems="center"
          justifyContent="flex-end"
          sx={{
            background:
              "linear-gradient(180deg, rgba(248, 248, 246, 0) 0%, rgba(248, 248, 246, 1) 100%)",
            position: "absolute",
            bottom: 0,
            width: "100%",
            height: SHOW_ALL_HISTORY_HEIGHT,
          }}
        >
          <Button
            size="medium"
            sx={{ marginBottom: "20px" }}
            onClick={() => setIsHidden(false)}
          >
            Show all History
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default DisputeCaseHistory;
