// mui
import Box from "@mui/material/Box";
// components
import Layout from "./Layout";
import { Drawer } from "@components/Drawer";
// data
import { Suspense, memo } from "react";
import PrivateRoutes from "routes/PrivateRoutes";
import LoadingSpinner from "@components/Snipper/LoadingSpinner";
import BreadcrumbsPageContainer from "@components/CustomBreadcrumbsPage/BreadcrumbsPage";
import InternetStatusWrapper from "@components/Wrapper/InternetStatusWrapper";
import { FileUploadSnackbarContainer } from "@components/UploadFile/FileUploadSnackbarContainer";
import { useGetEnterpriseConfiguration } from "@components/AcquirerEnterprises/CreateEnterprise/hooks/useGetEnterpriseConfiguration";
import { checkPortals } from "@utils/routing";
import { useRedirectTransaction } from "./hooks/useRedirectTransaction";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import useManageQueryRedirect from "@hooks/useManageQueryRedirect";
import { useRedirectToMerchantPanel } from "./hooks/useRedirectToMerchantPanel";

const Base = () => {
  const { isNoSidebar, isDashBoard } = checkPortals();

  useGetEnterpriseConfiguration();
  useRedirectTransaction();
  useManageQueryRedirect();
  useRedirectToMerchantPanel();

  if (isNoSidebar) {
    return (
      <Box component="main">
        <Suspense fallback={<LoadingSpinner />}>
          {PrivateRoutes()}
          <FileUploadSnackbarContainer />
        </Suspense>
      </Box>
    );
  }
  return (
    <Layout bgcolor={isDashBoard ? "#FFFFFF" : undefined}>
      <Drawer />
      <BreadcrumbsPageContainer>
        <InternetStatusWrapper>
          <Suspense fallback={<LoadingSpinner />}>
            {PrivateRoutes()}
            <FileUploadSnackbarContainer />
          </Suspense>
        </InternetStatusWrapper>
      </BreadcrumbsPageContainer>
    </Layout>
  );
};

export default memo(Base);
