import CopyButton from "@common/CopyButton";
import { RHFInput } from "@common/Input";
import { useFormContext } from "react-hook-form";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { SLUG_MAX_CHARACTER_LENGTH } from "@constants/constants";

type MerchantSlugInputProps = {
  id?: string;
  name: string;
  label?: string;
  parentName?: string;
  disabled?: boolean;
  focusViewColor?: string;
  value?: string;
};

const MerchantSlugInput = ({
  name,
  label,
  parentName,
  disabled = false,
  ...rest
}: MerchantSlugInputProps) => {
  const { watch } = useFormContext();

  const prefix = `https://${
    parentName || watch("parent_slug")
  }.givepayments.com/`;

  const { isMobileView } = useCustomTheme();

  const trimmedPrefix = `${prefix.slice(0, 25)}${prefix.length > 25 && "..."}`;

  return (
    <RHFInput
      name={name}
      label={label}
      placeholder="Permalink"
      fullWidth
      maxLength={SLUG_MAX_CHARACTER_LENGTH}
      disabled={disabled}
      inputPrefix={isMobileView ? trimmedPrefix : prefix}
      endIcon={<CopyButton hidden={!watch(name)} text={prefix + watch(name)} />}
      {...rest}
    />
  );
};

export default MerchantSlugInput;
