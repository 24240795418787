import { WithMissingPermissionModule } from "@common/WithMissingPermissionModule";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import RESOURCE_BASE, { OPERATIONS } from "@constants/permissions";
import { Box, Stack, styled } from "@mui/material";
import { useAppSelector } from "@redux/hooks";
import { useConversationsModal } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import {
  WithAccessControl,
  composePermission,
  useAccessControl,
} from "features/Permissions/AccessControl";
import {
  TBusinessOwner,
  TOFACLastCheckType,
  TPrimaryAccountHolder,
} from "../data.types";
import Header from "../manageUnderwriting/Header";
import OFACDetails from "./components/OFACDetails";
import OFACScreening from "./components/OFACScreening";
import PreviousChecks from "./components/PreviousChecks";
import {
  OFACCheckStatusType,
  useOFACCategories,
  useOFACChecks,
} from "./hooks/useOFAC";
import { TOFACTabs, useSetPage } from "./hooks/useSetPage";
import { checkPortals } from "@utils/routing";
import { Text } from "@common/Text";
import { palette } from "@palette";
import { CaretLeft } from "@phosphor-icons/react";
import { useMemo, useState } from "react";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { ofacArraySorter } from "../helpers/parsers";
import { OFACTabType, TabStatuses } from "./helpers";
import { isEmpty } from "lodash";
import { OFAC_MATCH_PANEL_WIDTH } from "../utils";

type OFACPanelProps = {
  toggleSecondModal: () => void;
  merchantId: number;
  legalEntityId: number;
  headerData: {
    name: any;
    imageURL: any;
  };
  isAcquirer: boolean;
  lastCheck: TOFACLastCheckType;
  checksListHeight?: string;
  isModal?: boolean;
  initialActiveTab?: OFACTabType;
  PAH: TPrimaryAccountHolder;
  businessOwners?: TBusinessOwner[];
  isEnterprisePanel?: boolean;
};

export const OFACPanel = ({
  toggleSecondModal,
  headerData,
  merchantId,
  legalEntityId,
  checksListHeight = "calc(100vh - 550px)",
  lastCheck,
  isAcquirer,
  isModal,
  initialActiveTab,
  PAH,
  businessOwners,
  isEnterprisePanel = false,
}: OFACPanelProps) => {
  const [activeTab, setActiveTab] = useState<OFACTabType>(
    initialActiveTab ??
      (legalEntityId ? "Business Profile" : "Primary Account Holder"),
  );
  const [tabStatuses, setTabStatuses] = useState<TabStatuses>({
    "Primary Account Holder": PAH?.ofac.lastCheckStatusName || "clear",
    "Business Profile": lastCheck.lastCheckStatusName || "clear",
    "Business Owner": businessOwners
      ? ofacArraySorter(businessOwners)[0]?.ofac.lastCheckStatusName
      : "clear",
  });
  const { page, setDefault, setOfac } = useSetPage();
  const { isAcquirerEnterprises } = checkPortals();
  const { isNewOfacTabsEnabled } = useGetFeatureFlagValues();
  const { openConversationHandler } = useConversationsModal();
  const hasNotMerchantPermission = useAppSelector(
    (state) => state.app.permissions?.["get-merchant-by-id"],
  );
  const { data: categories, isLoading: isLoadingCategories } =
    useOFACCategories();
  const [businessOwnerOrders, setBusinessOwnerOrders] = useState<any>();
  const [currentCheckId, setCurrentCheckId] = useState<number>(0);
  const [currentBusinessOwnerIndex, setCurrentBusinessOwnerIndex] =
    useState<number>(0);

  const handleOpenDetails = (checkId?: number) => {
    setOfac();
    if (checkId) setCurrentCheckId(checkId);
  };

  const composed = isNewOfacTabsEnabled
    ? composePermission(
        isAcquirerEnterprises
          ? RESOURCE_BASE.ENTERPRISE
          : RESOURCE_BASE.MERCHANT,
        RESOURCE_BASE.OFAC,
      )
    : composePermission(
        isAcquirerEnterprises
          ? RESOURCE_BASE.ENTERPRISE
          : RESOURCE_BASE.MERCHANT,
        RESOURCE_BASE.LEGAL_ENTITY,
        RESOURCE_BASE.OFAC,
      );

  const isRunOfacAllowed = useAccessControl({
    resource: composed,
    operation: OPERATIONS.CREATE,
  });
  const isListOfacAllowed = useAccessControl({
    resource: composed,
    operation: OPERATIONS.LIST,
  });
  const isEditOfacAllowed = useAccessControl({
    resource: composed,
    operation: OPERATIONS.UPDATE,
  });

  //use a fallback to avoid unexpected error
  const currentCategory: string = useMemo(() => {
    return (
      categories?.data?.find(
        (category: any) => category.DisplayName === activeTab,
      )?.name ?? "legal_entities"
    );
  }, [activeTab, categories]);

  const { data, isLoading } = useOFACChecks(
    merchantId,
    currentCategory,
    isNewOfacTabsEnabled ? undefined : legalEntityId,
    {
      enabled: isListOfacAllowed && !!categories?.data,
    },
  );

  const currentCheck = useMemo(() => {
    if (activeTab === "Business Owner") {
      const uniqueData = data?.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.resourceID === item.resourceID),
      );
      const sortOrder = {
        confirmed_match: 1,
        possible_match: 2,
        manually_cleared: 3,
        clear: 4,
      };
      if (!businessOwnerOrders || isEmpty(businessOwnerOrders)) {
        const sorted = uniqueData?.sort(
          (a, b) => sortOrder[a?.statusName] - sortOrder[b?.statusName],
        );

        const orderBOs = sorted?.reduce((acc, item, index) => {
          (acc as any)[item.resourceID] = index + 1;
          return acc;
        }, {});
        setBusinessOwnerOrders(orderBOs);

        return sorted;
      } else {
        const reusedSort = uniqueData?.sort((a, b) => {
          if (
            !businessOwnerOrders[a.resourceID] ||
            !businessOwnerOrders[b.resourceID]
          ) {
            return sortOrder[a?.statusName] - sortOrder[b?.statusName];
          } else {
            return (
              businessOwnerOrders[a.resourceID] -
              businessOwnerOrders[b.resourceID]
            );
          }
        });
        return reusedSort;
      }
    } else {
      return data && data.length > 0 ? data.slice(0, 1) : undefined;
    }
  }, [data, activeTab]);

  const detailsCheckIdDefault = currentCheck
    ? currentCheck[0]?.ID
    : currentCheckId;

  const onUpdateSuccess = (data: {
    resourceTypeName: string;
    statusName: OFACCheckStatusType;
    resourceID: string;
    updatedAt: number;
  }) => {
    if (data.resourceTypeName === "legal_entities")
      setTabStatuses((prev) => ({
        ...prev,
        "Business Profile": data.statusName,
      }));
    if (data.resourceTypeName === "merchant_owners")
      setTabStatuses((prev) => ({
        ...prev,
        "Primary Account Holder": data.statusName,
      }));
    if (data.resourceTypeName === "legal_principals") {
      const updatedBOList = businessOwners?.map((item) => {
        if (item.id === data.resourceID) {
          return {
            ...item,
            ofac: {
              lastCheckAt: data.updatedAt,
              lastCheckStatusName: data.statusName,
            },
          };
        }
        return item;
      });
      setTabStatuses((prev) => ({
        ...prev,
        "Business Owner": updatedBOList
          ? ofacArraySorter(updatedBOList as TBusinessOwner[])[0]?.ofac
              .lastCheckStatusName
          : data.statusName,
      }));
    }
  };

  const showPrevCheck =
    activeTab === "Business Owner"
      ? Boolean(data && currentBusinessOwnerIndex !== undefined && currentCheck)
      : !!data;

  const breadcrumb =
    activeTab === "Business Owner" && currentCheck
      ? currentCheck[currentBusinessOwnerIndex]?.resourceFullName || activeTab
      : activeTab;

  const UISections: Record<TOFACTabs, React.ReactNode> = {
    default: (
      <WithMissingPermissionModule
        message="Checklist hidden"
        notAuthorized={hasNotMerchantPermission}
      >
        <OFACScreening
          merchantId={merchantId}
          openDetails={handleOpenDetails}
          isRunOfacAllowed={isRunOfacAllowed}
          isEditOfacAllowed={isEditOfacAllowed}
          legalEntityId={legalEntityId}
          lastCheckBP={lastCheck}
          check={currentCheck}
          isLoading={isLoading || isLoadingCategories}
          isEnterprise={isAcquirerEnterprises}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setCurrentBusinessOwnerIndex={setCurrentBusinessOwnerIndex}
          currentBusinessOwnerIndex={currentBusinessOwnerIndex}
          businessOwners={businessOwners}
          PAH={PAH}
          tabStatuses={tabStatuses}
        />
      </WithMissingPermissionModule>
    ),
    Check: (
      <>
        {detailsCheckIdDefault && (
          <OFACDetails
            merchantId={merchantId}
            legalEntityId={legalEntityId}
            currentCheckId={currentCheckId ?? detailsCheckIdDefault}
            composed={composed}
            isEditAllowed={isEditOfacAllowed}
            activeTab={activeTab}
            tabCategory={currentCategory}
            onUpdateSuccess={onUpdateSuccess}
            isEnterprisePanel={isEnterprisePanel}
          />
        )}
      </>
    ),
  };

  return (
    <Stack
      spacing={2}
      width={OFAC_MATCH_PANEL_WIDTH}
      height="100%"
      sx={{
        "&:hover": {
          "& .MuiButtonBase-root.MuiButton-root": {
            visibility: "visible",
          },
        },
      }}
    >
      <Container
        width={OFAC_MATCH_PANEL_WIDTH}
        data-testid="ofac-panel-container"
      >
        <FadeUpWrapper
          delay={50}
          containerProps={{
            height: page === "Check" ? "100%" : undefined,
          }}
          customStyle={{
            height: page === "Check" ? "100%" : undefined,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Header
            firstPanelShowing
            toggleSecondModal={toggleSecondModal}
            headerData={headerData}
            setDefault={setDefault}
            openConversationHandler={openConversationHandler}
            showConversations={isAcquirer}
            page={page === "default" ? page : breadcrumb}
            mainText="OFAC"
          >
            {isModal && page !== "default" && (
              <>
                <CaretLeft
                  onClick={setDefault}
                  size={24}
                  color={palette.neutral[80]}
                  style={{ cursor: "pointer" }}
                />

                <Text fontSize="18px" color={palette.neutral[80]}>
                  OFAC
                </Text>
              </>
            )}
          </Header>
          {UISections[page]}
        </FadeUpWrapper>
        {page === "default" && (
          <WithAccessControl resource={composed} operation={OPERATIONS.LIST}>
            {showPrevCheck && (
              <PreviousChecks
                checks={
                  activeTab === "Business Owner"
                    ? data.filter(
                        (item) =>
                          !currentCheck?.includes(item) &&
                          currentCheck &&
                          currentCheck[currentBusinessOwnerIndex]
                            ?.resourceID === item.resourceID,
                      )
                    : data.slice(1)
                }
                listHeight={checksListHeight}
                activeTab={activeTab}
              />
            )}
          </WithAccessControl>
        )}
      </Container>
    </Stack>
  );
};

const Container = styled(Box)(() => ({
  position: "relative",
  background: "rgba(251, 251, 251, 0.75)",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  borderRadius: "16px",
  boxShadow: "0px 4px 15px 0px #0000000D",
  zIndex: 1,
  padding: "16px",
}));
