import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { selectUser } from "@redux/slices/auth/auth";
import {
  selectConversationTopic,
  selectReplies,
  selectThreads,
  setConversationTopic,
  setTemporaryThreads,
  setThreadError,
  setTmpReplyMessages,
} from "@redux/slices/conversations";
import { customInstance } from "@services/api";
import { useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as Yup from "yup";
import { TComment } from "../types";
import { parseInput } from "../utils/functions";

import DOMPurify from "dompurify";

export type ConversationFormInput = {
  conversationInput: string;
};

export const schema = Yup.object().shape({
  conversationInput: Yup.string(),
});

type TThreadPayload = {
  subjectAccID: number;
  topicID: number;
  message: {
    body: string;
    mentions: Array<number>;
  };
  challengeID?: number;
};

const extractMentions = (input: string): number[] => {
  const regex = /user:__(\d+)__/g;
  const matches: RegExpMatchArray | null = input.match(regex);

  const mentionsArray: number[] = matches
    ? matches.map((match) => parseInt(match.replace(/user:__|__/g, ""), 10))
    : [];
  return mentionsArray;
};

export const useCreateMessage = ({ merchantId }: { merchantId: number }) => {
  const {
    id: globalMerchantId,
    img,
    globalName,
    role,
    userAccID,
  } = useAppSelector(selectUser);
  const { isRepliesOpen } = useAppSelector(selectReplies);
  const { threadId, index } = useAppSelector(selectReplies);
  const threads = useAppSelector(selectThreads);
  const queryClient = useQueryClient();
  const betterImage = useMemo(
    () => (img ? img?.replace("/thumb", "") : ""),
    [img],
  );

  const dispatch = useAppDispatch();

  const methods = useForm<ConversationFormInput>({
    resolver: yupResolver(schema),
    defaultValues: { conversationInput: "" },
  });

  const {
    watch,
    formState: { isDirty },
    reset,
  } = methods;

  const {
    queryObject: {
      id,
      name,
      challengeId,
      factorId,
      escalationIds,
      paths,
      shouldHideMentionIcon,
      defaultMessage,
    },
  } = useAppSelector(selectConversationTopic);

  const createMessage = useMutation((data: TThreadPayload) => {
    const endpointBuilder = !isRepliesOpen
      ? `/merchants/${globalMerchantId}/threads`
      : `/merchants/${merchantId}/threads/${threadId}/messages`;

    const customData = !isRepliesOpen
      ? {
          ...data,
          ...(typeof challengeId === "number" && {
            challengeID: challengeId,
          }),
          ...(escalationIds && {
            escalationIDs: escalationIds,
          }),
          ...(factorId && {
            factorID: factorId,
          }),
        }
      : {
          body: data.message.body,
          mentions: data.message.mentions,
        };

    return customInstance({
      url: endpointBuilder,
      method: "POST",
      data: customData,
    });
  });

  const onSubmit: SubmitHandler<ConversationFormInput> = ({
    conversationInput,
  }) => {
    const parsedInput = parseInput(
      DOMPurify.sanitize(conversationInput, {
        ALLOWED_URI_REGEXP: /^https:\/\/[^\s]+$/i,
      }),
    );
    const mentionsArray = extractMentions(conversationInput);

    const data: TThreadPayload = {
      subjectAccID: merchantId,
      topicID: id!,
      message: {
        body: parsedInput,
        mentions: mentionsArray,
      },
    };

    const tmpThread = {
      authorAccID: userAccID,
      authorAvatarImageURL: betterImage,
      authorFirstName: globalName.firstName,
      authorLastName: globalName.lastName,
      createdAt: 0,
      id:
        threads.length > 0
          ? threads[threads.length - 1].id + 1
          : threads.length + 1,
      lastMessageAt: 0,
      messageCount: 1,
      messages: [
        {
          authorAvatarImageURL: betterImage,
          authorFirstName: globalName.firstName,
          authorLastName: globalName.lastName,
          body: parsedInput,
          createdAt: new Date().getTime() / 1000,
          id: 0,
          mentionedMembers: mentionsArray,
          threadID: 0,
          updatedAt: "",
        },
      ] as TComment[],
      ownerAccID: userAccID,
      subjectAccID: userAccID,
      topicID: 0,
      topicName: "",
      updatedAt: 0,
      isSending: true,
    };

    if (!isRepliesOpen) {
      dispatch(setTemporaryThreads([tmpThread]));
    } else {
      dispatch(
        setTmpReplyMessages({
          message: tmpThread.messages[0],
          threadIndex: index!,
        }),
      );
    }

    reset({ conversationInput: "" });

    createMessage.mutate(data, {
      onSuccess: (res) => {
        if (defaultMessage) {
          dispatch(
            setConversationTopic({
              queryObject: {
                id,
                name,
                challengeId,
                paths,
                shouldHideMentionIcon,
                defaultMessage: "",
              },
            }),
          );
        }
        if (!isRepliesOpen)
          queryClient.refetchQueries(["get-conversation-messages", id]);
        if (name === "Underwriting")
          queryClient.refetchQueries("get-challenges");
        if (name === "Risk Activity")
          queryClient.refetchQueries("risk-triggers")
      },
      onError: (res) => {
        dispatch(setThreadError());
      },
    });
  };
  return {
    onSubmit,
    methods,
    watch,
  };
};
