import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { AdvancedSettingsButton } from "./styles";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import useColumnSelection, {
  TBulkSelectAction,
} from "../hooks/useColumnSelection";
import {
  CheckboxContainer,
  CustomCheckbox,
  SettingsSectionTitle,
} from "./AdvancedSettings.atoms";

export type TAdvancedSettingsData = {
  columns: Record<string, string[]>;
  translations: Record<string, string>;
  sectionTitles: Record<string, string>;
  visibleColumns: string[];
  monthlyColumns: string[];
  showMonthlyReport: boolean;
  ofacBusinessColumns: string[];
  ofacPersonsColumns: string[];
  isExportAllowed: boolean;
  initialTimezone: string;
};

const AdvancedSettings = ({
  columns,
  translations,
  sectionTitles,
  visibleColumns,
  monthlyColumns,
  ofacBusinessColumns,
  ofacPersonsColumns,
  showMonthlyReport = false,
  isExportAllowed = false,
  initialTimezone,
}: TAdvancedSettingsData) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const {
    getCheckboxAttributes,
    updateColumn,
    bulkSelect,
    isSectionAllActive,
    columnsFilter,
    hasLastCheckbox,
    isOfacPersonSelected,
  } = useColumnSelection({
    initialColumns: columns,
    visibleColumns: visibleColumns,
    monthlyColumns: monthlyColumns,
    ofacBusinessColumns,
    ofacPersonsColumns,
    initialTimezone,
  });

  //logic to get the hidden sections
  const getHideSection = (key: string) => {
    const isMonhlyReportSectionHidden =
      !showMonthlyReport && key === "salesInfo";
    const isOfacSectionHidden = !isExportAllowed && key === "ofacInfo";
    if (isMonhlyReportSectionHidden || isOfacSectionHidden) return true;
    return false;
  };

  useEffect(() => {
    if (columnsFilter === "custom" && isCollapsed) setIsCollapsed(false);
  }, [columnsFilter]);

  if (isCollapsed) {
    return (
      <FadeUpWrapper delay={200}>
        <AdvancedSettingsButton
          background="tertiary"
          onClick={() => setIsCollapsed(false)}
        >
          Show Advanced Settings
        </AdvancedSettingsButton>
      </FadeUpWrapper>
    );
  }
  const renderCheckbox = (name: string) => {
    const { checked, disabled } = getCheckboxAttributes(name);
    const label = translations[name] || name;
    if (!isOfacPersonSelected && label === "Persons Type") return; //right now we only show the "Persons Type" checkbox when Ofac persone is selected
    return (
      <CustomCheckbox
        key={name}
        label={label}
        checked={checked}
        disabled={disabled}
        onChange={(e) => {
          updateColumn(name, e.target.checked);
        }}
      />
    );
  };

  return (
    <Stack direction="column" gap="8px" alignItems="stretch">
      <SettingsSectionTitle showDescription={hasLastCheckbox} />

      {Object.keys(columns).map((key, index) => {
        const isAllActive = isSectionAllActive(key);
        const label = isAllActive ? "Deselect all" : "Select all";
        const bulkAction: TBulkSelectAction = isAllActive
          ? "deselect"
          : "select";
        const showSection = getHideSection(key);
        if (showSection) return;
        return (
          <FadeUpWrapper key={key} delay={40 + index * 20}>
            <CheckboxContainer
              title={sectionTitles[key] || ""}
              action={{
                label,
                onClick: () => {
                  bulkSelect(bulkAction, key);
                },
              }}
            >
              {columns[key]?.map(renderCheckbox)}
            </CheckboxContainer>
          </FadeUpWrapper>
        );
      })}
    </Stack>
  );
};

export default AdvancedSettings;
