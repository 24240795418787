import { customInstance } from "@services/api";
import { useQuery } from "react-query";
import { DisputeCaseType, TDisputeData } from "../data.types";

type Props = {
  id: string;
};

const getDispute = (disputeId: string) => {
  return customInstance({
    url: `/disputes/${disputeId}`,
    method: "GET",
  });
};

const getDisputeHistory = (disputeId: string) => {
  return customInstance({
    url: `/disputes/${disputeId}/cases`,
    method: "GET",
  });
};

export const useDisputePreview = ({ id }: Props) => {
  const { data, isLoading } = useQuery(
    ["dispute-preview", id],
    async () => {
      const dispute = await getDispute(id);
      const history = await getDisputeHistory(id);

      return { dispute, history };
    },
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(id),
    },
  );

  return {
    data: { dispute: data?.dispute, history: data?.history?.data } as {
      dispute: TDisputeData;
      history: DisputeCaseType[];
    },
    isLoading,
  };
};
