import { TDocument } from "@common/FilePreview/types";
import { TMerchantDocument } from "@components/Merchants/MerchantPreview/data.types";
import { useModal } from "@ebay/nice-modal-react";
import { useDocumentPreview } from "@hooks/common/documents";
import { downloadDocument } from "@hooks/common/documents/utils";
import { Box, Divider, Stack, styled, SxProps } from "@mui/material";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { FILE_PREVIEW_MODAL } from "modals/modal_names";
import { memo, useCallback } from "react";
import { useChallengeActions } from "../../hooks/useChallengeActions";
import { TChallenge } from "../../hooks/useListChallenges";
import { SECTIONS, SUB_SECTIONS } from "../../hooks/useSectionFocusView";
import ChallengeActions from "./ChallengeActions";
import ChallengeBody from "./ChallengeBody";
import { TChallengeActions, useChallengeContext } from "./ChallengeContext";
import ChallengeHeader from "./ChallengeHeader";
import { useAppDispatch } from "@redux/hooks";
import AvatarPlaceholder from "@assets/images/avatar-placeholder.png";
import {
  setConversationTopic,
  setModalOpenConversation,
} from "@redux/slices/conversations";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import { getGlobalTopic } from "features/Minibuilders/Conversations/hooks/useConversationsModal";
import { Tooltip } from "@common/Tooltip";
import { Text } from "@common/Text";
import moment from "moment";
import { useGetMATCHReports } from "../../MATCH/hooks/useMATCHReports";

export type TChallengeStatus =
  | "open"
  | "ready_for_verification"
  | "closed"
  | "create";
export type ExtendedTChallengeStatus = TChallengeStatus | "all";

export const challengesWithCustomRejectedIcon = [
  "negative_news",
  "expected_activity",
];
const challengeKeys: { [key: string]: string[] } = {
  bank_account_verification: ["bank_account"],
  primary_account_holder_id_verification: [
    "account_owner_selfie",
    "account_owner",
  ],
};

const MATCHEnableNotifyStatuses: TChallengeStatus[] = [
  "open",
  "ready_for_verification",
];

type TAction = "confirm" | "reject";

type Props = {
  sx?: SxProps;
  message: React.ReactNode;
  challenge: TChallenge;
  needsVerification: boolean;
  hasBusinessProfile: boolean;
  merchantId: number;
  challengeFilter: ExtendedTChallengeStatus;
};

const ChallengeLayout = ({
  sx,
  message,
  challenge,
  needsVerification,
  hasBusinessProfile,
  merchantId,
  challengeFilter,
}: Props) => {
  const {
    focusOnSection,
    merchantData,
    isAcquirerPortal,
    isEnterprise,
    actionsDelayer,
  } = useChallengeContext() as TChallengeActions;

  const {
    statusName: challengeStatus,
    categoryName,
    name,
    challengeID,
    hasUnreadMessages,
    totalUsersCommentedInThread,
    userAvatarsInThread,
    threadID,
    enterpriseDisplayTitle,
    merchantDisplayTitle,
    challengeTypeName,
    createdAt,
    canNotifyMerchant,
    didMerchantReply,
    slug,
    id,
    notifyByFirstName,
    notifyByLastName,
    notifyByImageUrl,
    notifiedMessage,
  } = challenge;
  const isCustomChallenge = challengeTypeName === "enhanced_due_diligence";

  const { isDesktopView } = useCustomTheme();
  const modal = useModal(FILE_PREVIEW_MODAL);
  const dispatch = useAppDispatch();
  const { data: matchData } = useGetMATCHReports(
    slug === "underwriting_match_check"
      ? merchantData.merchantInfo.merchantID
      : undefined,
  );

  const handleClose = () => modal.remove();
  const challengeId = challengeID;
  const currentId = isCustomChallenge ? id : challengeId;

  const handleDownload = useCallback((file: TDocument | null) => {
    if (!file) return;
    downloadDocument({ fileName: file.name, fileURL: file.URL }, isDesktopView);
  }, []);

  const openActivityConversationTab = async ({
    isRejection,
  }: {
    isRejection?: boolean;
  }) => {
    const { data: topic } = await getGlobalTopic({
      topicName: "underwriting",
    });
    const activityTopic = topic?.find(
      (item: any) =>
        [item?.name, item?.Name]?.includes("underwriting") &&
        [item?.typeName, item?.Type]?.includes("activity"),
    );

    dispatch(setModalOpenConversation(true));
    return dispatch(
      setConversationTopic({
        isOpen: true,
        isOpenedFromSidePanel: false,
        numberOfUnreadMessages: 0,
        queryObject: {
          id: activityTopic?.id || activityTopic?.ID,
          name: "sds",
          tabs: "Activity",
          challengeId: currentId,
          merchantId,
          paths: [
            {
              avatars: [],
              isConversation: true,
              pathID: threadID,
              pathName: name,
              hideInputs: ["module", "subject"],
              isRejection,
            },
          ],
        },
      }),
    );
  };

  const { isEnabledConversationActivity } = useGetFeatureFlagValues();
  const { confirmAction, rejectAction, notifyMerchant, isDoingActions } =
    useChallengeActions({
      merchantId,
      challengeId: currentId,
      merchantName: merchantData.merchantInfo.merchantName,
      shouldDisplayNotifyModal: Boolean(
        merchantData.primaryAccountHolder.email,
      ),
      isEnterprise,
      challengeTypeName,
      slug,
    });

  const challengeName = isCustomChallenge
    ? name
    : isEnterprise
    ? enterpriseDisplayTitle
    : merchantDisplayTitle;

  const displayActions =
    challengeStatus === "ready_for_verification" ||
    (challengeStatus === "open" &&
      needsVerification &&
      !challengesWithCustomRejectedIcon.includes(slug)) ||
    (challengeStatus !== "closed" && isCustomChallenge && !!didMerchantReply);

  const { handlePreview } = useDocumentPreview({
    merchantID: merchantId,
    handlers: {
      handleDownload,
      handleApprove: () => {
        confirmAction();
        handleClose();
      },
      handleReject: () => {
        rejectAction();
        handleClose();
      },
    },
    isChallange: true,
    challengeDisabled:
      (challengeStatus === "open" && (needsVerification || isDoingActions)) ||
      challengeStatus === "closed",
  });

  const idDocuments =
    merchantData?.documents?.filter((item) => {
      return challengeKeys[name]?.includes(item?.attTypeName);
    }) || [];

  const handleDocumentOpenFromSections = useCallback(
    (documents: TMerchantDocument[]) => {
      handlePreview(documents[0], documents, {
        disableBackdropClick: true,
      });
    },
    [handlePreview],
  );

  const viewActionHandler = async () => {
    if (idDocuments && idDocuments.length) {
      return handleDocumentOpenFromSections(idDocuments);
    }

    if (isCustomChallenge && isEnabledConversationActivity) {
      return await openActivityConversationTab({});
    }
    focusOnSection({
      section: categoryName as SECTIONS,
      isEnterprise: Boolean(isEnterprise),
      subSection: name as SUB_SECTIONS,
      MATCHData: matchData,
    });
  };

  const disableOfacView =
    !hasBusinessProfile &&
    !merchantData.primaryAccountHolder.lastName &&
    !merchantData.primaryAccountHolder.firstName &&
    slug.includes("ofac");

  const disableBusinessProfile =
    !hasBusinessProfile &&
    (slug === "business_owner1" ||
      slug === "business_owner4" ||
      slug === "underwriting_match_check");

  const disableBusinessProfileOFACMatch =
    hasBusinessProfile &&
    merchantData.businessProfile.ofac.lastCheckStatusName ===
      "confirmed_match" &&
    (slug === "business_profile3" || slug === "business_profile1");

  const footerAction = (type: TAction, actionCallback: VoidFunction) => {
    const customId = challengesWithCustomRejectedIcon.includes(slug)
      ? challengeId
      : undefined;

    if (typeof customId !== "number") {
      actionCallback();
      return;
    }

    const { primaryActionLabel, title, body } = actionLabels[type];
    actionsDelayer({
      challengeId: customId,
      primaryActionLabel,
      title,
      body,
      fireAction: actionCallback,
      challengeTypeName,
    });
  };

  const _confirmAction = () => footerAction("confirm", confirmAction);
  const _rejectAction = () => {
    const callback = isCustomChallenge
      ? () => openActivityConversationTab({ isRejection: true })
      : () => rejectAction();
    footerAction("reject", callback);
  };

  return (
    <Stack
      direction="column"
      sx={{
        padding: "8px 0px",
        ...sx,
      }}
      gap={1.5}
      data-testid={`challenge-${challengeId}-${challengeFilter}`}
    >
      <Stack
        alignItems="flex-start"
        gap={1}
        sx={{
          maxWidth: "100%",
          padding: "16px",
          backgroundColor: "#FFF",
          boxShadow: "1px 1px 15px 0px rgba(161, 175, 180, 0.10)",
          borderRadius: "16px",
        }}
      >
        <ChallengeHeader
          challengeStatus={challengeStatus as TChallengeStatus}
          displayName={challengeName}
          challengeSlug={slug}
          isAcquirerPortal={isAcquirerPortal}
          hasUnreadMessages={hasUnreadMessages}
          userAvatarsInThread={userAvatarsInThread}
          totalUsersCommentedInThread={totalUsersCommentedInThread}
          threadId={threadID}
          challengeId={
            threadID === 0 && totalUsersCommentedInThread === 0
              ? challengeId
              : undefined
          }
          challengeTypeName={challengeTypeName}
        />

        <Stack paddingLeft={5} sx={{ gap: "16px", width: "100%" }}>
          <ChallengeBody
            message={message}
            threadId={threadID}
            challengeTypeName={challengeTypeName}
            merchantID={merchantId}
          />
          <ChallengeActions isReadyForConfirmation={displayActions}>
            <Stack direction="row" alignItems="center" gap={2}>
              <ChallengeActions.ViewActions
                viewAction={viewActionHandler}
                challengeId={challengeId}
                isFileAttached={!!idDocuments?.length}
                shouldDisableViewButtonBusinessProfile={disableBusinessProfile}
                shouldDisableViewOFAC={disableOfacView}
                shouldDisableBusinessProfileOFACMatch={
                  disableBusinessProfileOFACMatch
                }
                slug={slug}
              />
              {(canNotifyMerchant || isCustomChallenge) && (
                <CustomToolTip
                  createdAt={createdAt}
                  isCustomChallenge={isCustomChallenge}
                  name={`${notifyByFirstName} ${notifyByLastName}`}
                  notifyByImageUrl={notifyByImageUrl}
                  notifiedMessage={notifiedMessage}
                >
                  <ChallengeActions.NotifyButton
                    notifyMerchant={notifyMerchant}
                    isEnterprise={isEnterprise}
                    disabled={
                      slug === "underwriting_match_check"
                        ? !hasBusinessProfile ||
                          !MATCHEnableNotifyStatuses.includes(
                            challengeStatus as TChallengeStatus,
                          )
                        : challengeStatus !== "open"
                    }
                    {...(isCustomChallenge && {
                      // All EDD challenges should have Notified Merchant text
                      isMerchantNotified: true,
                    })}
                  />
                </CustomToolTip>
              )}
            </Stack>
            {displayActions && (
              <ChallengeActions.RejectApprove
                confirmAction={_confirmAction}
                rejectAction={_rejectAction}
                rejectHidden={challenge.slug === "underwriting_match_check"}
                isDoingActions={isDoingActions}
                needsVerification={
                  challengeStatus === "open" && needsVerification
                }
              />
            )}
          </ChallengeActions>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default memo(ChallengeLayout);

const ItemsContainer = styled(Box)(() => ({
  width: "355px",
  padding: "0px 16px",
  justifyContent: "space-between",
  display: "flex",
  flexDirection: "column",
}));

const CustomToolTip = ({
  isCustomChallenge,
  createdAt,
  children,
  name,
  notifiedMessage,
  notifyByImageUrl,
}: any) => {
  return (
    <Tooltip
      disableHoverListener={!isCustomChallenge}
      maxWidth="355px"
      title={
        <ItemsContainer>
          <Text
            textAlign="left"
            fontWeight="book"
            fontSize="12px"
            color="#575353"
            mb="8px"
          >
            Notified by
          </Text>
          <Stack
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
          >
            <Stack
              gap="8px"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                src={
                  notifyByImageUrl
                    ? `${notifyByImageUrl}/thumb`
                    : AvatarPlaceholder
                }
                component="img"
                width="24px"
                height="24px"
                borderRadius="50%"
              />
              <Text color="#403D3D" fontSize="14px" fontWeight="book">
                {name}
              </Text>
            </Stack>
            <Text fontWeight="book" fontSize="12px" color="#8F8F8F">
              {moment.unix(createdAt).format("DD MMM YYYY, HH:mm")}
            </Text>
          </Stack>
          <Divider sx={{ my: "8px", bgcolor: "#E1E1DE" }} />
          <Text
            textAlign="left"
            fontWeight="book"
            fontSize="12px"
            color="#575353"
          >
            Message
          </Text>
          <Text
            textAlign="left"
            fontWeight="book"
            fontSize="12px"
            color="#8F8F8F"
          >
            {notifiedMessage}
          </Text>
        </ItemsContainer>
      }
      bgColor="#FFFFFF"
    >
      {children}
    </Tooltip>
  );
};

type Tkeys = "primaryActionLabel" | "title" | "body";
type TLabels = Record<Tkeys, string>;

const actionLabels: Record<TAction, TLabels> = {
  confirm: {
    primaryActionLabel: "Confirm",
    title: "Confirm Challenge?",
    body: "Are you sure you want to confirm this challenge? This action cannot be undone.",
  },
  reject: {
    primaryActionLabel: "Reject",
    title: "Reject Challenge?",
    body: "Are you sure you want to reject this challenge? This action cannot be undone.",
  },
};
