import { getJsonValue } from "../utils";

export const getCustomBaseSwitchRootStyles = (
  mode: "light" | "dark",
) => ({
  width: 40,
  height: 20,
  padding: 0,
  overflow: "visible",

  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    borderRadius: 30,
    width: 20,
    height: 16,
    margin: 2,
  },

  "& .MuiSwitch-track": {
    borderRadius: 20 / 2,
    backgroundColor: getJsonValue(
      `tokens.${mode}.primitive.transparent.darken-10`
    ),
    width: 40,
    height: 20,
    opacity: 1,
  },

  "& .MuiSwitch-switchBase": {
    padding: 0,
    transitionDuration: "300ms",

    "&.Mui-checked": {
      color: getJsonValue(
        `tokens.${mode}.colour.text.remain-white`
      ),
      transform: "translateX(calc(40px - 20px - 5px))",
      
      "& + .MuiSwitch-track": {
        backgroundColor: getJsonValue(
          `tokens.${mode}.primitive.success.50`,
        ),
        opacity: 1,
        border: 0,
        "&::after": {
          backgroundColor: getJsonValue(
            `tokens.${mode}.primitive.success.50`,
          ),
        },
      },

      "&:hover + .MuiSwitch-track": {
        backgroundColor: getJsonValue(
          `tokens.${mode}.primitive.success.100`
        ),
      },

      "&.Mui-disabled .MuiSwitch-thumb": {
        boxShadow: "none",
        backgroundColor: getJsonValue(
          `tokens.${mode}.colour.text.remain-white`
        ),
        opacity: 0.6
      },

      "&.Mui-disabled + .MuiSwitch-track": {
        boxShadow: "none",
        backgroundColor: getJsonValue(
          `tokens.${mode}.primitive.success.50`,
        ),
        opacity: 1,
        "&::after": {
          backgroundColor: getJsonValue(
            `tokens.${mode}.primitive.success.50`,
          ),
        },
      },
    },

    "&.Mui-disabled .MuiSwitch-thumb": {
      boxShadow: "none",
      opacity: 0.6,
      backgroundColor: getJsonValue(
        `tokens.${mode}.colour.text.remain-white`
      ),
    },

    "&.Mui-disabled + .MuiSwitch-track": {
      boxShadow: "none",
      backgroundColor: getJsonValue(
        `tokens.${mode}.primitive.transparent.darken-5`
      ),
      opacity: 1,
    },
  },
});
