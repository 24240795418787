import React from "react";
import { palette } from "@palette";
import { FormProvider } from "react-hook-form";
import NiceModal, { muiDialogV5 } from "@ebay/nice-modal-react";
import Grid from "@mui/material/Grid";
import { Box, Stack } from "@mui/material";
import { Text } from "@common/Text";
import { Modal } from "@common/Modal";
import { RHFInput } from "@common/Input";
import { RHFSelect } from "@common/Select";
import useCreateBankAccount from "hooks/merchant-api/manage-money/useCreateBankAccount";
import { ArrowBackIcon } from "@assets/icons";
import { Tag } from "@common/Tag";
import { UploadFile } from "@components/UploadFile";
import { IDropzoneProps } from "react-dropzone-uploader";
import CampaignModalActions from "@common/Modal/CampaignModalActions";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import { UploadFileDocumentPreviewItem } from "@components/UploadFile/Rebranded/UploadFileDocumentPreviewItem";
import { useGetBankFiles } from "@hooks/merchant-api/BankAccounts/useGetBankFiles";
import { deleteDocument } from "@hooks/common/documents/utils";
import { useGetCurrentMerchantId } from "@hooks/common";
import { useQueryClient } from "react-query";
import useMasqueradeReducer from "@hooks/Reducers/useMasqueradeReducer";
import { BankProviderText } from "@components/BankAccounts";
import { gridItemsRenderer } from "@utils/rendering/nodesRenderers";
import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import { IMerchantBankAccount } from "@components/Merchants/MerchantPreview/data.types";
import { UploadFileNew } from "@components/UploadFile/Rebranded/UploadFileNew";
import { AcceptAllowedGeneralDocumentsTypes } from "@hooks/upload-api/uploadHooks";
import { useGetFeatureFlagValues } from "FeatureFlags/useGetFeatureFlagValues";
import FlaggedWrapper from "FeatureFlags/FlaggedWrapper";
import { FeatureFlagKeys } from "FeatureFlags/featureFlagKeys";

type NewAccountProps = {
  submitHandler?: () => void;
  data?: IMerchantBankAccount;
  closeAction: () => false | void;
  isDefault?: boolean;
};

const NewAccount = NiceModal.create(
  ({ submitHandler, data, closeAction, isDefault }: NewAccountProps) => {
    const { modal, open, SlideProps } = useNiceModal();
    const { isMobileView } = useCustomTheme();
    const { merchantId, selectedUser } = useGetCurrentMerchantId();
    const { isMasqueradeMode } = useMasqueradeReducer();
    const { isFileUploadRefactorEnabled } = useGetFeatureFlagValues();
    const { methods, onSubmit, isDisabled, isUploadAllowed } =
      useCreateBankAccount({
        submitHandler,
        data,
        closeAction,
        isDefault,
      });

    const { data: bankAcFiles, isLoading: isGettingBankFiles } =
      useGetBankFiles({
        bankAccountId: data?.id,
        enabled: !!data?.id,
        merchantId: isMasqueradeMode ? merchantId : selectedUser?.id,
      });
    const queryClient = useQueryClient();

    const {
      watch,
      setValue,
      getValues,
      formState: { isDirty },
    } = methods;
    const values = watch();
    const isApproved = data?.status === "approved";

    const hideModal = () => {
      if (!isFileUploadRefactorEnabled) {
        values?.files?.allFiles?.forEach((file) => file?.remove());
      }
      queryClient.removeQueries("get-bank-files");
      modal.remove();
    };

    const normalizeInput = (value: string) => {
      if (!value) return value;
      const currentValue = value.replace(/[^\d]/g, "");

      return currentValue;
    };
    const handleUpload = (file: File) => {
      const currFiles = getValues("files")?.allFiles || [];
      setValue(
        "files",
        {
          allFiles: [{ file, id: file.name }, ...currFiles],
        } as any,
        {
          shouldDirty: true,
        },
      );
    };
    const handleChangeStatus: IDropzoneProps["onChangeStatus"] = (
      fileWithMeta,
      status,
      allFiles,
    ) => {
      setValue(
        "files",
        {
          fileWithMeta,
          status,
          allFiles,
        } as any,
        {
          shouldDirty: true,
        },
      );
    };
    const deleteFileHandlerOld = (id: string | number) => {
      const newIndex = values.files.allFiles.findIndex(
        (item) => item.meta.id === id,
      );
      values.files.allFiles[newIndex]?.remove();
      setValue("files", {
        allFiles: values.files.allFiles.filter((item) => item.meta.id !== id),
      } as any);
    };

    const deleteFileHandlerNew = (index: string | number) => {
      setValue("files", {
        allFiles: values.files.allFiles.filter((_, i) => i !== index) as any[],
      } as any);
    };

    React.useEffect(() => {
      !data &&
        setValue("accountNumber", normalizeInput(watch("accountNumber")));
    }, [watch("accountNumber")]);

    React.useEffect(() => {
      setValue("routingNumber", normalizeInput(watch("routingNumber")));
    }, [watch("routingNumber")]);

    const inputList = [
      {
        node: (
          <RHFSelect
            name="accountType"
            placeholder="Select an account"
            label="Account type"
            disabled={isApproved}
            options={[
              {
                value: "checking",
                label: "Checking",
              },
              {
                value: "savings",
                label: "Savings",
              },
            ]}
          />
        ),
        sm: 4,
      },
      {
        node: (
          <RHFInput
            name="name"
            fullWidth
            placeholder="Business name on account"
            type="text"
            label="Business name on account"
            disabled={isApproved}
          />
        ),
        sm: 8,
      },
      {
        node: (
          <>
            <RHFInput
              name="routingNumber"
              fullWidth
              placeholder="Enter routing number"
              type="text"
              label="Routing number"
              inputProps={{ maxLength: 9 }}
            />
            <BankProviderText routingNumber={values.routingNumber} />
          </>
        ),
        sm: 4,
      },
      {
        node: (
          <RHFInput
            name="accountNumber"
            fullWidth
            placeholder="Enter account number"
            type="text"
            label="Account number"
            disabled={isApproved}
          />
        ),
        sm: 8,
      },
      {
        node: (
          <>
            <UploadDisclaimer />
            {/** -------- Upload Icon -------- */}
            <Box mb={1} mt={2.5}>
              <FlaggedWrapper
                ActiveComponent={
                  <UploadFileNew
                    accept={AcceptAllowedGeneralDocumentsTypes}
                    disabled={!isUploadAllowed}
                    multiple
                    uploadFunction={handleUpload}
                  />
                }
                FallbackComponent={
                  <UploadFile
                    disabled={!isUploadAllowed}
                    backgroundColor="white"
                    onChangeStatus={handleChangeStatus}
                    width="100%"
                  />
                }
                name={FeatureFlagKeys.FILE_UPLOAD_TECH_REFACTOR_KEY}
              />
            </Box>

            <Stack direction="column" gap={0.5}>
              {values.files.allFiles.map((props, index) => {
                return (
                  <UploadFileDocumentPreviewItem
                    key={isFileUploadRefactorEnabled ? index : props.meta.id}
                    fileName={
                      isFileUploadRefactorEnabled
                        ? props.file.name
                        : props.meta.name
                    }
                    id={isFileUploadRefactorEnabled ? index : props.meta.id}
                    deleteDocument={
                      isFileUploadRefactorEnabled
                        ? deleteFileHandlerNew
                        : deleteFileHandlerOld
                    }
                    fileURL={
                      isFileUploadRefactorEnabled
                        ? URL.createObjectURL(props.file)
                        : props.meta.previewUrl
                    }
                    merchantId={merchantId}
                    list={values.files.allFiles}
                    isUploaded={props?.isUploaded}
                  />
                );
              })}

              {bankAcFiles?.data?.map(
                ({ id, fileName, fileURL, isUploaded }: any) => {
                  if (values?.deletedFiles?.some((fileId) => fileId === id))
                    return null;

                  return (
                    <UploadFileDocumentPreviewItem
                      key={id}
                      fileName={fileName}
                      id={id}
                      deleteDocument={() => {
                        deleteDocument(
                          merchantId,
                          { id, fileName },
                          () => {
                            setValue(
                              "deletedFiles",
                              [...values.deletedFiles, id],
                              { shouldDirty: true },
                            );
                          },
                          { isTmpFile: true },
                        );
                      }}
                      fileURL={fileURL}
                      merchantId={merchantId}
                      list={bankAcFiles?.data}
                      isUploaded={isUploaded}
                    />
                  );
                },
              )}
            </Stack>
          </>
        ),
        sm: 12,
        hidden: isApproved,
      },
      {
        node: (
          <RHFInput
            name="notes"
            fullWidth
            placeholder="Enter notes about account..."
            type="text"
            label="Notes (optional)"
            multiline
            rows={7}
            inputProps={{
              maxLength: "200",
            }}
            helperText={`${
              200 - values.notes?.length || 0
            } Characters remaining`}
          />
        ),
        sm: 12,
      },
    ];

    const isSubmitDisabled = !isDirty || isDisabled;

    if (isMobileView) {
      return (
        <ModalFactory
          variant="dialog"
          renderMobile
          modalProps={{
            open,
            onClose: hideModal,
            DrawerProps: {
              SlideProps,
            },
          }}
        >
          <Stack direction="column" gap="24px" alignItems="center">
            <ModalTitle title={`${data ? "Edit" : "Add"} bank account`} />
            <FormProvider {...methods}>
              <Grid
                container
                columnSpacing={1.5}
                rowSpacing={2}
                id="newAccount"
                component="form"
                direction="row"
                alignItems="flex-start"
                onSubmit={methods.handleSubmit(onSubmit)}
              >
                {gridItemsRenderer(inputList)}
              </Grid>
            </FormProvider>
            <ModalActions
              fullWidth
              primaryAction={{
                form: "newAccount",
                label: data ? "Save" : "Add",
                disabled: isSubmitDisabled,
              }}
              secondaryAction={{
                label: data ? "Discard changes" : "Close",
              }}
            />
          </Stack>
        </ModalFactory>
      );
    }

    return (
      <Modal
        {...muiDialogV5(modal)}
        width="780px"
        headerComponent={
          <ModalTitle
            title={`${data ? "Edit" : "Add"} bank account`}
            onClose={hideModal}
            padding="24px 24px 0px 24px"
          />
        }
        onClose={hideModal}
        backgroundColor={palette.liftedWhite.main}
        actions={
          <CampaignModalActions
            isShowDiscard
            isNotFullWidth
            direction="row"
            form="newAccount"
            isPrimaryActionDisabled={isSubmitDisabled}
            isSecondaryActionDisabled={false}
            primaryBtnLabel="Save"
            handleDiscard={hideModal}
            discardBtnLabel={
              <Box gap={1.5} display="flex" alignItems="center">
                <ArrowBackIcon />
                <Text color={palette.neutral[600]}>Back to bank accounts</Text>
              </Box>
            }
          />
        }
        sx={{
          "& .MuiDialogContent-root": {
            padding: "24px 24px 16px 24px !important",
          },
        }}
      >
        <FadeUpWrapper delay={30}>
          <Stack gap={2} direction="row" sx={titleTagStyle}>
            <Text
              fontSize={18}
              variant="headline"
              fontWeight="book"
              lineHeight="21.6px"
              color={palette.neutral[80]}
            >
              Enter bank account details
            </Text>
            {data && <Tag shouldShowIcon type={data?.status} />}
          </Stack>
        </FadeUpWrapper>
        <FormProvider {...methods}>
          <Grid
            container
            columnSpacing={1.5}
            rowSpacing={3}
            id="newAccount"
            component="form"
            direction="row"
            alignItems="flex-start"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            {gridItemsRenderer(inputList)}
          </Grid>
        </FormProvider>
      </Modal>
    );
  },
);

const UploadDisclaimer = () => {
  return (
    <>
      <Text
        fontSize={18}
        variant="headline"
        fontWeight="book"
        lineHeight="21.6px"
        color={palette.neutral[80]}
      >
        Bank account documents
      </Text>
      <Text
        fontSize={14}
        variant="headline"
        fontWeight="book"
        lineHeight="16.8px"
        color={palette.neutral[70]}
      >
        Please upload a non blurry photo of the latest bank statement of your
        bank account. The name on the account, account number and address must
        be clearly displayed and match the information below.
        <br /> Scanned copies will not be accepted.
      </Text>
    </>
  );
};

const titleTagStyle = {
  alignItems: "center",
  mb: 1.5,
  justifyContent: "flex-start",
  "@media (max-width: 600px)": {
    justifyContent: "space-between",
  },
};

export default NewAccount;
