import { ModalActions, ModalTitle } from "@common/Modal/ModalFactory/atoms";
import useNiceModal from "@common/Modal/ModalFactory/hooks/useNiceModal";
import ModalFactory from "@common/Modal/ModalFactory/ModalFactory";
import NiceModal from "@ebay/nice-modal-react";
import { useCustomTheme } from "@theme/hooks/useCustomTheme";
import { UploadFile } from "@components/UploadFile";
import BulkImportContent from "./BulkImportContent";
import UploadedItem from "./UploadedItem";
import useCsvImport from "../hooks/useCsvImport";
import { TImportedRow } from "../types";

const BulkImport = ({
  handleImport,
}: {
  handleImport: (data: TImportedRow[]) => void;
}) => {
  const { open, onClose, SlideProps, TransitionProps } = useNiceModal();
  const { isMobileView } = useCustomTheme();
  const { handleChangeStatus, handleReplace, uploadedFile, importedRows } =
    useCsvImport();

  const handleSubmit = () => {
    handleImport(importedRows);
    onClose();
  };

  return (
    <ModalFactory
      variant="dialog"
      modalProps={{
        open,
        onClose,
        SlideProps,
        DialogProps: {
          PaperProps: {
            sx: {
              padding: "24px 24px 16px",
              "& div:first-of-type": {
                overflow: "visible",
              },
            },
          },
          TransitionProps,
        },
      }}
    >
      <ModalTitle
        title="Import Merchants by CSV"
        textStyles={{
          title: {
            fontSize: "18px",
            lineHeight: "21.6px",
            letterSpacing: "-0.01em",
          },
        }}
        closeButtonSize={22}
        onClose={onClose}
      />
      <BulkImportContent>
        {uploadedFile ? (
          <UploadedItem
            fileName={uploadedFile.name}
            actionHandler={handleReplace}
          />
        ) : (
          <UploadFile
            backgroundColor="white"
            onChangeStatus={handleChangeStatus}
            width="100%"
            maxFiles={1}
            accept="text/csv"
            supportedFormatText=".csv"
            maxSizeInBytes={1000 * 1000 * 2}
            customText="CSV format required, max 1,000 merchants"
          />
        )}
      </BulkImportContent>
      <ModalActions
        animationDelay={80}
        containerProps={{
          sx: {
            paddingTop: "20px",
            ...(isMobileView && {
              flexDirection: "column-reverse",
            }),
          },
        }}
        secondaryAction={{
          label: "Cancel",
          onClick: onClose,
          sx: {
            fontSize: "18px",
            lineHeight: "21.6px",
            padding: "12px 24px",
          },
        }}
        primaryAction={{
          type: "button",
          onClick: handleSubmit,
          label: "Import",
          disabled: !uploadedFile,
          sx: {
            fontSize: "18px",
            lineHeight: "21.6px",
            padding: "12px 24px",
          },
        }}
      />
    </ModalFactory>
  );
};

const BulkImportModal = NiceModal.create(BulkImport);
export default BulkImportModal;
