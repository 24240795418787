import React, { memo, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import PatternFormat from "react-number-format";
import { useFormContext, Controller } from "react-hook-form";
import { navigationKeys } from "@constants/constants";
import { Text } from "@common/Text";
import DropdownIcon from "@assets/icons/dropDownIcon";
import { TextField, styled } from "@mui/material";
import { isEmpty } from "lodash";
import { palette } from "@palette";
interface Props {
  taxIdName?: string;
  ssnName?: string;
  businessTypeName?: string;
  disabled?: boolean;
  isBusiness?: boolean;
  tinType?: string;
  helperText?: string;
}

function CustomTaxSsnInput({
  ssnName = "ssn",
  taxIdName = "taxId",
  businessTypeName = "",
  disabled = false,
  isBusiness = true,
  tinType = "tinType",
  helperText,
}: Props) {
  const {
    control,
    setValue,
    watch,
    formState: { isDirty },
  } = useFormContext();
  const values = watch();

  const [open, setOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  let presSelected = "";
  if (accessObjectProperty(values, tinType) === options.SSN.toLowerCase()) {
    presSelected = options.SSN;
  } else {
    if (!isEmpty(accessObjectProperty(values, taxIdName)) || isBusiness) {
      presSelected = options.EIN;
    } else {
      presSelected = options.SSN;
    }
  }

  const [selectValue, setSelectValue] = useState(presSelected);

  const isSoleProp =
    accessObjectProperty(values, businessTypeName) === sole_proprietorship;
  const isChangeAllowed = (isBusiness && isSoleProp) || !isBusiness;

  const isSSN = selectValue === options.SSN;
  const handleChange = (event: any) => {
    setSelectValue(event.target.value);
    setValue(ssnName, "", { shouldDirty: true });
    setValue(taxIdName, "", { shouldDirty: true });
    setIsHovered(false);
  };

  const handleKeyDown = (event: any, value: string) => {
    const key = event.key;
    const count = isSSN ? 11 : 10;
    const isMaxLength = value?.replace(/\s/g, "").length === count;

    if (!navigationKeys.some((item) => item === key) && isMaxLength) {
      event.preventDefault();
    }
  };

  const businessType = accessObjectProperty(values, businessTypeName);

  useEffect(() => {
    // reset ein/ssn dropdown and taxid value if business type is changed
    if (isDirty && businessType) {
      setValue(ssnName, "", { shouldDirty: true });
      setValue(taxIdName, "", { shouldDirty: true });
      if (businessType === sole_proprietorship) {
        setSelectValue(options.SSN);
      } else {
        setSelectValue(options.EIN);
      }
    }
  }, [businessType]);

  useEffect(() => {
    if (isDirty) return;
    setSelectValue((prev) => {
      if (prev != presSelected) {
        return presSelected;
      }
      return prev;
    });
  }, [values, presSelected, isDirty]);

  useEffect(() => {
    if (isSSN) {
      setValue(tinType, "ssn");
    } else {
      setValue(tinType, "ein");
    }
  }, [isSSN, tinType]);

  const inputName = isSSN ? ssnName : taxIdName;

  return (
    <Controller
      name={inputName}
      key={inputName}
      control={control}
      render={({ field: { ref, ...rest }, fieldState: { error } }) => {
        const borderColor = error
          ? palette.filled.red
          : isFocused
          ? `${palette.gray[300]}`
          : isHovered && !disabled
          ? `${palette.gray.main}`
          : `${palette?.liftedWhite[200]}`;

        return (
          <>
            <Box
              width="100%"
              sx={{
                display: "flex",
                alignItems: "center",
                borderRight: "none",
              }}
              onMouseOver={() => setIsHovered(true)}
              onMouseOut={() => setIsHovered(false)}
            >
              <FormControl
                sx={{
                  minWidth: "100px",
                  borderRadius: 0,
                  "& fieldset": { border: "none" },
                }}
              >
                <Select
                  open={open}
                  onClose={() => setOpen(false)}
                  onOpen={() => setOpen(true)}
                  value={selectValue}
                  onChange={handleChange}
                  disabled={disabled || !isChangeAllowed}
                  label="Select"
                  sx={{
                    bgcolor: "#F8F8F6",
                    ...(!disabled && {
                      "&:hover": {
                        bgcolor: `${palette.neutral[20]} !important`,
                      },
                    }),
                    border: `2px solid ${borderColor}`,
                    height: "54.13px",
                    borderRightWidth: "1px !important",
                    borderRadius: "8px 0 0 8px",
                    "& .MuiSelect-select": {
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      paddingRight: "8px !important",
                      paddingLeft: "8px !important",
                      width: "auto",
                      minWidth: "60px",
                    },
                  }}
                  IconComponent={() => (
                    <Box
                      sx={{
                        cursor: disabled ? "default" : "pointer",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={() =>
                        !disabled && isChangeAllowed && setOpen((prev) => !prev)
                      }
                    >
                      <DropdownIcon
                        width={11.5}
                        height={8.5}
                        fill={palette.gray[300]}
                      />
                    </Box>
                  )}
                >
                  {Object.values(options).map((text) => (
                    <MenuItem
                      key={text}
                      value={text}
                      sx={{ display: text === selectValue ? "none" : "block" }}
                    >
                      <Text
                        color={palette.black[100]}
                        fontWeight="regular"
                        textAlign="left"
                        fontSize="14px"
                      >
                        {text}
                      </Text>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Box flex={4} sx={{ height: "54.13px" }}>
                <PatternFormat
                  className="taxid-input"
                  format={isSSN ? "###-##-####" : "##-#######"}
                  fullWidth
                  onKeyDown={(event) => handleKeyDown(event, rest.value)}
                  customInput={CustomTextField}
                  pattern="\d*"
                  placeholder={isSSN ? "XXX-XX-XXXX" : "XX-XXXXXXX"}
                  {...rest}
                  ref={(inputRef) => {
                    ref({
                      ...inputRef,
                      focus: () => setIsFocused(true),
                    });
                  }}
                  error={!!error}
                  disabled={disabled}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  sx={{
                    height: "100%",
                    letterSpacing: "6px",
                    "& .MuiFormHelperText-root": {
                      letterSpacing: "0px",
                    },
                    "&  .MuiInputBase-root": {
                      borderRadius: "0 8px 8px 0",
                      borderLeft: "none",
                    },
                    "& .css-1a7prda-MuiInputBase-root-MuiOutlinedInput-root": {
                      padding: 0,
                      paddingLeft: "5px",
                    },
                    "& .MuiInputBase-root": {
                      borderColor: borderColor,
                      height: "100%",
                    },
                    "& .MuiInputBase-root input ": {
                      marginTop: "0 !important",
                      height: "100%",
                    },
                  }}
                />
              </Box>
            </Box>
            {(error?.message || helperText) && (
              <Text
                fontWeight="regular"
                paddingInline="12px"
                fontSize="12px"
                color={palette.gray[300]}
                marginTop="4px"
                textAlign="left"
              >
                {error?.message || helperText}
              </Text>
            )}
          </>
        );
      }}
    />
  );
}

export default memo(CustomTaxSsnInput);

const options = {
  EIN: "EIN",
  SSN: "SSN",
};
function accessObjectProperty(obj: any, path: string) {
  // Split the path by dot
  const parts = path.split(".");

  // Access the property dynamically
  const result = parts.reduce((acc, part) => {
    if (acc && typeof acc === "object" && part in acc) {
      return acc[part];
    } else {
      return undefined;
    }
  }, obj);

  return result;
}

const CustomTextField = (e: any) => {
  const { watch } = useFormContext();
  const [hasTouched, setHasTouched] = useState(false);

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHasTouched(true);
    e?.onChange(event);
  };
  const values = watch();
  const isSSN = values?.tinType === "ssn";
  const digits = e.value.replace(/[^0-9]/g, "");
  const isLast4 = digits?.length === 4;
  const ssnEIN = isSSN ? `XXX-XX-${digits}` : `XX-XXX${digits}`;
  const val = isLast4 && !hasTouched ? ssnEIN : e?.value;
  return <Input {...e} value={val} onChange={handleInput} />;
};

const sole_proprietorship = "individual_sole_proprietorship";

const Input = styled(TextField)(({ disabledTextcolor }: any) => ({
  "& .MuiInputBase-root.Mui-disabled": {
    backgroundColor: palette.liftedWhite[200],
    boxShadow: "none",
    border: "none",

    "& input": {
      marginTop: "18px",
      WebkitTextFillColor: disabledTextcolor || palette.gray[300],
      color: disabledTextcolor || palette.gray[300],
    },
  },
}));
