import { customInstance } from "..";

export const getTrustedDevices = () => {
  return customInstance({
    url: `/trusted-devices`,
    method: "GET",
  });
};

export const deleteTrustedDevices = async (deviceId: number) => {
  return customInstance({
    url: `/trusted-devices/${deviceId}`,
    method: "DELETE",
  });
};
