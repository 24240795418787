import { Divider, Stack } from "@mui/material";
import TitleSection from "@components/Disputes/DisputePreview/components/TitleSection";
import DisputeCaseSummary from "@components/Disputes/DisputePreview/components/DisputeCaseSummary";
import { palette } from "@palette";
import FadeUpWrapper from "@components/animation/FadeUpWrapper";
import DisputeCaseHistory from "@components/Disputes/DisputePreview/components/DisputeCaseHistory";
import { DisputeCaseType, TDisputeData } from "../data.types";

type Props = {
  data: {
    dispute: TDisputeData;
    history: DisputeCaseType[];
  };
};

const DisputePreviewBody = ({ data }: Props) => {
  return (
    <Stack gap={2}>
      <TitleSection data={data?.dispute} />
      <Stack gap={3}>
        <DisputeCaseHistory
          data={data?.history}
          issuingBankName={data?.dispute?.issuingBankName}
        />
        <Divider color={palette.neutral[20]} />
        <FadeUpWrapper delay={100}>
          <DisputeCaseSummary data={data?.dispute} />
        </FadeUpWrapper>
      </Stack>
    </Stack>
  );
};

export default DisputePreviewBody;
