import { Button } from "@common/Button";
import { Checkbox } from "@common/Checkbox";
import { Text } from "@common/Text";
import { maskEmail } from "@hooks/auth-api/useLoginOTP";
import { Box, Popover, Stack } from "@mui/material";
import { palette } from "@palette";
import { Info } from "@phosphor-icons/react";
import { sendTfaVerificationCode } from "@services/api/2FA/tfaVerifications";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import OtpInput from "react-otp-input";
import { useMutation } from "react-query";
import LoginContainer from "./LoginContainer";

type Props = {
  onSubmit?: SubmitHandler<any>;
  email: string;
  isMobileView: boolean;
  setTmpStep2?: Dispatch<
    SetStateAction<{
      email: string;
    }>
  >;
  isError: boolean;
  stripLoginContainer?: boolean;
  handleVerify: (data: {
    token: string;
    trust: boolean;
    email: string;
    onSuccessFn: (data: {
      password: string;
      remember: boolean;
      email: string;
      termsConditions: boolean;
    }) => void;
  }) => void;
  forceUntrust?: boolean;
  cancel?: () => void;
};

const LoginOTP = ({
  onSubmit,
  email,
  isMobileView,
  setTmpStep2,
  handleVerify,
  isError,
  stripLoginContainer = false,
  forceUntrust = false,
  cancel
}: Props) => {
  const [otp, setOtp] = useState("");
  const trustDeviceRef = useRef<boolean>(true);
  const [timer, setTimer] = useState<number>(120);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const tfaVerificationsMutation = useMutation(sendTfaVerificationCode);
  const resendCode = (data: "sms" | "email") => {
    tfaVerificationsMutation.mutate("email");
  };
  const handleInfoOver = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (otp: string) => setOtp(otp);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleResendClick = () => {
    setTimer(120);
    resendCode("email");
  };

  const verify = (e: any) => {
    e.preventDefault();
    const verifyObj = {
      token: otp,
      trust: forceUntrust ? forceUntrust : trustDeviceRef.current,
      email: email,
      onSuccessFn: onSubmit && onSubmit as any,
    };
    handleVerify(verifyObj);
  };

  return (
    <LoginContainer hideFooterLogo stripLoginContainer={stripLoginContainer}>
      <Stack
        gap={3}
        sx={{
          "& input": {
            border: `solid 1px ${isError ? "#D80D0D" : "#c1c1c1"}`,
            borderRightWidth: "0px",
            "&:focus": {
              outline: 0,
            },
          },
          "& input:first-child": {
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px",
          },
          "& input:last-child": {
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px",
            borderRightWidth: "1px",
          },
        }}
      >
        <Stack gap={1.5}>
          <Text
            variant="h5"
            sx={{
              fontWeight: 300,
              lineHeight: "33.6px",
              color: "#403D3D",
            }}
            lineHeight={isMobileView ? "27px" : "46.3px"}
            fontSize={isMobileView ? 16 : 28}
            color={palette.gray[300]}
            letterSpacing={isMobileView ? "-0.2px" : "-0.32px"}
          >
            Verify your identity
          </Text>
          <Text
            variant="body"
            fontWeight={isMobileView ? "book" : "light"}
            fontSize={isMobileView ? 14 : 16}
            lineHeight={isMobileView ? "19.2px" : "21.6px"}
            color={palette.black[100]}
            sx={{
              color: "#575353",
              width: "85%",
            }}
          >
            To continue, enter your 6 digit verification code sent to{" "}
            <strong>{maskEmail(email)}</strong>
          </Text>
          {isError && (
            <Box
              sx={{
                backgroundColor: "#F5E0DF",
                padding: "12px 16px",
                borderRadius: "8px",
              }}
            >
              <Text color="#941A1A">The code you entered is invalid</Text>
            </Box>
          )}
        </Stack>

        <Stack
          alignItems="center"
          width="100%"
          gap={2}
          component="form"
          id="login-otp"
          onSubmit={verify}
        >
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={6}
            renderInput={(props) => <input {...props} />}
            inputStyle={{
              width: "48px",
              height: "48px",
              padding: "8px",
              borderRadius: "none",
              userSelect: "none",
              fontSize: "18px",
              fontWeight: 400,
            }}
            inputType="number"
            containerStyle={{
              margin: "0 auto",
            }}
          />
          {timer === 0 ? (
            <Text
              variant="body"
              fontSize={isMobileView ? 12 : 14}
              lineHeight={isMobileView ? "19.2px" : "21.6px"}
              color={palette.black[100]}
              sx={{
                color: "#8F8F8F",
                textAlign: "center",
                fontWeight: 350,
              }}
            >
              Didn&apos;t get a code?{" "}
              <span
                onClick={handleResendClick}
                style={{
                  color: "#292727",
                  cursor: "pointer",
                }}
              >
                Click to resend
              </span>
            </Text>
          ) : (
            <Text
              variant="body"
              fontSize={isMobileView ? 12 : 14}
              lineHeight={isMobileView ? "19.2px" : "21.6px"}
              color={palette.black[100]}
              sx={{
                color: "#8F8F8F",
                textAlign: "center",
                fontWeight: 350,
              }}
            >
              Code sent! You can resend in{" "}
              <span
                style={{
                  color: "#292727",
                  cursor: "pointer",
                }}
              >
                {`${Math.floor(timer / 60)}:${(timer % 60)
                  .toString()
                  .padStart(2, "0")}`}
              </span>
            </Text>
          )}
          {!forceUntrust && (
            <Stack direction="row" alignItems="center">
              <Checkbox
                label="Trust this device"
                defaultChecked={true}
                value={trustDeviceRef}
                onChange={(e, checked) => (trustDeviceRef.current = checked)}
                name="trust_device"
                sx={{
                  "& .MuiButtonBase-root.MuiCheckbox-root": {
                    height: 20,
                    "& > svg": {
                      height: 18,
                      width: 18,
                    },
                  },
                  "& .MuiFormControlLabel-root": {
                    marginRight: "8px",
                  },
                }}
              />
              <span
                onMouseEnter={handleInfoOver}
                onMouseLeave={() => {
                  setAnchorEl(null);
                }}
                style={{ cursor: "pointer", height: "16px" }}
              >
                <Info
                  style={{ cursor: "pointer" }}
                  // onMouseLeave={() => setAnchorEl(null)}
                />
              </span>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                style={{ pointerEvents: "none" }}
                PaperProps={{
                  sx: {
                    borderRadius: "8px",
                    boxShadow: "0px 3px 15px 0px #02020226",
                  },
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Stack
                  sx={{
                    color: "#575353",
                    fontSize: "12px",
                    padding: "12px 16px",
                    borderRadius: "8px",
                    width: "355px",
                    backgroundColor: "#FFFFFF",
                  }}
                  gap={1}
                >
                  <Text>Trust this device</Text>
                  <Text>
                    By checking this box, you are allowing this device to be
                    recognized as trusted. This means you won&apos;t need to
                    re-enter your credentials or complete additional
                    verification steps on this device in the future.
                  </Text>
                  <Text>
                    <b>Important</b>: If you use this device from a different
                    network or if it&apos;s shared with others, ensure it&apos;s
                    secure before trusting it. You can manage trusted devices in
                    your account &gt; Security.
                  </Text>
                </Stack>
              </Popover>
            </Stack>
          )}
        </Stack>
      </Stack>
      <Box display="flex" justifyContent="flex-end">
        <Button
          background="tertiary"
          onClick={() => setTmpStep2 ? setTmpStep2({ email: "" }) : cancel?.()}
        >
          {setTmpStep2 ? "Go Back" : "Cancel"}
        </Button>
        <Button disabled={otp.length < 6} type="submit" form="login-otp">
          Verify
        </Button>
      </Box>
    </LoginContainer>
  );
};

export default LoginOTP;
