import React from "react";
//mui
import Logo from "@assets/icons/RebrandedIcons/Logo";
import { MainLogo } from "@hooks/common/useEnterpriseLogo";
import { Box, keyframes, Stack, styled } from "@mui/material";
import Footer from "@pages/Login/Footer";

type Props = {
  children: React.ReactNode;
  hideFooterLogo?: boolean;
  stripLoginContainer?: boolean;
};

const LoginContainer = ({
  children,
  hideFooterLogo,
  stripLoginContainer,
}: Props) => {
  return (
    <StyledRoot stripLoginContainer={stripLoginContainer}>
      {!stripLoginContainer && (
        <MainLogo position="center" defaultLogo={<Logo />} />
      )}
      {
        <StyledStack gap={3} stripLoginContainer={stripLoginContainer}>
          {children}
        </StyledStack>
      }
      {!stripLoginContainer && <Footer hideLogo={hideFooterLogo} />}
    </StyledRoot>
  );
};

const StyledRoot = styled(Box, {
  shouldForwardProp: (prop) => prop !== "stripLoginContainer",
})<{ stripLoginContainer?: boolean }>(({ theme, stripLoginContainer }) => ({
  ...(!stripLoginContainer && {minHeight: "100vh"}),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  ...(!stripLoginContainer && {padding: "41px 32px 24px 32px",}),
  [theme.breakpoints.down("sm")]: {
    padding: "24px 16px",
  },
}));

const StyledStack = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "stripLoginContainer",
})<{ stripLoginContainer?: boolean }>(({ theme, stripLoginContainer }) => ({
  width: 448,
  padding: "24px 12px",
  borderRadius: 16,
  border: "2px solid #FFFFFF",
  backdropFilter: "blur(20px)",
  background: "rgba(255, 255, 255, 0.7)",
  ...(!stripLoginContainer && {boxShadow: "0px 60px 180px rgba(0, 0, 0, 0.15)"}),
  animation: `${fadeIn} 250ms ease-in-out`,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export default LoginContainer;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
